# Date recurrence series generation

namespace 'Slzr.Util', (exports, top) ->
  $ = Slzr.jQuery
  moment = Slzr.moment
  _ = modulejs.require('underscore')

  # Returns true if the week difference between dates is in the sequence
  class WeekInterval
    constructor: (start_date, interval) ->
      @start_date = moment(start_date).set(day: 0)
      @interval = interval

    matches: (check_date) ->
      date = moment(check_date).set(day: 0)
      Math.ceil(date.diff(@start_date, 'week', true)) % @interval == 0

  # Returns true if the month difference between dates is in the sequence
  class MonthInterval
    constructor: (start_date, interval) ->
      @start_date = moment(start_date).set(date: 1)
      @interval = interval

    matches: (check_date) ->
      date = moment(check_date).set(date: 1)
      Math.ceil(date.diff(@start_date, 'month', true)) % @interval == 0

  class LastDayOfMonth
    matches: (check_date) ->
      date = moment(check_date)
      date.date() == date.daysInMonth()

  exports.intervals = {WeekInterval, MonthInterval}

  # Returns a list of dates in the specified range, conforming to the specified recurrence options.
  class exports.DateRecurrence
    # First date in range
    start_date: null
    # Last date in range (inclusive)
    end_date: null
    # Array of days of week to include [0-6]
    weekdays: null
    # Recurrence type
    #  none
    #  daily (on all days through end_date, +weekdays+ option ignored)
    #  weekly (on specified +weekdays+ through end_date); note that weekly with weekdays 0-6 is same as daily, but
    #                                                     can skip weeks with interval
    #  monthly (on specified date through end_date, +weekdays+ option ignored), will be "last day of month" if
    #                                                                           start date is the last date
    #  monthly_day (on specified day of week through end_date based on start_date, +weekdays+ option ignored)
    #  yearly (on specified date through end_date, +weekdays+ option ignored)
    type: null
    # Limit to this number of dates
    limit: null
    # Interval between recurrences
    interval: 1

    # Expected options:
    # start_date: the date of the first instance
    # end_date: the end of the recurrance range
    # weekdays: array of weekdays for recurrence to fall on (0=sunday, 1=monday, etc.)
    # type: recurrence type (none, daily, weekly, monthly)
    #
    # String formatted dates should be YYYY-MM-DD
    constructor: (options) ->
      # Parse start date into a moment object
      @start_date = if typeof(options.start_date) == 'string'
        moment(options.start_date, ['YYYY-MM-DD', 'MM/DD/YYYY', 'MM/DD/YY'])
      else
        moment(options.start_date)

      @end_date = if options.end_date?
        if typeof(options.end_date) == 'string'
          moment(options.end_date, ['YYYY-MM-DD', 'MM/DD/YYYY', 'MM/DD/YY'])
        else
          moment(options.end_date)
      else
        @start_date

      @limit = if options.limit? then options.limit else 1000
      @weekdays = if options.weekdays? then options.weekdays else [0, 1, 2, 3, 4, 5, 6]
      @type = if options.type? then options.type else 'none'
      @interval = if options.interval? then options.interval else 1

    # Return dates in the range
    dates: ->
      if @type == 'none' || @type == 'once'
        # Single instance, just return start
        [@start_date.toDate()]
      else
        # Primary recurrence rule
        recurrence = moment(@start_date).recur(@end_date)
        rules = [recurrence]

        # Set up recurrence rules
        switch @type
          when 'daily'
            recurrence
              .every(@interval).day()
              .every(@weekdays).dayOfWeek()
          when 'weekly'
            recurrence
              .every(@weekdays).dayOfWeek()

            rules.push new WeekInterval(@start_date, @interval) if @interval > 1
          when 'monthly'
            if @start_date.date() == @start_date.daysInMonth()
              # Special exception: last date of months
              rules = [new LastDayOfMonth]
              rules.push new MonthInterval(@start_date, @interval) if @interval > 1
            else
              recurrence
                .every(@interval).month()
          when 'monthly_day'
            week_of_month = @start_date.monthWeekByDay();
            # If it's the 5th week, we want the last week (to move dates to 4th week)
            week_of_month = -1 if week_of_month == 4

            recurrence
              .every(@start_date.day()).dayOfWeek()
              .every(week_of_month).weeksOfMonthByDay()

            # Additional recurrence rule
            rules.push new MonthInterval(@start_date, @interval) if @interval > 1

          when 'yearly'
            recurrence
              .every(@interval).year()

        date = @start_date.clone()
        result = []

        # Loop over every possible date in the specified range, and include it
        # only if it falls on a specified weekday
        i = 0 # Prevent infinite loops
        added = 0
        while added < @limit && i < 10000 && moment(date).isSameOrBefore(@end_date)
          i++
          if _.every(rules, (rule) -> rule.matches(date))
            result.push date.toDate()
            added += 1
          date.add(days: 1)

        result
