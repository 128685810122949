modulejs.define 'registration/components/ticket_list',
  ['underscore', 'react', 'react-dnd', 'prop-types', 'registration/constants', 'registration/components/ticket_list_item'],
  (_, React, ReactDnD, PropTypes, Constants, TicketListItem) ->

    # Display the table listing ticket classes
    class TicketList extends React.Component
      @propTypes:
        tickets: PropTypes.array
        openedEditors: PropTypes.array
        header: PropTypes.bool
        emptyMessage: PropTypes.string
        noTicketsMessage: PropTypes.string
        showEmptyMessage: PropTypes.bool
        haveNoTickets: PropTypes.bool
        onChange: PropTypes.func
        onDelete: PropTypes.func
        onToggleTicketEditor: PropTypes.func

      @defaultProps:
        tickets: []
        header: true
        settings: {}
        openedEditors: []
        emptyMessage: "Drag tickets here to add to this section"
        noTicketsMessage: "There are no tickets"
        showEmptyMessage: true
        haveNoTickets: false


      render: ->

        # The widths used below must match the widths used in TicketListItem
        header = if this.props.header
          `<thead key="header">
            <tr>
              <th width="41%">Name</th>
              <th width="13%">Status</th>
              <th width="15%"># Sold</th>
              <th width="20%">Price</th>
              <th width="11%"></th>
            </tr>
          </thead>`

        contents = if this.props.haveNoTickets
          `<tbody key="notickets">
            <tr>
              <td colSpan="5">
                <a href="#" onClick={this.props.onAddTicketClass}>Create your first ticket</a>
              </td>
            </tr>
          </tbody>`
        else if this.props.tickets.length == 0
          `<tbody key="notickets">
            <tr>
              <td colSpan="5">{this.props.showEmptyMessage && this.props.emptyMessage}</td>
            </tr>
          </tbody>`
        else
          for ticket in this.props.tickets
            `<TicketListItem key={ticket.id}
                             debug={this.props.debug}
                             ticket={ticket}
                             editorOpened={this.props.openedEditors.indexOf(ticket.id) > -1}
                             settings={this.props.settings}
                             onChange={this.props.onChange}
                             onDelete={this.props.onDelete}
                             onToggleTicketEditor={this.props.onToggleTicketEditor}
                             onDragStart={this.props.onDragStart}
                             onDragFinish={this.props.onDragFinish}
                             onDragCancel={this.props.onDragCancel}
                             onDragUpdate={this.props.onDragUpdate} />`

        @props.connectGroupDragPreview `<table className="form-item-list nospace">
          {header}
          {this.props.groupHeader}
          {contents}
        </table>`

