# Remove no-js class when javascript is enabled
Slzr.jQuery(document.documentElement).removeClass('no-js')

# Fix embedded fonts in IE8
#
# Based on:
#  https://github.com/FortAwesome/Font-Awesome/issues/954#issuecomment-65414146
#  http://stackoverflow.com/a/10557782
if window.attachEvent? and !window.addEventListener?
  head = document.getElementsByTagName('head')[0]
  style = document.createElement('style')
  style.type = 'text/css'
  style.styleSheet.cssText = "html.ie8-font-fix :before,html.ie8-font-fix :after{content: '' !important;}"
  head.appendChild(style)

  htmlEl = document.documentElement;
  htmlClass = htmlEl.className;
  htmlEl.className = htmlClass + " ie8-font-fix"

  _.defer ->
    htmlEl.className = htmlClass
    head.removeChild(style)


# Various methods to run on dom ready
Slzr.jQuery ($) ->
  # Extract localist meta tags into Slzr.Meta

  Slzr.Meta = {}
  $('meta[name|="localist"]').each ->
    name = $(this).attr('name')
    content = $(this).attr('content') || ''

    # Automatically decode JSON-encoded arrays and objects
    content = $.parseJSON(content) if typeof(content) == 'string' && (content.charAt(0) == '{' || content.charAt(0) == '[')

    # Decode booleans
    content = true if content == "true"
    content = false if content == "false"
    content = null if content == ''

    name = name.substring(9) # remove "localist-" prefix
    Slzr.Meta[name] = content
    true

  $('input[placeholder], textarea[placeholder]').addPlaceholder
    class: 'input_placeholder'
    allowspaces: true
    dopass: true
    dotextarea: true
    checkafill: true

  $('a[data-action="toggle"]').live 'click', (ev) ->
    $this = $(this)
    $target = $ $this.data('target')
    $target.toggle()
    $this.hide() if $this.data('hide-me')
    $this.toggleClass($this.data('toggle-class')) if $this.data('toggle-class')
    false

  $('[data-action="toggle-class"]').live 'click', (ev) ->
    $this = $(this)
    $target = $ $this.data('target')
    $target.toggleClass $this.data('class') || $this.data('toggle-class')
    false

  $('[data-ignore]').live 'click', (event) ->
    console.log 'ignore click', event
    event.preventDefault()
    event.stopPropagation()
    false

  # Handler for displaying popup windows from links
  #
  # Expects data-popup=true, data-popup-name and data-popup-options can also be set
  $(document).delegate '[data-popup]', 'click', (ev) ->
    target = $(ev.target).closest('[data-popup]')
    href = target.attr('href')
    name = target.data('popup-name')
    options = target.data('popup-options')

    window.open href, name, options
    ev.preventDefault()

  $(document).delegate '[data-update]', 'ajax:success', (ev, data) ->
    target = $($(ev.target).data('update'))
    $(target).html(data)

  # Call the calendar item refresh
  $(document).delegate '[data-complete]', 'ajax:success', (ev, data) ->
    target = $(ev.target)
    args = target.data('complete')

    refreshItem args[0], args[1], args[2], args[3]

  # Register analytics listeners, for data-ganame and data-ga-event
  modulejs.require('slzr/analytics').registerListeners()

  # Rendering page-wide tooltips
  $('a[rel=tooltip]').tooltip(container: 'body').on 'click', (evt) ->
    if $(this).attr('href') == '#'
      evt.preventDefault()
      false
    else
      true

  # Fix accessibility of tooltip labels
  # TODO: Remove when tooltip library can be upgraded or replaced with an accessible version
  $("a[rel='tooltip']").each ->
    $this = $(this)
    
    label = $this.data('tooltip').$element.context.dataset.originalTitle
    $this.attr('aria-label', label) unless $this.attr('aria-label')
  
  $(document).onAction 'hide-notice', -> hideNotice()

  # Actiavte rich text editor lightbox from a link
  $(document).onAction 'edit-description', ->
    editor = null

    $.fancybox
      href: '#x-edit-description'
      onStart: ->
        editor = CKEDITOR.replace($('#x-text-editor')[0])
      onCleanup: ->
        editor.destroy()
        editor = null

  # Reinitialize dynamic autocompletes when ajax happens and returns
  # a html or script response
  $(document).ajaxSuccess (event, xhr, settings) ->
    response_type = xhr.getResponseHeader('content-type')

    is_script = response_type?.match(settings.contents.script)
    is_html = response_type?.match(settings.contents.html)

    Slzr.initializeAutocompletes() if is_script or is_html

