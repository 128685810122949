# Google Analytics wrapper module
#
# Primary purpose is to allow broadcasting custom events to all defined trackers, for the old ga.js,
# the slightly newer analytics.js, and the newest GA4 gtag.js

modulejs.define 'slzr/google_analytics',
  ['slzr'],
  (Slzr) ->

    # Send a command to all legacy ga.js trackers
    sendToAllGAJS = (category, action, label) ->
      if window._gaq?
        _gaq = window._gaq

        for tracker in window._gat._getTrackers()
          prefix = tracker._getName()
          prefix += '.' if prefix != ''

          try
            _gaq.push ["#{prefix}trackEvent", category, action, label]
          catch err
            console?.error "Error tracking event (#{prefix}, legacy)", category, action, label, err

      true

    # Send a command to all active analytics.js universal analytics trackers
    sendToAllAnalyticsJS = (command, rest...) ->
      if window.ga?
        ga = window.ga

        ga ->
          for tracker in ga.getAll()
            prefix = tracker.get('name')
            next if prefix == 'olark'
            prefix = '' if prefix == 't0'
            prefix += '.' if prefix != ''

            try
              ga "#{prefix}#{command}", rest...
            catch err
              console?.error "Error sending ga command (#{prefix})", command, rest...

      true

    # Send a command to all active gtag trackers
    #
    # This relies on gtag's built in behavior of sending events to all configured trackers
    sendToAllGtags = (command, category, action, label, value=undefined) ->
      if window.gtag?
        try
          gtag(command, action, {
            event_category: category,
            event_label: label,
            value: value
          })
        catch err
          console?.error "Error sending gtag command", command, action, { category, label, value }

      true

    # Track an event in all google analytics trackers
    trackGAEvent = (category, action, label, value=undefined) ->
      # GA4 gtags
      sendToAllGtags 'event', category, action, label, value
      # Universal analytics trackers
      sendToAllAnalyticsJS 'send', 'event', category, action, label, value
      # Legacy ga.js trackers
      sendToAllGAJS category, action, label

      # Legacy ga.js trackers
      if !gtag? && !window.ga? && !window._gaq?
        console?.debug "[DEV GA] Would record analytic event: ", {category: category, action: action, label: label, value: value}

      true

    # Register it on the global
    Slzr.trackGAEvent = trackGAEvent

    # Define the shared analytics event queue, and override `push` to just call `trackGAEvent`
    #
    # Events can be queued in this array by pushing a { category, action, label, value } object.
    # Any events found at initialization time will be tracked and removed automatically.
    #
    # Any method other than +push+ called on the array afterwards is not supported.
    Slzr.AnalyticsQueue ||= []
    Slzr.AnalyticsQueue.push = ({ category, action, label, value }) ->
      trackGAEvent(category, action, label, value)

    # Process any events queued before the above JS has executed
    while typeof(event = Slzr.AnalyticsQueue.shift()) != "undefined"
      trackGAEvent(event.category, event.action, event.label, event.value)

    # Exports
    return { trackGAEvent }