modulejs.define 'registration/components/checkout/cart_item',
  ['react', 'prop-types', 'registration/utilities',
   'slzr/react/form_row'],
  (React, PropTypes, Utilities,
   FormRow) ->

    class CartItem extends React.Component
      @propTypes:
        # Item details
        item: PropTypes.object
        # Currency for item
        currency: PropTypes.string

      render: ->
        currency = this.props.currency

        `<div className="ticket_class" key={this.props.item.ticket_class_id}>
          <p className="leftside">{this.props.item.quantity}x {this.props.item.name}</p>
          <p className="rightside">{Utilities.formatCurrency(this.props.item.total, currency)}</p>
        </div>`
