# Generic checkbox multiselect.
# This is a more generic version of the checkbox_multiselect (CheckboxMultiSelect). 
# That code relies mainly on all checkboxes on the form needing to be maganged.
# This code relies on data attributes and meta items being set to precisely select the checkboxes that need managing.

# Meta Items required
## multiselect_child_checkboxes_container_id, the id to show / hide the list of checkboxes
## multiselect_parent_checkbox_id, the controlling checkbox

# Data attributes required
## data-multiselect-child-checkboxes-container-id, should match multiselect_child_checkboxes_container_id meta item.
#### Surrounds the list of checkboxes to be managed. If this is not defined, list of checkboxes will display by default.
## data-multiselect-parent-checkbox-id, should match multiselect_parent_checkbox_id meta item. Add this to parent checkox
#### data-hide-unless-checked, if true, will hide the container if parent checkbox is not checked on first render
## data-multiselect-child-checkbox-parent-id, should match multiselect_parent_checkbox_id meta item. Add this to all child checkboxes to be managed

namespace 'Slzr.Page.GenericCheckboxMultiSelect', (exports, top) ->
  Slzr.jQuery ($) ->
    return unless $(document.body).is('.admin-events-edit,.admin-events-new,.admin-platforms-edit_config')

    container = $("[data-multiselect-child-checkboxes-container-id=#{Slzr.Meta.multiselect_child_checkboxes_container_id}]")
    parent_checkbox = $(":checkbox[data-multiselect-parent-checkbox-id=#{Slzr.Meta.multiselect_parent_checkbox_id}]")
    child_checkboxes = $(":checkbox[data-multiselect-child-checkbox-parent-id=#{Slzr.Meta.multiselect_parent_checkbox_id}]")
    
    # Hide the container if parent checkbox is not checked
    if parent_checkbox.prop("checked")
      container.show()
    else
      container.hide()

    # Check all child checkboxes when parent is selected
    # Uncheck all child checkboxes when parent is deselected
    parent_checkbox.on "change", (event) ->
      if parent_checkbox.prop("checked")
        container.show() if container?
        # check all checkboxes if none are previously selected
        exports.updateCheckboxes(child_checkboxes)
      else
        container.hide() if container?
    
    # Check parent check box if any child checkbox is checked
    # Uncheck parent if all child checkboxes are deslected
    child_checkboxes.on "change", (event) ->
      if child_checkboxes.filter(":checked").length > 0
        parent_checkbox.prop("checked", true)
      else
        parent_checkbox.prop("checked", false)
    
  exports.updateCheckboxes = (child_checkboxes) =>
    child_checkboxes.prop('checked', true) if child_checkboxes.filter(":checked").length < 1