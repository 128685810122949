modulejs.define 'registration/components/ticket_group_form',
  ['react', 'react-redux',
   'registration/store/actions', 'registration/utilities', 'slzr/react/form_row', 'slzr/react/form_input_group',
   'slzr/react/form_header', 'prop-types', 'slzr/react/form_theme_context'],
  (React, ReactRedux,
   Actions, Utilities, FormRow, FormInputGroup,
   FormHeader, PropTypes, FormThemeContext) ->

    class TicketGroupForm extends React.Component
      @contextType: FormThemeContext

      @propTypes:
        ticketGroup: Actions.TicketGroupShape
        # Callback when an input field changes
        onChange: PropTypes.func
        # Callback for the "Update" button, which just closes the editor
        onClose: PropTypes.func

      onInputChange: (event, name, value) =>
        field_name = if name? then name else event.target.name
        field_value = if value? then value else if event.target.type == 'checkbox' then event.target.checked else event.target.value
        this.props.onChange?(field_name, field_value, event)

      # Returns a callback function to call onInputChange with the specified name
      inputChanged: (name) =>
        (event) => this.onInputChange(event, name)

      isValid: =>
        return false if this.props.ticketGroup.name.isBlank()
        true

      render: ->
        input_id = "ticket-group-name-#{this.props.ticketGroup.id}"

        `<tr>
          <td className="edit-form" colSpan="5">
            <FormInputGroup>
              <FormRow title="Name"
                       labelFor={input_id}
                       required
                       {...this.context.textInputRow}>
                <input name="name"
                       id={input_id}
                       type="text"
                       className={this.context.textInputRow.inputClassName}
                       value={this.props.ticketGroup.name}
                       onChange={this.onInputChange}
                       autoFocus />
              </FormRow>

            </FormInputGroup>

            <div className="action-button pull-right">
              <button className="btn-color"
                      disabled={!this.isValid()}
                      onClick={this.props.onClose}>
                Save Ticket Type
              </button>
            </div>
          </td>
        </tr>`
