# Implements the header hover menus

namespace 'Slzr.Menus', (exports, top) ->
  $ = Slzr.jQuery

  # Base menu functionality
  class BaseMenu
    # Initialize the basic menu functions
    #
    # This fires menu:open and menu:close events on the anchor element when the menu is shown or hidden
    #
    # If data-menu-reparent is set to true on menu_element,
    #
    # anchor_element: the element which displays the menu
    # menu_element: the element containing the menu items (this is hidden/shown)
    constructor: (anchor_element, menu_element) ->
      @menu_element = $(menu_element)
      @anchor_element = $(anchor_element)
      @menu_shown = false
      @needs_select_fix = navigator.userAgent.match /MSIE [56]/
      @move_to_body = if @menu_element.data('menu-reparent')? then @menu_element.data('menu-reparent') else false
      @move_to_body = true if @move_to_body == 'true'
      @move_to_body = false if @move_to_body == 'false'

      # Set up initial state of menus (positioned and hidden)
      @menu_element.width(@menu_element.width() + 1)
      @positionMenu()
      @hideMenu()

      # Reposition menus if the window gets resized
      $(window).resize => @positionMenu()

      # Fix IE under select stuff
      @setupIESelectFix() if @needs_select_fix

    # Show the menu
    showMenu: ->
      # Don't show the menu on small devices (phones)
      return if document.width <= 767

      @menu_shown = true

      # Move to end of BODY, and insert marker to return
      if @move_to_body
        @original_position = $('<!-- menu placeholder -->')
        @menu_element.before(@original_position)
        @menu_element.appendTo(document.body)

      @menu_element.show()
      @positionMenu()
      $(@anchor_element).addClass('open').attr('aria-expanded', 'true')
      $(@anchor_element).trigger('menu:open', [@])
      @showIESelectFix()

    # Hide the menu
    hideMenu: ->
      @menu_shown = false
      @menu_element.hide()
      $(@anchor_element).removeClass('open').attr('aria-expanded', 'false')
      $(@anchor_element).trigger('menu:close', [@])
      @hideIESelectFix()

      if @move_to_body && @original_position
        # Move it back to the original position
        @menu_element.insertBefore(@original_position)
        @original_position.remove()
        @original_position = null


    # Setup the IE SELECT fix (for IE5/6)
    #
    # Creates an empty IFRAME that gets positioned underneath the menu
    setupIESelectFix: ->
      @ie_frame_fix ?= $('<iframe/>')
      @ie_frame_fix
        .attr({src:'javascript:false;', zsrc: 'javascript:void;', frameborder: 0, tabindex: -1})
        .css({display: 'block', position: 'absolute', zIndex: 9999})

    # Positions and displays the IE SELECT iframe shim
    showIESelectFix: ->
      if @needs_select_fix and @ie_frame_fix
        # Get the size and position of the dropdown element
        position         = @menu_element.offset()
        height           = @menu_element.outerHeight(true)
        width            = @menu_element.outerWidth(true)

        @ie_frame_fix
          .show()
          .insertBefore(@menu_element)
          .offset(position)
          .height(height)
          .width(width)

    # Hide the IE SELECT fix by removing it from DOM
    hideIESelectFix: -> $(@ie_frame_fix).hide() if @needs_select_fix

    selfHorizontalPadding: -> @menu_element.innerWidth() - @menu_element.width()

    # Size and position menu_element below the anchor item
    #
    # Options:
    #  alignment: How to horizontally align the menu relative to anchor_el ('center' or 'left')
    #  width: The width of the list element, can be 'full' to match the sizing of the dropdown-menu
    positionMenu: (options={}) ->
      options.alignment or= 'left'
      options.alignment = @menu_element.data('menu-alignment') if @menu_element.data('menu-alignment')?
      options.verticalAlignment or= 'bottom'
      options.verticalAlignment = @menu_element.data('menu-vertical-alignment') if @menu_element.data('menu-vertical-alignment')?
      options.width = @menu_element.data('menu-width') if @menu_element.data('menu-width')?
      options.textAlignment = @menu_element.data('text-alignment') if @menu_element.data('text-alignment')?
      
      menuName = @menu_element.data('dropdown-menu')

      if options.width == 'full'
        # adjust full width by subtracting padding, otherwise the element may be longer than the parent
        width = $("[data-dropdown='#{menuName}']").outerWidth() - @selfHorizontalPadding()
      else
        width = @menu_element.width()

      if options.alignment == 'center'
        my_position = 'center top'
        at_position = 'center bottom'
      else if options.alignment == 'left'
        my_position = 'left top'
        at_position = 'left bottom'
      else if options.alignment == 'right'
        my_position = 'right top'
        at_position = 'right bottom'

      # Flip top/bottom if a top-aligned menu
      if options.verticalAlignment == 'top'
        [my_position, at_position] = [at_position, my_position]

      @menu_element
        .css(position: 'absolute', textAlign: options.textAlignment)
        .position
          my: my_position
          at: at_position
          of: @anchor_element
          collision: "flipfit flip"
        .width(width)

  # Hover menu functionality
  class HoverMenu extends BaseMenu
    # Initialize a hover menu, and attach hide/show handlers to hover events
    constructor: (anchor_element, menu_element) ->
      super anchor_element, menu_element
      @hide_timer = null

      $(@anchor_element)
        .add(@menu_element)
        .mouseover(@mouseover)
        .focusin(@mouseover)
        .mouseout(@mouseout)
        .focusout(@mouseout)

    # Event handler for showing the menu
    mouseover: =>
      @showMenu() unless @menu_shown
      clearTimeout(@hide_timer)
      @hide_timer = null

    # Event handler to hide the menu
    mouseout: =>
      if @menu_shown and not @hide_timer
        @hide_timer = setTimeout(@hideTimerFired, 0)

    # Callback to hide
    hideTimerFired: =>
      @hide_timer = null
      @hideMenu()


  # Click-to-display menu
  class ClickMenu extends BaseMenu
    constructor: (anchor_element, menu_element) ->
      super anchor_element, menu_element

      $(@anchor_element)
        .add(@menu_element)
        .click(@click)

    # Event handler for toggling menu
    click: (ev) =>
      if @menu_shown
        # Defer a moment, to allow Firefox to activate a file INPUT
        setTimeout (=> @hideMenu()), 0
        $(document).off '.clickMenu'
        true
      else
        @showMenu()

        # Attach handler for clicks elsewhere on the page
        child_elements = $(@anchor_element).add(@menu_element).add(@anchor_element.children()).add(@menu_element.children())
        $(document).on 'click.clickMenu', (evt) =>
          if $(evt.target).not(child_elements).length > 0
            # Defer the hide a moment to fix Firefox
            setTimeout (=> @hideMenu()), 0

  # Make everything available globally
  for key, value of {
    BaseMenu: BaseMenu,
    HoverMenu: HoverMenu,
    ClickMenu: ClickMenu
  }
    exports[key] = value

Slzr.jQuery ($) ->
  # Automatically enable any hover menus
  $('.hover_menu').each ->
    new Slzr.Menus.HoverMenu(@, $("##{@id}-list"))

  # Automatically enable click menus
  $('[data-dropdown]').each ->
    menu_name = $(@).data('dropdown')
    menu_contents = $("[data-dropdown-menu='#{menu_name}']")
    new Slzr.Menus.ClickMenu(@, menu_contents)

  # Fix IE z-index issues with the hover menus, by forcing a high z-index on all the element's
  # ancestors
  if navigator.userAgent.match /MSIE [567]/
    $('.action_menu, .action_menu_items').each ->
      $(this).parents().each ->
        p = $(this)
        pos = p.css 'position'

        p.css({zIndex: 99999}) if pos == 'relative' or pos == 'absolute' or pos == 'fixed'
