modulejs.define 'registration/components/promo_code_form',
  ['react', 'react-redux',
   'registration/store/actions', 'registration/utilities', 'slzr/react/form_row', 'slzr/react/form_input_group', 'slzr/react/form_theme_context',
   'slzr/react/form_header', 'slzr/components/date_input_row', 'slzr/react/filter_selector', 'slzr/react/currency_input', 'prop-types'],
  (React, ReactRedux,
   Actions, Utilities, FormRow, FormInputGroup, FormThemeContext
   FormHeader, DateInputRow, FilterSelector, CurrencyInput, PropTypes) ->

    class PromoCodeForm extends React.Component
      @contextType: FormThemeContext

      @propTypes:
        # Promo code data
        promoCode: Actions.PromoCodeShape
        # Ticket classes available for selection
        availableTicketClasses: PropTypes.array
        # Callback when an input field changes
        onChange: PropTypes.func
        # Callback when a ticket class is added
        onAddTicketClass: PropTypes.func
        # Callback when a ticket class is removed
        onRemoveTicketClass: PropTypes.func
        # Callback for the "Update" button, which just closes the editor
        onClose: PropTypes.func

      @defaultProps:
        availableTicketClasses: []

      onInputChange: (event, name, value) =>
        field_name = if name? then name else event.target.name
        field_value = if value? then value else if event.target.type == 'checkbox' then event.target.checked else event.target.value
        this.props.onChange?(field_name, field_value, event)

      # Returns a callback function to call onInputChange with the specified name
      inputChanged: (name) =>
        (event) => this.onInputChange(event, name)

      dateInputChanged: (event, value, input_value) =>
        if value == 'blank'
          new_value = ''
        else if value != 'error'
          new_value = value.toString('yyyy-MM-dd')

        this.onInputChange(event, event.target.name, new_value)

      onPriceChange: (event, cents) =>
        this.onInputChange event, event.target.name, cents

      ticketClassAdded: (id) =>
        id = if !isNaN(Number.parseInt(id)) then Number.parseInt(id) else id
        this.props.onAddTicketClass?(this.props.promoCode.id, id)

      ticketClassRemoved: (id) =>
        id = if !isNaN(Number.parseInt(id)) then Number.parseInt(id) else id
        this.props.onRemoveTicketClass?(this.props.promoCode.id, id)

      isValid: =>
        return false if this.props.promoCode.code.isBlank()
        true

      render: ->
        # Prepare the ticket class option array for the FilterSelector
        ticket_class_options = []
        ticket_class_labels = {}

        for ticket_class in this.props.availableTicketClasses
          ticket_class_options.push [ticket_class.name, ticket_class.id]
          ticket_class_labels[ticket_class.id] = ticket_class.name

        id_prefix = "promo-code-#{this.props.promoCode.id}"

        `<tr>
          <td className="edit-form" colSpan="5">
            <FormInputGroup>
              <FormRow title="Active"
                       tooltip="Allow promo code to be used"
                       labelFor={id_prefix + "-active"}
                       {...this.context.checkboxRow}>
                <input name="active"
                       type="checkbox"
                       id={id_prefix + "-active"}
                       className={this.context.checkboxRow.inputClassName}
                       checked={this.props.promoCode.active}
                       onChange={this.onInputChange} />
              </FormRow>

              <FormRow title="Code"
                       required
                       labelFor={id_prefix + "-code"}
                       {...this.context.textInputRow}>
                <input name="code"
                       type="text"
                       id={id_prefix + "-code"}
                       className={this.context.textInputRow.inputClassName}
                       value={this.props.promoCode.code}
                       onChange={this.onInputChange}
                       autoFocus />
              </FormRow>

              <FormRow title="Discount Type"
                       labelFor={id_prefix + "-discount_type"}
                       {...this.context.selectInputRow}>
                <select name="discount_type"
                        value={this.props.promoCode.discount_type}
                        id={id_prefix + "-discount_type"}
                        className={this.context.selectInputRow.inputClassName}
                        onChange={this.onInputChange}>
                  <option value="amount">Amount</option>
                  <option value="percent">Percent</option>
                </select>
              </FormRow>

              {this.props.promoCode.discount_type == 'amount' &&
                <FormRow title="Amount"
                         tooltip="The currency amount off the stated price of the ticket"
                         labelFor={id_prefix + "-amount"}>
                  <div className="rightside">
                    <CurrencyInput name="amount"
                                   id={id_prefix + "-amount"}
                                   value={this.props.promoCode.amount}
                                   currency={this.props.settings.currency}
                                   onChange={this.onPriceChange} />
                  </div>
              </FormRow>}

              {this.props.promoCode.discount_type == 'percent' &&
                <FormRow title="Percent"
                         tooltip="The percentage amount off the stated price of the ticket"
                         labelFor={id_prefix + "-percent"}
                         {...this.context.textInputRow}>
                  <input name="percent"
                         type="text"
                         id={id_prefix + "-percent"}
                         className={this.context.textInputRow.inputClassName}
                         value={this.props.promoCode.percent}
                         onChange={this.onInputChange} />
              </FormRow>}

              <DateInputRow label="Expires"
                            name="expires_at"
                            initialValue={this.props.promoCode.expires_at}
                            placeholder="'Tomorrow' or 'Next Friday' or '4/10'"
                            tooltip="Deactivate the promo code after this date"
                            {...this.context.textInputRow}
                            onChange={this.dateInputChanged} />

              <FormRow title="Number of Uses"
                       tooltip="The number of times this promo code can be used"
                       labelFor={id_prefix + "-usage_limit"}
                       {...this.context.textInputRow}>
                <input name="usage_limit"
                       type="text"
                       id={id_prefix + "-usage_limit"}
                       className={this.context.textInputRow.inputClassName}
                       value={this.props.promoCode.usage_limit}
                       onChange={this.onInputChange}/>
              </FormRow>

              <FormRow title="Associated Tickets"
                       tooltip="Restrict promo code usage to specific tickets"
                       labelFor={id_prefix + "-ticket_class_ids"}
                       {...this.context.selectInputRow}>
                <FilterSelector param="ticket_class_ids"
                                id={id_prefix + "-ticket_class_ids"}
                                inputClassName={this.context.selectInputRow.inputClassName}
                                options={ticket_class_options}
                                labels={ticket_class_labels}
                                selected={this.props.promoCode.ticket_class_ids}
                                blankLabel={this.props.promoCode.ticket_class_ids && this.props.promoCode.ticket_class_ids.length > 0 ? "" : "All Tickets"}
                                tagAdded={this.ticketClassAdded}
                                tagRemoved={this.ticketClassRemoved} />
              </FormRow>
            </FormInputGroup>

            <div className="action-button pull-right">
              <button className="btn-color"
                      disabled={!this.isValid()}
                      onClick={this.props.onClose}>
                Save Promo Code
              </button>
            </div>
          </td>
        </tr>`
