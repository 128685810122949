modulejs.define 'registration/components/checkout/cart',
  ['react', 'prop-types', 'moment', 'registration/utilities',
   'registration/components/checkout/cart_item', 'slzr/react/form_input_group', 'slzr/react/form_row',
   'slzr/react/form_header'],
  (React, PropTypes, moment, Utilities,
   CartItem, FormInputGroup, FormRow,
   FormHeader) ->

    class Cart extends React.Component
      @propTypes:
        items: PropTypes.array
        fee: PropTypes.number
        total: PropTypes.number
        currency: PropTypes.string
        onItemPriceChange: PropTypes.func

      render: ->
        currency = this.props.currency
        ticket_rows = for item in this.props.items
          `<CartItem item={item}
                     key={item.ticket_class_id}
                     currency={this.props.currency}
                     onItemPriceChange={this.props.onItemPriceChange} />`

        `<FormInputGroup>
          {ticket_rows}

          {this.props.fee > 0 && <div className="ticket_class">
            <p className="leftside">Fee</p>
            <p className="rightside ticket_price">{Utilities.formatCurrency(this.props.fee, currency)}</p>
          </div>}

          <div className="ticket_class">
            <p className="leftside">TOTAL</p>
            <p className="rightside ticket_price">{Utilities.formatCurrency(this.props.total, currency)}</p>
          </div>
        </FormInputGroup>`

