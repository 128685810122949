modulejs.define("slzr/react/form_theme_context", ["react"], (React) => {
  /**
   * This context is intended to hold theme configuration details for the form, primarily for the
   * legacy components reused in the form.
   */
  const FormThemeContext = React.createContext({
    // Properties for rows with a text input field
    textInputRow: {
      // Tag and class for the element wrapping the input tag
      inputWrapperElement: undefined,
      inputWrapperClassName: undefined,
      // Class name on input tag
      inputClassName: undefined,
      // Tag and class name of element containing label text
      labelElement: undefined,
      labelClassName: undefined,
      // Tag and class name of element containing the row
      wrapperElement: undefined,
      wrapperClassName: undefined,
      inputErrorClassName: undefined
    },
    // Properties for rows with a select input field
    selectInputRow: {
      // Tag and class for the element wrapping the input tag
      inputWrapperElement: undefined,
      inputWrapperClassName: undefined,
      // Class name on input tag
      inputClassName: undefined,
      // Tag and class name of element containing label text
      labelElement: undefined,
      labelClassName: undefined,
      // Tag and class name of element containing the row
      wrapperElement: undefined,
      wrapperClassName: undefined
    },
    // Properties for rows with a checkbox
    checkboxRow: {
      // Tag and class for the element wrapping the input tag
      inputWrapperElement: undefined,
      inputWrapperClassName: undefined,
      // Class name on input tag
      inputClassName: undefined,
      // Tag and class name of element containing label text
      labelElement: undefined,
      labelClassName: undefined,
      // Tag and class name of element containing the row
      wrapperElement: undefined,
      wrapperClassName: undefined
    },
    error: {
      errorClassName: undefined
    },

    // Set to true to request that all inputs have a visual label
    labelAllInputs: false
  });
  FormThemeContext.displayName = "FormThemeContext";
  return FormThemeContext;
});
