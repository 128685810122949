modulejs.define 'slzr/components/photo_chooser/no_photo',
  ['react', 'prop-types', 'underscore', 'slzr/components/dropzone', 'slzr/react/progress_bar'],
  (React, PropTypes, _, Dropzone, ProgressBar) ->

    # The "No Photo" display
    #
    # This also implements file selection and drop acceptance
    class NoPhoto extends React.Component
      @propTypes =
        # Callback when an image file is selected
        imageSelected: PropTypes.func
        # Indicates an upload is in progress
        uploading: PropTypes.bool
        # Percentage progress for the current upload
        uploadProgress: PropTypes.number
        # Status message to show
        statusMessage: PropTypes.string
        # True when showing an empty message
        showingEmpty: PropTypes.bool
        # Callback for closing the empty message
        onClose: PropTypes.func
        # Allow choosing a photo from the library
        allowChoose: PropTypes.bool
        # Allow uploading a photo
        allowUpload: PropTypes.bool
        # Dropzone component (expects onDrop callback and accept props, and just accepts file drops)
        #
        # Only should be used when NoPhoto is included in a ReactDnD context, to use a dropzone integrated
        # with ReactDnD instead of pure HTML events
        dropzoneComponent: PropTypes.func


      @defaultProps:
        uploading: false
        uploadProgress: 0
        showingEmpty: false
        allowChoose: true
        allowUpload: true
        dropzoneComponent: Dropzone

      constructor: (props) ->
        super props

        this.state =
          progressSupported: (!!window.ProgressEvent && !!window.FormData)
          dragActive: false

      # User click to open the file upload dialog
      uploadClicked: (event) =>
        this.file_input?.click()
        event.preventDefault()

      # File selected in the file input
      onFileChanged: (event) =>
        this.imageSelected(event.target.files[0]) if event.target.files[0]
        event.target.value = null

      # Image changed, either from drop or input select, so push it out
      imageSelected: (file) =>
        this.props.imageSelected?(file)

      chooseClicked: (event) =>
        event.preventDefault()
        this.props.libraryOpened?(event)

      closeClicked: (event) =>
        event.preventDefault()
        this.props.onClose?(event)

      _setFileInputRef: (element) => this.file_input = element

      render: ->
        if this.props.uploading
          if this.state.progressSupported
            message = `<div className="drop-target-text">
              <ProgressBar percent={this.props.uploadProgress}>
                <p>{this.props.uploadProgress < 100 ? "Uploading (" + this.props.uploadProgress + "%)..." : "Processing..."}</p>
              </ProgressBar>
            </div>`
          else
            message = `<div className="drop-target-text">
              <ProgressBar percent={50}>
                <p>Uploading...</p>
              </ProgressBar>
            </div>`
        else if this.props.showingEmpty
          message = `<div className="drop-target-text">
            Your recent photos are empty. Drag one here to add it.
            {' '/* jsx collapses spaces */}
            <button className="btn btn-color" onClick={this.closeClicked}>Go Back</button>
          </div>`
        else
          message = `<div className="drop-target-text">
            Drop <i className="fa fa-file-image-o" /> here or
            {' '/* jsx collapses spaces */}
            <a href="#" onClick={this.uploadClicked} className="btn btn-color" aria-label="Upload Photo">Upload</a>
            {this.props.allowChoose && ' '/* jsx collapses spaces */}
            {this.props.allowChoose && <a href="#" onClick={this.chooseClicked} className="btn btn-color">Choose from Photo Library</a>}
          </div>`

        DropzoneComponent = this.props.dropzoneComponent

        `<div className="no-photos">
          <input type="file"
                 ref={this._setFileInputRef}
                 onChange={this.onFileChanged}
                 style={{display: "none"}}
                 name="photo"
                 accept="images"/>

          <DropzoneComponent onDrop={this.imageSelected}
                             accept="image/*">
            {message}
          </DropzoneComponent>
        </div>`
