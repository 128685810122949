modulejs.define('registration/checkout/modal_coordinator',
function() {
  class CheckoutModalCoordinator {
    constructor() {
      this.modalShown = false;
      // Map of event => listener set
      this.listeners = {};
    }

    subscribe(event, listener) {
      (this.listeners[event] || (this.listeners[event] = new Set())).add(listener);
    }

    unsubscribe(event, listener) {
      if(!this.listeners[event]) return;
      this.listeners[event].delete(listener);
    }

    emit(event, ...args) {
      if(!this.listeners[event]) return;
      this.listeners[event].forEach((listener) => listener(...args));
    }

    // Request that the modal be shown
    showModal() {
      if(!this.modalShown) {
        this.modalShown = true;
        this.emit('modalShow');
      }
    }

    // Request that the modal be hidden
    hideModal() {
      if(this.modalShown) {
        this.modalShown = false;
        this.emit('modalHide');
      }
    }

    startLoading() {
      this.emit('startLoading');
    }

    stopLoading() {
      this.emit('stopLoading');
    }
  }

  return CheckoutModalCoordinator;
});
