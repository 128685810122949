modulejs.define 'registration/components/attendee_question_choice_list_item',
  ['underscore', 'react', 'react-dnd', 'prop-types',
    'registration/constants'],
  (_, React, ReactDnD, PropTypes,
    Constants) ->

    class AttendeeQuestionChoiceListItem extends React.Component
      @propTypes:
        onAdd: PropTypes.func
        # Callback for input change 
        onChange: PropTypes.func
        onDelete: PropTypes.func
        id: PropTypes.number
        value: PropTypes.string
        connectDragSource: PropTypes.func
        label: PropTypes.string

      @defaultProps:
        value: ""
        size: 6

      constructor: (props) ->
        super(props)

        # Generate unique ID to use for linking the label and the input
        this._inputId = _.uniqueId("choice-input-")

      onDelete: (event) =>
        event.preventDefault()
        this.props.onDelete?()
      
      render: ->
        `<div className="attendee-question-choice">
          <div className="attendee-question-choice--label">
            {this.props.connectDragSource && this.props.connectDragSource(<i className="fa fa-bars drag-handle" aria-hidden="true"></i>)}
            <label htmlFor={this._inputId}>{this.props.label}</label>
          </div>
          <input  id={this._inputId}
                  type="text"
                  value={this.props.value}
                  placeholder="Add choice"
                  onChange={this.props.onChange}/>
          <a href="#" aria-label={'Remove choice "' + this.props.value + '"'} onClick={this.onDelete}><i className="localist-icon-trash-small"></i></a>
        </div>`