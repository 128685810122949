/**
 * Lightweight API for retrieving and setting a user's cookie consent status.
 */

/**
 * Active consent status enum
 * @type {{UNACCEPTED: string, ACCEPTED: string, NECESSARY_ONLY: string}}
 */
export const ConsentStatus = {
  /** User has not responded */
  UNACCEPTED: "UNACCEPTED",
  /** User only accepted necessary cookies */
  NECESSARY_ONLY: "NECESSARY_ONLY",
  /** User accepted all cookies */
  ACCEPTED: "ACCEPTED"
};

/**
 * Local Storage key for storing consent status
 * @type {string}
 */
const CONSENT_STATUS_KEY = "cookiePolicyStatus";

/**
 * Subscribed event callbacks
 */
let subscribers = [];

/**
 * Return the user's current consent status.
 *
 * @return {string}
 */
export function getConsentStatus() {
  let status = localStorage.getItem(CONSENT_STATUS_KEY);
  if (status == null) {
    return ConsentStatus.UNACCEPTED;
  }

  let data;
  try {
    data = JSON.parse(status);
  } catch {
    return ConsentStatus.UNACCEPTED;
  }

  return isConsentStatusValid(data) ? data.status : ConsentStatus.UNACCEPTED;
}

/**
 * Check if the consent status is set and not expired
 */
function isConsentStatusValid({ dateUpdated }) {
  let dateExpire = new Date(dateUpdated);

  // Expires in one year
  dateExpire.setFullYear(dateExpire.getFullYear() + 1);
  return dateExpire > new Date();
}

/**
 * Set the new user's consent status
 *
 * @param status {string} new consent status
 */
export function setConsentStatus(status /*: ConsentStatus */) {
  localStorage.setItem(
    CONSENT_STATUS_KEY,
    JSON.stringify({ status, dateUpdated: new Date() })
  );

  notifySubscribers(status);
}

/**
 * Reset consent status
 */
export function resetConsentStatus() {
  localStorage.removeItem(CONSENT_STATUS_KEY);
  notifySubscribers(ConsentStatus.UNACCEPTED);
}

/**
 * Subscribe to consent status changes
 *
 * Callback will receive one argument, a string of a value in ConsentStatus
 */
export function subscribe(callback) {
  subscribers.push(callback);
}

/**
 * Unsubscribe from content status changes
 * @param callback
 */
export function unsubscribe(callback) {
  subscribers = subscribers.filter((item) => item !== callback);
}

/**
 * Notify subscribers of a change in consent status
 * @param status
 */
function notifySubscribers(status) {
  subscribers.forEach((callback) => callback(status));
}

/**
 * Configure GA4/GTag for consent status
 */
export function configureGA4(gtag) {
  const status = getConsentStatus();
  if (status === ConsentStatus.UNACCEPTED) {
    // defaults
    gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "denied",
      wait_for_update: 500
    });
  } else {
    // user-specified behavior
    const full_consent_value =
      status === ConsentStatus.ACCEPTED ? "granted" : "denied";
    const necessary_only_value =
      status === ConsentStatus.ACCEPTED ||
      status === ConsentStatus.NECESSARY_ONLY
        ? "granted"
        : "denied";

    // user-specified behavior
    gtag("consent", "default", {
      ad_storage: full_consent_value,
      analytics_storage: full_consent_value,
      functionality_storage: necessary_only_value,
      personalization_storage: full_consent_value,
      security_storage: necessary_only_value,
      wait_for_update: 500
    });
  }

  subscribe((status) => updateGA4ConsentStatus(status, gtag));
}

/**
 * Update the GA4 consent status
 * @param status {string} New consent status
 * @param gtag {function} gtag instance function
 */
function updateGA4ConsentStatus(status, gtag) {
  const full_consent_value =
    status === ConsentStatus.ACCEPTED ? "granted" : "denied";
  const necessary_only_value =
    status === ConsentStatus.ACCEPTED || status === ConsentStatus.NECESSARY_ONLY
      ? "granted"
      : "denied";

  // user-specified behavior
  gtag("consent", "update", {
    ad_storage: full_consent_value,
    analytics_storage: full_consent_value,
    functionality_storage: necessary_only_value,
    personalization_storage: full_consent_value,
    security_storage: necessary_only_value
  });
}
