# Map drawing functions
namespace 'Slzr.Maps', (exports, top) ->
  $ = Slzr.jQuery

  class exports.Map
    # Initialize the map
    #
    # Options include:
    #   element: (required) the container for the map
    #   zoom: (require) the initial zoom level
    #   overlays: array of URLs to KML/georss overlays
    #   points: array of [lat, lng, url] points to put markers at.  url is optional, and will link there if given
    #   infoWindow: element to show as an info window at the first point
    constructor: (options) ->
      @options = options

      @overlays = []
      @points = []
      @markers = []
      @bounds = new google.maps.LatLngBounds

      @marker_image = new google.maps.MarkerImage 'https://www.localist.com/assets/main/pin.png', null, null, new google.maps.Point(10, 32), new google.maps.Size(20, 32) # WP_CHANGE: hostname may not be correct
      @marker_shadow = new google.maps.MarkerImage 'https://www.localist.com/assets/main/pin_shadow.png', null, null, new google.maps.Point(10, 32), new google.maps.Size(36, 32) # WP_CHANGE: hostname may not be correct
      @marker_shape = {
        coord: [26,0,28,1,30,2,32,3,33,4,34,5,35,6,36,7,36,8,37,9,38,10,38,11,38,12,39,13,39,14,39,15,39,16,39,17,39,18,39,19,39,20,39,21,39,22,39,23,39,24,39,25,39,26,38,27,38,28,38,29,37,30,37,31,37,32,36,33,36,34,35,35,35,36,34,37,34,38,34,39,33,40,33,41,32,42,32,43,31,44,31,45,30,46,30,47,29,48,29,49,28,50,28,51,27,52,26,53,26,54,25,55,25,56,24,57,24,58,23,59,23,60,22,61,21,62,21,63,18,63,18,62,17,61,17,60,16,59,15,58,15,57,14,56,14,55,13,54,13,53,12,52,12,51,11,50,11,49,10,48,10,47,9,46,8,45,8,44,7,43,7,42,7,41,6,40,6,39,5,38,5,37,4,36,4,35,3,34,3,33,2,32,2,31,2,30,1,29,1,28,1,27,0,26,0,25,0,24,0,23,0,22,0,21,0,20,0,19,0,18,0,17,0,16,0,15,0,14,0,13,1,12,1,11,2,10,2,9,3,8,4,7,4,6,5,5,6,4,8,3,9,2,11,1,13,0,26,0],
        type: 'poly'
      }

      @map = new google.maps.Map options.element,
        zoom: options.zoom
        mapTypeId: if options.satellite then google.maps.MapTypeId.HYBRID else google.maps.MapTypeId.ROADMAP
        streetViewControl: false
        overviewMapControl: false
        mapTypeControl: false

      @addOverlay(url) for url in options.overlays
      @addMarker(point[0], point[1], point[2]) for point in options.points
      @showInfoWindow if options.infoWindow?

    # Add a KML/GeoRSS overlay to the map
    addOverlay: (url) =>
      @overlays.push url

      overlay = new google.maps.KmlLayer url, preserveViewport: true
      overlay.setMap @map
      overlay

    # Show an info window
    showInfoWindow: =>
      infoWindow = new google.maps.InfoWindow content: @options.infoWindow
      $(@options.infoWindow).show()
      infoWindow.open map, @markers[0]
      infoWindow

    # Add a marker at 
    addMarker: (lat, lng, url=null) =>
      @points.push [lat, lng]

      point = new google.maps.LatLng lat, lng
      marker = new google.maps.Marker position: point, map: @map, icon: @marker_image, shadow: @marker_shadow, shape: @marker_shape
      @markers.push marker

      if url? then google.maps.event.addListener marker, 'click', -> window.open(url)

      @bounds.extend point
      if @markers.length > 1
        @map.fitBounds @bounds
      else
        @map.setCenter point
        @map.setZoom @options.zoom

      marker

# Maps (2) utility functions
namespace 'SlzrMaps', (exports, top) ->
  # Draw a circle as a map overlay
  #
  # map: the google map instance
  # center: GLatLng of the center point
  # radius: radius of the circle
  # options: units: 'km' or 'mi' (default=mi)
  #
  # Returns the GPolygon
  exports.drawCircle = (map, center, radius, options) ->
    options or= {}
    options.units = options.units || 'mi'

    points = []

    if options.units == 'km' or options.units == 'kms'
      d = radius / 6378.8
    else
      d = radius / 3963.189

    lat1 = center.lat() * (Math.PI / 180)
    lng1 = center.lng() * (Math.PI / 180)

    for i in [0..360]
      tc = i * (Math.PI / 180)
      y = Math.asin(Math.sin(lat1) * Math.cos(d) + Math.cos(lat1) * Math.sin(d) * Math.cos(tc))
      dlng = Math.atan2(Math.sin(tc) * Math.sin(d) * Math.cos(lat1), Math.cos(d) - Math.sin(lat1) * Math.sin(y))
      x = ((lng1 - dlng + Math.PI) % (2 * Math.PI)) - Math.PI
      point = new GLatLng parseFloat(y * (180 / Math.PI)), parseFloat(x * (180 / Math.PI))
      points.push point

    poly = new GPolygon points
    map.addOverlay poly
    poly

  # Make the specified map instance static
  #
  # That is, disable any interaction
  exports.makeStatic = (map) ->
    map.disableDragging()
    map.disableInfoWindow()
    map.disableDoubleClickZoom()
    map.disableScrollWheelZoom()
    map.disablePinchToZoom()
    map.disableGoogleBar()
