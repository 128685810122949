/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

// Flag to ignore focus changes while refocusing an element
let focusInProgress = false;

// Last element focused
let lastFocused = null;

// Element to trap focus within
let trappedElement = null;

// Element focused before trap
let focusedBeforeTrap = null;

// "Dummy" focusable, but invisible elements placed around the element trapping focus, to prevent
// focus from leaving the document
let dummyElements = [];

// Priority of the current trap
let trapPriority = 0;

// Current trap needs setup
let needsSetup = false;

// If dummy element insertion is requested
let insertDummies = true;
// If moving focus into the trap is requested
let moveFocus = true;

// Stack of saved trap states
const savedTrapStates = [];

// Focusable selector
const FOCUSABLE_SELECTORS = [
  'a[href]:not([tabindex^="-"])',
  'area[href]:not([tabindex^="-"])',
  'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
  'input[type="radio"]:not([disabled]):not([tabindex^="-"])',
  'select:not([disabled]):not([tabindex^="-"])',
  'textarea:not([disabled]):not([tabindex^="-"])',
  'button:not([disabled]):not([tabindex^="-"])',
  'iframe:not([tabindex^="-"])',
  'audio[controls]:not([tabindex^="-"])',
  'video[controls]:not([tabindex^="-"])',
  '[contenteditable]:not([tabindex^="-"])',
  '[tabindex]:not([tabindex^="-"])'
].join(", ");

const FocusTrap = {
  FOCUSABLE_SELECTORS
};

// Save the current trapped element state
const pushTrapState = function () {
  const state = {
    trappedElement,
    focusedBeforeTrap,
    dummyElements,
    trapPriority,
    moveFocus,
    insertDummies,
    needsSetup
  };
  return savedTrapStates.push(state);
};

// Restore the previous trap state
const popTrapState = function () {
  const state = savedTrapStates.pop();
  if (state) {
    ({
      trappedElement,
      dummyElements,
      focusedBeforeTrap,
      trapPriority,
      moveFocus,
      insertDummies,
      needsSetup
    } = state);
  }
  return state;
};

const insertPendingTrap = function (state) {
  console.log("insertPendingTrap", state);
  // Find insertion point, the rightmost position with the same or less priority
  let index = savedTrapStates.length - 1;
  while (
    index >= 0 &&
    savedTrapStates[index].trapPriority > state.trapPriority
  ) {
    index--;
  }

  // Insert new state
  savedTrapStates.splice(index + 1, 0, state);
  console.log("new state", savedTrapStates);
};

// Return true if the element is visible (that is, it has size dimensions)
const isVisible = (element) =>
  Boolean(
    element.offsetWidth ||
      element.offsetHeight ||
      element.getClientRects().length
  );

// Return array of visible descendants of +element+ that can receive keyboard focus
const focusableDescendants = (element) =>
  Array.from(element.querySelectorAll(FOCUSABLE_SELECTORS)).filter(isVisible);

// Attempt to focus +element+, returning true if it got focus
const attemptFocus = function (element) {
  focusInProgress = true;
  try {
    element.focus();
  } catch (e) {
    true;
  }
  focusInProgress = false;
  return document.activeElement === element;
};

const focusFirstDescendant = function (element) {
  const elements = focusableDescendants(element);
  for (let i = 0, end = elements.length - 1; i <= end; i++) {
    let target = elements[i];
    if (attemptFocus(target)) {
      return true;
    }
  }
  return false;
};

const focusLastDescendant = function (element) {
  const elements = focusableDescendants(element);
  for (let i = elements.length - 1; i >= 0; i--) {
    let target = elements[i];
    if (attemptFocus(target)) {
      return true;
    }
  }
  return false;
};

const focusChange = function (event) {
  if (focusInProgress) {
    return;
  }
  if (!trappedElement) {
    return;
  }

  if (trappedElement.contains(event.target)) {
    return (lastFocused = event.target);
  } else {
    // Try focusing the first focusable element in the trapped element
    focusFirstDescendant(trappedElement);
    if (lastFocused === document.activeElement) {
      // We focused the first element again, so try focusing the last
      focusLastDescendant(trappedElement);
    }

    return (lastFocused = document.activeElement);
  }
};

// Insert dummy elements before and after element
//
// These elements are focusable, in order to prevent focus from leaving the document if the modal is at the beginning
// or end of the document
const insertDummyElements = function (element) {
  const beforeDummy = document.createElement("div");
  beforeDummy.tabIndex = 0;
  beforeDummy.style.position = "fixed";
  beforeDummy.dataset.focusTrapIgnore = "true";
  element.insertAdjacentElement("beforebegin", beforeDummy);
  dummyElements.push(beforeDummy);

  const afterDummy = document.createElement("div");
  afterDummy.tabIndex = 0;
  afterDummy.style.position = "fixed";
  afterDummy.dataset.focusTrapIgnore = "true";
  element.insertAdjacentElement("afterend", afterDummy);
  return dummyElements.push(afterDummy);
};

// Remove the dummy elements inserted by insertDummyElements
const removeDummyElements = () =>
  (() => {
    let dummyElement;
    const result = [];
    while ((dummyElement = dummyElements.pop())) {
      result.push(dummyElement.parentNode.removeChild(dummyElement));
    }
    return result;
  })();

// Mark all elements in the document, other than the specified element, as inert and aria-hidden.
const hideOtherElements = function (element) {
  // If this element doesn't have a parent, we're done
  if (!element.parentElement) {
    return;
  }
  // Stop at body
  if (element === document.body) {
    return;
  }

  // For all siblings of the element, apply aria-hidden=true and inert
  for (let el of Array.from(element.parentElement.children)) {
    if (!el || el === element || el.dataset.focusTrapIgnore === "true") {
      continue;
    }

    el.setAttribute("aria-hidden", "true");
    el.setAttribute("inert", "inert");
    // Attribute to allow us to easily query and clear
    el.setAttribute("data-hidden-by-focus-trap", "true");
  }

  // And repeat with the element's parent
  return hideOtherElements(element.parentElement);
};

// Clear inert and aria-hidden from all elements affected by hideOtherElements
const unhideOtherElements = function () {
  const elements = document.querySelectorAll("[data-hidden-by-focus-trap]");
  return (() => {
    const result = [];
    for (let element of Array.from(elements)) {
      element.removeAttribute("aria-hidden");
      element.removeAttribute("inert");
      result.push(element.removeAttribute("data-hidden-by-focus-trap"));
    }
    return result;
  })();
};

// Set up the current trap
const setupTrap = function () {
  unhideOtherElements();
  hideOtherElements(trappedElement);
  if (insertDummies) {
    insertDummyElements(trappedElement);
  }

  if (moveFocus) {
    focusedBeforeTrap = document.activeElement;
    focusFirstDescendant(trappedElement);
  }

  // Register focus listener only for the first trap attempt
  if (savedTrapStates.length === 1) {
    return document.addEventListener("focus", focusChange, true);
  }
};

// Trap focus into the specified element
//
// When insertDummies is true, inserts focusable elements before and after the modal element to prevent focus from
// leaving the document.
//
// options:
//   insertDummies: Insert dummy elements around the trapped element
//   moveFocus: Focus first focusable element inside the trapped element
//   priority: Priority of the focus trap. Lower values will be queued but not activated if a higher one is active.
FocusTrap.trap = function (
  element,
  { insertDummies = true, moveFocus = true, priority = 0 } = {}
) {
  if (insertDummies == null) {
    insertDummies = true;
  }
  if (moveFocus == null) {
    moveFocus = true;
  }
  if (!element) {
    return;
  } // Trapping null is a noop

  if (priority < trapPriority) {
    // New trap is a lower priority, so defer setting it up and just add it to the stack
    insertPendingTrap({
      trappedElement: element,
      dummyElements: [],
      trapPriority: priority,
      needsSetup: true,
      moveFocus,
      insertDummies
    });
    return;
  }

  // Save and blank the current trap state
  pushTrapState();
  trappedElement = element;
  dummyElements = [];
  trapPriority = priority;

  setupTrap();
};

// Untrap focus, cleaning up event handlers and dummy elements
FocusTrap.untrap = function () {
  unhideOtherElements();
  removeDummyElements();

  // Save the *current* value of focusedBeforeTrap, since popping state will overwrite it
  const wasFocused = focusedBeforeTrap;
  popTrapState();

  if (needsSetup) {
    // If we popped a pending trap, make sure it's initialized
    setupTrap();
  } else {
    // Restore inert and aria-hidden on the non-trapped elements
    if (trappedElement) {
      unhideOtherElements();
      hideOtherElements(trappedElement);
    }
  }

  // Refocus the previously focused element when the trap was set
  if (wasFocused) {
    wasFocused.focus();
  }

  // Remove focus listener if we've untrapped the last time
  if (savedTrapStates.length === 0) {
    return document.removeEventListener("focus", focusChange, true);
  }
};

// Expose some focus helper method
FocusTrap.focusableDescendants = focusableDescendants;
FocusTrap.focusFirstDescendant = focusFirstDescendant;
FocusTrap.hideOtherElements = hideOtherElements;
FocusTrap.unhideOtherElements = unhideOtherElements;

export default FocusTrap;
