# Modal dialog
#
# This allows arbitrary JS to be attached to data-action inside the view,
# powered by a template.

namespace 'Slzr.UI', (exports, top) ->
  jQuery = $ = Slzr.jQuery

  class exports.Modal
    default_options:
      # template name to use for the modal content
      template: null
      # handlers for actions defined in content
      actions: null
      # Callback when modal is closed
      onClose: null
      # Parameters for template evaluation
      params: {}
      # Callback after the content is created, to attach custom event handlers, etc.
      afterContentCreated: null

    # Create and display
    constructor: (options) ->
      @template = options.template
      @actions = options.actions || {}
      @formActions = options.formActions || {}
      @onClose = options.onClose
      @params = options.params || {}
      @afterContentCreated = options.afterContentCreated

      @_createModalContent()
      @show()

    # Show the modal
    show: =>
      openIbox "##{@modal_id}", onClosed: @_onClose

    # Hide the modal
    hide: =>
      hideIbox()

    # Destroy the modal
    destroy: =>
      @modal_container_div.remove()
      @modal_container_div = @modal_div = @modal_id = null

    # Action handler
    onAction: (element, event) =>
      name = $(element).data('action')
      if @actions[name]?
        @actions[name](this, element, event)
      else
        # Action not defined, so true
        true

    onFormAction: (element, event) =>
      name = $(element).data('action')
      if @formActions[name]?
        @formActions[name](this, element, event)
      else
        # Action not defined, so true
        true

    # Close handler
    _onClose: =>
      @onClose?()
      @destroy()

    # Create the modal content
    _createModalContent: ->
      # Create the HTML element
      @modal_id = "localist_modal_" + Math.floor(Math.random() * 1000000000)

      @modal_container_div = $('<div></div>')
        .css('display', 'none')
        .html(JST[@template](@params))

      @modal_container_div.children().attr('id', @modal_id)
      @modal_div = @modal_container_div.children()

      $(document.body).append(@modal_container_div)

      self = this
      @modal_container_div.find('[data-action]').on 'click', (evt) ->
        self.onAction(this, evt)

      @modal_container_div.find('form[data-action]').on 'submit', (evt) ->
        self.onFormAction(this, evt)

      @afterContentCreated(this, @modal_container_div) if @afterContentCreated

      @modal_div