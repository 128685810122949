// Component for showing list of purchased tickets for an order.
modulejs.define(
  "registration/components/checkout/tickets_list",
  ["react", "prop-types", "registration/utilities", "classnames"],
  (React, PropTypes, RegistrationUtilities, classNames) => {
    const TicketsList = ({
      containerClass,
      rowClass,
      tableClass,
      failuresTableClass,
      firstTotalRowClass,
      totalRowClass,
      showCheckboxes,
      onCheckboxChange,
      showTotal,
      tickets,
      orderDetails,
      selectedIds,
      ticketFailures,
      ticketsFeeLabelClass,
      ticketsSubtotalLabelClass,
      ticketsTotalLabelClass,
      ticketCheck,
      caption,
      currency,
      checkboxContainerClass
    }) => {
      // Format all event tickets with attendee name, ticket class name, and price.
      const _formatTickets = (_tickets) => {
        let tix = _tickets.map((ticket) => (
          <tr key={ticket.id} className={rowClass}>
            {showCheckboxes && (
              <td className={checkboxContainerClass}>
                <input
                  id={ticket.id}
                  type="checkbox"
                  className="em-ticket-refundable"
                  name="ticket_ids[]"
                  value={ticket.id}
                  onChange={onCheckboxChange}
                  checked={selectedIds.includes(ticket.id.toString())}
                  disabled={!ticketCheck(ticket)}
                />
              </td>
            )}

            <td className="em-ticket-attendee-name">
              <label htmlFor={ticket.id}>{ticket.attendee_name}</label>
            </td>
            <td className="em-ticket-name">1x {ticket.name}</td>
            <td className="em-ticket-status">
              {RegistrationUtilities.ticket_status_lookup(ticket.state)}
            </td>
            <td className="em-ticket-price">
              {RegistrationUtilities.formatCurrency(
                ticket.item_total,
                currency
              )}
            </td>
          </tr>
        ));

        return tix;
      };

      // Format the appropriate subtotal, ticket fee, service fee, and grand total rows for the order.
      // Subtotal will only be shown if fees were paid. Ticket fee and service fee will only be shown if they are non-zero.
      const _formatReceiptTotals = () => {
        // Determine which of "subtotal" or "total" is the first total row shown, and apply the first class to it
        const subtotalRowClassNames = classNames(totalRowClass, {
          [firstTotalRowClass]: orderDetails.total_fees > 0
        });
        const totalRowClassNames = classNames(totalRowClass, {
          [firstTotalRowClass]: orderDetails.total_fees === 0
        });

        return (
          <React.Fragment>
            {/* total_fees represents the total fees paid by the user. If it's zero, the customer didn't pay fees,
            so no need to show them */}
            {orderDetails.total_fees > 0 && (
              <React.Fragment>
                {/* Subtotal, amount paid before fees */}
                <tr className={subtotalRowClassNames}>
                  <td colSpan="3" className={ticketsSubtotalLabelClass}>
                    Subtotal
                  </td>
                  <td>
                    {RegistrationUtilities.formatCurrency(
                      orderDetails.total_amount,
                      orderDetails.currency
                    )}
                  </td>
                </tr>
                {/* Ticket Fee (our fee) row */}
                {orderDetails.charged_application_fee > 0 && (
                  <tr className={totalRowClass}>
                    <td colSpan="3" className={ticketsFeeLabelClass}>
                      Ticket Fee
                    </td>
                    <td>
                      {RegistrationUtilities.formatCurrency(
                        orderDetails.charged_application_fee,
                        orderDetails.currency
                      )}
                    </td>
                  </tr>
                )}
                {/* Transaction (payment) fee */}
                {orderDetails.charged_transaction_fee > 0 && (
                  <tr className={totalRowClass}>
                    <td colSpan="3" className={ticketsFeeLabelClass}>
                      Service Fee
                    </td>
                    <td>
                      {RegistrationUtilities.formatCurrency(
                        orderDetails.charged_transaction_fee,
                        orderDetails.currency
                      )}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )}
            <tr className={totalRowClassNames}>
              <td colSpan="3" className={ticketsTotalLabelClass}>
                {orderDetails.amount_refunded > 0 ? "Previous Total" : "Total"}
              </td>
              <td>
                {RegistrationUtilities.formatCurrency(
                  orderDetails.grand_total,
                  orderDetails.currency
                )}
              </td>
            </tr>
            {/* Refund details */}
            {orderDetails.amount_refunded > 0 && (
              <React.Fragment>
                <tr className={totalRowClass}>
                  <td colSpan="3" className={ticketsTotalLabelClass}>
                    Refunded
                  </td>
                  <td>
                    {RegistrationUtilities.formatCurrency(
                      -orderDetails.amount_refunded,
                      orderDetails.currency
                    )}
                  </td>
                </tr>
                <tr className={totalRowClass}>
                  <td colSpan="3" className={ticketsTotalLabelClass}>
                    New Total
                  </td>
                  <td>
                    {RegistrationUtilities.formatCurrency(
                      orderDetails.grand_total - orderDetails.amount_refunded,
                      orderDetails.currency
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      };

      return (
        <div className={containerClass}>
          <table className={tableClass}>
            <tbody>
              {_formatTickets(
                tickets.filter((ticket) => !ticketFailures.includes(ticket.id))
              )}
              {showTotal && _formatReceiptTotals()}
            </tbody>
          </table>

          {ticketFailures.length > 0 && (
            <table className={failuresTableClass}>
              <caption>{caption}</caption>
              <tbody>
                {_formatTickets(
                  tickets.filter((ticket) => ticketFailures.includes(ticket.id))
                )}
              </tbody>
            </table>
          )}
        </div>
      );
    };

    TicketsList.propTypes = {
      containerClass: PropTypes.string,
      rowClass: PropTypes.string,
      tableClass: PropTypes.string,
      failuresTableClass: PropTypes.string,
      firstTotalRowClass: PropTypes.string,
      totalRowClass: PropTypes.string,
      showCheckboxes: PropTypes.bool,
      onCheckboxChange: PropTypes.func,
      showTotal: PropTypes.bool,
      tickets: PropTypes.array,
      selectedIds: PropTypes.array,
      ticketFailures: PropTypes.array,
      caption: PropTypes.string,
      ticketsSubtotalLabelClass: PropTypes.string,
      ticketsFeeLabelClass: PropTypes.string,
      ticketsTotalLabelClass: PropTypes.string,
      ticketCheck: PropTypes.func,
      currency: PropTypes.string,
      checkboxContainerClass: PropTypes.string,
      orderDetails: PropTypes.shape({
        amount_refunded: PropTypes.number,
        charged_application_fee: PropTypes.number,
        charged_transaction_fee: PropTypes.number,
        currency: PropTypes.string,
        grand_total: PropTypes.number,
        total_amount: PropTypes.number,
        total_fees: PropTypes.number
      })
    };

    TicketsList.defaultProps = {
      containerClass: "em-tickets-list-container",
      rowClass: "em-ticket-container",
      tableClass: "em-tickets-list",
      failuresTableClass: "em-tickets-list-failures",
      firstTotalRowClass: "em-tickets-total-row--first",
      totalRowClass: "em-tickets-total-row",
      showCheckboxes: false,
      showTotal: false,
      tickets: [],
      selectedIds: [],
      orderDetails: {
        amount_refunded: 0,
        charged_application_fee: 0,
        charged_transaction_fee: 0,
        currency: "USD",
        grand_total: 0,
        total_amount: 0,
        total_fees: 0
      },
      ticketFailures: [],
      caption: "The following tickets could not be cancelled or refunded:",
      ticketsSubtotalLabelClass: "em-ticket-subtotal-label",
      ticketsFeeLabelClass: "em-ticket-fee-label",
      ticketsTotalLabelClass: "em-ticket-total-label",
      ticketCheck: () => true,
      currency: "USD",
      checkboxContainerClass: "em-ticket-refundable-container"
    };

    return TicketsList;
  }
);
