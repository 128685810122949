modulejs.define 'registration/components/checkout/checkout',
  ['jquery', 'react', 'react-dom', 'prop-types', 'object-path-immutable', '@stripe/react-stripe-js', '@stripe/stripe-js', 'slzr/local_session_store',
   'slzr/react/focus_first',
   'registration/components/checkout/buy_tickets_button', 'registration/components/checkout/quantity_chooser'
   'registration/components/checkout/attendee_details', 'registration/components/checkout/payment'
   'registration/components/checkout/confirmation', 'slzr/react/register_modal', 'registration/components/checkout/refund_request', 'registration/components/checkout/refund_confirmation'],
  ($, React, ReactDOM, PropTypes, immutable, ReactStripeElements, Stripe, LocalSessionStore,
   FocusFirst,
   BuyTicketsButton, QuantityChooser,
   AttendeeDetails, Payment,
   Confirmation, RegisterModal, RefundRequest, RefundConfirmation) ->

    # Flag variable to make sure only one finalize call happens
    # (This component can be on a page multiple times)
    finalize_called = false

    # Empty register container, to replace RegisterModal when not using it in a modal
    RegisterContainer = (props) ->
      `<div className="em-register-checkout">
        {props.children}
      </div>`

    # Main component for registration checkout
    #
    # This is a little bit unconventional in that the state is primarily server controlled,
    # instead of being controlled by props. This component is essentially entirely self-contained
    # without any external integration.
    #
    # As the user works their way through the checkout process, forms are submitted over XHR
    # and new state is pushed down that is merged into with the local state after each step,
    # including which step to show.
    #
    # There is no global state beyond a loading flag.
    #
    # The individaul individual step components may contain their own internal state, but again,
    # what the server sends is the truth.
    #
    # This component, in addition to managing the components that are shown, is also where all
    # the logic to submit from the server and handle updates is contained.
    class Checkout extends React.Component
      @propTypes:
        # Event ID
        event_id: PropTypes.number.isRequired
        # Event name
        event_name: PropTypes.string.isRequired
        # true if the event is free
        free: PropTypes.bool
        # currency used for this event
        currency: PropTypes.string
        # Class name for "Buy Tickets" button
        buy_tickets_class: PropTypes.string
        # Label for "Buy Tickets" button
        buy_tickets_label: PropTypes.string
        # Class name for "Register" button (free)
        register_class: PropTypes.string
        # Label for "Register" button (free)
        register_label: PropTypes.string
        # Automatically open checkout
        openCheckout: PropTypes.bool
        # Flag indicating that this hosts the modal container
        isModalContainer: PropTypes.bool
        # Modal coordinator instance
        modalCoordinator: PropTypes.object
        # Start in refund mode
        refund: PropTypes.bool
        # Start in confirm mod
        confirm: PropTypes.bool

      @defaultProps:
        free: false
        currency: 'usd'
        buy_tickets_class: ""
        buy_tickets_label: "Buy Tickets"
        register_class: ""
        register_label: "Register"
        openCheckout: false
        event: {}
        isModalContainer: true

      constructor: (props) ->
        super props

        @state =
          # True when there's some busy indicator
          loading: false
          # The waiting state, i.e. hasn't clicked the button
          mode: 'waiting'
          # Any error state
          error: false
          errorMessage: ''
          # True to force focus on the button (when closing)
          focusButton: false
          # Flag indicating the checkout modal is opened somewhere on the page
          checkoutOpen: false
          # Flag set when this is the instance that caused checkout to be shown
          didOpenCheckout: true
          # Flag set when modal was autoOpened
          autoOpenedModal: false

        @localStore = new LocalSessionStore

        # If completed order ID is indicated, set the defined ticket here, and flag we want to call finalize
        if props.completed_ticket_order_id
          @localStore.set "registration_#{props.event_id}", {order_id: props.completed_ticket_order_id, cart_id: props.completed_ticket_order_cart_id}, 7 * 86400
          this.need_to_call_finalize = true

        @state.cart_id = props.cart_id if props.cart_id
        @state.order_id = props.order_id if props.order_id
        
        if props.refund or props.confirm
          @state.loading = true
          @state.mode = if props.refund then 'refund' else 'confirmation'
          @state.order_id = props.order_id
          @state.ticket_id = props.ticket_id
          @state.token = props.token
        else if @localStore.get("registration_#{props.event_id}")
          registration_data = @localStore.get("registration_#{props.event_id}")
          if registration_data.token || this.need_to_call_finalize
            @state.mode = 'confirmation'
            @state.order_id = registration_data.order_id
            @state.cart_id = registration_data.cart_id
            @state.token = registration_data.token
            @state.loading = true
            this.need_to_call_order_details = true

      componentDidMount: ->
        # Subscribe to state changes from the modal coordinator
        this.props.modalCoordinator.subscribe('modalShow', this.onModalCoordinatorShow)
        this.props.modalCoordinator.subscribe('modalHide', this.onModalCoordinatorHide)
        this.props.modalCoordinator.subscribe('startLoading', this.onModalCoordinatorStartLoading)
        this.props.modalCoordinator.subscribe('stopLoading', this.onModalCoordinatorStopLoading)

        # If we were signaled that we need to finalize the order, call it now
        if this.need_to_call_finalize
          @_finalizeCheckout()
        else if this.need_to_call_order_details
          @_getOrderDetails({order_id: @state.order_id, token: @state.token})

        # Automatically open checkout if the URL requests it
        if @props.openCheckout
          if @props.refund or @props.confirm
            options = {order_id: @state.order_id, ticket_id: @state.ticket_id, token: @state.token}
            options['refund'] = 1 if @props.refund
            options['confirmation'] = 1 if @props.confirmation
            @_getOrderDetails(options)
          else
            @onBuyTicketsClick(new Event('auto-open-checkout'))
        else
          # Debugging: Simulate mode transitions by faking data
          event_instance_id = 258490
          switch @props.initialMode
            when 'tickets'
              @onBuyTicketsClick(new Event('dummy'))
            when 'attendees'
              @onBuyTicketsClick(new Event('dummy')).then =>
                event_instance_id = @state.instances[0].id
                ticket_quantities = {}
                ticket_quantities[@state.ticket_class_groups[0][1][0].id] = 1
                @onQuantitySubmit { event_instance_id, ticket_quantities }
            when 'payments'
              @onBuyTicketsClick(new Event('dummy')).then =>
                event_instance_id = @state.instances[0].id
                ticket_quantities = {}
                ticket_quantities[@state.ticket_class_groups[0][1][0].id] = 1
                @onQuantitySubmit { event_instance_id, ticket_quantities }
              .then =>
                event_instance_id = @state.instances[0].id
                attendees = {}
                attendees[@state.ticket_class_groups[0][1][0].id] = [
                  {name: 'Test User', email: 'registered@localist.user'}
                ]

                @onAttendeeSubmit { event_instance_id, attendees }
            when 'confirmation'
              @onBuyTicketsClick(new Event('dummy')).then =>
                event_instance_id = @state.instances[0].id
                ticket_quantities = {}
                ticket_quantities[@state.ticket_class_groups[0][1][0].id] = 1
                @onQuantitySubmit { event_instance_id, ticket_quantities }
              .then =>
                event_instance_id = @state.instances[0].id
                attendees = {}
                attendees[@state.ticket_class_groups[0][1][0].id] = [
                  {name: 'Test User', email: 'registered@localist.user'}
                ]

                @onAttendeeSubmit { event_instance_id, attendees }
              .then =>
                event_instance_id = @state.instances[0].id
                @onPaymentSubmit event_instance_id: event_instance_id, token: 'tok_visa', billing_name: 'Joe Test', billing_email: 'joe.test@localist.test'

      componentWillUnmount: () =>
        # Clean up subscriptions to the modal coordinator, to avoid memory leaks
        this.props.modalCoordinator.unsubscribe('modalShow', this.onModalCoordinatorShow)
        this.props.modalCoordinator.unsubscribe('modalHide', this.onModalCoordinatorHide)
        this.props.modalCoordinator.unsubscribe('startLoading', this.onModalCoordinatorStartLoading)
        this.props.modalCoordinator.unsubscribe('stopLoading', this.onModalCoordinatorStopLoading)

      componentDidUpdate: (prevProps) =>
        if this.state.focusButton && this._buttonRef
          this.setState focusButton: false
          buttonRef = this._buttonRef
          setTimeout (=> buttonRef.focus()), 0

      # Modal coordinator callbacks
      onModalCoordinatorShow: (event) =>
        @setState checkoutOpen: true

        if this.props.isModalContainer
          registration_data =  @localStore.get("registration_#{this.props.event_id}")
          if registration_data && registration_data.token
            @setState mode: 'confirmation'
            return
          
          return if @state.loading
          @_getTicketList()

      onModalCoordinatorHide: (event) =>
        @setState checkoutOpen: false

        if this.state.didOpenCheckout
          @setState focusButton: true, didOpenCheckout: false

        if this.props.isModalContainer
          @setState mode: 'waiting', loading: false

      onModalCoordinatorStartLoading: (event) =>
        @setState loading: true

      onModalCoordinatorStopLoading: (event) =>
        @setState loading: false

      onBuyTicketsClick: (event) =>
        event.preventDefault()
        @setState didOpenCheckout: true
        this.props.modalCoordinator.showModal()

      # Handler to cancel the transaction, releasing holds and clearing session
      onCancelClick: (event) =>
        @_cancelReservations()
        # Immediately transition back to waiting state, letting the reset happen in the background
        this.props.modalCoordinator.hideModal()

      onHideModal: (event) =>
        this.props.modalCoordinator.hideModal()

      onApplyPromoCode: (promo_code) =>
        @_getTicketList { promo_code }

      onRemovePromoCode: =>
        @_getTicketList promo_code: ''

      onQuantitySubmit: (data) =>
        return if @state.loading
        @_reserveTickets(data)

      onSubmitRefund: (data) =>
        options = immutable(data)
          .set('order_id',@state.order_id)
          .set('ticket_id',@state.ticket_id)
          .set('token',@state.token)
          .value()

        @_submitRefund(options)

      # Render the ticket quantity chooser
      renderTickets: ->
        `<FocusFirst key="tickets" delay={100} restoreLostFocus={true}>
          <QuantityChooser {...this.props}
                           {...this.state}
                           onSubmit={this.onQuantitySubmit}
                           onCancel={this.onCancelClick}
                           onHideModal={this.onHideModal}
                           onApplyPromoCode={this.onApplyPromoCode}
                           onRemovePromoCode={this.onRemovePromoCode}/>
        </FocusFirst>`

      setButtonRef: (ref) => @_buttonRef = ref

      renderWaiting: =>
        if !@state.loading && @state.checkoutOpen
          # Rendering null keeps the behavior of the button disappearing while the modal is open, but we want it
          # to be visible while loading (for the "Checking" message)
          null
        else
          `<BuyTicketsButton {...this.props}
                             buttonRef={this.setButtonRef}
                             className={this.props.checkoutButtonClassName}
                             error={this.state.error}
                             errorMessage={this.state.errorMessage}
                             loading={this.state.loading}
                             autoFocus={this.state.hasBeenOpened}
                             onClick={this.onBuyTicketsClick} />`

      onAttendeeSubmit: (data) =>
        @_submitAttendees data

      onAttendeeGoBack: (data) =>
        # Immediately go back to 'tickets', and handle updates in background
        @setState mode: 'tickets'
        @_submitAttendees(data, 'tickets')


      # Render the attendee info form
      renderAttendeeDetails: ->
        `<FocusFirst key="attendees" restoreLostFocus={true}>
          <AttendeeDetails {...this.props}
                           {...this.state}
                           onSubmit={this.onAttendeeSubmit}
                           onCancel={this.onCancelClick}
                           onHideModal={this.onHideModal}
                           onGoBack={this.onAttendeeGoBack}/>
        </FocusFirst>`


      onPaymentGoBack: (data) =>
        # Don't worry about saving/restoring anything for the payment
        #
        # Because the inputs are via Stripe, we can't access them anyway
        @setState mode: 'attendees'

      onPaymentSubmit: (data) =>
        @_submitPayment data

      renderPayment: ->
        `<FocusFirst key="payment" restoreLostFocus={true}>
          <Payment {...this.props}
                   {...this.state}
                   onSubmit={this.onPaymentSubmit}
                   onCancel={this.onCancelClick}
                   onHideModal={this.onHideModal}
                   onGoBack={this.onPaymentGoBack}/>
        </FocusFirst>`

      onStartOverClick: =>
        @_cancelReservations().then =>
          this.props.modalCoordinator.hideModal()
          @onBuyTicketsClick(new Event('dummy'))

        # Immediately transition back to waiting state, letting the reset happen in the background
        @setState mode: 'waiting', loading: true
      
      onRequestRefund: =>
        @setState loading: true
        
        options = {order_id: @state.order_details.order.order_id, token: @state.order_details.order.token, refund: 1}
        
        @_getOrderDetails(options)

      renderConfirmation: ->
        if !this.state.autoOpenedModal
          @setState autoOpenedModal: true
          this.props.modalCoordinator.showModal(new Event('auto-open-modal'))

        `<FocusFirst key="confirmation" restoreLostFocus={true}>
          <Confirmation {...this.props}
                       {...this.state}
                       onCancel={this.onStartOverClick}
                       onRequestRefund={this.onRequestRefund}
                       formatInstanceDate={this._formatInstanceDate}
                       hasRefundableTickets={this._hasRefundableTickets} />
        </FocusFirst>`
      
      renderRefundRequest: ->
        `<FocusFirst key="refund" restoreLostFocus={true}>
          <RefundRequest {...this.props}
                  {...this.state}
                  onSubmitRefund={this.onSubmitRefund}
                  formatInstanceDate={this._formatInstanceDate}
                  hasRefundableTickets={this._hasRefundableTickets} />
        </FocusFirst>`

      renderRefundConfirmation: ->
        `<FocusFirst key="refund_confirmation" restoreLostFocus={true}>
          <RefundConfirmation {...this.props}
                              {...this.state}
                              refund_success_ids={this.state.refund_success_ids}
                              refund_failure_ids={this.state.refund_failure_ids}
                              cancel_success_ids={this.state.cancel_success_ids}
                              formatInstanceDate={this._formatInstanceDate} />
        </FocusFirst>`

      # Render the inner components for the current mode
      renderInner: ->
        switch @state.mode
          when 'waiting' then this.renderWaiting()
          when 'tickets' then this.renderTickets()
          when 'attendees' then this.renderAttendeeDetails()
          when 'payment' then this.renderPayment()
          when 'confirmation' then this.renderConfirmation()
          when 'refund' then this.renderRefundRequest()
          when 'refund_confirmation' then this.renderRefundConfirmation()
          else
            console.error 'unknown checkout mode', this.state.mode

            `<p>FIXME Unknown Mode: {this.state.mode}</p>`

      # Render the component, wrapping in a StripeProvider
      render: =>        
        if this.state.mode == 'waiting'
          # Don't wrap waiting in a stripe provider. We don't know yet if there's a stripe API key to use
          # (that comes with the up-to-date ticket data requested by clicking the register button), and
          # StripeProvider doesn't seem to properly reinitialize Stripe if an apiKey is passed in
          # after passing in a null stripe object.
          @renderInner()
        else
          # Render it wrapped in stripe
          stripe_provider_props = {}
          if this.state.stripe_api_key
            stripe_provider_props.apiKey = this.state.stripe_api_key
          else
            stripe_provider_props.stripe = null

          Modal = if this.props.uses_modal then RegisterModal else RegisterContainer

          stripePromise = Stripe.loadStripe(stripe_provider_props.apiKey)

          hideHeader = @state.mode == 'confirmation' || @state.mode == 'refund' || @state.mode == 'refund_confirmation'

          `<ReactStripeElements.Elements stripe={stripePromise}>
              <Modal eventName={this.props.event_name}
                     eventLocation={this.props.event_location}
                     eventPhotoUrl={this.props.event_photo}
                     eventPhotoCaption={this.props.event_photo_caption}
                     headerLabel={this.props.header_label}
                     onCancel={this.onCancelClick}
                     onHideModal={this.onHideModal}
                     modalContainerElement={this.props.modalContainerElement}
                     isModalContainer={this.props.isModalContainer}
                     hideHeader={hideHeader}>
                {this.renderInner()}
              </Modal>
            </ReactStripeElements.Elements>`

      # Clear error state
      _clearError: =>
        @setState error: false, errorMessage: null

      # Generate the URL for the specified endpoint
      _url: (endpoint, include_cart_id=true) =>
        endpoint = "/#{endpoint}" unless endpoint == ""
        endpoint = "/event/#{@props.event_id}/tickets#{endpoint}"
        endpoint = "#{endpoint}?cart_id=#{this.state.cart_id}" if this.state.cart_id # Append cart ID if we have one

        endpoint

      _setLoading: (isLoading) =>
        this.setState loading: isLoading
        if isLoading
          this.props.modalCoordinator?.startLoading()
        else
          this.props.modalCoordinator?.stopLoading()

      # Request the list of available tickets and dates from the server, optionally with additional options
      #
      # The options supported are:
      #   +promo_code+ The requested promo code to apply
      _getTicketList: (options) ->
        # Broadcast the loading state to other instances.
        #
        # Only this loading state change needs to be announced, as it is the only load state that happens when
        # the modal isn't shown.
        @_setLoading true

        $.ajax
          type: 'GET'
          url: @_url('') # nothing at the end
          dataType: 'json'
          data: options
        .then (data, textStatus, jqXHR) =>
          @_clearError()
          @_setLoading false
          @setState immutable.set(data, 'loading', false), @_refreshBrowserTimeOffset
        , (jqXHR, textStatus, errorThrown) =>
          @_setLoading false
          @setState loading: false, error: true, errorMessage: errorThrown
          console.error 'error', textStatus, errorThrown

      # Submit the quantities to the server, reserving the tickets and prompting for attendee details
      _reserveTickets: (options) ->
        @setState loading: true

        $.ajax
          type: 'POST'
          url: @_url('hold')
          dataType: 'json'
          data: options
        .then (data, textStatus, jqXHR) =>
          @_clearError()
          @setState immutable.set(data, 'loading', false), @_refreshBrowserTimeOffset
        , (jqXHR, textStatus, errorThrown) =>
          @setState loading: false, error: true, errorMessage: errorThrown
          console.error 'error reserving tickets', textStatus, errorThrown


      # Submit the attendee details to the server, storing it and proceeding to collect payment information
      _submitAttendees: (options, next_mode=null) ->
        @setState loading: true

        options = immutable(options)
          .set('event_instance_id', @state.selected_instance_id)
          .value()

        $.ajax
          type: 'POST'
          url: @_url('attendees')
          dataType: 'json'
          data: options
        .then (data, textStatus, jqXHR) =>
          @_clearError()
          nextData = immutable.set(data, 'loading', false)
          nextData = immutable.set(nextData, 'mode', next_mode) if next_mode
          @setState nextData, @_refreshBrowserTimeOffset
        , (jqXHR, textStatus, errorThrown) =>
          @setState loading: false, error: true, errorMessage: errorThrown
          console.error 'error saving attendees', textStatus, errorThrown

      # Submit the payment details to the server
      _submitPayment: (options) ->
        @setState loading: true

        options = immutable(options)
          .set('event_instance_id', @state.selected_instance_id)
          .set('seen_compliance_level', @props.compliance_level)
          .set('seen_compliance_text', @props.compliance_text)
          .value()

        $.ajax
          type: 'POST'
          url: @_url('checkout')
          dataType: 'json'
          data: options
        .then (data, textStatus, jqXHR) =>
          @_clearError()

          if data.order_status == 'payment_redirect'
            # A browser redirect is needed to collect payment information.
            switch data.redirect_method
              when 'form_post'
                # Build a form POST and submit it
                form = document.createElement('form')
                form.action = data.redirect_url
                form.method = 'POST'
                form.style = 'display:none;'

                # Create form elements
                for key, value of data.redirect_form_data
                  input = document.createElement('input')
                  input.type = 'hidden'
                  input.name = key
                  input.value = value
                  form.appendChild(input)

                # Append to document and submit
                document.body.appendChild(form)
                form.submit()


              when 'redirect'
                # Conventional redirect
                window.location.href = data.redirect_url

              else
                # Conventional redirect for compatibility
                window.location.href = data.redirect_url

          else if data.order_status == 'confirmed'
            # Order is complete, so store the confirmation data and display it to the user
            @localStore.set "registration_#{data.event_id}", {order_id: data.order_id, token: data.order_details.order.token}, 7 * 86400
            next_state = immutable(data)
                          .set('loading', false)
                          .set('order_id', data.order_id)
                          .set('token', data.order_details.order.token)
                          .value()
           
            @setState next_state, @_refreshBrowserTimeOffset

            # Trigger event for CRM integration
            @_dispatchOrderCompleteEvent(data)
          else
            @setState immutable.set(data, 'loading', false), @_refreshBrowserTimeOffset


        , (jqXHR, textStatus, errorThrown) =>
          @setState loading: false, error: true, errorMessage: errorThrown
          console.error 'error handling payment', textStatus, errorThrown

      # Request that the server clears reservations and session state
      _cancelReservations: (options) ->
        @setState loading: true
        @localStore.remove "registration_#{@props.event_id}"

        $.ajax
          type: 'POST'
          url: @_url('cancel')
          dataType: 'json'
          data: options
        .then (data, textStatus, jqXHR) =>
          @setState (previousState) ->
            # Clear everything in state
            nextState = {}
            for key of previousState
              nextState[key] = undefined

            # and reset it to defaults
            nextState['loading'] = false
            nextState['mode'] = 'waiting'
            nextState['error'] = false
            nextState['errorMessage'] = ''

            nextState
        , (jqXHR, textStatus, errorThrown) =>
          @setState loading: false, error: true, errorMessage: errorThrown
          console.error 'error cancelling reservation', textStatus, errorThrown

      # Finalize the checkout
      #
      # This doesn't need to update any component state, just trigger the localist:order:complete event
      _finalizeCheckout: (options) ->
        return if finalize_called

        # Mark it as called before issuing the request, to avoid multiple calls to finalize
        finalize_called = true
        $.ajax
          type: 'POST'
          url: @_url('finalize')
          dataType: 'json'
          data: options || {}
        .then (data, textStatus, jqXHR) =>
          @localStore.set "registration_#{this.props.event_id}", {
            cart_id: this.props.completed_ticket_order_cart_id,
            order_id: data.order_details.order.order_id,
            token: data.order_details.order.token
          }, 7 * 86400

          next_state = immutable(data)
                        .set('loading', false)
                        .set('order_id', data.order_id)
                        .set('token', data.order_details.order.token)
                        .value()

          @setState next_state, @_refreshBrowserTimeOffset
          
          if data.order_status == 'confirmed'
            @_dispatchOrderCompleteEvent(data)
      
      # Retrieve details for given order
      _getOrderDetails: (options) ->
        $.ajax
          type: 'GET'
          url: @_url('order_details')
          dataType: 'json'
          data: options || {}
        .then (data, textStatus, jqXHR) =>
          @setState immutable.set(data, 'loading', false), @_refreshBrowserTimeOffset
        , (jqXHR, textStatus, errorThrown) =>
          @setState loading: false, error: true, errorMessage: errorThrown
          console.error 'error getting order details', textStatus, errorThrown
      
      # Submit a ticket refund request
      _submitRefund: (data) ->
        @setState loading: true

        $.ajax
          type: 'POST'
          url: @_url('refund')
          dataType: 'json'
          data: data || {}
        .then (data, textStatus, jqXHR) =>
          @_clearError()
          @setState immutable.set(data, 'loading', false), @_refreshBrowserTimeOffset
        , (jqXHR, textStatus, errorThrown) =>
          @setState loading: false, error: true, errorMessage: errorThrown
          console.error 'error refunding tickets', textStatus, errorThrown
      
      # Refresh the browser's time offset for the server
      #
      # Assumes that the offset is consistent, so only calculate it once
      _refreshBrowserTimeOffset: () ->
        return if @state.browser_time_offset

        now = moment(@state.now)
        reservation_ends_at = moment(@state.reservationEnds)
        time_difference = reservation_ends_at.diff(now)
        browser_time_offset = moment().diff(now)

        @setState browser_time_offset: browser_time_offset

      # Trigger the order:complete DOM CustomEvent on this element
      #
      # The event's detail is a cleaned up version of +data+
      _dispatchOrderCompleteEvent: (data) =>
        # Transform data
        imm = immutable({})
        imm.set('event', data.event)
        imm.set('event.id', data.event_id)
        selected_instance = _.find data.instances, id: data.selected_instance_id
        imm.set('event.instance', selected_instance)
        imm.set('purchaser', name: data.user_name, email: data.user_email, id: data.user_id)
        imm.set('order', id: data.order_id, status: data.order_status, total: data.cart.total, currency: data.currency, promo_code: data.promo_code)

        # Collect attendees and group with their questions
        question_map = data.questions.reduce (memo, question) ->
          memo[question.id] = question.question
          memo
        , {}

        ticket_map = data.ticket_class_groups.reduce (memo, ticket_group) ->
          ticket_group[1].forEach (ticket) ->
            memo[ticket.id] = ticket
          memo
        , {}


        # Attendees is ticket_type_id => array of attendee data hashes, so we
        # need to flatten it a bit
        imm.set('attendees', [])

        for ticket_type_id, attendees of data.attendees
          for attendee in attendees
            questions = []
            for question_id, response of attendee.answers
              questions.push id: question_id, question: question_map[question_id], response: response

            attendee_data = {
              name: attendee.name,
              email: attendee.email,
              ticket_type: ticket_map[ticket_type_id].name,
              questions: questions
            }

            imm.push('attendees', attendee_data)

        event_detail = imm.value()

        # Create event
        event = new CustomEvent('localist:order:complete', {
          detail: event_detail,
          bubbles: true,
          cancelable: false,
          composed: false
        })

        # Dispatch it
        node = ReactDOM.findDOMNode(this)
        node.dispatchEvent(event)
      
      # Format an instance hash as a date and time.
      #
      # The +instance+ hash should have start_date and start_time properties.
      _formatInstanceDate: (instance) =>
        date_format = "dddd, MMMM D, YYYY ";
        time_format = "h:mma";
        date_time_string = moment
          .parseZone(instance.start_date)
          .format(date_format);

        if instance.start_time
          date_time_string += moment
            .parseZone(instance.start_time)
            .format(" #{time_format}");

        if instance.end_time
          date_time_string += " to ";
          date_time_string += moment
            .parseZone(instance.end_time)
            .format("#{time_format}");

        return date_time_string;
      
      _hasRefundableTickets: (tickets) =>
        tickets.some((ticket) => ticket["may_request_refund?"])