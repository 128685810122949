import React from "react";
import PropTypes from "prop-types";
import { ConsentStatus } from "../api";

export function SettingsPanel({ onUpdateConsentStatus }) {
  return (
    <div className="c3d-cookie-consent-prompt--panel c3d-cookie-consent-prompt--panel--settings">
      <div className="c3d-cookie-consent-prompt--description">
        <h1 id="c3d-cookie-consent-prompt-title">Cookie Settings</h1>
        <p id="c3d-cookie-consent-prompt-description">
        When you visit any Concept3D website, it may store or retrieve information on your browser, mostly in the form of cookies. 
        This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. 
        The information does not usually directly identify you, but it can give you a more personalized web experience. 
        Because we respect your right to privacy, you can choose to accept only the necessary cookies essential to the functioning of 
        this calendar. Please note, blocking some types of cookies may impact your experience of the site and the services we are able to 
        offer.{" "}
          <a
            href="https://concept3d.com/concept3d-privacy-policy/"
            target="_blank"
          >
            Learn More »
          </a>
        </p>
      </div>
      <div className="c3d-cookie-consent-prompt--actions">
        <button
          className="c3d-cookie-consent-prompt--button c3d-cookie-consent-prompt--button-primary"
          onClick={() => onUpdateConsentStatus(ConsentStatus.ACCEPTED)}
        >
          Accept All Cookies
        </button>
        <button
          className="c3d-cookie-consent-prompt--button"
          onClick={() => onUpdateConsentStatus(ConsentStatus.NECESSARY_ONLY)}
        >
          Accept Necessary Cookies
        </button>
      </div>
    </div>
  );
}

SettingsPanel.propTypes = {
  onUpdateConsentStatus: PropTypes.func
};
