# DateJS-backed dynamic date input widget and utilities

namespace 'Slzr.UI.DateField', (exports, top) ->
  $ = Slzr.jQuery
  jQuery = Slzr.jQuery

  # Controller for date inputs
  #
  # Manages input parsing and triggers events
  class Input
    default_options:
      # Input element to attach to
      element: null

      # Element to set parsed value on
      value_element: null

      # Initial format for parsed dates
      initial_date_format: Slzr.Formats.ShortDate

      # Initial format for parsed times
      initial_time_format: Slzr.Formats.LongTime

      # Bias in date input
      bias: 'future_date'

    constructor: (options) ->
      @options = $.extend {}, @default_options, options
      @element = $(@options.element)
      @value_element = $(@options.value_element) if @options.value_element

      @element.on 'keyup change slzr:date_changed', @_inputChanged

      @_initializeInput()
      @_inputChanged()

    # Handler for input field being changed
    #
    # Parses the date, sets data, and triggers the appropriate events
    _inputChanged: =>
      # If we're in an emulated placeholder, pretend it's blank
      val = @element.val()
      val = '' if @element.hasClass('input_placeholder')

      switch date = @_parseDate val
        when 'blank'
          @element.data 'date', null
          @element.data 'date-status', 'blank'
          @element.trigger 'slzr:date:change', ['blank', null]
          @value_element?.val null
        when 'error'
          @element.data 'date', null
          @element.data 'date-status', 'error'
          @element.trigger 'slzr:date:change', ['invalid', null]
          @value_element?.val null
        else
          @element.data 'date', date
          @element.data 'date-status', 'ok'
          @element.trigger 'slzr:date:change', ['valid', date]
          @value_element?.val date.format('Y-m-d')

    # Parse the entered date and return the parsed date, 'blank', or 'error'
    _parseDate: (str) ->
      if str.isBlank()
        return 'blank'
      else
        bias = @options.bias || 'future_date'
        if d = Date.parse(str, bias: bias)
          return d
        else
          return 'error'

    # Initialize the input
    #
    # Mainly will take a pre-filled date and standardize it, including 
    # "today" or "tomorrow" as appropriate.
    #
    # The initial value is expected to be either a YYYY-MM-DD formatted date for date mode, or
    # a HH:MM:SS formatted time for time mode.
    _initializeInput: =>
      # Check initial value, and if it matches placeholder ignore it
      val = @element.val()
      val = null if @element.hasClass('input_placeholder')

      if @options.mode == 'date'
        if d = new Date("#{val}T00:00:00")
          if 0 == Date.compare d, Date.today()
            @element.val 'Today'
          else if 0 == Date.compare d, Date.today().add(1).days()
            @element.val 'Tomorrow'
          else
            @element.val d.toString(@options.initial_date_format)
      else # time
        if d = new Date(val)
          @element.val d.toString(@options.initial_time_format)

  # Date Preview Controller
  class Preview
    default_options:
      # The input element to sync with
      input_element: null

      # The preview output element
      element: null

      # The preview formats
      date_format: Slzr.Formats.LongDate
      time_format: Slzr.Formats.LongTime

      # Error messages
      date_error: 'Unknown date'
      time_error: 'Unknown time'

      # Mode (date or time)
      mode: 'date'

    constructor: (options) ->
      @options = $.extend {}, @default_options, options
      @element = $(@options.element)
      @input_element = $(@options.input_element)

      if @options.mode == 'date'
        @format = @options.date_format
        @error_message = @options.date_error
      else
        @format = @options.time_format
        @error_message = @options.time_error

      @input_element.on 'slzr:date:change.preview', @_updatePreview

    # Update the preview based on state and date
    _updatePreview: (event, state, date) =>
      switch state
        when 'blank'   then @element.html ''
        when 'invalid' then @element.html @error_message
        when 'valid'   then @element.html date.toString(@format)

  initialize = (el, my_options) ->
    input_element = $(el)
    preview_element = if input_element.data('preview')? then $(input_element.data('preview')) else my_options.preview_element
    value_element = if input_element.data('value')? then $(input_element.data('value')) else null
    
    if my_options.mode == 'date'
      date_format = input_element.data('format')
      initial_date_format = input_element.data('initial-format')
      date_error = input_element.data('date-error')
    else
      time_format = input_element.data('format')
      initial_time_format = input_element.data('initial-format')
      time_error = input_element.data('time-error')

    bias = input_element.data('date-bias')

    preview_options = $.extend {
      input_element: input_element
      element: preview_element
      time_format: time_format
      time_error: time_error
      date_format: date_format
      date_error: date_error
    }, my_options

    input_options = $.extend {
      element: input_element
      value_element: value_element
      initial_date_format: initial_date_format
      initial_time_format: initial_time_format
      bias: bias
    }, my_options

    new Preview(preview_options) if preview_element?
    new Input(input_options)

   
  # Add jQuery function for attaching
  jQuery.fn.extend
    dateInput: (options) ->
      my_options = options

      this.each -> initialize(this, my_options)
      this
  # Automatically attach to anything with data-input=date or data-input=time
  #
  # Data attributes:
  #   data-input => is this a 'date' or 'time' input
  #   data-preview => selector to the associated preview element
  #   data-format => datejs format string for preview
  #   data-initial-format => datejs format string for initial input
  #   data-date-error => "Unknown" string for invalid dates
  # 
  # Only data-input is required
  jQuery ->
    $('input[data-input="date"]').dateInput(mode: 'date')
    $('input[data-input="time"]').dateInput(mode: 'time')

  # Export everything
  exports.Input = Input
  exports.Preview = Preview
  exports.initialize = initialize
