# The main logic for handling message center actions

namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  class exports.MessageCenter
    constructor: (options={}) ->
      @attachEventListeners()

    attachEventListeners: ->
      jQuery(document).delegate '[data-message-item] input[type="button"][data-remote]', 'click', (ev) ->
        target = $(ev.target)
        container = target.closest '[data-message-item]'
        form = container.find '[data-message-item-form]'

        url = target.data 'url'
        disable_with = target.data 'disable-with'

        if disable_with?
          old_label = target.val()
          target.val(disable_with).prop('disabled', true)
        else
          old_label = null

        # Serialize the form
        data = form.find(':input').serialize()

        $.post url, data, (data) ->
          if old_label?
            target.val(old_label).prop('disabled', false)

        ev.preventDefault()
