// registration/components/checkout/refund_confirmation
modulejs.define(
  "registration/components/checkout/refund_confirmation",
  ["react", "prop-types", "registration/components/checkout/tickets_list", 'registration/utilities'],
  (React, PropTypes, TicketsList, RegistrationUtilities) => {
    const RefundConfirmation = ({
      order_details,
      refund_failure_ids,
      loading,
      formatInstanceDate,
      ...props
    }) => {
      if (loading) {
        return (
          <div className="em-register_confirm widget-content">Loading...</div>
        );
      }

      let { event_instance, order, tickets } = order_details;

      return (
        <div className="em-register_confirm widget-content">
          <h2>Manage your tickets for {props.event_name}</h2>

          <p className="em-ticket-date">
            <i className="fa fa-calendar"></i>
            {formatInstanceDate(event_instance)}
          </p>

          <p className="em-ticket-order">
            <i className="fas fa-ticket-alt"></i>
            Order #{order.order_id}
          </p>

          <div className="em-order-summary">
            {refund_failure_ids.length > 0 && (
              <span className="error_marker" role="alert">
                Some tickets could not be refunded. Please contact the event
                organizer.
              </span>
            )}

            <h3 className="em-order-title">
              Your ticket cancellation or refund request has been submitted to the event organizer for:
            </h3>

            <TicketsList
              tickets={tickets}
              ticketFailures={tickets.filter((ticket) =>
                refund_failure_ids.includes(ticket.id)
              )}
              currency={props.currency}
            />
          </div>

          <p className="em-general-info">
            If you have any questions about your cancellation or refund, please contact the
            organizer below.
          </p>

          <ul className="em-actions-list">
            {event_instance.organizer_email && (
              <li className="em-list-item">
                <a href={RegistrationUtilities.organizerTicketMailTo(event_instance.organizer_email, props.event_name)} className="em-link">
                  Contact Organizer
                </a>
              </li>
            )}
          </ul>
        </div>
      );
    };

    RefundConfirmation.propTypes = {
      order_details: PropTypes.object,
      refund_failure_ids: PropTypes.array,
      loading: PropTypes.bool,
      formatInstanceDate: PropTypes.func
    };

    RefundConfirmation.defaultProps = {
      order_details: {},
      refund_failure_ids: [],
    };

    return RefundConfirmation;
  }
);