modulejs.define 'registration/utilities',
  ['underscore', 'currency-formatter'],
  (_, currencyFormatter) ->

    # Format a currency amount
    #
    # Assumes cents
    formatCurrency: (cents, currency='usd') ->
      currencyData = currencyFormatter.findCurrency(currency.toUpperCase()) || currencyFormatter.findCurrency('USD')
      cents_units = Math.pow(10, currencyData.decimalDigits)
      currencyFormatter.format(cents / cents_units, {code: currencyData.code})

    # Format a duration as minutes/seconds
    formatDuration: (duration) ->
      minutes = duration.minutes()
      seconds = duration.seconds()
      seconds = "0#{seconds}" if seconds < 10

      "#{minutes}:#{seconds}"

    # Calculate fees
    #
    # amount is the amount in cents to calculate fees for
    # fee_settings is a hash of fee definitions. each is a hash of  {base: base fee, percent: percentage amount}
    # the fee definitions are:
    #   localist: localist per-order fees
    #   payment: payment provider per-order fees
    #   localist_item: localist per-item fees
    calculateFee: (amount, fee_settings) ->
      # Add any per item fees to the item's amount
      localist_item_fee = fee_settings.localist_item.base + (amount * fee_settings.localist_item.percent)
      subtotal = amount + localist_item_fee

      # Calculate the per-order fees on the subtotal calculated above
      payment_fee = fee_settings.payment.base + (subtotal * fee_settings.payment.percent)
      localist_fee = fee_settings.localist.base + (subtotal * fee_settings.localist.percent)
      Math.round(payment_fee + localist_fee + localist_item_fee)

    # Returns true if +value+ is blank
    isBlank: (value) ->
      value == null ||
      value == undefined ||
      (typeof(value) == 'string' && !!value.match(/^\s*$/))

    organizerTicketMailTo: (email, event_name) =>
      "mailto:#{email}?subject=Question%20about%20my%20ticket%20to%20#{encodeURIComponent(event_name)}"
    
    # Make sure this in sync with registration.ticket_states in config/locales/en.yml
    ticket_status_lookup: (ticket_status) =>
      status_lookup =
        pending: "Pending",
        purchased: "Purchased",
        checked_in: "Checked In",
        refunded: "Refunded",
        voided: "Cancelled",
        disputed: "In Dispute",
        dispute_lost: "Dispute Lost",
        cancelled: "Cancelled",
        refund_requested: "Refund Requested"
        refund_denied: "Refund Denied"

      status_lookup[ticket_status]