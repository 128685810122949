import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { PolicyPanel } from "./policy_panel";
import { SettingsPanel } from "./settings_panel";
import FocusTrap from "../../utils/modal-focus-trap";

export function CookieConsentPrompt({ onUpdateConsentStatus }) {
  const [currentPanel, setCurrentPanel] = useState("policy");
  const dialogElement = useRef(null);

  // Traps focus on the parent node to avoid React warnings inside a controlled
  // element.
  useEffect(() => {
    FocusTrap.focusFirstDescendant(dialogElement.current.parentNode);
  }, []);

  let innerPanel;
  switch (currentPanel) {
    case "policy":
      innerPanel = (
        <PolicyPanel
          onUpdateConsentStatus={onUpdateConsentStatus}
          onSettingsClick={() => {
            setCurrentPanel("settings");
          }}
        />
      );
      break;

    case "settings":
      innerPanel = (
        <SettingsPanel onUpdateConsentStatus={onUpdateConsentStatus} />
      );
      break;

    default:
      console.error("Unknown prompt page: ", currentPanel);
      innerPanel = <div>Unknown page!!</div>;
      break;
  }

  return (
    <div
      ref={dialogElement}
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="c3d-cookie-consent-prompt-title"
      aria-describedby="c3d-cookie-consent-prompt-description"
      id="c3d-cookie-consent-prompt"
      className="c3d-cookie-consent-prompt"
    >
      {innerPanel}
    </div>
  );
}

CookieConsentPrompt.propTypes = {
  // Callback when the user selects a consent status
  // Receives one parameter, a string from the ConsentStatus enum
  onUpdateConsentStatus: PropTypes.func
};
