# Photo chooser UI
#
# Links photo chooser react UI with the page

namespace 'Slzr.UI', (exports, top) ->
  $ = jQuery = Slzr.jQuery
  EventEmitter = modulejs.require('EventEmitter')

  React = require('react')
  ReactDOM = require('react-dom')
  PhotoChooserComponent = modulejs.require('slzr/components/photo_chooser')
  _ = modulejs.require('underscore')

  class exports.PhotoChooser extends EventEmitter
    default_options:
      # Dummy photo record
      photo:      {}
      # React element
      element:    null
      # Target URL for uploads
      upload_url: "/photos/upload"
      # URL to retrieve library contents from
      library_url:"/photos/library"
      # Active photo aspect ratio
      aspect: 1
      # Allow cropping
      cropping: true
      # Allow uploading
      uploading: true
      # Allow choosing
      library: true
      # Allow removal
      remove: true
      # Always allow cropping
      force_cropping: false
      # Crop area name
      crop_area_name: 'default'
      # Crop area param name
      crop_area_param: 'photo[crop_areas]'
      # Show adjust cropping link
      show_crop_editor_link: true
      # Show editor link
      show_editor_link: true

    constructor: (options) ->
      super(options)

      @options = $.extend {}, @default_options, options

      ReactDOM.render React.createElement(PhotoChooserComponent,
            initial_photo:  @options.photo,
            upload_url:     @options.upload_url,
            library_url:    @options.library_url,
            aspect:         @options.aspect
            can_choose:     @options.library,
            can_remove:     @options.remove,
            can_upload:     @options.uploading,
            can_crop:       @options.cropping,
            force_cropping: @options.force_cropping,
            crop_area_name: @options.crop_area_name,
            crop_area_param: @options.crop_area_param
            showCropEditorLink: @options.show_crop_editor_link
            showEditorLink: @options.show_editor_link
            allowCaptionEdit: @options.allow_caption_edit
          ),
          @options.element.get(0)

  # Activate instance manager if the page says so
  $ ->
    $elements = $('[data-photo-chooser="chooser"]')
    return unless $elements.length > 0

    # This needs FormData for uploads, so just completely disable (leaving a lone upload field) if it isn't supported
    return unless !!window.FormData

    $elements.each ->
      $element = $(this)

      # Set default aspect ratio based on current theme, defaulting to square
      photo_aspect_ratio = if $element.data('photo-aspect') == 'image'
        -1
      else
        Number($element.data('photo-aspect')) || LOCALIST_SETTINGS.photo_aspect_ratio || 1.0

      # Only enable functions specified in data-photo-chooser-enable, or enable
      # everything if not specified.
      if $element.data("photo-chooser-enable")
        parts = $element.data("photo-chooser-enable").split(/\s+/)
        library = parts.indexOf('library') > -1
        remove = parts.indexOf('remove') > -1
        uploading = parts.indexOf('upload') > -1
        cropping = parts.indexOf('crop') > -1
      else
        library = true
        remove = true
        uploading = true
        cropping = true

      force_cropping = !!$element.data('photo-chooser-always-allow-crop')
      crop_area_name = $element.data('photo-chooser-crop-area-name')
      crop_area_param = $element.data('photo-chooser-crop-area-param')
      show_crop_editor_link = $element.data('photo-chooser-show-crop-editor-link')
      show_editor_link = $element.data('photo-chooser-show-editor-link')
      allow_caption_edit = $element.data('photo-chooser-allow-caption-edit')

      # If crop data is specified (as an array of x,y,w,h), override it in the photo metadata
      crop_data = $element.data('photo-crop')

      if crop_data
        photo_data = _.extend {}, Slzr.Meta['photo-data']
        photo_data['crop'] =
          x: crop_data[0]
          y: crop_data[1]
          w: crop_data[2]
          h: crop_data[3]
      else
        photo_data = Slzr.Meta['photo-data'] || {}


      top.photo_chooser = new Slzr.UI.PhotoChooser
        element:        $element
        photo:          photo_data
        aspect:         photo_aspect_ratio
        library:        library
        remove:         remove
        uploading:      uploading
        cropping:       cropping
        force_cropping: force_cropping
        crop_area_name: crop_area_name
        crop_area_param: crop_area_param
        show_crop_editor_link: show_crop_editor_link
        show_editor_link: show_editor_link
        allow_caption_edit: allow_caption_edit

    # Disable drops on the entire page
    $(document.body).on 'dragover', (event) ->
      try
        event.originalEvent.dataTransfer.dropEffect = 'none'
      catch err
        # Silently eat errors
        true

      event.preventDefault()

    $(document.body).on 'drop', (event) ->
      event.preventDefault()
