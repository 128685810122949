# UI widget for inline photo uploader field
#
# A thin wrapper around jquery-fileupload, mostly to centralize sane defaults

namespace 'Slzr.UI.PhotoUploader', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  class exports.Input
    UPLOAD_URL = '/photos/upload'

    default_options:
      url: UPLOAD_URL
      autoUpload: true
      acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i
      dataType: 'json'
      statusElement: null
      type: 'POST'
      pasteZone: null

    # Initialize this instance of the photo uploader on +element+
    constructor: (element, options) ->
      @element = $(element)
      @options = $.extend {}, @default_options, options

      @status_el = if @options.statusElement then $(@options.statusElement) else $(@element.data('status'))

      # Attach the upload handler
      @element.fileupload(@options)
      @_bindEvents()

    # Bind an event to the input element
    bind: (event, callback) =>
      @element.bind(event, callback)
      this

    # Attach listeners for handling the status thing
    _bindEvents: =>
      @element.bind 'fileuploadsend', (event, data) =>
        # debugger
        setTimeout (=> @status_el.html('Uploading...').removeClass('error').show()), 0
        # @status_el.html('Uploading...').removeClass('error').show()

      @element.bind 'fileuploaddone', (event, data) =>
        if data.result.status == 'error'
          message = if data.result.error == 'not_an_image'
                      "Uploaded file must be an image (JPG, GIF, or PNG)"
                    else if data.result.error == 'image_too_big'
                      "Image must be smaller than 10MB"
                    else if data.result.error == 'image_dimensions_too_big'
                      "Image must be smaller than 5000x5000 pixels"
                    else
                      data.result.message

          @status_el.html(message).addClass('error').show()
        else
          @status_el.html('Uploaded').removeClass('error').hide()

      @element.bind 'fileuploadfail', (event, data) =>
        console?.error('Error uploading photo', event, data)
        @status_el.html('Error uploading photo').addClass('error').show()

      # Don't allow pastes if an input element is focused
      # @element.bind 'fileuploadpaste', (event, data) =>
      #   active_element = $(document.activeElement)
      #   if !$(event.target).is(@element) && active_element.is('div.editable, .cke_editable, textarea, input, select') && !active_element.is('input[type="file"]')
      #     event.stopPropagation()
      #     event.preventDefault()
      #     return false
      #   true