modulejs.define 'slzr/react/form_row',
  ['react', 'slzr/components/tooltip', 'underscore', 'prop-types', 'classnames'],
  (React, Tooltip, _, PropTypes, classNames) ->

    class FormRow extends React.Component
      @propTypes:
        title: PropTypes.string
        className: PropTypes.string
        tooltip: PropTypes.string
        tooltipUrl: PropTypes.string
        tooltipLabel: PropTypes.string
        required: PropTypes.bool
        labelFor: PropTypes.string

        # Theme-related things
        wrapperElement: PropTypes.elementType.isRequired
        labelElement: PropTypes.elementType.isRequired
        labelClassName: PropTypes.string

      @defaultProps:
        wrapperElement: 'label'
        labelElement: 'span'
        labelClassName: ''

      render: ->
        tooltip_tag = if this.props.tooltip
          `<Tooltip label={this.props.tooltipLabel}
                    url={this.props.tooltipUrl}
                    text={this.props.tooltip} />`

        required_tag = `<sup className="required">Required</sup>` if this.props.required
        if _.isBlank(this.props.title)
          title_row = `<this.props.labelElement className={this.props.labelClassName} htmlFor={this.props.labelElement === 'label' ? this.props.labelFor : undefined }/>`
        else
          title_row = `<this.props.labelElement className={this.props.labelClassName} htmlFor={this.props.labelElement === 'label' ? this.props.labelFor : undefined }>
            {this.props.title} {required_tag} {tooltip_tag}
          </this.props.labelElement>`

        wrapper_class = classNames this.props.wrapperClassName, this.props.className

        `<this.props.wrapperElement className={wrapper_class}
                                    htmlFor={this.props.wrapperElement === 'label' ? this.props.labelFor : undefined }>
          {title_row}
          {this.props.children}
        </this.props.wrapperElement>`