# Redux store middlewares
modulejs.define 'slzr/redux/middleware',
  ['underscore'],
  (_) ->
    isPromise = (val) -> val? && typeof(val.then) == 'function'

    timer = if typeof performance != "undefined" && typeof performance.now == 'function'
      performance
    else
      Date

    # Promise middleware: loosely based on https://github.com/acdlite/redux-promise/blob/master/src/index.js
    #
    # If action payload is a promise, dispatches the result when it resolves
    promiseMiddleware: (store) ->
      (next) ->
        (action) ->
          if isPromise(action.payload)
            action.payload.then (result) ->
              store.dispatch _.extend({}, action, {payload: result})
            , (error) ->
              store.dispatch _.extend({}, action, {payload: error, error: true})
          else
            next(action)

    # Thunk middleware
    thunkMiddleware: (store) -> (next) -> (action) ->
      if typeof(action) == 'function'
        action(store.dispatch, store.getState)
      else
        next(action)

    # Debounce middleware, debounces on leading edge (more like a throttle?)
    debounceMiddleware: (store) ->
      timers = {}

      (next) -> (action) ->
        debounce = action.meta?.debounce
        if debounce?.timeout? && debounce?.key?
          timeout = debounce.timeout
          key = debounce.key

          unless timers[key]
            next(action)
            timers[key] = setTimeout(
              -> timers[key] = null
            , timeout)
        else
          next(action)

    loggerMiddleware: (store) -> (next) -> (action) ->
      logger = window.console

      return next(action) unless logger?

      started = timer.now()
      prevState = store.getState()

      error = null
      returnedValue = null

      try
        returnedValue = next(action)
      catch e
        error = e

      took = timer.now() - started
      nextState = store.getState()

      time = new Date()
      title = "action #{action.type} #{time} in #{took.toFixed(2)} ms"

      logger.group "%c%s", "color: #000000", title
      logger.log "%c prev state", "color: #9E9E9E", prevState
      logger.log "%c action", "color: #03A9F4", action
      if error
        logger.log "%c error", "color: #F20404", error
      else
        logger.log "%c next state", "color: #4CAF50", nextState

      logger.groupEnd()

      throw error if error
      returnedValue

    # Utility function, returns true if running or built in development mode (either via process.env.NODE_ENV or window.LOCALIST_SETTINGS)
    isDevelopment: ->
      isDevelopment = false

      # Prefer checking process.env.NODE_ENV, but fall back to value provided in LOCALIST_SETTINGS
      #
      # This uses try/catch, rather than conditional chanining process?.env?.NODE_ENV, since process.env.NODE_ENV
      # has some special handling in Webpack via DefinePlugin, and `process.env.NODE_ENV` gets replaced with the literal
      # value configured.
      #
      # Using optional chaining will always fail, because `process` isn't actually defined in webpack builds.
      #
      # When process isn't available (such as in asset pipeline builds), fall back to window.LOCALIST_SETTINGS.development,
      # finally assuming it's not development.
      try
        isDevelopment = process.env.NODE_ENV == 'development';
      catch e
        isDevelopment = window.LOCALIST_SETTINGS?.development || false;

      return isDevelopment