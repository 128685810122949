modulejs.define 'registration/checkout/field_mapper',
  ['object-path'],
  (objectPath) ->

    # Normalize a string, by lowercasing and removing non-letters, numbers, and collapsing spaces
    normalizeString = (string) ->
      string.toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '')
        .replace(/\s+/, ' ')

    # Look up the value at +path+ in +data+
    #
    # This is pretty much a pass-through to object-path.get, except for the following cases:
    #
    # Custom fields:
    #
    # path "event.location.custom_field.*" will match on place custom fields
    # path "event.custom_field.*" will match on event custom fields
    #
    # Attendee questions:
    #
    # path "attendee.question.*" will match on attendee question responses
    #
    # For all of the above, the match is case insensitive, and normalized to remove punctuation, and will match any of id, question, label, key
    #
    # First/Last name
    #
    # path "attendee.first_name", "attendee.last_name" will return the first or remaining words of "attendee.name"
    # path "purchaser.first_name", "purchaser.last_name" will return the first or remaining words of "purchaser.name"
    #
    # All other paths are passed through as is
    lookupValue = (data, path, defaultValue=null) ->
      # Fixed overrides, for split first/last name
      switch path
        when 'attendee.first_name', 'attendee.last_name'
          name = objectPath.get(data, 'attendee.name', defaultValue)

          if path == 'attendee.first_name'
            return name.substring(0, name.indexOf(' ')).trim()
          else
            return name.substring(name.indexOf(' ')).trim()

        when 'purchaser.first_name', 'purchaser.last_name'
          name = objectPath.get(data, 'purchaser.name', defaultValue)

          if path == 'purchaser.first_name'
            return name.substring(0, name.indexOf(' ')).trim()
          else
            return name.substring(name.indexOf(' ')).trim()

      # Prefix overrides, for custom fields and questions
      if matches = path.match(/^(event(?:\.location)?\.custom_field|attendee\.question)\./)
        field_name = path.substring(matches[0].length)
        custom_fields = switch matches[1]
          when 'event.location.custom_field' then objectPath.get(data, 'event.location.custom_fields', defaultValue)
          when 'event.custom_field' then objectPath.get(data, 'event.custom_fields', defaultValue)
          when 'attendee.question' then objectPath.get(data, 'attendee.questions', defaultValue)
          else null

      # Find a matching item in the custom fields array
      if field_name && custom_fields
        normalized_field_name = normalizeString(field_name)

        for item in custom_fields
          if item.question && normalizeString(item.question) == normalized_field_name
            return item.response

          if item.label && normalizeString(item.label) == normalized_field_name
            return item.value

          if item.key && normalizeString(item.key) == normalized_field_name
            return item.value

        # Not found
        return null

      # Path fallback
      objectPath.get(data, path, defaultValue)





    # exports
    { lookupValue }