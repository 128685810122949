modulejs.define 'slzr/conference/schedule_form/form_component',
  ['underscore', 'moment', 'react', 'react-dom', 'prop-types', 'slzr/react/form_row', 'slzr/react/form_input_group', 'slzr/components/date_input_row'],
  ( _, moment, React, ReactDOM, PropTypes, FormRow, FormInputGroup, DateInputRow) ->

    class ConferenceFormComponent extends React.Component
      @propTypes:
        startDate: PropTypes.string
        endDate: PropTypes.string
        eventInstances: PropTypes.array
        invalidText: PropTypes.string
      
      @defaultProps:
        startDate: null
        endDate: null
        eventInstances: []
        invalidText: "Dates invalid"

      constructor: (props) ->
        super(props)
        
        this.state =
          start_date: props.startDate
          start_date_invalid: true
          end_date: props.endDate
          end_date_invalid: true
          valid_entry: false
          scheduled_date: props.eventInstances

      _inputChanged: (event, value) =>
        
        switch event.target.name
          when 'start_date'
            if value == 'error' || value == 'blank'
              @setState start_date_invalid: true, start_date: null, @_validateState
            else
              @setState start_date_invalid: false, start_date: value, @_validateState
          when 'end_date'
            if value == 'error' || value == 'blank'
              @setState end_date_invalid: true, end_date: null, @_validateState
            else
              @setState end_date_invalid: false, end_date: value, @_validateState
      
      # Validate the state and set the global validation flag appropriately
      _validateState: =>
        # Form validation checks
        valid_start_date = !this.state.start_date_invalid && this.state.start_date
        valid_end_date = !this.state.end_date_invalid || this.state.end_date
        start_date_invalid = this.state.start_date_invalid || !this.state.start_date
        end_date_invalid = this.state.end_date_invalid || !this.state.end_date
        
        @setState
            valid_entry: valid_start_date && valid_end_date
            start_date_invalid: start_date_invalid
            end_date_invalid: end_date_invalid
        , @_updateScheduleDate

      _updateScheduleDate: =>
        @setState summary_text: this._updateSummaryText(this.state.start_date, this.state.end_date)

        if this.state.start_date && this.state.end_date
          format = "YYYY-MM-DD"
          dates = []
          start_date = moment(this.state.start_date)
          now = start_date.clone()
          end_date = moment(this.state.end_date)
          
          if end_date.diff(start_date, 'days') >= 0
            while now.isSameOrBefore(end_date)
              dates.push(now.format(format) + ",,")
              now.add 1, "days"
            
            this.setState
              scheduled_date: dates
              summary_text: this._updateSummaryText(this.state.start_date, this.state.end_date)
          else
            this.setState
              schedule_date: []
              summary_text: this._updateSummaryText(this.state.start_date, this.state.end_date)
      
      _updateSummaryText: (start_date, end_date)=>
        return this.props.invalidText unless start_date && end_date
        "#{moment(start_date).format('ddd, MMM D, YYYY')} through #{moment(end_date).format('ddd, MMM D, YYYY')}"

      _renderScheduledDates: =>
        if this.state.scheduled_date
          _.map this.state.scheduled_date, (instance_date) =>
            `<input key={instance_date} type='hidden' name='scheduled_date[]' value={instance_date} />`

      render: =>
        `<div>
          {this._renderScheduledDates()}
          
          <input type="hidden" name="event[start_date]" value={this.state.start_date} />
          
          <DateInputRow label="Start Date"
                        name="start_date"
                        placeholder="'Tomorrow' or 'Next Friday' or '4/10'"
                        initialValue={this.state.start_date}
                        onChange={this._inputChanged}
                        showPreview={false}
                        showError={false}
                        required={true} />
          
          <DateInputRow label="End Date"
                        name="end_date"
                        placeholder="'Tomorrow' or 'Next Friday' or '4/10'"
                        initialValue={this.state.end_date}
                        onChange={this._inputChanged}
                        showPreview={false}
                        showError={false}
                        required={true} />
                        
          {this.state.summary_text && <FormRow title="Summary">
            <div className="schedule-summary">{this.state.summary_text}</div>
          </FormRow>}
        </div>`