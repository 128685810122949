module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 /* Admin override for filter_item JST template */ ;
__p += '\n<span class="picked_item em-picked_item" id="' +
((__t = ( selected_prefix )) == null ? '' : __t) +
'_' +
((__t = ( tag_id )) == null ? '' : __t) +
'">\n  <input type="hidden" value="' +
((__t = ( tag_id )) == null ? '' : __t) +
'" name="';
 if(param_name) { ;
__p +=
((__t = ( param_name )) == null ? '' : __t);
 } else { ;
__p +=
((__t = ( param )) == null ? '' : __t) +
'' +
((__t = ( attribute ? '[' + attribute + ']' : '' )) == null ? '' : __t) +
'[]';
 } ;
__p += '" />\n  ' +
((__t = ( tag_name )) == null ? '' : __t) +
'\n  <a href="#" data-action="remove-tag" data-tag-prefix="' +
((__t = ( selected_prefix )) == null ? '' : __t) +
'" data-tag-id="' +
((__t = ( tag_id )) == null ? '' : __t) +
'" data-tag-context="' +
((__t = ( attribute )) == null ? '' : __t) +
'">&times;</a>\n</span>\n';

}
return __p
}