# Very simple translation file for EventReach
#
# Note: This is CoffeeScript, not YAML, so even though it looks similar
# the syntax is more strict. In particular, quotes are required.
modulejs.define 'slzr/eventreach/messages', ->
  # Return the translation dictionary
  #
  # The top level key is the rule name, which has three subkeys:
  #   `label`: Displayed label
  #   `tooltip`: Optional tooltip to display
  #   `order`: Order to show
  name:
    label: 'Name'
    order: 1
  description:
    label: 'Description at least 160 characters'
    order: 2
  date:
    label: 'Date more than 7 days away'
    order: 3
  location:
    label: "Location"
    order: 4
  place:
    label: "Linked to place page"
    order: 5
    #tooltip: "Place tooltip"
  photo:
    label: "Photo"
    order: 6
  filters:
    label: "Filters"
    order: 7
  ticket_url:
    label: "Ticket URL"
    order: 8
  keywords_or_tags:
    label: "Keywords or tags"
    order: 9
  hashtag:
    label: "Hashtag"
    order: 10
  facebook_or_url:
    old_label: "Website or Facebook URL"
    label: "Website"
    order: 11
  featured_or_sponsored:
    label: "Featured or sponsored"
    order: 12
  allows_reviews:
    label: "Allows user interaction"
    order: 13
  included_in_trending:
    label: "Included in trending"
    order: 14
