modulejs.define 'slzr/xhrfileupload',
  ['jquery'],
  ($) ->

    # Feature detection
    supportsXhrUploads = !!(window.XMLHttpRequestUpload && window.FileReader);
    supportsFormData = !!window.formData;

    # Upload a file using XR, returning a jquery promise
    #
    # options:
    #   url: URL to upload to
    #   method: HTTP method to use
    #   formData: additional form data to include
    #   file: File object
    #   param: Param name for file
    #   progress: Callback for upload progress
    #   dataType: data type of the response
    xhrfileupload = (options) ->
      formData = (options.formData || new FormData)
      formData.append options.param, options.file

      xhr_options =
        url: options.url
        type: options.method
        data: formData
        contentType: false
        processData: false
        dataType: options.dataType || 'json'

      # If a progress callback is provided, enhance the jqXHR object to call it
      if options.progress
        xhr_options.xhr = ->
          my_xhr = $.ajaxSettings.xhr()
          if my_xhr.upload
            my_xhr.upload.addEventListener 'progress', options.progress, false
          my_xhr

      $.ajax xhr_options

    xhrfileupload