modulejs.define 'slzr/channel_pages/ui/maps',
  ['underscore', 'jquery', 'slzr', 'moment',
   'slzr/utils/truncate'],
  (_, $, Slzr, moment,
   truncate) ->

    # A list of events for a specific geographic location
    class Marker
      # Marker point
      latitude: null
      longitude: null
      address: null
      title: null
      place_url: null

      # Events
      events: []

    class Maps
      markers: []
      maps: []

      # Collect json-ld data on the page, deduplicate it, and return it
      _collectData: ->
        # Collect the json content for all json-ld tags on the page
        events = $('script[type="application/ld+json"]').map (index, element) =>
          JSON.parse(element.text)
        .get()

        # Only keep events with a location
        events = _.select events, (item) ->
          item['@type'] == 'Event' &&
            (item['@context'] == 'http://schema.org' || item['@context'] == 'https://schema.org') &&
            item.location?.geo?['@type'] == 'GeoCoordinates'

        # Sort by date
        events = _.sortBy events, (item) -> [item['startDate'], item['name']]

        # And deduplicate it to get the unique events on this page
        #
        # This leaves the earliest instance of each event in the list
        events = _.unique events, false, (item) -> item['url']

        # Now, we need to cluster it by location
        events = _.groupBy events, (item) =>
          [item.location?.geo?.latitude, item.location?.geo?.longitude]

        # Create a marker for each
        @markers = []
        _.each events, (location_events, location) =>
          if location_events[0].location?.geo?.latitude?
            # Find the best event's location for labelling the marker
            best_location = _.sortBy location_events, (item) ->
              (if item.location?.geo?.latitude then 1 else 0) +
                (if item.location?.address then 1 else 0) +
                (if item.location?.url then 1 else 0)
            best_location = best_location[0]

            marker = new Marker()
            marker.latitude = best_location.location.geo.latitude
            marker.longitude = best_location.location.geo.longitude
            marker.address = best_location.location.address
            marker.title = best_location.location.name
            marker.place_url = best_location.location.url
            marker.events = location_events
            @markers.push marker

      # Callback for a map marker being clicked
      #
      # +map+ is the google map instance
      # +marker+ is the Marker instance representing which marker was clicked
      # +google_marker+ is the google maps marker clicked
      _markerClicked: (map, marker, google_marker) =>
        event_links = []

        for event in marker.events.slice(0, 5)
          event_date = moment(event['startDate']).format('M/D')
          event_links.push "<p><span class=\"event-date\">#{event_date}</span> <a href=\"#{event.url}\">#{truncate(event.name, 60)}</a></p>"

        contents = """
          <div>
            <div class="event-date">#{marker.title}</div>
            <p>#{marker.address}</p>
            <p></p>
            #{event_links.join('')}
          </div>
        """

        @infoWindow = new google.maps.InfoWindow(maxWidth: 200) unless @infoWindow
        @infoWindow.setContent(contents)
        @infoWindow.open(map, google_marker)


      # Initialize the map on the provided element, creating markers and such
      _initializeMap: (element) ->
        map_options = _.assign {}, @map_options, {
          element: element,
          points: []
        }
        map = new Slzr.Maps.Map map_options

        for marker in @markers
          google_marker = map.addMarker marker.latitude, marker.longitude
          google_marker.addListener 'click', @_markerClicked.bind(this, map, marker, google_marker)

      # Initialize the specified list of map elements on the page
      _initializeMaps: (elements) ->
        my = this
        elements.each -> my._initializeMap(this)

      constructor: (elements) ->
        if elements.length > 0
          @map_options = Slzr.Meta['map-options']

          # Collect and dedupe the json-ld data
          @_collectData()
          @_initializeMaps(elements)
