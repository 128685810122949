modulejs.define 'slzr/react/focus_first',
  ['react', 'slzr/modal-focus-trap'],
  (React, FocusTrap, PropTypes) ->

    # Utility component to force focus on the first focusable descendant on mount
    #
    # Delay can be specified to make it wait longer before focusing, such as to allow animations to finish
    class FocusFirst extends React.Component
      @defaultProps:
        delay: 0
        restoreLostFocus: false

      componentDidMount: =>
        setTimeout (=> this.focusFirst()), this.props.delay

      componentDidUpdate: (prevProps) =>
        return unless this.props.restoreLostFocus

        # If focus fell out of this component, restore it to the first element
        unless this._ref.contains(document.activeElement)
          setTimeout (=> this.focusFirst()), 0

      # Focus the first focusable descendent, if possible
      focusFirst: =>
        return false unless this._ref
        
        elements = FocusTrap.focusableDescendants(this._ref)
        for i in [0 .. elements.length - 1] by 1
          target = elements[i]

          try
            target.focus()
          catch e
            true

          return true if document.activeElement == target
        return false


      setRef: (ref) => this._ref = ref

      render: ->
        `<div ref={this.setRef}>
          {this.props.children}
        </div>`
