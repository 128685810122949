const { set } = require("react-hook-form");

modulejs.define(
  "registration/components/checkout/refund_request",
  ["react", "prop-types", "registration/components/checkout/tickets_list", 'registration/utilities'],
  (React, PropTypes, TicketsList, RegistrationUtilties) => {
    const RefundRequest = ({
      order_details,
      onSubmitRefund,
      loading,
      formatInstanceDate,
      hasRefundableTickets,
      ...props
    }) => {
      if (loading) {
        return (
          <div className="em-register_confirm widget-content" role="status">Loading...</div>
        );
      }

      const [eventTicketIds, setEventTicketIds] = React.useState([]);
      const [canSubmit, setCanSubmit] = React.useState(true);
      let { event_instance, order, tickets } = order_details;
      let notPastEvent = !moment(event_instance.start_date).isBefore(moment());

      const handleCheckboxChange = (event) => {
        if (event.target.checked) {
          setEventTicketIds([...eventTicketIds, event.target.value]);
        } else {
          setEventTicketIds(
            eventTicketIds.filter((id) => id !== event.target.value)
          );
        }
      };

      // call onSubmitRefund with selectedTickets
      const handleOnSubmit = (e) => {
        e.preventDefault();

        if (!onSubmitRefund) {
          return;
        }

        if (eventTicketIds.length === 0) {
          setCanSubmit(false);
        } else {
          setCanSubmit(true);

          onSubmitRefund({
            event_ticket_ids: eventTicketIds,
            order_id: order.order_id
          });
        }
      };

      const setTickets = () => {
        if (props.ticket_id) {
          return tickets.filter((ticket) => {
            return ticket.id === parseInt(props.ticket_id);
          });
        } else {
          return tickets;
        }
      };

      return (
        <div className="em-register_confirm widget-content">
          {!canSubmit && (
            <span className="error_marker" role="alert">
              Please select a ticket to cancel or refund.
            </span>
          )}

          <h2>Manage your tickets for {props.event_name}</h2>
          <p className="em-ticket-date">
            <i className="fa fa-calendar"></i>
            {formatInstanceDate(event_instance)}
          </p>

          <p className="em-ticket-order">
            <i className="fas fa-ticket-alt"></i>
            Order #{order.order_id}
          </p>

          <p className="em-register_confirm-info">
            If you have any questions about your order, reply directly to the
            confirmation email, or contact the organizer below.
          </p>

          <form onSubmit={handleOnSubmit}>
            <div className="em-order-summary">
              <h3 className="em-order-title">
                Please select which tickets to cancel or request a refund for:
              </h3>

              <TicketsList
                tickets={setTickets()}
                onCheckboxChange={handleCheckboxChange}
                selectedIds={eventTicketIds}
                showCheckboxes={notPastEvent}
                ticketCheck={(ticket) =>
                  ticket["may_request_refund?"]
                }
                currency={props.currency}
              />
            </div>

            <ul className="em-actions-list">
              {notPastEvent && (
                <li className="em-list-item">
                  <button className="em-link">
                    Submit Request
                  </button>
                </li>
              )}
              {event_instance.organizer_email && (
                <li className="em-list-item">
                  <a href={RegistrationUtilties.organizerTicketMailTo(order_details.event_instance.organizer_email, props.event_name)} className="em-link">
                    Contact Organizer
                  </a>
                </li>
              )}
            </ul>
          </form>
        </div>
      );
    };

    RefundRequest.propTypes = {
      onSubmitRefundRequest: PropTypes.func,
      order_details: PropTypes.object,
      loading: PropTypes.bool
    };

    RefundRequest.defaultProps = {};

    return RefundRequest;
  }
);
