# Popup utility used with zoom_authorization route
namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  # WP_CHANGE: i18n definitions comes from en.yml:489
  MESSAGES = {
    authorize_and_create_meeting: 'Create Zoom Meeting'
    create_meeting: 'Create Zoom Meeting'
    create_meeting_progress: 'Creating Zoom Meeting...'
    create_meeting_error: 'There was a problem creating the meeting. Please try again'
    delete_meeting: 'Delete Zoom Meeting'
    delete_meeting_progress: 'Deleting Zoom Meeting...'
    delete_meeting_error: 'There was a problem deleting the meeting. Please try again'
    need_reauthorize_error: 'You must reconnect your Zoom account. Please try again to do so'
    delete_confirmation: 'Are you sure you want to delete this Zoom meeting? This cannot be undone.'
  }


  class exports.ZoomConnect
    constructor: (state, event_id, has_zoom_integration) ->
      this.state = state
      
      this.event_id = if !event_id || event_id?.trim() == "null"
                        ""
                      else
                        event_id
      
      if has_zoom_integration
        this.authLink = this.getElement("authorize_and_create_zoom_meeting_link")
        this.authLink?.addEventListener("click", this.connect)
        this.authorize_and_create_wrapper = this.getElement("authorize_and_create_zoom_meeting")
        
        this.meetingLink = this.getElement("create_zoom_meeting_link")
        this.meetingLink?.addEventListener("click", this.create)
        this.create_wrapper = this.getElement("create_zoom_meeting")
        
        this.destroyLink = this.getElement("delete_zoom_meeting_link")
        this.destroyLink?.addEventListener("click", this.destroy)
        this.destroy_wrapper = this.getElement("delete_zoom_meeting")
        
        this.updateUrlDisplay()

    ###*
     * Update the title stored on meetingLink
     *
     * @param {String} new_title The new meeting title
    ###
    updateTitleData: (new_title) =>
      this.meetingLink?.dataset.title = new_title

    ###*
     * Update the start time and duration data stored on meetingLink
     *
     * @param {EventInstanceManager} event_instance_manager The EventInstanceManager instance to update data from
    ###
    updateTimeData: (event_instance_manager) =>
      if event_instance_manager? && this.meetingLink?
        next_instance = event_instance_manager.nextInstanceAt()
        duration = event_instance_manager.nextInstanceDurationMinutes()
        this.meetingLink.dataset.startTime = if next_instance then next_instance.toJSON() else ''
        this.meetingLink.dataset.duration = if duration > 0
                                              duration
                                            else if event_instance_manager.nextInstance()?.start_time == ''
                                              # All day events, 24 hours
                                              24 * 60
                                            else
                                              # No end time, default to 60 minutes (matches Zoom's default)
                                              60


    updateUrlDisplay: =>
      if this.state == "no_meeting"
        this.authorize_and_create_wrapper?.style.display = "none"
        this.create_wrapper?.style.display = "block"
        this.destroy_wrapper?.style.display = "none"
      else if this.state == "meeting"
        this.authorize_and_create_wrapper?.style.display = "none"
        this.create_wrapper?.style.display = "none"
        this.destroy_wrapper?.style.display = "block"
      else
        this.authorize_and_create_wrapper?.style.display = "block"
        this.create_wrapper?.style.display = "none"
        this.destroy_wrapper?.style.display = "none"
    
    updateState: (state) =>
      this.state = state
      this.updateUrlDisplay()
    
    # Show auth popup
    connect: (e)=>
      e.preventDefault()
      
      windowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,height=600, width=600"
      
      @popup = window.open "/zoom/connect", "Authorize", windowFeatures
    
    # Called after user has been redirected to /zoom_authorization/connected from zoom authorization
    connected: () =>
      target = document.getElementById('create_zoom_meeting_link')
      this.createMeeting(target.dataset.title, "authorize_and_create_meeting_link", target.dataset.startTime, target.dataset.duration)
        
    create: (e) =>
      e.preventDefault()
      this.createMeeting(e.target.dataset.title, "create_zoom_meeting_link", e.target.dataset.startTime, e.target.dataset.duration)
    
    destroy: (e) =>
      e.preventDefault()
      this.destroyMeeting(e.target.dataset.meetingId, "create_zoom_meeting_link")
      
    getElement: (id) =>
      document.getElementById(id)
    
    setStreamService: (text) =>
      event_stream_service = this.getElement("event_stream_service")
      event_stream_service.value = text

    setStreamServiceId: (id) =>
      event_stream_service_id = this.getElement("event_stream_service_id")
      event_stream_service_id.value = id

    triggerChangeEvent: (element) ->
      event = null
      # IE doesn't support new Event
      if typeof('Event') == 'function'
        event = new Event('change', {bubbles: true, cancelable: true})
      else
        event = document.createEvent('Event')
        event.initEvent('change', true, true)

      element.dispatchEvent(event)

    setStreamUrl: (url) =>
      event_stream_url = this.getElement("event_stream_url")
      event_stream_url.value = url
      this.triggerChangeEvent(event_stream_url)

    setPublishAccessTokenId: (id) =>
      event_publish_access_token_id = this.getElement("event_publish_access_token_id")
      event_publish_access_token_id.value = id
    
    setDescription: (text) =>
      text = text.split("\n").slice(6).join("\n")
      CKEDITOR.instances.event_stream_info.insertText(text)
    
    clearDescription: =>
      CKEDITOR.instances.event_stream_info.setData("")
    
    setStreamServiceError: (message) =>
      stream_service_error = this.getElement("stream_service_error")
      stream_service_error.innerHTML = message
    
    clearStreamServiceError: =>
      stream_service_error = this.getElement("stream_service_error")
      stream_service_error.innerHTML = ""

    hideAllLinks: (e) =>
      this.authorize_and_create_wrapper?.style.display = "none"
      this.create_wrapper?.style.display = "none"
      this.destroy_wrapper?.style.display = "none"
    
    setProgressMessage: (message) =>
      this.hideAllLinks()
      token_id = this.getElement("authorize_and_create_zoom_meeting")
      token_id.insertAdjacentHTML "beforebegin", "<div id='zoom-notification'>" + message + "</div>"
    
    clearProgressMessage: =>
      element = this.getElement('zoom-notification')
      element.parentNode.removeChild(element)
    
    createMeeting: (title, linkId, start_time=null, duration=null) =>
      this.clearStreamServiceError()
      this.setProgressMessage(MESSAGES.create_meeting_progress)
      
      title = "" unless title
      
      that = this
      
      $.ajax
        url: "/zoom_meetings"
        type: "POST"
        dataType: "json"
        data: 
          title: title
          start_time: start_time
          duration: duration
          event_id: that.event_id
        success: (data, status, jqXHR) ->
          if data.status == 'error'
            # Error returned from Zoom

            # Update status and error messaging based on the response from Zoom
            switch data.code
              # 124 is "Invalid access token"
              when 'invalid_request', 124
                error_message = MESSAGES.need_reauthorize_error
                that.updateState('unauthorized')
              else
                error_message = "#{MESSAGES.create_meeting_error} (#{data.code}: #{data.message})"

            that.clearProgressMessage()
            that.setStreamServiceError(error_message)

          else if data.status == "created"
            # Zoom Meeting created successfully
            that.setStreamService("zoom")
            that.setStreamServiceId(data.id)
            that.setStreamUrl(data.join_url)
            that.setPublishAccessTokenId(data.publish_access_token_id)
            that.destroyLink.dataset.meetingId = data.id
            that.setDescription(data.invitation)
            that.clearStreamServiceError()
            that.clearProgressMessage()

            that.updateState("meeting")
          else
            that.setStreamService("")
            that.setStreamServiceId("")
            that.setStreamUrl("")
            that.setPublishAccessTokenId("")
            delete that.destroyLink.dataset.meetingId
            that.clearDescription()
            that.clearProgressMessage()

            console.error("Error creating meeting: ", data.status, data.message)
            that.setStreamServiceError(MESSAGES.create_meeting_error)

        error: (jqXHR, status, errorMessage) ->
          console.error("Server error creating meeting: ", status, errorMessage)
          that.clearProgressMessage()
          that.setStreamServiceError(MESSAGES.create_meeting_error)
    
    destroyMeeting: (id) =>
      if confirm MESSAGES.delete_confirmation
        this.clearStreamServiceError()
        this.setProgressMessage(MESSAGES.delete_meeting_progress)

        path = "/zoom_meetings/" + id
        
        that = this
        
        $.ajax
          url: path
          type: "DELETE"
          dataType: "json"
          data:
            event_id: that.event_id
          success: (data, status, jqXHR) ->
            if data.status == 'error'
              # Error returned from Zoom

              # Update status and error messaging based on the response from Zoom
              switch data.code
                # 124 is "Invalid access token"
                when 'invalid_request', 124
                  error_message = MESSAGES.need_reauthorize_error
                  that.updateState('unauthorized')
                else
                  error_message = "#{MESSAGES.delete_meeting_error} (#{data.code}: #{data.message})"

              that.clearProgressMessage()
              that.setStreamServiceError(error_message)

            else if data.status == "deleted"
              that.setStreamService("")
              that.setStreamServiceId("")
              that.setStreamUrl("")
              that.setPublishAccessTokenId("")
              delete that.destroyLink.dataset.meetingId
              that.clearDescription()
              that.clearStreamServiceError()
              that.clearProgressMessage()

              that.updateState("no_meeting")
            else
              that.clearProgressMessage()
              console.error("Error deleting meeting: ", data.status, data.message)
              that.setStreamServiceError(MESSAGES.delete_meeting_error)

          error: (jqXHR, status, errorMessage) ->
            console.error("Server error removing meeting: ", status, errorMessage)
            that.clearProgressMessage()
            that.setStreamServiceError(MESSAGES.delete_meeting_error)
