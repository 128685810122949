# Client-side code for search and calendar filtering (right side)
namespace 'Slzr.UI.Filters', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  # Toggle a filter list between expanded/collapsed state
  $(document).on 'click', '[data-action="toggle-filter-list"]', (event) ->
    $this = $(this)
    $listitem = $(this).closest('li')
    $list = $(this).closest('ul')

    # Toggle visibility of additional list items
    is_expanded = $list.data('expanded')
    $list.find('> li[data-additional]').toggle !is_expanded

    # Store current state
    $list.data('expanded', !is_expanded)

    # update link title and state
    expanded_label = $this.data('expanded-label') || 'Show Less'
    collapsed_label = $this.data('collapsed-label') || 'Show More'
    $this.html(if is_expanded then collapsed_label else expanded_label)
    $this.attr('aria-expanded', !is_expanded)

    event.preventDefault()
    false

  # Search nearby (within X of ZIP) form
  updateNearbyParams = (event) ->
    near = $('[data-nearby="zip"]').val()
    within = $('[data-nearby="within"]').val()
    updateUrl { near, within }

  $(document).onAction 'update-nearby', updateNearbyParams

  $(document).on 'keyup', '[data-nearby="zip"]', (event) ->
    updateNearbyParams(event) if event.keyCode == 13