/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
modulejs.define(
  "registration/components/checkout/payment",
  [
    "react",
    "prop-types",
    "@stripe/react-stripe-js",
    "object-path-immutable",
    "isemail",
    "slzr/react/form_input_group",
    "slzr/react/form_row",
    "slzr/react/form_header",
    "registration/components/checkout/reservation_timer",
    "registration/components/checkout/cart",
    "registration/utilities",
    "registration/components/checkout/stripe_payment_row",
    "registration/components/checkout/compliance_form"
  ],
  function (
    React,
    PropTypes,
    ReactStripeElements,
    immutable,
    isemail,
    FormInputGroup,
    FormRow,
    FormHeader,
    ReservationTimer,
    Cart,
    Utilities,
    StripePaymentRow,
    ComplianceForm
  ) {
    // Main Payment "pane" of the checkout process
    class Payment extends React.Component {
      constructor(props) {
        super(props);

        this.UNSAFE_componentWillReceiveProps =
          this.UNSAFE_componentWillReceiveProps.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCardChange = this.onCardChange.bind(this);
        this._setError = this._setError.bind(this);
        this._validateForm = this._validateForm.bind(this);
        this.onAgreementStateChanged = this.onAgreementStateChanged.bind(this);
        this.renderRequired = this.renderRequired.bind(this);

        const initial_agreement_state = (() => {
          switch (props.compliance_level) {
            case "none":
            case "light":
              return true;
            default: // strong, hardcore
              return false;
          }
        })();

        this.state = {
          loading: false,
          name: props.user_name || "",
          email: props.user_email || "",
          agreement_state: initial_agreement_state,
          errors: {
            stripe: null,
            name: null,
            email: null,
            provider: null
          }
        };
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
          return;
        }

        if (
          nextProps.checkout_errors != null
            ? nextProps.checkout_errors.card
            : undefined
        ) {
          this._setError("stripe", nextProps.checkout_errors.card);
        }
        if (
          nextProps.checkout_errors != null
            ? nextProps.checkout_errors.name
            : undefined
        ) {
          this._setError("name", nextProps.checkout_errors.name);
        }
        if (
          nextProps.checkout_errors != null
            ? nextProps.checkout_errors.email
            : undefined
        ) {
          this._setError("email", nextProps.checkout_errors.email);
        }
        if (
          nextProps.checkout_errors != null
            ? nextProps.checkout_errors.provider
            : undefined
        ) {
          this._setError("provider", nextProps.checkout_errors.provider);
        }

        return this.setState({
          loading: false
        });
      }

      onBack(event) {
        event.preventDefault();
        return typeof this.props.onGoBack === "function"
          ? this.props.onGoBack()
          : undefined;
      }

      onCancel(event) {
        event.preventDefault();
        return typeof this.props.onCancel === "function"
          ? this.props.onCancel()
          : undefined;
      }

      // Create the stripe token from the entered data, and if valid, call the callback specified
      onSubmit(event) {
        event.preventDefault();

        // Clear error during submission
        this.setState({ loading: true });
        this._setError("stripe", null);

        if (this.props.cart.total > 0) {
          switch (this.props.payout_account_type) {
            case "ccp":
            case "authorize_net":
            case "touch_net":
              this.setState({ loading: true });
              return typeof this.props.onSubmit === "function"
                ? this.props.onSubmit({
                    billing_name: this.state.name,
                    billing_email: this.state.email
                  })
                : undefined;

            case "stripe":
              // Request the token from stripe, and then post to the server
              const cardElement = this.props.elements.getElement(
                ReactStripeElements.CardElement
              );

              return this.props.stripe
                .createToken(cardElement)
                .then((response) => {
                  this.setState({ loading: true });
                  if (response.token) {
                    return typeof this.props.onSubmit === "function"
                      ? this.props.onSubmit({
                          token: response.token.id,
                          billing_name: this.state.name,
                          billing_email: this.state.email
                        })
                      : undefined;
                  } else if (response.error) {
                    this.setState({ loading: false });
                    return this._setError("stripe", response.error.message);
                  }
                });

            default:
              return console.error(
                "Unknown payment provider type " +
                  this.props.payout_account_type
              );
          }
        } else {
          // cart total == 0
          // This is a zero-cost order, so don't involve stripe and just issue to the server
          this.setState({ loading: true });
          return typeof this.props.onSubmit === "function"
            ? this.props.onSubmit({
                token: null,
                billing_name: this.state.name,
                billing_email: this.state.email
              })
            : undefined;
        }
      }

      // Callback when the other input fields are changed.
      onChange(event) {
        return this.setState(
          immutable.set(this.state, event.target.name, event.target.value),
          this._validateForm
        );
      }

      // Callback when the card input element is changed.
      //
      // This allows stripe validation errors to be shown immediately
      onCardChange(event) {
        if (event.error) {
          return this._setError("stripe", event.error.message);
        } else {
          return this._setError("stripe", null);
        }
      }

      // Update the component error state with the key and message provided
      _setError(key, message) {
        return this.setState(
          immutable.set(this.state, ["errors", key], message)
        );
      }

      // Validate the form, email and name. Stripe handles cc validation
      _validateForm() {
        const next_errors = immutable(this.state.errors);

        if (Utilities.isBlank(this.state.name)) {
          next_errors.set("name", "required");
        } else {
          next_errors.set("name", null);
        }

        if (Utilities.isBlank(this.state.email)) {
          next_errors.set("email", "required");
        } else if (!isemail.validate(this.state.email)) {
          next_errors.set("email", "invalid_format");
        } else {
          next_errors.set("email", null);
        }

        return this.setState({ errors: next_errors.value() });
      }

      onAgreementStateChanged(event) {
        return this.setState({ agreement_state: event.target.checked });
      }

      renderRequired() {
        return <span className="required">REQUIRED</span>;
      }

      checkIfEventIsFree() {
        const areTicketsInCartFree = this.props.cart.items.filter((item) => item.price === 0)
        if (areTicketsInCartFree.length === this.props.cart.items.length) {
          return true
        } else {
          return false
        }
      }

      // Render some instructional text for external payments
      _renderExternalPaymentForm() {
        return (
          <FormInputGroup className="payment-group-wrap">
            {this.checkIfEventIsFree() ? 
              <span className="em-section_register_title">Contact Information</span> : 
              <span className="em-section_register_title">Billing Contact</span>
            }
            <div className="em-attendee_info">
              <div className="em-input_container ">
                <div
                  className={
                    this.state.errors.name == "required"
                      ? "em-input-floating em-error"
                      : "em-input-floating"
                  }
                >
                  <input
                    type="text"
                    id="billing-name"
                    name="name"
                    required="required"
                    onChange={this.onChange}
                    value={this.state.name}
                    placeholder="Full Name"
                  />
                  <label htmlFor="billing-name">
                    Name {this.renderRequired()}
                  </label>
                </div>
                {this.state.errors.name == "required" && (
                  <span className="error_marker" role="alert">
                    The name is required
                  </span>
                )}
              </div>
              <div className="em-input_container">
                <div
                  className={
                    this.state.errors.email == "invalid_format"
                      ? "em-input-floating em-error"
                      : "em-input-floating"
                  }
                >
                  <input
                    type="text"
                    id="billing-email"
                    name="email"
                    required="required"
                    onChange={this.onChange}
                    value={this.state.email}
                    placeholder="Email Address"
                  />
                  <label htmlFor="billing-email">
                    Email Address {this.renderRequired()}
                  </label>
                </div>
                {this.state.errors.email == "required" && (
                  <span className="error_marker" role="alert">
                    The email is required
                  </span>
                )}
                {this.state.errors.email == "invalid_format" && (
                  <span className="error_marker" role="alert">
                    That is not a valid email
                  </span>
                )}
              </div>
            </div>

            {!!this.props.payment_instructions && (
              <div className="payment-instructions-wrap">
                <p>{this.props.payment_instructions}</p>
              </div>
            )}
          </FormInputGroup>
        );
      }

      // Render the stripe payment form, and the free payment form
      _renderStripePaymentForm() {
        return (
          <FormInputGroup className="payment-group-wrap">
            {this.checkIfEventIsFree() ? 
              <legend className="em-section_register_title">Contact Information</legend> : 
              <legend className="em-section_register_title">Billing Contact</legend>
            }
            <div className="em-attendee_info">
              <div className="em-input_container ">
                <div
                  className={
                    this.state.errors.name == "required"
                      ? "em-input-floating em-error"
                      : "em-input-floating"
                  }
                >
                  <input
                    type="text"
                    id="billing-name"
                    name="name"
                    required="required"
                    onChange={this.onChange}
                    value={this.state.name}
                    placeholder="Full Name"
                  />
                  <label htmlFor="billing-name">
                    Name {this.renderRequired()}
                  </label>
                </div>
                {this.state.errors.name == "required" && (
                  <span className="error_marker" role="alert">
                    The name is required
                  </span>
                )}
              </div>
              <div className="em-input_container">
                <div
                  className={
                    this.state.errors.email == "invalid_format"
                      ? "em-input-floating em-error"
                      : "em-input-floating"
                  }
                >
                  <input
                    type="text"
                    id="billing-email"
                    name="email"
                    required="required"
                    onChange={this.onChange}
                    value={this.state.email}
                    placeholder="Email Address"
                  />
                  <label htmlFor="billing-email">
                    Email Address {this.renderRequired()}
                  </label>
                </div>
                {this.state.errors.email == "required" && (
                  <span className="error_marker" role="alert">
                    The email is required
                  </span>
                )}
                {this.state.errors.email == "invalid_format" && (
                  <span className="error_marker" role="alert">
                    That is not a valid email
                  </span>
                )}
              </div>
            </div>

            {this.props.cart.total > 0 && (
              <StripePaymentRow
                errors={this.state.errors.stripe}
                onChange={this.onCardChange}
              />
            )}
          </FormInputGroup>
        );
      }

      // Render the appropriate payments form
      _renderPaymentForm() {
        const payment_is_external = (() => {
          switch (this.props.payout_account_type) {
            case "ccp":
            case "authorize_net":
            case "touch_net":
              return true;
            default:
              return false;
          }
        })();

        // Only use the External payment form if payments are actually required. The stripe form currently pulls
        // double duty as the no-payment-required interface.
        if (this.props.cart.total > 0 && payment_is_external) {
          return this._renderExternalPaymentForm();
        } else {
          return this._renderStripePaymentForm();
        }
      }

      render() {
        const submit_label =
          this.state.loading || this.props.loading
            ? "Processing..."
            : this.props.cart.total > 0
            ? `Pay ${Utilities.formatCurrency(
                this.props.cart.total,
                this.props.currency
              )}`
            : "Register";

        const have_errors =
          !!this.state.errors.email ||
          !!this.state.errors.stripe ||
          !!this.state.errors.name ||
          Utilities.isBlank(this.state.email) ||
          Utilities.isBlank(this.state.name);

        return (
          <div className="widget-content">
            <ReservationTimer
              now={this.props.now}
              reservationEnds={this.props.reservation_ends_at}
              browserTimeOffset={this.props.browser_time_offset}
            />

            <form className="car_form form-sidebar" onSubmit={this.onSubmit}>
              <span className="em-section_register_title">Checkout</span>

              <Cart
                items={this.props.cart.items}
                fee={this.props.cart.fee}
                total={this.props.cart.total}
                currency={this.props.currency}
              />

              {this._renderPaymentForm()}

              <ComplianceForm
                level={this.props.compliance_level}
                agreementState={this.state.agreement_state}
                termsUrl={this.props.registrant_terms_url}
                platformName={this.props.platform_name}
                onAgreementChange={this.onAgreementStateChanged}
                text={this.props.compliance_text}
              />

              {/* Generic errors, either from the provider... */}
              {this.state.errors.provider && (
                <div className="payment-provider-error">
                  <p>{this.state.errors.provider}</p>
                </div>
              )}

              {/* or an internal server error, or other XHR error */}
              {this.props.error && (
                <div className="payment-provider-error">
                  <p>
                    Unable to complete order. Please try again later. (
                    {this.props.errorMessage})
                  </p>
                </div>
              )}

              <div className="action_button">
                <a
                  href="#"
                  className="cancel_link em-link"
                  onClick={this.onBack}
                >
                  Back
                </a>
                <a
                  href="#"
                  className="cancel_link em-link"
                  onClick={this.onCancel}
                >
                  Cancel
                </a>
                <input
                  type="submit"
                  className="em-button em-primary"
                  onClick={this.onSubmitForm}
                  disabled={
                    this.state.loading ||
                    this.props.loading ||
                    !this.state.agreement_state ||
                    have_errors
                  }
                  value={submit_label}
                />
              </div>
            </form>
          </div>
        );
      }
    }

    Payment.propTypes = {
      // Initial billing name value
      name: PropTypes.string,
      // Initial billing email value
      email: PropTypes.string,
      // Callback for form submission
      onSubmit: PropTypes.func,
      // Callback to navigate back
      onGoBack: PropTypes.func,
      // Callback to abort checkout
      onCancel: PropTypes.func
    };

    const InjectedPayment = (props) => (
      <ReactStripeElements.ElementsConsumer>
        {({ stripe, elements }) => (
          <Payment {...props} stripe={stripe} elements={elements} />
        )}
      </ReactStripeElements.ElementsConsumer>
    );

    return InjectedPayment;
  }
);
