# Utility methods for the photo cropper and chooser
modulejs.define 'slzr/components/photo_chooser/utilities',
  ->

    # Primary exports

    # Clamp a value to min..max inclusive
    clamp: (value, min, max) ->
      Math.min Math.max(value, min), max

    # Rectangle math
    #
    # Most of these expect a hash of {left, top, width, height} defining a rectangular area.

    # Inset a rect's edges by the specified amount
    insetRect: (rect, amount) ->
      left: rect.left + amount
      top: rect.top + amount
      width: rect.width - amount * 2
      height: rect.height - amount * 2

    # Translate a rect's coordinates by the specified scaling amounts
    scaleRect: (rect, amount_x, amount_y=amount_x) ->
      left: rect.left * amount_x
      top: rect.top * amount_y
      width: rect.width * amount_x
      height: rect.height * amount_y

    # Transform +source+ rect to overlap +target+, keeping the smallest dimension
    # but allowing the other to be larger.
    scaleTo: (source, target) ->
      if source.width / source.height > 1
        scale = target.width / source.width
        scaled_width = target.width
        scaled_height = source.height * scale
      else
        scale = target.height / source.height
        scaled_width = source.width * scale
        scaled_height = target.height

      left: target.left
      top: target.top
      width: scaled_width
      height: scaled_height

    # Return the x and y scale factors from source to target rect
    #
    # Returns an array of [scale_x, scale_y]
    getRectScale: (source, target) ->
      scale_x = target.width / source.width
      scale_y = target.height / source.height

      [scale_x, scale_y]

    # Perform a center cut of a rectangle to fit the specified
    # aspect ratio
    cropRect: (rect, aspect) ->
      rect_aspect = rect.width / rect.height

      # If the target is portrait, or square from a portrait source,
      # keep the full height and crop from the left and right
      if aspect < 1 || (aspect == 1 && rect.height < rect.width)
        target_height = rect.height
        target_width = target_height * aspect
        target_top = rect.top
        target_left = rect.left + ((rect.width - target_width) / 2)
      else
        # Otherwise, keep the full width and crop from the top and bottom
        target_width = rect.width
        target_height = target_width / aspect
        target_left = rect.left
        target_top = rect.top + ((rect.height - target_height) / 2)

      # Return the resulting rect with rounded values
      left: Math.floor target_left
      top: Math.floor target_top
      width: Math.ceil target_width
      height: Math.ceil target_height

    # Round the values in a rect
    roundRect: (rect) ->
      left: Math.round rect.left
      top: Math.round rect.top
      width: Math.round rect.width
      height: Math.round rect.height