modulejs.define 'slzr/react/checkbox',
  ['react', 'jquery'],
  (React, jQuery) ->
    $ = jQuery

    # Checkbox component
    #
    # Just wraps an input type=checkbox and activates iCheck on it.
    #
    # Note that this component needs an explicit onChange callback, it does not automatically bubble
    class Checkbox extends React.Component
      @defaultProps:
        use_icheck: LOCALIST_SETTINGS?.icheck
        type: 'checkbox'

      # Initialize iCheck after adding to DOM
      componentDidMount: ->
        @_initializeiCheck() if @props.use_icheck

      _initializeiCheck: ->
        $(@element).iCheck
          checkboxClass: 'icheckbox_minimal'
          radioClass: 'iradio_minimal'
          increaseArea: '20%'
          labelHover: false

        $(@element).on 'ifChanged.checkbox_component', (event) =>
          this.props.onChange?(event, event.target.checked)

      componentDidUpdate: (prevProps, prevState) ->
        if prevProps.use_icheck && !@props.use_icheck
          $(@element).iCheck('destroy').off('ifChanged.checkbox_component')
        else if !prevProps.use_icheck && @props.use_icheck
          @_initializeiCheck()
        else
          $(@element).iCheck('update') if @props.use_icheck

      # Remove iCheck before removing from DOM
      componentWillUnmount: ->
        $(@element).iCheck('destroy').off('ifChanged.checkbox_component') if @props.use_icheck

      # Proxy change events back, with a second argument for check state
      _changed: (event) =>
        this.props.onChange?(event, event.target.checked)

      render: ->
        `<input ref={(ref) => this.element = ref}
                {...this.props}
                onChange={this._changed} />`