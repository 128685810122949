module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form method="post" action="/admin/events/reject/' +
((__t = ( id )) == null ? '' : __t) +
'" data-action="confirm-reject">\n  <div class="localist_modal localist_modal_medium">\n    <div class="content-padding-small">\n      <div class="box_title">\n        <h4><span>Reason for Rejection</span></h4>\n      </div>\n\n      ';
 if(list) { ;
__p += '<input type="hidden" name="list" value="1">';
 } ;
__p += '\n\n      <textarea name="message"></textarea>\n    </div>\n    <div class="action_button form_action_buttons">\n      <a class="link" data-action="cancel-reject" href="#">Cancel</a>\n      <input class="btn btn-color" type="submit" name="commit" value="Skip Message and Reject Event" data-disable-with="Rejecting...">\n    </div>\n  </div>\n</form>\n';

}
return __p
}