# Lockable UI widget implementation

modulejs.define 'slzr/ui/lockable',
['jquery', 'underscore'],
($, _) ->

  # Implements a lockable input text.
  #
  # should be some input field with data-lockable with a page-unique identifier.
  #
  # Value of form input with matching data-lockable-value is set to 0 or 1 based on lock state
  # 
  class Lockable

    # Identifier (should be page-unique)
    name: null

    # Primary input element
    element: null

    # Associated indicator element
    indicator_element: null

    # Associated value element
    value_element: null

    # Current lock state
    locked: false

    # Options (and defaults)
    options:
      # Classes for locked/unlocked state applied to indicator_element
      #
      # Note that these are NOT the unlock/lock icons (below)
      locked_class: 'locked'
      unlocked_class: 'unlocked'

      # FontAwesome icon classes
      #
      # This applies to any elements matching icon_selector (below)
      locked_icon: 'fa-lock'
      unlocked_icon: 'fa-unlock'

      # Selector for icon inside the indicator_element (including indicator_element)
      # FontAwesome icon classes, above, will be applied to any matching element
      icon_selector: 'i.fa'

    constructor: (element, options={}) ->
      _.extend @options, options

      @element = $(element)
      @name = @element.data('lockable')

      @indicator_element = $("[data-lockable-status='#{@name}']")
      @value_element = $("[data-lockable-value='#{@name}']")

      @locked = @value_element.val() == 'true'

      @_updateStyles()
      @_attachEvents()

    # Attach events to keep elements in sync
    #
    # Which events are listened to depends on the type of input (i.e. is it rich text, etc.)
    _attachEvents: ->
      event_names = if @element.is('[data-rich-text]')
        'slzr:richtext:change.lockable'
      else
        'change.lockable keypress.lockable slzr:location:remove.lockable'

      @element.on event_names, (event) =>
        @_setState(true)

      # Handle manual clicks of the lock to toggle status
      @indicator_element.on 'click', (event) =>
        @_setState !@locked
        event.preventDefault()

    _setState: (locked) =>
      @locked = locked
      @_updateStyles()
      @value_element.val if locked then 'true' else 'false'

    # Update active styles for the current lock state
    _updateStyles: =>
      @indicator_element.toggleClass @options.locked_class, @locked
      @indicator_element.toggleClass @options.unlocked_class, !@locked

      icon_elements = @indicator_element.find(@options.icon_selector)
      icon_elements = icon_elements.add(@indicator_element) if @indicator_element.is(@options.icon_selector)

      icon_elements.toggleClass @options.locked_icon, @locked
      icon_elements.toggleClass @options.unlocked_icon, !@locked