# CKEditor plugin to apply links to URLs in plain-text pastes
modulejs.define 'slzr/ckeditor/linkify_pastes', ->
  URL_REGEXP = /\b(https?|ftp):\/\/(-\.)?([^\s\/?\.#]+\.?)+(\/[^\s]*)?[^\s\.,]\b/ig
  EMAIL_REGEXP =  /\b[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\b/g

  requires: 'clipboard,textmatch'
  init: (editor) ->
    urlTemplate = new CKEDITOR.template('<a href="{link}">{text}</a>')
    emailTemplate = new CKEDITOR.template('<a href="mailto:{link}">{text}</a>')

    pasteCallback = (evt) ->
      return if evt.data.dataTransfer.getTransferType(editor) == CKEDITOR.DATA_TRANSFER_INTERNAL

      data = evt.data.dataValue

      # Parse the HTML using CKEDITOR, so we can transform standalone text not in a link.
      fragment = CKEDITOR.htmlParser.fragment.fromHtml(data)
      writer = new CKEDITOR.htmlParser.basicWriter()

      fragment.forEach (node) ->
        if node instanceof CKEDITOR.htmlParser.text
          unless node.getAscendant('a')
            # Not in a link, so ok to replace.
            textData = node.value
            linkifiedData = textData.replace(URL_REGEXP, (match, offset, string) -> urlTemplate.output(text: match, link: match))
            linkifiedData = linkifiedData.replace(EMAIL_REGEXP, (match, offset, string) -> emailTemplate.output(text: match, link: match))

            if textData != linkifiedData
              # Replace the node with parsed replacement
              newNode = new CKEDITOR.htmlParser.fragment.fromHtml(linkifiedData)
              node.replaceWith(newNode)

      fragment.writeHtml(writer)

      linkifiedData = writer.getHtml()

      if linkifiedData != data
        evt.data.dataValue = linkifiedData
        evt.data.type = 'html'

    editor.on 'paste', pasteCallback