modulejs.define 'registration/components/checkout/ticket_class_quantity_row',
  ['underscore', 'react', 'prop-types', 'moment',
   'registration/utilities', 'slzr/react/currency_input', 'slzr/react/form_input_group', 'slzr/react/form_row',
   'registration/components/checkout/quantity_select'],
  (_, React, PropTypes, moment,
   Utilities, CurrencyInput, FormInputGroup, FormRow,
   QuantitySelect) ->

    class TicketClassQuantityRow extends React.Component
      @propTypes:
        id: PropTypes.number
        # Date format
        dateFormat: PropTypes.string
        # Time format
        timeFormat: PropTypes.string
        # Callback for a quantity change
        onQuantityChange: PropTypes.func
        # Callback for donation amount change
        onItemPriceChange: PropTypes.func
        # Donation amount
        donationAmount: PropTypes.number

      @defaultProps:
        dateFormat: "dddd, MMMM Do YYYY"
        timeFormat: "h:mma"

      onItemPriceChange: (event, cents) =>
        @props.onItemPriceChange?(this.props.id, cents)

      render: ->
        # +true+ if there no quantity available, +false+ otherwise
        sold_out = this.props.availability == 0
        available_for_sale = !sold_out && this.props.available_now

        error_message = switch this.props.error
          when 'sold_out'
            "This ticket is sold out"
          when 'invalid_quantity'
            "You cannot buy that many tickets"
          when 'invalid_price'
            if this.props.min_price > 0 && this.props.max_price > 0
              "You must enter a price between " +
                Utilities.formatCurrency(this.props.min_price, this.props.currency) +
                " and " +
                Utilities.formatCurrency(this.props.max_price, this.props.currency)
            else if this.props.min_price == 0 && this.props.max_price > 0
              "You must enter a price less than #{Utilities.formatCurrency(this.props.max_price, this.props.currency)}"
            else
              "You must enter a price greater than #{Utilities.formatCurrency(this.props.min_price, this.props.currency)}"
          else
            null

        if sold_out
          max_purchase = min_purchase = 0
        else
          # Build the select options
          max_purchase = this.props.availability
          # An availability of -1 means there is no limit on the amount of tickets available
          max_purchase = 20 if max_purchase == -1

          # Don't allow less than the purcahse minimum number of tickets
          min_purchase = _.max [1, this.props.purchase_minimum]
          # Limit to 20 tickets at a time
          max_purchase = _.min [max_purchase, this.props.purchase_limit || 20]
          # But limit donation tickets to quantity of one, regardless of other settings
          max_purchase = _.min [max_purchase, 1] if this.props.donation

        price = if this.props.price == 0 then "Free" else Utilities.formatCurrency(this.props.price, this.props.currency)

        `<div className="ticket_class">
          <label className="ticket_name" htmlFor={this.props.id + "_instance_quantity_select"}>{this.props.name + " quantity"}</label>
          <div className="ticket_info">
            <div className="ticket_text">
              <p>{this.props.availability == 0 ? 'SOLD OUT' : !this.props.donation && price}</p>
              {this.props.description && (<span className="ticket_dexcription">{this.props.description}</span>)}
            </div>
            <span className="ticket_price">
              {available_for_sale && this.props.donation && <div className="donation_amount">
                <CurrencyInput currency={this.props.currency}
                              value={this.props.donationAmount}
                              placeholderValue={this.props.min_price}
                              onChange={this.onItemPriceChange} />
              </div>}

              {available_for_sale && !this.props.donation && <div className="ticket_quantity">
                <QuantitySelect min={min_purchase}
                                max={max_purchase}
                                value={this.props.selectedQuantity}
                                onChange={this.onQuantityChange}
                                id={this.props.id + "_instance_quantity_select"}
                                aria-label={this.props.name + " quantity"} />
              </div>}

              {this.props.not_yet_available && <p className="ticket_notes">Available Starting {this._formatDateTime(this.props.availability_start)}</p>}
              {this.props.no_longer_available && <p className="ticket_notes">Sales Ended {this._formatDate(this.props.availability_finish)}</p>}
              {this.props.available_now && this.props.availability_finish && <p className="ticket_notes">On Sale Until {this._formatDateTime(this.props.availability_finish)}</p>}
            </span>
          </div>
          {error_message && <p className="error_marker" role="alert">{error_message}</p>}
        </div>`

      onQuantityChange: (event) =>
        this.props.onQuantityChange?(this.props.id, event.target.value)

      # Format a date as a string
      _formatDate: (time) -> moment(time).format(this.props.dateFormat)

      # Format a date and time as a string
      _formatDateTime: (time) -> moment(time).format(this.props.dateFormat + ' ' + this.props.timeFormat)