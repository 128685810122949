# Hubspot integration component for Register
#
# When enabled, listens to the localist:order:complete event, and triggers a Hubspot form submission for each attendee.
#
# Usage:
#   Call function during page load

modulejs.define 'registration/checkout/hubspot',
  ['jquery', 'underscore', 'object-path-immutable', 'registration/checkout/field_mapper'],
  ($, _, immutable, FieldMapper) ->

    # Store the page's configuration
    portal_id = null
    form_id = null

    # Look up the field value

    # Build an array of form submission(s) for the order
    #
    # One submission is built for each attendee
    #
    # Each submission returns the fields property for the Hubspot submission, which is an array of objects with name and value
    buildFormSubmissions = (details, field_mapping) ->
      for attendee in details.attendees
        # Promote the current attendee to a top-level item
        attendee_details = immutable.set(details, 'attendee', attendee)

        # and perform the field mapping
        for target, source of field_mapping
          {
            name: target,
            value: FieldMapper.lookupValue(attendee_details, source, '') || ''
          }

    # Submit a form to hubspot, and just log any errors out to console
    submitForms = (form_submissions) ->
      url = "https://api.hsforms.com/submissions/v3/integration/submit/#{portal_id}/#{form_id}"

      body = {
        context:        {
                          pageUri:  location.href,
                          pageName: document.title
                        },
        skipValidation: true
      }
      # Extract the hubspotutk cookie, and set it if present
      #
      # Note that adding it to the submission as an empty string causes Hubspot to return an error
      hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")
      body.context.hutk = hutk if hutk != ''

      for submission, the_index in form_submissions
        index = the_index
        body.fields = submission

        Sentry?.addBreadcrumb({
          category: "register",
          message: "Hubspot form ##{index} submission data",
          data: body
        })

        # Post the form submission, and only report on errors
        xhr = new XMLHttpRequest()
        xhr.open('POST', url)
        xhr.onreadystatechange = ->
          if xhr.readyState == 4
            if xhr.status == 200
              Sentry?.addBreadcrumb({
                category: "register",
                message: "Hubspot form ##{index} submit success"
              })
              # Successful submission
            else
              xhrError = new Error("Error submitting hubspot form ##{index}: status=" + xhr.status + ", response=" + xhr.responseText)
              xhrError.name = 'HubspotSubmitError'
              Sentry?.addBreadcrumb({
                category: "register",
                message: "Hubspot error response",
                data: {
                  status: xhr.status.toString()
                  response: xhr.responseText.toString()
                      }
              })
              Sentry?.captureException(xhrError)

              console.error "Error submitting hubspot form ##{index}: ", xhr.status, xhr.responseText, 'url', url, xhr

        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(JSON.stringify(body))


    # Initialization function for hubspot integration, to apply map
    #
    # +config+ expects:
    #  portal_id: HubSpot portalId
    #  form_id: HubSpot formGuid
    initializeHubspot = (config={}) ->
      # Store configuration, and ensure it's set
      portal_id = config.portal_id
      form_id = config.form_id

      throw "HubSpot portal_id must be specified" unless !!portal_id
      throw "HubSpot form_id must be specified" unless !!form_id

      document.addEventListener 'localist:order:complete', (event) ->
        Sentry?.addBreadcrumb({
          category: "register",
          message: "Order completed",
          data: event.detail,
          level: Sentry.Severity.Info
        })

        form_submissions = buildFormSubmissions(event.detail, config.fields)

        Sentry?.addBreadcrumb({
          category: "register",
          message: "Generated hubspot form data",
          data: form_submissions,
          level: Sentry.Severity.Info
        })

        # Submit each form, logging errors
        submitForms(form_submissions)




    # Export
    initializeHubspot


namespace 'Slzr.Register', (exports, top) ->
  exports.Hubspot = () ->
    hubspot = modulejs.require('registration/checkout/hubspot')
    hubspot.apply(null, arguments)