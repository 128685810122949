namespace 'Slzr.Page', (exports, top) ->
  $ = Slzr.jQuery

  # Disable all links for the event preview
  exports.disableEventPreviewLinks = () ->
    $(document.body).on 'click', 'a, input[type="submit"], button[type="submit"]', (event) ->
      event.preventDefault()

    $(document.body).on 'click', 'a[data-method], a[data-remote]', (event) ->
      event.stopPropagation()

  $ ->
    $container = $('[data-event-feedback]')
    $form_container = $container.find('[data-event-feedback="form-container"]')

    # Only activate on pages that have a feedback form
    return if $container.length == 0

    # utility functions
    setStatus = (status) ->
      $container
        .removeClass('em-event-feedback--status-complete em-event-feedback--status-ignored em-event-feedback--status-declined')
        .addClass("em-event-feedback--status-#{status}")

    openForm = -> $form_container.addClass('em-event-feedback--open').removeClass('em-event-feedback--closed')
    closeForm = -> $form_container.removeClass('em-event-feedback--open').addClass('em-event-feedback--closed')

    # event handlers
    $container.on 'click', '[data-event-feedback-action="open-survey"]', (ev) ->
      ev.preventDefault()
      openForm()

    $container.on 'click', '[data-event-feedback-action="close-survey"]', (ev) ->
      ev.preventDefault()
      closeForm()

    $container.on 'click', '[data-event-feedback-action="show-feedback-form"]', (ev) ->
      ev.preventDefault()
      setStatus('pending')
      openForm()

    # On an event page preview with the form, disable most links and submit buttons
    if LOCALIST_SETTINGS.event_page_preview
      exports.disableEventPreviewLinks()
