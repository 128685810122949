
modulejs.define 'registration/components/ticket_form',
  ['react', 'react-redux',
   'registration/store/actions', 'registration/utilities', 'slzr/react/form_row', 'slzr/react/form_input_group',
   'slzr/react/form_header', 'slzr/components/date_input_row', 'slzr/react/currency_input', 'underscore', 'prop-types', 'slzr/react/form_theme_context', "slzr/react/common/info", "slzr/react/common/link"],
  (React, ReactRedux,
   Actions, Utilities, FormRow, FormInputGroup,
   FormHeader, DateInputRow, CurrencyInput, _, PropTypes, FormThemeContext, Info, Link) ->

    class TicketForm extends React.Component
      @contextType: FormThemeContext

      @propTypes:
        # Ticket data
        ticket: Actions.TicketClassShape
        # Callback when an input field changes
        onChange: PropTypes.func
        # Callback for the "Update" button, which just closes the editor
        onClose: PropTypes.func

      constructor: (props) ->
        super props

        @state =
          hasOnsaleDates:      !!props.ticket.availability_start || !!props.ticket.availability_finish
          advancedOptionsOpen: false
          urlRegex:            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
          ticketUrlError:      null

      onInputChange: (event, name, value) =>
        field_name = if name? then name else event.target.name
        field_value = if value?
          value
        else if event.target.type == 'checkbox'
          # Use the checked state of checkboxes, instead of the value
          event.target.checked
        else if event.target.name == 'customer_pays_fees' || event.target.name == 'donation'
          # Customer pays fees is a boolean, but presented as a dropdown, so convert it
          event.target.value == '1'
        else
          event.target.value

        this.props.onChange?(field_name, field_value, event)

      onPriceChange: (event, cents) =>
        this.onInputChange event, event.target.name, cents

      # Returns a callback function to call onInputChange with the specified name
      inputChanged: (name) =>
        (event) => this.onInputChange(event, name)

      dateInputChanged: (event, value, input_value) =>
        if value == 'blank'
          new_value = ''
        else if value != 'error'
          new_value = value.toString('yyyy-MM-dd')

        this.onInputChange(event, event.target.name, new_value)

      # Toggle visibility of onsale dates
      onHasOnsaleDatesChange: (event) =>
        @setState hasOnsaleDates: event.target.checked

        # Clear the set values when unchecking
        unless event.target.checked
          this.props.onChange?('availability_start', null)
          this.props.onChange?('availability_finish', null)

      # Toggle visibility of advanced options
      onAdvancedOptionsToggle: (event) =>
        event.preventDefault()
        @setState advancedOptionsOpen: !@state.advancedOptionsOpen

      # Validate the ticket type props
      isValid: =>
        return false if this.props.ticket.name.isBlank()

        true

      isValidUrl: (str) =>
        str && str.match(this.state.urlRegex) != null

      render: ->
        id_prefix = "ticket-form-#{this.props.ticket.id}"

        fee_preview = if this.props.ticket.price > 0
          # Calculate fee
          fee_amount = Utilities.calculateFee(this.props.ticket.price, this.props.settings.fees)

          if this.props.ticket.customer_pays_fees
            `<p className="preview">Fees: {Utilities.formatCurrency(fee_amount)}</p>`
          else
            `<p className="preview">Fees: {Utilities.formatCurrency(fee_amount)}</p>`
        else
          null

        `<tr>
          <td className="edit-form" colSpan="5">
            <FormInputGroup>
              <FormRow title="Active"
                       labelFor={id_prefix + "-active"}
                       tooltip="Allow this ticket to be used"
                       {...this.context.checkboxRow}>
                <input name="active"
                       id={id_prefix + "-active"}
                       type="checkbox"
                       className={this.context.checkboxRow.className}
                       checked={this.props.ticket.active}
                       onChange={this.onInputChange} />
              </FormRow>

              <FormRow title="Ticket Name"
                       labelFor={id_prefix + "-name"}
                       {...this.context.textInputRow}
                       required>
                <input name="name"
                       type="text"
                       className={this.context.textInputRow.inputClassName}
                       value={this.props.ticket.name}
                       onChange={this.onInputChange}
                       autoFocus />
              </FormRow>

              <FormRow title="Description"
                       tooltip="Limited to 160 characters"
                       labelFor={id_prefix + "-description"}
                       {...this.context.textInputRow}>
                <input type="text"
                       id={id_prefix + "-description"}
                       className={this.context.textInputRow.inputClassName}
                       maxLength="160"
                       name="description"
                       value={this.props.ticket.description}
                       onChange={this.onInputChange} />
              </FormRow>

              <FormRow title='Ticket Type'
                       labelFor={id_prefix + "-ticket_type"}
                       {...this.context.selectInputRow}
                       tooltip="Selecting virtual will replace traditional tickets with a link to the event URL (like a Zoom link)">
                <select name='ticket_type'
                        id={id_prefix + "-ticket_type"}
                        value={this.props.ticket.ticket_type}
                        className={this.context.selectInputRow.inputClassName}
                        onChange={this.onInputChange}>
                  <option value='in_person'>In-Person</option>
                  <option value='virtual'>Virtual</option>
                  <option value='wait_list'>Waiting List</option>
                </select>
              </FormRow>

              {this.props.ticket.ticket_type ==='virtual' &&
                <FormRow title='Event URL'
                         labelFor={id_prefix + "-virtual_event_url"}
                         {...this.context.textInputRow}>
                  <input type='text'
                         id={id_prefix + "-virtual_event_url"}
                         className={this.context.textInputRow.inputClassName}
                         name='virtual_event_url'
                         value={this.props.ticket.virtual_event_url}
                         onChange={this.onInputChange}/>
                  <p className="error">{this.state.ticketUrlError}</p>
              </FormRow>}

              <FormRow title='Ticket Type Message'
                       labelFor={id_prefix + "-virtual_event_message"}
                       {...this.context.textInputRow}>
                <textarea name='virtual_event_message'
                          id={id_prefix + "-virtual_event_message"}
                          className={this.context.textInputRow.inputClassName}
                          value={this.props.ticket.virtual_event_message}
                          onChange={this.onInputChange}/>
              </FormRow>

              {!this.props.settings.has_payout_account && <Info>
                <label>
                  <div className='admin-common-info'>
                    <i className="fa fa-info-circle"></i>
                    <p>
                      You must add a payout account in order to set pricing options.
                      In order to add a Payout Account, go to <Link text="Settings > Register" href="/admin/registration" target="_blank" />
                    </p>
                  </div>
                </label>
              </Info>}
              
              <FormRow title="Price Type"
                        labelFor={id_prefix + "-donation"}
                        {...this.context.selectInputRow}
                        className=""
                        tooltip="Use User-Specified for donation tickets">
                <select name="donation"
                        id={id_prefix + "-donation"}
                        className={this.context.selectInputRow.inputClassName}
                        onChange={this.onInputChange}
                        disabled={!this.props.settings.has_payout_account}
                        value={this.props.ticket.donation ? "1" : "0"}>
                  <option value="0">Fixed</option>
                  <option value="1">User-Specified (Donation)</option>
                </select>
              </FormRow>

              <FormRow title="Fees"
                       labelFor={id_prefix + "-customer_pays_fees"}
                       {...this.context.selectInputRow}
                       className="">
                  <select name="customer_pays_fees"
                          id={id_prefix + "-customer_pays_fees"}
                          className={this.context.selectInputRow.inputClassName}
                          onChange={this.onInputChange}
                          disabled={!this.props.settings.has_payout_account || !this.props.settings.customer_pays_fees_supported}
                          value={!this.props.settings.customer_pays_fees_supported ? "0" : this.props.ticket.customer_pays_fees ? "1" : "0"}>
                    <option value="1">Pass fees on to customer</option>
                    <option value="0">Organizer pays fees</option>
                  </select>
                  {(!this.props.settings.has_payout_account || !this.props.settings.customer_pays_fees_supported) &&
                    <p className="warning-message">The selected payout account does not support passing fees on to the customer.</p>
                  }
              </FormRow>

              {!this.props.ticket.donation && <FormRow title="Price">
                <div className="rightside">
                  <CurrencyInput name="price"
                                 value={this.props.ticket.price}
                                 disabled={!this.props.settings.has_payout_account}
                                 currency={this.props.settings.currency}
                                 onChange={this.onPriceChange}/>
                  {fee_preview}
                </div>
              </FormRow>}

              {this.props.ticket.donation && this.props.settings.has_payout_account && <FormRow title="Min Price">
                <div className="rightside">
                  <CurrencyInput name="min_price" value={this.props.ticket.min_price} currency={this.props.settings.currency} onChange={this.onPriceChange} />
                </div>
              </FormRow>}

              {this.props.ticket.donation && this.props.settings.has_payout_account && <FormRow title="Max Price">
                <div className="rightside">
                  <CurrencyInput name="max_price" value={this.props.ticket.max_price} currency={this.props.settings.currency} onChange={this.onPriceChange} />
                </div>
              </FormRow>}
            </FormInputGroup>

            {!this.state.advancedOptionsOpen && <FormRow title="">
              <a href="#" onClick={this.onAdvancedOptionsToggle}>Additional ticket options</a>
            </FormRow>}

            {this.state.advancedOptionsOpen && <div>
              <FormHeader title="Ticket Availability Dates" className="sub-heading"/>

              <FormInputGroup>
                <DateInputRow label="On Sale From"
                              name="availability_start"
                              initialValue={this.props.ticket.availability_start}
                              placeholder="'Tomorrow' or 'Next Friday' or '4/10'"
                              tooltip="Ticket will not be available for purchase until this date"
                              {...this.context.textInputRow}
                              onChange={this.dateInputChanged} />

                <DateInputRow label="On Sale To"
                              name="availability_finish"
                              initialValue={this.props.ticket.availability_finish}
                              placeholder="'Tomorrow' or 'Next Friday' or '4/10'"
                              tooltip="Ticket will become unavailable for purchase on this date"
                              {...this.context.textInputRow}
                              onChange={this.dateInputChanged} />
              </FormInputGroup>

              <FormHeader title="Settings" className="sub-heading"/>

              <FormInputGroup>
                <FormRow title="Hidden"
                         labelFor={id_prefix + "-hidden"}
                         {...this.context.checkboxRow}
                         tooltip="Ticket will be hidden until an associated promo code is entered">
                  <input name="hidden"
                         id={id_prefix + "-hidden"}
                         className={this.context.checkboxRow.className}
                         type="checkbox"
                         checked={this.props.ticket.hidden}
                         onChange={this.onInputChange} />
                </FormRow>

                <FormRow title="Tickets Available"
                         tooltip="The number of times this ticket can be purchased"
                         labelFor={id_prefix + "-quantity_limit"}
                         {...this.context.textInputRow}>
                  <input name="quantity_limit"
                         id={id_prefix + "-quantity_limit"}
                         className={this.context.textInputRow.inputClassName}
                         type="text"
                         value={this.props.ticket.quantity_limit}
                         onChange={this.onInputChange} />
                </FormRow>

                <FormRow title="Min. Quantity Per Sale"
                         tooltip="The minimum number of tickets required per transaction (for four-pack orders, etc.)"
                         labelFor={id_prefix + "-purchase_maximum"}
                         {...this.context.textInputRow}>
                  <input name="purchase_minimum"
                         type="text"
                         className={this.context.textInputRow.inputClassName}
                         id={id_prefix + "-purchase_maximum"}
                         value={this.props.ticket.purchase_minimum}
                         onChange={this.onInputChange} />
                </FormRow>

                <FormRow title="Max. Quantity Per Sale"
                         tooltip="The maximum number of tickets allowed per transaction (to prevent cyberscalping)"
                         labelFor={id_prefix + "-purchase_limit"}
                         {...this.context.textInputRow}>
                  <input name="purchase_limit"
                         type="text"
                         id={id_prefix + "-purchase_limit"}
                         className={this.context.textInputRow.inputClassName}
                         value={this.props.ticket.purchase_limit}
                         onChange={this.onInputChange} />
                </FormRow>

              </FormInputGroup>
            </div>}

            <div className="action-button pull-right">
              <button className="btn-color"
                      disabled={!this.isValid()}
                      onClick={this.props.onClose}>
                Save {this.props.ticket.name}
              </button>
            </div>
          </td>
        </tr>`
