# TODO: This file is a mess. It needs to be refactored and cleaned up. At least split the logic of an order view vs a ticket view into separate components.
modulejs.define 'registration/components/checkout/confirmation',
  ['react', 'prop-types', "registration/components/checkout/tickets_list", 'registration/utilities'],
  (React, PropTypes, TicketsList, RegistrationUtilities) ->

    # Share Links component for Emphasis theme
    ShareLinksEmphasis = (props) ->
      `<div className="table-buttons">
        <a href={props.twitterShareUrl} target="_blank" ><i className="fab fa-twitter"></i></a>
        <a href={props.facebookShareUrl} target="_blank" ><i className="fab fa-facebook-f"></i></a>
      </div>`

    # Share Links component for the old theme
    ShareLinksOldTheme = (props) ->
      `<div className="table-buttons">
        <a href={props.twitterShareUrl} target="_blank" ><span className="fa fa-2x fa-twitter"></span><br/>Twitter</a>
        <a href={props.facebookShareUrl} target="_blank" ><span className="fa fa-2x fa-facebook"></span><br/>Facebook</a>
      </div>`

    class Confirmation extends React.Component
      @defaultProps:
        confirmation_view: 'order' # default

      onCancel: (event) =>
        event.preventDefault()
        this.props.onCancel?()

      onRequestRefund: (event) =>
        event.preventDefault()
        this.props.onRequestRefund?()

      render: ->
        if this.props.loading
          return `<div className="em-register_confirm widget-content">Loading...</div>`

        # Build facebook and twitter share URLs
        facebook_share_url = "https://www.facebook.com/share.php?u=#{encodeURIComponent(this.props.event_url)}"

        twitter_message = "I'm going! Are you? #{this.props.event_name}"
        twitter_share_url = "https://twitter.com/intent/tweet?url=#{encodeURIComponent(this.props.event_url)}&text=#{encodeURIComponent(twitter_message)}&hashtags=#{encodeURIComponent(this.props.event_hashtag)}&via=localist"

        # Determine the right share links component for the theme
        ShareLinks = if this.props.uses_modal then ShareLinksEmphasis else ShareLinksOldTheme

        organizer_email = this.props.order_details.event_instance.organizer_email
        
        is_order = this.props.confirmation_view == 'order'
        grand_total = this.props.order_details.order.grand_total

        if !is_order
          ticket = this.props.order_details.tickets[0]
          ticket_state = ticket.state
          is_refund_requested = ticket_state == 'refund_requested'
          is_refunded = ticket_state == 'refunded'
          is_denied = ticket_state == 'refund_denied'
          is_free = ticket.grand_total == 0
          ticket_status = ticket_state
          grand_total = ticket.grand_total
        
        `<div className="em-register_confirm widget-content">
            <h2>Your order for {this.props.event_name}</h2>
            
            <p className="em-ticket-date">
              <i className="fa fa-calendar"></i>
              {this.props.formatInstanceDate(this.props.order_details.event_instance)}
            </p>

            {is_order || (!is_order && is_denied) && this.props.event_location && <p className="em-event-location">
              <i className="fas fa-map-marker-alt"></i>
              {this.props.event_location_url ? <a href={this.props.event_location_url}>{this.props.event_location}</a> : this.props.event_location}
            </p>}

            <p className="em-ticket-info">
              <i className="fas fa-ticket-alt"></i>
              {this.props.order_details.tickets.length}x {this.props.order_details.tickets.length == 1 ? 'Ticket' : 'Tickets'} {is_order ? "" : " - " + RegistrationUtilities.ticket_status_lookup(ticket_status)} - {RegistrationUtilities.formatCurrency(grand_total, this.props.currency)} total
            </p>
            
            {is_order && <p className="em-register_confirm-info">
              You will be receiving an email confirmation with your order attached. Attendees will also be receiving their tickets in their emails.
            </p>}
            
            <p>
              If you have any questions about your order, reply directly to the confirmation email, or contact the organizer below.
            </p>
            
            <div className="confirm_share_box">
              <label>Share your plans</label>

              <ShareLinks twitterShareUrl={twitter_share_url}
                          facebookShareUrl={facebook_share_url} />
            </div>

            <ul className="em-actions-list">
              <li className="em-list-item">
                <a href="#" className="cancel_link em-link" onClick={this.onCancel}>Buy More Tickets</a>
              </li>
              {(is_order && this.props.hasRefundableTickets(this.props.order_details.tickets) || (!is_order && !is_denied && !is_refund_requested && !is_refunded)) && <li className="em-list-item">
                <a href="#" className="em-link" onClick={this.onRequestRefund}>Cancel or Request a Refund</a>
              </li>}

              {organizer_email && <li className="em-list-item">
                <a href={RegistrationUtilities.organizerTicketMailTo(organizer_email, this.props.event_name)} className="em-link">Contact Organizer</a>
              </li>}
            </ul>
            
            <div className="em-order-summary em-divider">
              <h3 className="em-order-title">Order Summary</h3>

              <p className="em-order-info">
                Order #{this.props.order_details.order.order_id} - Paid on {moment(this.props.order_details.order.paid_at).format("MMMM D, YYYY")}
              </p>
              
              <TicketsList tickets={this.props.order_details.tickets}
                           orderDetails={this.props.order_details.order}
                           currency={this.props.currency}
                           showTotal />
             
            </div>
          </div>`
