# Featured items related functions
#
# SectionTabs, for switching content sections

namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  class SectionTabs
    # Initialize the SectionTab controller
    #
    # Expects the following options:
    #  title: selector/element object of the element for the section title (will be changed)
    #  buttonContainer: selector/element of element containing the tab buttons
    #  tab_selector: selector of all tab pages
    #
    # The buttons are found in buttonContainer with "a[data-title]".  The
    # href of the button must have a fragment containing the ID of the tab page
    # to show.  data-title attribute is the title of the tab (put in #title).
    constructor: (options) ->
      @title = $(options.title)
      @button_container = $(options.buttonContainer)
      @tab_selector = options.tabSelector
      @current_tab = null

      # data-section-tabs-location="false" disables location updating, otherwise enable it
      update_location_data = @button_container.data('section-tabs-location')
      @update_location = !(update_location_data? && update_location_data == false)

      $(@tab_selector).data('section-tabs', 'tab').hide()
      $(@tab_selector).first().show()
      @button_container.find('a[data-title]').click @changeTab if @button_container

      # Set initial tab to the location hash, if this container has a link to the selected tab
      if @update_location
        initial_tab = @button_container.find("a[href='#{location.hash}']")
        scroll = false
        unless initial_tab[0]
          # If the location isn't found as is, try finding a title if the hash is prefixed with "tab:"
          if m = location.hash.match(/^#?tab:(.*)/)
            m[1] = decodeURIComponent(m[1])
            initial_tab = @button_container.find("a[data-title='#{m[1]}']")
            scroll = true

        @showTab(initial_tab[0], scroll) if initial_tab[0]


    changeTab: (event) =>
      button = $(event.target).closest 'a'
      @showTab(button)
      event.preventDefault()

    # Show the tab referenced by +button+
    showTab: (button, scroll=false) =>
      button = $(button)
      title = button.data('title') || button.html()
      @title.html(title)

      # Hide all tabs
      $(@tab_selector).hide()
      # Reset state of all buttons
      @button_container.find('a[data-title]')
        .removeClass('selected_tab_colorfive')
        .attr('aria-selected', 'false')

      $(@current_tab).trigger 'slzr:tabhidden', tab: @current_tab

      # Update the current button
      button.addClass('selected_tab_colorfive')
        .attr('aria-selected', 'true')
      tab_link = button.attr('href').replace(/^(.*)#/, '#')

      # Show the tab
      tab_panel.show() if tab_panel = $(tab_link)
      @current_tab = tab_panel
      $(tab_panel).trigger('slzr:tabshown', {tab: tab_panel, link: button})

      # Update URL to the new tab, and use replaceState when possible to bypass the browser scroll behavior
      if @update_location
        if !scroll && 'history' of window
          history.replaceState(null, '', tab_link)
        else
          location.replace tab_link


  exports.SectionTabs = SectionTabs

  # Activate section tabs on page load
  $ ($) ->
    $('[data-section-tabs="container"]').each ->
      $this = $(this)

      title = $this.find '[data-section-tabs="title"]'
      selector = $this.data 'section-tabs-selector'

      tabs = new Slzr.SectionTabs title: title, buttonContainer: $this, tabSelector: selector
      $this.data('section-tabs', tabs)