# Helper methods for handling LinkedIn logins
namespace 'Slzr', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  class exports.LinkedIn
    @login_url: null
    @logout_url: null

    @login: =>
      IN?.User?.authorize => exports.LinkedIn.loggedIn()

    @logout: =>
      IN?.User?.logout()
      exports.LinkedIn.loggedOut()

    @loggedIn: =>
      # Redirect to the login_url to finish handling linked in
      # console.log '[linkedin] logged in'
      location.href = exports.LinkedIn.login_url

    @loggedOut: =>
      # Redirect to /auth/logout, to clean up the session
      # console.log '[linkedin] logged out'
      location.href = exports.LinkedIn.logout_url
