modulejs.define 'slzr/components/date_input_floating_row',
  ['jquery', 'react', 'slzr/components/tooltip', 'underscore', 'prop-types', 'classnames'],
  ($, React, Tooltip, _, PropTypes, classNames) ->

    class DateInputFloatingRow extends React.Component
      @propTypes:
        label: PropTypes.string
        icon: PropTypes.string
        tooltip: PropTypes.string
        tooltipUrl: PropTypes.string
        placeholder: PropTypes.string
        type: PropTypes.oneOf(['time', 'date']).isRequired
        initialValue: PropTypes.oneOfType [
          PropTypes.string,
          PropTypes.instanceOf(Date)
        ]
        onChange: PropTypes.func
        instanceKey: PropTypes.any
        required: PropTypes.bool
        name: PropTypes.string
        showPreview: PropTypes.bool
        showError: PropTypes.bool
        formHasDateError: PropTypes.bool
        dateError: PropTypes.string

      @defaultProps:
        type: 'date'
        # Format for initial field value when date
        initialDateFormat: Slzr.Formats.ShortDate
        # Format for initial field value when time
        initialTimeFormat: Slzr.Formats.LongTime
        # Format for date preview
        previewDateFormat: Slzr.Formats.LongDate
        # Format for time preview
        previewTimeFormat: Slzr.Formats.LongTime

        # Error message when invalid date
        dateError: 'Unknown date'
        # Error message when invalid time
        timeError: ''

        # Arbitrary identifier, when changes causes the component to be reset (allowing reuse)
        instanceKey: ''

        # Show preview text
        showPreview: true
        # Show unknown (error) text
        showError: true

        # Indicate that the containing form has a date validation error.
        formHasDateError: false

        # Callback when date is changed
        # Receives:
        #   event object
        #   parsed date, or "error" or "blank"
        #   raw input value
        onChange: (event, current_date, input_value) -> # empty

      constructor: (props) ->
        super props

        @id_prefix = _.uniqueId('date-input-')

        # Get the pretty version of initialValue
        initial_value = null
        current_value = @props.initialValue
        is_valid = show_preview = @props.initialValue?
        is_blank = !@props.initialValue?

        if @props.type == 'date'
          if typeof(@props.initialValue) == 'string'
            date = new Date("#{@props.initialValue}T00:00:00")
          else
            date = @props.initialValue

        if @props.initialValue?
          initial_value = if @props.type == 'date'
            if 0 == Date.compare date, Date.today()
              'Today'
            else if 0 == Date.compare date, Date.today().add(1).days()
              'Tomorrow'
            else
              date.toString @props.initialDateFormat
          else
            date = @props.initialValue.toString @props.initialTimeFormat

        # Initial state
        @state =
          isValid: is_valid
          isBlank: is_blank
          # Preview visibility and content
          showPreview: show_preview
          # Parsed and display value
          currentValue: date #initial_value
          value: initial_value

      UNSAFE_componentWillReceiveProps: (nextProps) ->
        # If the initialValue changes, we need to reset everything
        return if nextProps.instanceKey == this.props.instanceKey

        initial_value = null
        current_value = nextProps.initialValue
        is_valid = show_preview = nextProps.initialValue?
        is_blank = !nextProps.initialValue?

        if nextProps.type == 'date'
          if typeof(nextProps.initialValue) == 'string'
            date = new Date("#{nextProps.initialValue}T00:00:00")
          else
            date = nextProps.initialValue

        if nextProps.initialValue?
          initial_value = if nextProps.type == 'date'
            if 0 == Date.compare date, Date.today()
              'Today'
            else if 0 == Date.compare date, Date.today().add(1).days()
              'Tomorrow'
            else
              date.toString nextProps.initialDateFormat
          else
            nextProps.initialValue.toString nextProps.initialTimeFormat

        this.setState
          isValid: is_valid
          isBlank: is_blank
          showPreview: show_preview
          currentValue: date
          value: initial_value



      # Handle change of input
      _inputChanged: (evt) =>
        input_value = evt.target.value

        # Parse input into a date or error
        current_value = if !input_value || input_value.isBlank()
          'blank'
        else
          d = Date.parse(input_value, bias: 'future_date')
          if d instanceof Date
            d
          else
            'error'

        @props.onChange? evt, current_value, input_value

        @setState
          isValid: current_value != 'error'
          isBlank: current_value == 'blank'
          showPreview: current_value != 'blank'
          currentValue: current_value
          value: input_value

      # Handle a key press.
      #
      # If an onEnterKey callback is defined, captures the ENTER keypress and triggres that, otherwise
      # lets it continue.
      #
      # The callback is expected to return true if the keypress should be blocked.
      _keyPressed: (evt) =>
        if @props.onEnterKey? && evt.key == 'Enter'
          evt.preventDefault() if @props.onEnterKey(evt)

      # Format the given Date for the preview
      #
      # date should be a Date representing the parsed input value,
      # or "blank" or "error" for those states
      _formatPreview: (date) =>
        if @props.type == 'date'
          switch date
            when 'blank' then ''
            when 'error'
              @props.dateError
            else date.toString @props.previewDateFormat
        else # time
          switch date
            when 'blank' then ''
            when 'error' then @props.timeError
            else date?.toString @props.previewTimeFormat

      renderFormDateError: =>
        return unless this.props.formHasDateError

        message = "You need a start date."
        `<span role="alert">{message}</span>`

      render: =>
        preview_el = if @state.currentValue == 'error' && @props.showError
          `<span id={this.id_prefix + '-preview'} role="alert" className="preview error">{this._formatPreview(this.state.currentValue)}</span>`
        else if @state.currentValue != 'blank' && @props.showPreview && @state.showPreview
          `<span id={this.id_prefix + '-preview'} className="preview">{this._formatPreview(this.state.currentValue)}</span>`
        else
          null

        if this.props.label
          tooltip_tag = unless _.isBlank(this.props.tooltip)
            `<Tooltip text={this.props.tooltip}
                      url={this.props.tooltipUrl} />`

          required_tag = `<span className="required">REQUIRED</span>` if this.props.required
          label_tag = `<label className="em-input-floating--label" htmlFor={this.props.name}>{this.props.label} {required_tag} {tooltip_tag}</label>`
        else
          label_tag = null

        is_invalid = this.props.formHasDateError || (this.state.currentValue == 'error' && this.props.showError)

        classes = classNames 'em-input-floating',
          'em-input-floating--placeholder': this.state.value == ''
          'em-error': is_invalid

        `<div className="em-input_container">
          <div className={classes}>
            {label_tag}
            <input type="text" value={this.state.value}
                   aria-describedby={this.id_prefix + '-preview'}
                   aria-invalid={is_invalid}
                   name={this.props.name}
                   id={this.props.name}
                   className="em-input-floating--input"
                   onChange={this._inputChanged}
                   onKeyDown={this._keyPressed}/>
            <i className="fas fa-info-circle em-icon-error"></i>
            {this.props.icon && <i className={this.props.icon}></i>}
          </div>
          {preview_el}
          {this.renderFormDateError()}
        </div>`
