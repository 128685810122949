modulejs.define 'registration/store/reducer',
  ['jquery', 'react', 'redux', 'underscore', 'object-path-immutable', 'moment', 'registration/store/actions'],
  ($, React, Redux, _, objectPath, moment, Actions) ->
    ## Registration editor Reducer
    #
    # The state for the registration editor consists of:
    #   attendee_questions: List of attendee questions for the event
    #   payout_accounts: Available payout accounts for the user to choose from
    #   promo_codes: List of promo codes for the event
    #   ticket_groups: List of ticket groups for the event
    #   ticket_classes: List of ticket classes for the event
    #   dragging: Various state for drag-drop operations in progress
    #     ticket_classes: State for tracking in-progress drag of ticket classes
    #       dragging_id: ID of ticket class currently being dragged
    #       previous: Position and ticket group of ticket class before dragging
    #     ticket_groups: State for tracking in-progress drag of ticket groups
    #       (see ticket_classes)
    #
    # The lists are flat arrays, not keyed by IDs, and order isn't really important.
    # Therefore, lots of the reducer actions will find the index by ID.

    # Return a blank ticket class array
    newTicketClass = (attributes={}) ->
      new_id = attributes.id || Actions.getId('ticket')

      _.extend
        id: new_id
        name: ""
        price: 0
        customer_pays_fees: true
        hidden: false
        active: true
        create: true
        delete: false
        ticket_type: 'in_person'
        virtual_event_message: ""
        virtual_event_url: ""
      , attributes

    # Return a blank ticket group hash
    newTicketGroup = (attributes={}) ->
      new_id = attributes.id || Actions.getId('ticket_group')

      _.extend
        id: new_id
        name: ""
        create: true
        delete: false
        ticket_class_ids: []
      , attributes

    # Return a blank promo code hash
    newPromoCode = (attributes={}) ->
      new_id = attributes.id || Actions.getId('promo_code')

      _.extend
        id: new_id
        code: ''
        discount_type: 'percent'
        active: true
        create: true
        delete: false
        ticket_class_ids: []
      , attributes

    # Return a blank attendee question hash
    newAttendeeQuestion = (attributes={}) ->
      new_id = attributes.id || Actions.getId('question')

      _.extend
        id: new_id
        question: ''
        required: false
        response_type: 'string'
        active: true
        create: true
        delete: false
        choices: ['', '']
      , attributes

    # Helper to parse a string to a Date object, or return the value unchanged
    parseDate = (date) ->
      parsed = moment(date)
      if parsed.isValid()
        parsed.toDate()
      else
        date

    # Move the ticket class to the appropriate place, by adjusting position as necessary for all other classes
    #
    # This cheats a bit by keeping the position as if the tickets are in one large list, instead of sub-lists by ticket_group_id.
    # The relation between values is the important part, not the absolute values.
    moveTicketClass = (previousState, ticket_class_id, target_group_id, target_position) ->
      target_position = 99999 if target_position == 'end'
      target_position = 0 if target_position == 'top'

      ticket_index = _.findIndex previousState.ticket_classes, id: ticket_class_id
      current_position = previousState.ticket_classes[ticket_index].position
      current_group = previousState.ticket_classes[ticket_index].ticket_group_id

      current_group_index = _.findIndex previousState.ticket_groups, id: current_group
      target_group_index = _.findIndex previousState.ticket_groups, id: target_group_id

      # If moving down, offset the target position so it feels more natural when dragging down
      target_position += 1 if target_position > current_position

      newState = previousState

      # If switching between groups, update the list of ticket_class_ids on each
      #
      # The ticket_group_id and position on the ticket class is set below
      if current_group_index != target_group_index
        # Remove from current group
        if current_group_index > -1
          class_ids = newState.ticket_groups[current_group_index].ticket_class_ids
          newState = objectPath.set newState, ['ticket_groups', current_group_index, 'ticket_class_ids'], _.reject(class_ids, (i) -> i == ticket_class_id)

        # Add to new group
        if target_group_index > -1
          newState = objectPath.push newState, ['ticket_groups', target_group_index, 'ticket_class_ids'], ticket_class_id

      newState = objectPath(newState)
        # Move lower items to make room for the current one
        .update ['ticket_classes'], (ticket_classes) ->
          for ticket_class in ticket_classes
            if ticket_class.position >= target_position
              objectPath.set ticket_class, 'position', ticket_class.position + 1
            else
              ticket_class

        # Set new position and group
        .set ['ticket_classes', ticket_index, 'position'], target_position
        .set ['ticket_classes', ticket_index, 'ticket_group_id'], target_group_id
        .value()

      # Adjust positions to be contiguous
      renumberTicketClassPositions(newState)

    # For all ticket classes, make sure the position value is contiguous starting from 0
    # within each ticket group
    renumberTicketClassPositions = (state) ->
      newState = state
      classes = _.sortBy state.ticket_classes, 'position'
      positions = {'default': 0}

      for ticket_class in classes
        positions[ticket_class.ticket_group_id] ||= 0
        index = _.findIndex state.ticket_classes, id: ticket_class.id
        newState = objectPath.set newState, ['ticket_classes', index, 'position'], positions[ticket_class.ticket_group_id]
        positions[ticket_class.ticket_group_id] += 1

      newState

    # Move the ticket group to the appropriate place, by adjusting position as necessary for all other classes
    moveTicketGroup = (previousState, ticket_group_id, target_position) ->
      target_position = 99999 if target_position == 'end'
      target_position = 0 if target_position == 'top'

      ticket_group_index = _.findIndex previousState.ticket_groups, id: ticket_group_id
      current_position = previousState.ticket_groups[ticket_group_index].position

      # If moving down, offset the target position so it feels more natural when dragging down
      target_position += 1 if target_position > current_position

      newState = objectPath(previousState)
        # Move lower items to make room for the current one
        .update ['ticket_groups'], (ticket_groups) ->
          for ticket_group in ticket_groups
            if ticket_group.position >= target_position
              objectPath.set ticket_group, 'position', ticket_group.position + 1
            else
              ticket_group

        # Set new position and group
        .set ['ticket_groups', ticket_group_index, 'position'], target_position
        .value()

      # Adjust positions to be contiguous
      renumberTicketGroupPositions(newState)

    # For all ticket groups, make sure the position value is contiguous starting from 0
    renumberTicketGroupPositions = (state) ->
      newState = state
      groups = _.sortBy state.ticket_groups, 'position'
      position = 0

      for ticket_group in groups
        index = _.findIndex state.ticket_groups, id: ticket_group.id
        newState = objectPath.set newState, ['ticket_groups', index, 'position'], position
        position += 1

      newState

    # Move the attendee question to the appropriate place, by adjusting position as necessary for all other questions
    moveAttendeeQuestion = (previousState, attendee_question_id, target_position) ->
      target_position = 99999 if target_position == 'end'
      target_position = 0 if target_position == 'top'

      attendee_question_index = _.findIndex previousState.attendee_questions, id: attendee_question_id
      current_position = previousState.attendee_questions[attendee_question_index].position

      # If moving down, offset the target position so it feels more natural when dragging down
      target_position += 1 if target_position > current_position

      newState = objectPath(previousState)
        # Move lower items to make room for the current one
        .update ['attendee_questions'], (attendee_questions) ->
          for attendee_question in attendee_questions
            if attendee_question.position >= target_position
              objectPath.set attendee_question, 'position', attendee_question.position + 1
            else
              attendee_question

        # Set new position and group
        .set ['attendee_questions', attendee_question_index, 'position'], target_position
        .value()

      # Adjust positions to be contiguous
      renumberAttendeeQuestionPositions(newState)

    # For all attendee questions, make sure the position value is contiguous starting from 0
    renumberAttendeeQuestionPositions = (state) ->
      newState = state
      attendee_questions = _.sortBy state.attendee_questions, 'position'
      position = 0

      for attendee_question in attendee_questions
        index = _.findIndex state.attendee_questions, id: attendee_question.id
        newState = objectPath.set newState, ['attendee_questions', index, 'position'], position
        position += 1

      newState

    # Reorder the choices array for question_id to place item at target_index
    moveAttendeeQuestionChoice = (state, question_id, item, target_index) ->
      attendee_question_index = _.findIndex state.attendee_questions, id: question_id
      attendee_question = state.attendee_questions[attendee_question_index]

      # Find the previous location of the item
      previous_index = _.indexOf attendee_question.choices, item

      return state if previous_index == target_index

      target_index = attendee_question.choices.length if target_index == 'end'

      # Copy choices
      next_choices = attendee_question.choices.slice()
      # And move the item to the new location at target_index
      next_choices.splice(previous_index, 1) unless previous_index == -1
      next_choices.splice(target_index, 0, item)

      objectPath.set state, ['attendee_questions', attendee_question_index, 'choices'], next_choices

    # Update the payout-account related settings under `settings` based on the selected payout account
    updatePayoutAccountSettings = (state) ->
      # Get currently payout account selection and details from the state
      payout_account_id = state.event.payout_account_id

      # Find matching payout account, by comparing as string IDs
      payout_account = _.find state.payout_accounts, (payout_account) -> String(payout_account.id) == String(payout_account_id)
      if payout_account
        # Update the settings state with the selected payout account's fee structure and support flags
        new_fees =
          payment:
            base: payout_account.provider_fee_base
            percent: payout_account.provider_fee_percent
          localist:
            base: payout_account.localist_fee_base
            percent: payout_account.localist_fee_percent
          localist_item:
            base: payout_account.localist_item_fee_base
            percent: payout_account.localist_item_fee_percent

        objectPath(state)
          .set(['settings', 'has_payout_account'], !!payout_account_id)
          .set(['settings', 'customer_pays_fees_supported'], payout_account['supports_customer_pays_fees?'])
          .set(['settings', 'fees'], new_fees)
          .value()

      else
        # No payout account was found
        objectPath(state)
          .set(['settings', 'has_payout_account'], false)
          .value()


    reducer = (previousState, action) ->
      switch action.type
        when Actions.SET_TICKET_CLASSES_GROUPS_ATTENDEE_QUESTIONS_PROMOS
          objectPath(previousState)
            .set ['ticket_classes'], action.payload.ticket_classes
            .set ['ticket_groups'], action.payload.ticket_groups
            .set ['attendee_questions'], action.payload.attendee_questions
            .set ['promo_codes'], action.payload.promo_codes
            .value()

        when Actions.EVENT_EXPERIENCE_CHANGE
          objectPath(previousState)
            .set ['event', 'experience'], action.payload.experience
            .value()

        when Actions.EVENT_STREAM_URL_CHANGE
          objectPath(previousState)
            .set ['event', 'stream_url'], action.payload.stream_url
            .value()

        when Actions.EVENT_PAYOUT_ACCOUNT_ID_CHANGE
          nextState = objectPath(previousState)
            .set ['event', 'payout_account_id'], action.payload.payout_account_id
            .value()

          updatePayoutAccountSettings(nextState)

        when Actions.TICKET_CLASS_CHANGE
          update_index = _.findIndex previousState.ticket_classes, {id: action.payload.id}

          switch action.payload.name
            when 'price', 'min_price', 'max_price'
              # Price inputs are cents
              new_value = Number(action.payload.value)
            else
              new_value = action.payload.value

          objectPath.set previousState, ['ticket_classes', update_index, action.payload.name], new_value

        when Actions.TICKET_CLASS_ADD
          event_id = previousState.eventId
          stream_url = previousState.event.stream_url
          # Make the new ticket virtual only if the event's experience is virtual
          #
          # Note there is also a difference in the value for in-person: "in_person" here vs "inperson" on the experience
          experience = if previousState.event.experience == 'virtual'
                         'virtual'
                       else
                         'in_person'

          # Append the new ticket class
          new_position = previousState.ticket_classes.length
          new_ticket_class = newTicketClass(
            position: new_position
            event_id: event_id
            id: action.payload.id
            ticket_type: experience
            virtual_event_url: stream_url
          )
          next_state = objectPath.push previousState, 'ticket_classes', new_ticket_class

          # and move it to the right spot
          moveTicketClass(next_state, new_ticket_class.id, null, 'top')


        when Actions.TICKET_CLASS_REMOVE
          update_index = _.findIndex previousState.ticket_classes, {id: action.payload.id}

          objectPath(previousState)
            .set ['ticket_classes', update_index, 'delete'], true
            .set ['ticket_classes', update_index, 'create'], false
            .value()

        when Actions.TICKET_CLASS_DRAG_START
          # Stash the group/position for the ticket class and mark it as dragging
          ticket_class = _.find previousState.ticket_classes, id: action.payload.id

          objectPath(previousState)
            .set ['dragging', 'ticket_classes', 'dragging_id'], ticket_class.id
            .set ['dragging', 'ticket_classes', 'previous'],
              ticket_group_id: ticket_class.ticket_group_id
              position: ticket_class.position
            .value()

        when Actions.TICKET_CLASS_DRAG_FINISH
          # Reset dragging state
          objectPath(previousState)
            .set ['dragging', 'ticket_classes', 'dragging_id'], null
            .set ['dragging', 'ticket_classes', 'previous'], null
          .value()

        when Actions.TICKET_CLASS_DRAG_CANCEL
          # Revert state to old group/position
          newState = moveTicketClass previousState,
            action.payload.id,
            previousState.dragging.ticket_classes.previous.ticket_group_id,
            previousState.dragging.ticket_classes.previous.position

          objectPath(newState)
            .set ['dragging', 'ticket_classes', 'dragging_id'], null
            .set ['dragging', 'ticket_classes', 'previous'], null
          .value()

        when Actions.TICKET_CLASS_DRAG_UPDATE
          moveTicketClass previousState,
            action.payload.item.id,
            action.payload.target_group_id,
            action.payload.target_position


        when Actions.TICKET_GROUP_CHANGE
          update_index = _.findIndex previousState.ticket_groups, {id: action.payload.id}

          new_value = action.payload.value
          objectPath.set previousState, ['ticket_groups', update_index, action.payload.name], new_value

        when Actions.TICKET_GROUP_ADD
          event_id = previousState.eventId
          new_position = previousState.ticket_groups.length
          new_ticket_group = newTicketGroup(
            position: new_position
            event_id: event_id
            id: action.payload.id
          )

          next_state = objectPath.push previousState, 'ticket_groups', new_ticket_group

          # Move it to the right spot
          moveTicketGroup(next_state, new_ticket_group.id, 'top')

        when Actions.TICKET_GROUP_REMOVE
          update_index = _.findIndex previousState.ticket_groups, {id: action.payload.id}

          next_state = objectPath(previousState)
            .set ['ticket_groups', update_index, 'delete'], true
            .set ['ticket_groups', update_index, 'create'], false
            .set ['ticket_groups', update_index, 'ticket_group_ids'], []
            .value()

          # Move any ticket classes in the group to the end of the default group
          for ticket_class in next_state.ticket_classes
            if ticket_class.ticket_group_id == action.payload.id
              next_state = moveTicketClass next_state, ticket_class.id, null, 'end'

          next_state

        when Actions.TICKET_GROUP_DRAG_START
          # Stash the group/position for the ticket group and mark it as dragging
          ticket_group = _.find previousState.ticket_groups, id: action.payload.id

          objectPath(previousState)
            .set ['dragging', 'ticket_groups', 'dragging_id'], ticket_group.id
            .set ['dragging', 'ticket_groups', 'previous'],
              position: ticket_group.position
            .value()

        when Actions.TICKET_GROUP_DRAG_FINISH
          # Reset dragging state
          objectPath(previousState)
            .set ['dragging', 'ticket_groups', 'dragging_id'], null
            .set ['dragging', 'ticket_groups', 'previous'], null
          .value()

        when Actions.TICKET_GROUP_DRAG_CANCEL
          # Revert state to old group/position
          newState = moveTicketGroup previousState,
            action.payload.id,
            previousState.dragging.ticket_groups.previous.position

          objectPath(newState)
            .set ['dragging', 'ticket_groups', 'dragging_id'], null
            .set ['dragging', 'ticket_groups', 'previous'], null
          .value()

        when Actions.TICKET_GROUP_DRAG_UPDATE
          moveTicketGroup previousState,
            action.payload.item.id,
            action.payload.target_position


        when Actions.PROMO_CODE_CHANGE
          update_index = _.findIndex previousState.promo_codes, {id: action.payload.id}

          switch action.payload.name
            when 'amount'
              # Price inputs are in cents
              new_value = Number(action.payload.value)
            else
              new_value = action.payload.value

          objectPath.set previousState, ['promo_codes', update_index, action.payload.name], new_value

        when Actions.PROMO_CODE_ADD
          event_id = previousState.eventId

          objectPath.insert previousState, 'promo_codes', newPromoCode(
            event_id: event_id
            id: action.payload.id
          ), 0

        when Actions.PROMO_CODE_REMOVE
          update_index = _.findIndex previousState.promo_codes, {id: action.payload.id}

          objectPath(previousState)
            .set ['promo_codes', update_index, 'delete'], true
            .set ['promo_codes', update_index, 'create'], false
            .value()

        when Actions.PROMO_CODE_TICKET_CLASS_ADD
          promo_code_index = _.findIndex previousState.promo_codes, {id: action.payload.id}

          if previousState.promo_codes[promo_code_index].ticket_class_ids?.indexOf(action.payload.ticket_class_id) > -1
            # already added, so no change necessary
            previousState
          else
            # append value to the list
            objectPath.push previousState, ['promo_codes', promo_code_index, 'ticket_class_ids'], action.payload.ticket_class_id

        when Actions.PROMO_CODE_TICKET_CLASS_REMOVE
          promo_code_index = _.findIndex previousState.promo_codes, {id: action.payload.id}
          new_ticket_class_ids = _.reject previousState.promo_codes[promo_code_index].ticket_class_ids, (item) -> item == action.payload.ticket_class_id
          objectPath.set previousState, ['promo_codes', promo_code_index, 'ticket_class_ids'], new_ticket_class_ids




        when Actions.ATTENDEE_QUESTION_CHANGE
          update_index = _.findIndex previousState.attendee_questions, {id: action.payload.id}
          new_value = action.payload.value
          objectPath.set previousState, ['attendee_questions', update_index, action.payload.name], new_value

        when Actions.ATTENDEE_QUESTION_ADD
          event_id = previousState.eventId
          new_position = previousState.attendee_questions.length

          next_state = objectPath.insert previousState, 'attendee_questions', newAttendeeQuestion(
            position: new_position
            event_id: event_id
            id: action.payload.id
          ), 0

          moveAttendeeQuestion next_state, action.payload.id, 'start'

        when Actions.ATTENDEE_QUESTION_REMOVE
          update_index = _.findIndex previousState.attendee_questions, {id: action.payload.id}

          objectPath(previousState)
            .set ['attendee_questions', update_index, 'delete'], true
            .set ['attendee_questions', update_index, 'create'], false
            .value()

        when Actions.ATTENDEE_QUESTION_REMOVE
          update_index = _.findIndex previousState.attendee_questions, {id: action.payload.id}

          objectPath(previousState)
            .set ['attendee_questions', update_index, 'delete'], true
            .set ['attendee_questions', update_index, 'create'], false
            .set ['attendee_questions', update_index, 'attendee_question_ids'], []
            .value()

        when Actions.ATTENDEE_QUESTION_DRAG_START
          # Stash the group/position for the attendee question and mark it as dragging
          attendee_question = _.find previousState.attendee_questions, id: action.payload.id

          objectPath(previousState)
            .set ['dragging', 'attendee_questions', 'dragging_id'], attendee_question.id
            .set ['dragging', 'attendee_questions', 'previous'],
              position: attendee_question.position
            .value()

        when Actions.ATTENDEE_QUESTION_DRAG_FINISH
          # Reset dragging state
          objectPath(previousState)
            .set ['dragging', 'attendee_questions', 'dragging_id'], null
            .set ['dragging', 'attendee_questions', 'previous'], null
          .value()

        when Actions.ATTENDEE_QUESTION_DRAG_CANCEL
          # Revert state to old group/position
          newState = moveAttendeeQuestion previousState,
            action.payload.id,
            previousState.dragging.attendee_questions.previous.position

          objectPath(newState)
            .set ['dragging', 'attendee_questions', 'dragging_id'], null
            .set ['dragging', 'attendee_questions', 'previous'], null
          .value()

        when Actions.ATTENDEE_QUESTION_DRAG_UPDATE
          moveAttendeeQuestion previousState,
            action.payload.item.id,
            action.payload.target_position

        when Actions.ATTENDEE_QUESTION_CHOICE_ADD
          update_index =  _.findIndex previousState.attendee_questions, {id: action.payload.question_id}
          objectPath.push previousState, ['attendee_questions', update_index, 'choices'], ''
        
        when Actions.ATTENDEE_QUESTION_CHOICE_CHANGE
          update_question_index =  _.findIndex previousState.attendee_questions, {id: action.payload.question_id}
          objectPath.set previousState, ["attendee_questions", update_question_index, "choices", action.payload.index], action.payload.value
        
        when Actions.ATTENDEE_QUESTION_CHOICE_REMOVE
          update_question_index =  _.findIndex previousState.attendee_questions, {id: action.payload.question_id}
          objectPath.del previousState, ["attendee_questions", update_question_index, "choices", action.payload.index]

        when Actions.ATTENDEE_QUESTION_CHOICE_DRAG_START
          # Store details about the item being dragged, and the initial position
          objectPath(previousState)
            .set ['dragging', 'attendee_question_choice', 'question_id'], action.payload.question_id
            .set ['dragging', 'attendee_question_choice', 'dragging_id'], action.payload.item.id
            .set ['dragging', 'attendee_question_choice', 'previous'], action.payload.item.index
          .value()

        when Actions.ATTENDEE_QUESTION_CHOICE_DRAG_UPDATE
          # Move the question to the new state
          moveAttendeeQuestionChoice(previousState, action.payload.question_id, action.payload.item.id, action.payload.index)

        when Actions.ATTENDEE_QUESTION_CHOICE_DRAG_FINISH
          # Clear drag state tracking data
          objectPath(previousState)
            .set ['dragging', 'attendee_question_choice', 'question_id'], null
            .set ['dragging', 'attendee_question_choice', 'dragging_id'], null
            .set ['dragging', 'attendee_question_choice', 'previous'], null
          .value()

        when Actions.ATTENDEE_QUESTION_CHOICE_DRAG_CANCEL
          # Revert question state
          nextState = moveAttendeeQuestionChoice(previousState,
            action.payload.question_id,
            previousState.dragging.attendee_question_choice.dragging_id,
            previousState.dragging.attendee_question_choice.previous
          )

          # Clear drag state tracking data
          objectPath(nextState)
            .set ['dragging', 'attendee_question_choice', 'question_id'], null
            .set ['dragging', 'attendee_question_choice', 'dragging_id'], null
            .set ['dragging', 'attendee_question_choice', 'previous'], null
          .value()

        else
          # Initialize reducer state
          newState = objectPath(previousState)
            # Convert various attributes from strings to Dates
            .update ['ticket_classes'], (ticket_classes) ->
              for ticket_class in ticket_classes
                for attr in ['availability_start', 'availability_finish', 'created_at', 'updated_at', 'deleted_at']
                  ticket_class = objectPath.set(ticket_class, attr, parseDate(ticket_class[attr]))
                ticket_class

            # Sort promo codes by code case insensitive
            .update ['promo_codes'], (promo_codes) ->
              new_promo_codes = for promo_code in promo_codes
                for attr in ['expires_at']
                  promo_code = objectPath.set(promo_code, attr, parseDate(promo_code[attr]))
                promo_code

              _.sortBy(new_promo_codes, (i) -> i.code.toLowerCase())

            # Make sure all attendee questions have at least two choices
            .update ['attendee_questions'], (attendee_questions) ->
              for attendee_question in attendee_questions
                if attendee_question.choices.length == 0
                  objectPath.set(attendee_question, 'choices', ['', ''])
                else
                  attendee_question

            # Initialize drag state
            .set ['dragging', 'ticket_classes'], {}
            .set ['dragging', 'ticket_groups'], {}
            .set ['dragging', 'attendee_questions'], {}
            .value()

          # Fix the members of each ticket_group based on the id stored on the ticket class
          #
          # In some cases, the server can provide an empty list of ticket IDs for each ticket group
          tickets_by_group_id = _.groupBy newState.ticket_classes, (i) -> i.ticket_group_id

          newState = objectPath(newState)
            .update ['ticket_groups'], (ticket_groups) ->
              for ticket_group in ticket_groups
                new_ticket_class_ids = tickets_by_group_id[ticket_group.id] || []
                new_ticket_class_ids = _.pluck(new_ticket_class_ids, 'id')
                objectPath.set(ticket_group, 'ticket_class_ids', new_ticket_class_ids)
            .value()

          newState = updatePayoutAccountSettings(newState)

          # Make sure the initial positions are sane
          renumberTicketClassPositions newState
