// Provides a registry of EJS/JST templates.

// This is basically a hash of files underneath app/assets/javascripts/templates,
// named to match the naming Sprockets provides for EJS/JST templates.
//
// The value of each key is a function implementing the template.
//
// Any new templates or usages shouldn't rely on the JST variable or registration here.
//
// The builds via Sprockets recursively include the contents of this directory,
// registering each EJS template in a global window.JST hash based on the template's
// name relative to the app/assets/javascripts/templates directory.
//
// See config/initializers/sprockets.rb for the monkey patch to strip the templates prefix/
// See config/webpack/ejs.config.js for configuration of ejs-loader.

module.exports = {
  // Public
  'filter_item': require('javascripts/templates/filter_item.jst.ejs'),
  'upload_recent_activity': require('javascripts/templates/upload_recent_activity.jst.ejs'),

  // Admin (included here as they're in the public bundle under Sprockets)
  'admin/event_reject_reason_lightbox': require('javascripts/templates/admin/event_reject_reason_lightbox.jst.ejs'),
  'admin/filter_item': require('javascripts/templates/admin/filter_item.jst.ejs'),
  'admin/map_info_window': require('javascripts/templates/admin/map_info_window.jst.ejs'),
  'admin/newsletters/item_listing': require('javascripts/templates/admin/newsletters/item_listing.jst.ejs'),
  'admin/newsletters/item_row': require('javascripts/templates/admin/newsletters/item_row.jst.ejs'),
}