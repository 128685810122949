# Message center client-side code

namespace 'Slzr.Page.Messages', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  MESSAGE_CENTER_CONVERSATION_URL = "/messagecenter/@ID@"

  Slzr.jQuery ($) ->
    return unless Slzr.Meta.page == 'messages'

    conversation_container = $('[data-conversation="content"]')
    conversation_list = $('[data-conversation="conversations"]')
    loading = $('[data-conversation="loading"]')

    showLoading = ->
      loading.show()
      conversation_container.hide()

    hideLoading = ->
      loading.hide()
      conversation_container.show()

    # Update the HTML based on a response
    updateHTML = (result) ->
      conversation_list.html(result.conversation_list) if result.conversation_list?
      conversation_container.html(result.messages) if result.messages?

    # Refresh both a selected conversation and the conversation list
    refreshConversations = (selected=null) ->
      conversation_url = MESSAGE_CENTER_CONVERSATION_URL.replace('@ID@', selected)

      showLoading()

      # If a group message is sent, display a sent notice and no conversation.
      if selected.match(/^group_/)
        flashNotice "Message sent"
        selectConversation('none')
      else # show user to user conversation like normal.
        $.get conversation_url, (result) ->
          updateHTML(result)
          hideLoading()

          conversation_list.find('.active_entry').removeClass('active_entry')
          conversation_list.find('[data-with-id="' + selected + '"]').addClass('active_entry')

    # Select/refresh the messages for a conversation
    selectConversation = (with_id) ->
      conversation_url = MESSAGE_CENTER_CONVERSATION_URL.replace('@ID@', with_id)

      showLoading()
      $.get conversation_url, (result) ->
        updateHTML(result)
        hideLoading()

        conversation_list.find('.active_entry').removeClass('active_entry')
        conversation_list.find('[data-with-id="' + with_id + '"]').addClass('active_entry')

    # Load a new conversation into the conversation viewer
    $(document).onAction 'select-conversation', (evt) ->
      with_id = $(this).data('with-id')
      selectConversation(with_id)
      evt.preventDefault()

    # Load previous page of messages
    $(document).onAction 'load-previous', (evt) ->
      page_url = $(this).attr('href')
      $.get page_url, updateHTML
      evt.preventDefault()

    $(document).onAction 'compose-message', (evt) ->
      conversation_url = MESSAGE_CENTER_CONVERSATION_URL.replace('@ID@', 'new')

      # Get the HTML for a new message
      showLoading()
      $.get conversation_url, (result) ->
        updateHTML(result)
        hideLoading()

    # Delete all messages from a conversation
    $(document).onAction 'remove-conversation', (evt) ->
      evt.stopPropagation()
      conversation_delete_url = $(this).attr('href')
      conversation_element = $(this).closest('[data-conversation="conversation"]')

      $.ajax
        url: conversation_delete_url
        type: 'DELETE'
      .fail (jqxhr, textStatus, errorThrown) ->
        console?.error('error removing conversation:', errorThrown.stack)
        flashNotice 'There was a problem removing the conversation. Please try again'
      .done (result) ->
        conversation_element.remove()
        selectConversation('none')
        hideNotice()

    $(document).onAction 'cancel-compose', (evt) ->
      selectConversation('none')
      evt.stopPropagation()

    $(document).on 'submit', 'form[data-form="compose-message"]', (evt) ->
      $form = $(this)
      url = $form.attr('action')
      post_data = $form.serializeArray()

      # validate form
      message = $form.find('[name="message"]').val()
      with_id = $form.find('[name="send_to"]').val()

      ok = true
      if with_id.isBlank()
        alert "I don't know where to send the message!"
        ok = false
      else if message.isBlank()
        alert "You must specify a message to send!"
        ok = false

      evt.preventDefault()
      return false unless ok

      # Post!
      submit_button = $form.find('input[type="submit"]')
      submit_button.data('old-value', submit_button.val()).val('Sending...').prop('disabled', true).addClass('disabled')

      $.ajax
        url: url
        type: 'POST'
        data:
          send_to: with_id
          message: message
      .fail (jqxhr, textStatus, errorThrown) ->
        console?.error('error sending message:', errorThrown.stack)
        flashNotice 'There was a problem sending the message. Please try again'
        submit_button.val(submit_button.data('old-value')).prop('disabled', false).removeClass('disabled')
      .done (result) ->
        if result.ok
          hideNotice()
          # Refresh conversation and message list
          refreshConversations with_id
        else
          # Do something useful
          if result.error == 'no_message'
            flashNotice 'You must provide a message to send'
          else if result.error == 'no_target'
            flashNotice "I don't know where to send the message"
          else
            flashNotice 'There was a problem sending the message. Please try again'

          submit_button.val(submit_button.data('old-value')).prop('disabled', false).removeClass('disabled')

      evt.preventDefault()
      false
