modulejs.define 'slzr/components/instance_manager',
  ['react', 'moment', 'underscore', 'slzr/react/form_row', 'slzr/react/form_input_group', 'slzr/components/date_input_floating_row', 'slzr/components/date_input_row',
   'slzr/components/instance_list', 'slzr/react/checkbox', 'slzr/react/form_header', 'slzr/react/form_theme_context'],
  (React, moment, _, FormRow, FormInputGroup, DateInputFloatingRow, DateInputRow,
   InstanceList, Checkbox, FormHeader, FormThemeContext) ->

    # Return a number's ordinal form
    asOrdinal = (number) ->
      suffixes = ['th', 'st', 'nd', 'rd']
      v = number % 100
      suffix = suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]

      "#{number}#{suffix}"

    # Spell out small (<= 10) positive integers, otherwise just return hte number
    numberAsWord = (number) ->
      numbers = [null, 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']
      if number > 0 && number <= 10
        numbers[number]
      else
        number

    # Pluralize the given string for count
    pluralize = (count, string, plural_string=null) ->
      if Number(count) == 1
        string
      else
        plural_string || (string + "s")

    # Component wrapping a form segment for the instance management
    #
    # This covers the date inputs as well as the instance list
    class InstanceManagerComponent extends React.Component
      @contextType: FormThemeContext

      @defaultProps:
        # When true, the containing form is in an error state due to the instances currently set
        formHasDateError: false
        # Component accessibility title (used as overall group title)
        componentTitle: "Schedule"
        # Confirmed Dates header
        confirmedDatesHeader: "Confirmed Dates"
        # Show * instead of REQUIRED label on inputs
        requiredAsAsterisk: false

      constructor: (props) ->
        super(props)

        # Create initial state
        this.state =
          # Repeat type
          repeat_type: 'once'
          # Repeat interval
          interval: 1
          # Selected days of week (weekly recurrence)
          weekdays: [0, 1, 2, 3, 4, 5, 6]
          # Month repeat type (monthly recurrence)
          month_repeat_on: 'day_of_month'
          # End type for recurrence
          recurrence_end_type: 'date'
          # End date for recurrence
          recurrence_end_date: null
          recurrence_end_date_invalid: false
          # End count for recurrence
          recurrence_end_count: null
          recurrence_end_count_invalid: false
          # Current entry start date
          start_date: null
          start_date_invalid: false
          # Current entry start time
          start_time: null
          start_time_invalid: false
          # Current entry end time
          end_time: null
          end_time_invalid: false
          # Remove future instances
          remove_future_instances: false
          # Valid values entered, allowing addition of dates
          valid_entry: false
          # Have entries of dates
          have_dates: this.props.eventInstances.count() > 0

        # Update field values based on the initial stuff
        this.state = Object.assign({}, this.state, @props.eventInstances.getInputFields())

      componentDidMount: =>
        @props.eventInstances.on 'slzr:instance:add', @_instancesChanged
        @props.eventInstances.on 'slzr:instance:change', @_instancesChanged
        @props.eventInstances.on 'slzr:instance:remove', @_instancesChanged
        @props.eventInstances.on 'slzr:fields:changed', @_resetInputFields

      componentWillUnmount: =>
        @props.eventInstances.removeListener 'slzr:instance:add', @_instancesChanged
        @props.eventInstances.removeListener 'slzr:instance:change', @_instancesChanged
        @props.eventInstances.removeListener 'slzr:instance:remove', @_instancesChanged
        @props.eventInstances.removeListener 'slzr:fields:changed', @_resetInputFields

      _instancesChanged: (instances) =>
        @setState have_dates: @props.eventInstances.count() > 0

      _resetInputFields: =>
        @setState @props.eventInstances.getInputFields()

      # Handler for input changes
      _inputChanged: (event, value) =>
        switch event.target.name
          when 'start_date'
            if value == 'error' || value == 'blank'
              @setFieldState start_date_invalid: true, start_date: null, start_date_input: event.target.value, @validateState
            else
              @setFieldState start_date_invalid: false, start_date: value, start_date_input: event.target.value, @validateState

          when 'start_time'
            if value == 'error'
              @setFieldState start_time_invalid: true, start_time: null, start_time_input: event.target.value, @validateState
            else if value == 'blank'
              @setFieldState start_time_invalid: false, start_time: null, start_time_input: event.target.value, @validateState
            else
              @setFieldState start_time_invalid: false, start_time: value, start_time_input: event.target.value, @validateState

          when 'end_time'
            if value == 'error'
              @setFieldState end_time_invalid: true, end_time: null, end_time_input: event.target.value, @validateState
            else if value == 'blank'
              @setFieldState end_time_invalid: false, end_time: null, end_time_input: event.target.value, @validateState
            else
              @setFieldState end_time_invalid: false, end_time: value, end_time_input: event.target.value, @validateState

          when 'repeats'
            @setFieldState repeat_type: event.target.value, @validateState

          when 'interval'
            @setFieldState interval: event.target.value, @validateState

          when 'new_instance_recurrence_day'
            new_weekdays = @state.weekdays.splice(0)
            weekday = Number(event.target.value)

            if value
              # Chosen
              new_weekdays.push weekday unless new_weekdays.indexOf(weekday) > -1
            else
              # Removed
              index = new_weekdays.indexOf(weekday)
              if index > -1
                new_weekdays.splice index, 1

            @setFieldState weekdays: new_weekdays.sort(), @validateState

          when 'month_repeat_on'
            @setFieldState month_repeat_on: event.target.value, @validateState

          when 'recurrence_end_type'
            @setFieldState recurrence_end_type: event.target.value, @validateState

          when 'recurrence_end_date'
            if value == 'error'
              @setFieldState recurrence_end_date_invalid: true, recurrence_end_date: null, recurrence_end_date_input: event.target.value, @validateState
            else if value == 'blank'
              @setFieldState recurrence_end_date_invalid: true, recurrence_end_date: null, recurrence_end_date_input: event.target.value, @validateState
            else
              @setFieldState recurrence_end_date_invalid: false, recurrence_end_date: value, recurrence_end_date_input: event.target.value, @validateState

          when 'recurrence_end_count'
            if Number(event.target.value) > 0
              @setFieldState recurrence_end_count: event.target.value, recurrence_end_count_invalid: false, @validateState
            else
              @setFieldState recurrence_end_count: event.target.value, recurrence_end_count_invalid: true, @validateState

          when 'new_instance_remove_future_dates'
            @setFieldState remove_future_instances: value, @validateState

        @props.onInputChange?(event)
        event.stopPropagation()

      # Handler for clicking of add button, this commits the changes to the instance list
      addInstances: (event) =>
        settings = @getRecurrenceSettings()
        @props.eventInstances.addRecurring(settings)
        @props.onInstancesAdded?(event)

      # Wrap setState to additionaly sync the changes to the EventInstances object
      setFieldState: (changes, callback) =>
        # Set the form state in EventInstances without triggering an update event
        @props.eventInstances.setInputFields(changes, true)
        # And update react
        @setState changes, callback

      # Validate the state and set the global validation flag appropriately
      validateState: =>
        # Form validation checks
        start_date_invalid = this.state.start_date_invalid || !this.state.start_date
        recurrence_end_count_invalid = this.state.recurrence_end_count_invalid || !this.state.recurrence_end_count
        recurrence_end_date_invalid = this.state.recurrence_end_date_invalid || !this.state.recurrence_end_date

        valid_start_date = !this.state.start_date_invalid && this.state.start_date
        valid_start_time = !this.state.start_time_invalid
        valid_end_time = !this.state.end_time_invalid
        valid_recurrence_end = this.state.repeat_type == 'once' ||
          (this.state.recurrence_end_type == 'date' && !this.state.recurrence_end_date_invalid && this.state.recurrence_end_date) ||
          (this.state.recurrence_end_type == 'count' && !this.state.recurrence_end_count_invalid && this.state.recurrence_end_count)

        valid_entry = valid_start_date && valid_start_time && valid_end_time && valid_recurrence_end

        @props.eventInstances.setInputFields(valid_entry: valid_entry, true)
        @setState
          valid_entry: valid_entry
          start_date_invalid: start_date_invalid


      render: ->
        my = this

        # Generate some of the option selections for large, programmtic lists

        # ...count list
        interval_unit = switch this.state.repeat_type
          when 'daily'
            'day'
          when 'weekdays', 'weekdays_mwf', 'weekdays_tth', 'weekly'
            'week'
          when 'monthly_day_of_week', 'monthly_day_of_month'
            'month'
          when 'yearly'
            'year'
          else
            ''

        interval_options = (`<option key={i} value={i}>{i} {interval_unit}{i != 1 ? 's' : ''}</option>` for i in [1..30])

        # ...weekday option checkboxes
        weekday_options = (`<td key={day}>
                              <span className="day">{lbl}</span>
                              <Checkbox value={day}
                                        checked={my.state.weekdays.indexOf(day) > -1}
                                        className="recur_day"
                                        data-instance="recurrence-day"
                                        onChange={my._inputChanged}
                                        name="new_instance_recurrence_day" />
                            </td>` for [day, lbl] in [
          [0, 'Sun'],
          [1, 'Mon'],
          [2, 'Tue'],
          [3, 'Wed'],
          [4, 'Thu'],
          [5, 'Fri'],
          [6, 'Sat']
        ])


        weekday_options_list = (`<label key={day} className="em-checkbox">
                              
                              <Checkbox value={day}
                                        checked={my.state.weekdays.indexOf(day) > -1}
                                        className="recur_day"
                                        data-instance="recurrence-day"
                                        onChange={my._inputChanged}
                                        name="new_instance_recurrence_day" />
                              <span className="day">{lbl}</span>
                            </label>` for [day, lbl] in [
          [0, 'Sun'],
          [1, 'Mon'],
          [2, 'Tue'],
          [3, 'Wed'],
          [4, 'Thu'],
          [5, 'Fri'],
          [6, 'Sat']
        ])

        # Calculate visibility options
        is_recurring = this.state.repeat_type != 'once'
        summary_text = this.getRecurranceSummary()

        # Active date text
        start = if this.state.start_date then moment(this.state.start_date) else moment()

        if start.date() == start.daysInMonth()
          day_of_month_text = 'last'
        else
          day_of_month_text = start.format('Do')

        week_number = start.monthWeekByDay()
        if week_number == 4
          day_of_week_text = "last #{start.format('dddd')}"
        else
          day_of_week_text = ['first', 'second', 'third', 'fourth'][week_number] + " " + start.format('dddd')

        # Main UI
        if this.props.isRenderedInAdmin
            `<div>
              <FormInputGroup>
                <legend className="sr-only">{this.props.componentTitle}</legend>
                <div className="form_column-2">
                <DateInputRow label="Start Date"
                              name="start_date"
                              placeholder="'Tomorrow' or 'Next Friday' or '4/10'"
                              initialValue={this.state.start_date}
                              initialInputValue={this.state.start_date_input}
                              onChange={this._inputChanged}
                              showPreview={false}
                              showError={!!this.props.startDateError}
                              errorMessage={this.props.startDateError}
                              required={true}
                              requiredAsAsterisk={this.props.requiredAsAsterisk}
                              {...this.context.textInputRow} {...this.context.error} />
                <DateInputRow label="Start Time"
                              name="start_time"
                              placeholder="6pm"
                              initialValue={this.state.start_time}
                              initialInputValue={this.state.start_time_input}
                              onChange={this._inputChanged}
                              showPreview={false}
                              showError={false}
                              type="time"
                              {...this.context.textInputRow} />
                <DateInputRow label="End Time"
                              name="end_time"
                              initialValue={this.state.end_time}
                              initialInputValue={this.state.end_time_input}
                              onChange={this._inputChanged}
                              showPreview={false}
                              showError={false}
                              type="time"
                              {...this.context.textInputRow} />

                <FormRow title="Repeating"
                         labelFor="repeats"
                         {...this.context.selectInputRow}>
                  <select ref="repeats"
                          className="admin-common-dropdown"
                          id="repeats"
                          value={this.state.repeat_type}
                          onChange={this._inputChanged}
                          name="repeats" >
                    <option value="once">Never</option>
                    <option value="daily">Every day</option>
                    <option value="weekdays">Every weekday</option>
                    <option value="weekdays_mwf">Every Mon, Wed, Fri</option>
                    <option value="weekdays_tth">Every Tue, Thu</option>
                    <option value="weekly">Every week</option>
                    <option value="monthly_day_of_month">The {day_of_month_text} day</option>
                    <option value="monthly_day_of_week">The {day_of_week_text}</option>
                    <option value="yearly">Every year</option>
                  </select>
                </FormRow>

                {['daily', 'weekly', 'monthly_day_of_month', 'monthly_day_of_week', 'yearly'].indexOf(this.state.repeat_type) > -1 &&
                  <FormRow title="Every"
                           labelFor="interval"
                           {...this.context.selectInputRow}>
                    <select ref="interval"
                            id="interval"
                            className="admin-common-dropdown"
                            value={this.state.interval}
                            onChange={this._inputChanged}
                            name="interval">
                      {interval_options}
                    </select>
                  </FormRow>}

                {this.state.repeat_type == 'weekly' && <FormRow title="Repeat on"
                                                                {...this.context.selectInputRow}
                         className="week_table_wrapper"
                         data-instance="recurrence-day-wrapper">
                  <div className="week_table">
                    <table border="0">
                      <tbody>
                        <tr>
                          {weekday_options}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </FormRow>}

                {/*
                  should use this css to align properly

                 .form-horizontal.nosides label.end-date-range > .end-date-range-input {
                     width: 35%;
                     float: none;
                 }
                  */}
                {is_recurring && <FormRow title="Repeating until"
                                          labelFor="recurrence_end_type"
                                          {...this.context.selectInputRow}
                                          className="end-date-range">
                  <select className="admin-common-dropdown end-date-range-input"
                          id="recurrence_end_type"
                          name="recurrence_end_type"
                          value={this.state.recurrence_end_type}
                          onChange={this._inputChanged}>
                    <option value="date">A specific date has passed</option>
                    <option value="count">A number of times have passed</option>
                  </select>
                </FormRow>}

                {is_recurring && this.state.recurrence_end_type == 'date' &&
                  <DateInputRow label={this.context.labelAllInputs ? "Repeat until this date" : ""}
                                name="recurrence_end_date"
                                onChange={this._inputChanged}
                                placeholder="tomorrow"
                                initialValue={this.state.recurrence_end_date}
                                initialInputValue={this.state.recurrence_end_date_input}
                                showPreview={false}
                                showError={false}
                                type="date"
                                {...this.context.textInputRow} />}

                {is_recurring && this.state.recurrence_end_type == 'count' &&
                  <FormRow title={this.context.labelAllInputs ? "Repeat this number of times" : ""}
                           labelFor="recurrence_end_count"
                           {...this.context.textInputRow}>
                    <input type="text"
                           id="recurrence_end_count"
                           name="recurrence_end_count"
                           className={this.context.textInputRow.inputClassName}
                           value={this.state.recurrence_end_count}
                           onChange={this._inputChanged}
                           placeholder="12" />
                  </FormRow>}

                {summary_text && <FormRow title="Summary">
                  <div className="schedule-summary">{summary_text}</div>
                </FormRow>}

                {/* WP_CHANGE: i18n definitions comes from en.yml:463 */}
                {is_recurring && <FormRow title="Overwrite scheduled dates"
                                          labelFor="new_instance_remove_future_dates"
                                          {...this.context.checkboxRow}>
                  <Checkbox name="new_instance_remove_future_dates"
                            id="new_instance_remove_future_dates"
                            type="checkbox"
                            value="1"
                            className="admin-common-checkbox checkbox_input"
                            onChange={this._inputChanged}
                            data-instance="remove-future-dates"/>
                </FormRow>}

                {/* WP_CHANGE: i18n definitions comes from en.yml:459-461 */}
                <div className="action-button pull-right">
                  <input type="button"
                         className="btn-color"
                         name="add_instance"
                         disabled={!this.state.valid_entry}
                         onClick={this.addInstances}
                         value={is_recurring && this.state.remove_future_instances ? 'Replace Existing Schedule' :
                           this.state.have_dates ? "Include Above in Schedule" : "Add Above to Schedule"}
                         data-action="add-instance"
                         data-ganame="add_recurring_submit"
                         id="x-add-instance" />

                </div>
                </div>
              </FormInputGroup>

              {this.state.have_dates && <FormInputGroup>
                <FormHeader title={this.props.confirmedDatesHeader} className="sub-heading" />
              </FormInputGroup>}

              {this.state.have_dates && <FormInputGroup className="form-event-date">
                <legend className='sr-only'>{this.props.confirmedDatesHeader}</legend>
                <InstanceList eventInstances={this.props.eventInstances} isRenderedInAdmin={this.props.isRenderedInAdmin} />
              </FormInputGroup>}
            </div>`
        else
           `<div>
              <FormInputGroup>
                <legend className="sr-only">{this.props.componentTitle}</legend>
                <div className="em-form_column-2 em-align-start">
                <DateInputFloatingRow label="Start Date"
                              name="start_date"
                              placeholder="'Tomorrow' or 'Next Friday' or '4/10'"
                              onChange={this._inputChanged}
                              icon=""
                              formHasDateError={this.props.formHasDateError}
                              required={true} 
                              dateError="Invalid start date."/>
                <DateInputFloatingRow label="Start Time"
                              name="start_time"
                              placeholder="6pm"
                              onChange={this._inputChanged}
                              showPreview={false}
                              showError={false}
                              type="time" />
                <DateInputFloatingRow label="End Time"
                              name="end_time"
                              onChange={this._inputChanged}
                              showPreview={false}
                              showError={false}
                              type="time" />



                  <div className="em-select-floating">
                    <label htmlFor="repeats">Repeating</label>
                    <select ref="repeats"
                            value={this.state.repeat_type}
                            onChange={this._inputChanged}
                            name="repeats"
                            id="repeats">
                      <option value="once">Never</option>
                      <option value="daily">Every day</option>
                      <option value="weekdays">Every weekday</option>
                      <option value="weekdays_mwf">Every Mon, Wed, Fri</option>
                      <option value="weekdays_tth">Every Tue, Thu</option>
                      <option value="weekly">Every week</option>
                      <option value="monthly_day_of_month">The {day_of_month_text} day</option>
                      <option value="monthly_day_of_week">The {day_of_week_text}</option>
                      <option value="yearly">Every year</option>
                    </select>
                    <i className="fas fa-sort-down"></i>
                  </div>


                {['daily', 'weekly', 'monthly_day_of_month', 'monthly_day_of_week', 'yearly'].indexOf(this.state.repeat_type) > -1 &&

                  <div className="em-select-floating">
                    <label htmlFor="interval">Every</label>
                    <select ref="interval"
                            value={this.state.interval}
                            onChange={this._inputChanged}
                            id="interval"
                            name="interval">
                      {interval_options}
                    </select>
                    <i className="fas fa-sort-down"></i>
                  </div>
                  }

                {this.state.repeat_type == 'weekly' && <div
                         className="week_table_wrapper"
                         data-instance="recurrence-day-wrapper">
                         <span className="em-input_label">Repeat on</span>
                  <div className="em-week_table week_table">
                    
                          {weekday_options_list}
                        
                  </div>
                </div>}

                {/*
                  should use this css to align properly

                 .form-horizontal.nosides label.end-date-range > .end-date-range-input {
                     width: 35%;
                     float: none;
                 }
                  */}
                {is_recurring && <div className="end-date-range">
                    <div className="em-select-floating">
                      <label id="recurrence_end_type_label" for="recurrence_end_type">Repeating until</label>
                      <select className="end-date-range-input"
                              name="recurrence_end_type"
                              id="recurrence_end_type"
                              value={this.state.recurrence_end_type}
                              onChange={this._inputChanged}>
                        <option value="date">A specific date has passed</option>
                        <option value="count">A number of times have passed</option>
                      </select>
                      <i className="fas fa-sort-down"></i>
                    </div>
                </div>}

                {is_recurring && this.state.recurrence_end_type == 'date' &&
                  <DateInputFloatingRow label="A specific date has passed"
                                name="recurrence_end_date"
                                onChange={this._inputChanged}
                                placeholder="A specific date has passed"
                                initialValue={this.state.recurrence_end_date}
                                showPreview={false}
                                showError={false}
                                type="date"/>}

                {is_recurring && this.state.recurrence_end_type == 'count' &&
                  <div className="em-input-floating">
                    <input type="text"
                           name="recurrence_end_count"
                           value={this.state.recurrence_end_count}
                           onChange={this._inputChanged}
                           placeholder="Number of times have passed" />
                    <label>Number of times have passed</label>
                  </div>}
              </div>

                {summary_text && <p className="edit_event_summary">
                  <span role="heading" aria-level="3">Summary</span>
                  <div className="schedule-summary">{summary_text}</div>
                </p>}
                <div className="em-form_column-2">
                {/* WP_CHANGE: i18n definitions comes from en.yml:463 */}
                {is_recurring && <label className="em-checkbox">
                  <Checkbox name="new_instance_remove_future_dates"
                            id="new_instance_remove_future_dates"
                            type="checkbox"
                            value="1"
                            className="checkbox_input"
                            onChange={this._inputChanged}
                            data-instance="remove-future-dates"/>
                <span>Overwrite scheduled dates</span>
                </label>}
                {/* WP_CHANGE: i18n definitions comes from en.yml:459-461 */}
                <div className="action-button pull-right">
                  <input type="button"
                         className="btn-color em-button em-primary"
                         name="add_instance"
                         disabled={!this.state.valid_entry}
                         onClick={this.addInstances}
                         value={is_recurring && this.state.remove_future_instances ? "Replace Existing Schedule" :
                           this.state.have_dates ? "Above in Schedule" : "Add Above to Schedule"}
                         data-action="add-instance"
                         data-ganame="add_recurring_submit"
                         id="x-add-instance" />

                </div>
                </div>
              </FormInputGroup>

              {this.state.have_dates && <FormInputGroup>
                <span className="em-input_label">{this.props.confirmedDatesHeader}</span>
              </FormInputGroup>}

              {this.state.have_dates && <FormInputGroup className="form-event-date">
                <legend className='sr-only'>{this.props.confirmedDatesHeader}</legend>
                <InstanceList eventInstances={this.props.eventInstances} isRenderedInAdmin={this.props.isRenderedInAdmin} />
              </FormInputGroup>}
            </div>`

      # Generate summary text
      #
      # DAILY/WEEKLY: Every [DOW1, DOW2 | Weekday | Weekend | Day]
      # MONTHLY: Every Nth day | Every third DOW
      # YEARLY: Every X years
      #
      # ending with:
      #  ; X times
      #  ; until Date
      getRecurranceSummary: =>
        settings = this.getRecurrenceSettings()

        # Return error message if part of the form is invalid
        return "Invalid Start Date" if this.state.start_date_invalid
        return "Invalid Start Time" if this.state.start_time_invalid
        return "Invalid End Time" if this.state.end_time_invalid

        # Show errors or hints for recurrence end
        if this.state.repeat_type != 'once'
          # Recurrence end date
          if this.state.recurrence_end_type == 'date'
            if this.state.recurrence_end_date_invalid
              return "Invalid Recurrence End Date"
            else if !this.state.recurrence_end_date
              return "Enter a recurrence end date above"

          # Recurrence end count
          if this.state.recurrence_end_type == 'count'
            if this.state.recurrence_end_count_invalid
              return "Invalid Recurrence End Count"
            else if !this.state.recurrence_end_count
              return "Enter a recurrence end count above"

        # Otherwise, build up the instance summary text
        summary_text_date = []
        if settings.start_date
          summary_text_date.push moment(settings.start_date).format('ddd, MMM D, YYYY') if settings.start_date
          if settings.start_time
            summary_text_date.push moment(settings.start_time).format(', h:mma') if settings.start_time
            summary_text_date.push moment(settings.end_time).format(' - h:mma') if settings.end_time
          else
            summary_text_date.push ', all day'

        summary_text_date = summary_text_date.join('')

        if this.state.repeat_type != 'once'
          day_labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

          summary_text = "repeating every "
          summary_text += numberAsWord(settings.interval) + " " if settings.interval > 1

          switch settings.type
            when 'daily'
              interval_label = pluralize(settings.interval, 'day')
            when 'weekly'
              interval_label = pluralize(settings.interval, 'week')

              if _.isEqual settings.weekdays, [0, 1, 2, 3, 4, 5, 6]
                interval_label += ' on all days'
              else if _.isEqual settings.weekdays, [0, 6]
                interval_label += ' on weekends'
              else if _.isEqual settings.weekdays, [1, 2, 3, 4, 5]
                interval_label += ' on weekdays'
              else
                interval_label += ' on ' + (day_labels[day] for day in settings.weekdays).join(', ')
            when 'monthly'
              interval_label = pluralize(settings.interval, 'month')

              # Show the date it recurs on (either date or weekday)
              if settings.start_date
                start_date = moment(settings.start_date)

                if settings.repeat_on == 'day_of_month'
                  if start_date.date() == start_date.daysInMonth()
                    interval_label += " on the last day"
                  else
                    interval_label += " on the #{start_date.format('Do')}"
                else if settings.repeat_on == 'day_of_week'
                  week_number = start_date.monthWeekByDay()
                  if week_number == 4
                    week_number = 'last'
                  else
                    week_number = ['first', 'second', 'third', 'fourth'][week_number]

                  interval_label += " on the #{week_number} #{start_date.format('dddd')}"

            when 'yearly'
              interval_label = pluralize(settings.interval, 'year')

          summary_text += interval_label

          # Range end date
          if this.state.recurrence_end_type == 'date' && this.state.recurrence_end_date
            summary_text += " until "
            summary_text += moment(this.state.recurrence_end_date).format('MMM D, YYYY')
          else if this.state.recurrence_end_type == 'count' && this.state.recurrence_end_count > 0
            summary_text += ", " + this.state.recurrence_end_count + " times"
        else
          summary_text = null

        result = _.compact([summary_text_date, summary_text]).join(', ')

        # Hint if we aren't showing an error or a summary
        result = 'Enter a start date above' unless result

        result

      # Return the current recurrence settings based on the user's selections
      #
      # This normalizes the weekday dropdown choices with the selected weekdays
      getRecurrenceSettings: =>
        this.props.eventInstances.getRecurrenceSettings()
