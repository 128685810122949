# Multi-file upload selector

namespace 'Slzr', (exports, top) ->
  jQuery = $ = Slzr.jQuery

  # A simple widget to handle multiple file uploads
  class exports.MultiUploadSelector
    constructor: (options) ->
      defaults =
        # The input container
        input_container: null
        # The file list container
        file_list: null
        # Template string for added items
        template: '''
                  <span class="filename">#{filename}</span>
                  <input type="text" name="caption[]" />
                  <p>Caption or Title <a href="#" class="remove-link">Remove</a></p>
                  '''
        # Tag to put in container
        item_container_tag: null
        item_container_attributes: {}
        file_name: 'image[]'
        input_id: null
        maximum: 8

      # Options extracted from data attributes

      @options = $.extend({}, defaults, options)
      @num_files = 0

      # Set up necessary elements
      @input_container = $(@options.input_container)
      @file_list = $(@options.file_list)
      @template = if typeof(@options.template) == 'function' then @options.template else _.template(@options.template)

      @options['item_container_tag'] ||= @file_list.data('multi-upload-container-tag') || 'li'

      # Find the existing input element
      if @options.input
        @input_el = $(@options.input)
      else if @options.input_id
        @input_el = $("##{@options.input_id}")
      else
        @input_el = @input_container.find('input[type="file"]')

      @bindEvents @input_el

    bindEvents: (obj) ->
      obj.bind 'change.MultiUploadSelector', => @fileSelected()

    # When a file is selected, move the input element into @file_list
    # and create a new input element
    fileSelected: (ev) ->
      new_item = $("<#{@options.item_container_tag}/>", @options.item_container_attributes)
      new_item.append @template({filename: @input_el.val().split("\\").pop() })
      new_item.append @input_el

      # Hook up the remove handler
      new_item.find('.remove-link').click (ev) => @removeFile(ev, new_item)

      # Collect the old attributes from the input element
      old_attributes =
        name: @input_el.attr('name')
        style: @input_el.attr('style')
        class: @input_el.attr('class')
        size: @input_el.attr('size')
        type: 'file'

      # Clean up and hide the element
      @input_el
        .unbind('change.MultiUploadSelector')
        .attr('name', @options.file_name)
        .hide()
      @file_list.append(new_item)

      @num_files++

      # If we aren't at maximum, create a new input element
      if @num_files < @options.maximum
        new_input = $('<input/>', old_attributes)
        @bindEvents new_input
        new_input.appendTo(@input_container)
        @input_el = new_input
      else
        @input_el = null

    removeFile: (ev, item) ->
      item.remove()
      false
