# Event instance manager
#
# Handles adding/deleting of instances from the in-memory list.
#
# Expects each item to be in an element with data-instance=true and data-date=start_date
#
# Inside, a click on something with data-action="remove-instance" will remove the instance
# and the associated element
#
# The form used for adding instances is the add_form option.
#   A data-action="add-instance" inside will add an instance
#   Will use data-instance to determine what type of data it is,
#     "start-date" "start-time" or "end-time"
#   Recurrence is supported with
#     "recurrence-ends" "recurrence-type" and "recurrency-day"
#
#   Recurrence types are 'daily', 'weekly', 'monthly'
#   Recurrence days is 0-6 for days of week (can be multiple)
#
#   The other instances should be date inputs
#
# Events will be triggered on the instance container when an instance is added
# or deleted.  The extra parameter for each event is a hash with start_date, start_time,
# end_time.
#   slzr:instance:add => Triggered when an instance is added
#   slzr:instance:remove => Triggered when an instance is removed

modulejs.define 'slzr/event/instances',
  ['jquery', 'underscore', 'EventEmitter', 'slzr/date_utils', 'moment'],
  ($, _, EventEmitter, DateUtils, moment) ->
    jQuery = $
    _sameDate = DateUtils.sameDate
    _sameTime = DateUtils.sameTime

    # Event Instance model
    #
    # This class contains the list of event instances associated with an event, as well as
    # methods for manipulating the list items.
    #
    # Additionally, this class can hold input field state. This should be in the same format
    # as the +options+ parameter to +addRecurring+. See +getInputFields+ and +setInputFields+.
    #
    # This class also provides support for basic limitations on number of instances and the
    # number of instances added with one recurrence entry.
    #
    # Instances are keyed by their start date and start time.
    #
    # Instances of this class are EventEmitters, with the following events:
    #   slzr:instance:add => Triggered when an instance is added
    #   slzr:instance:change => Triggered when the start/end time of an instance is changed
    #   slzr:instance:remove => Triggered when an instance is removed
    #   slzr:fields:changed => Triggered when the instance field data is changed
    class EventInstances extends EventEmitter
      # Internal instances store
      #
      # Array of objects: {start_date:, start_time:, end_time:}
      instances: []
      # default input field options
      default_field_values:
        # The date/time input fields, in both their parsed and raw ("_input") values
        start_date: null
        start_date_input: null
        end_time: null
        end_time_input: null
        start_time: null
        start_time_input: null
        recurrence_end_date: null
        recurrence_end_date_input: null

        # Additional input fields that are not "parsed" like date/time inputs
        #
        # These values should match the default state in InstanceManagerComponent
        repeat_type: 'once'
        interval: 1
        weekdays: [0, 1, 2, 3, 4, 5, 6]
        limit: null
        month_repeat_on: 'day_of_month'
        recurrence_end_type: 'date'

        # Indicator of a valid entry being input
        valid_entry: false
      
      # Determine if *input_fields* changed
      is_dirty: false

      default_options:
        # Maximum number of instances to allow
        max_instances: 20

        # Don't allow adding more than 300 instances at once (via recurring)
        add_limit: 300

        # Initial instances
        #
        # Each element is [start_date, start_time, end_time], should be string values
        initial_instances: []

      constructor: (options, initial_input_fields={}) ->
        super()
        
        @instances = []
        @options = _.extend {}, @default_options, options

        # # Input field options
        #
        # Do not access this directly; use +getInputFields+ and +setInputFields+.
        # Initial assignment comes from +@default_field_values*
        @input_fields = _.extend {}, @default_field_values, initial_input_fields

        @_addInitialInstances()

      # Retrieve the current input field state
      getInputFields: =>
        return @input_fields

      getIsDirty: =>
        return @is_dirty
        
      # Merge the input field state with the provided updates
      #
      # If silent is true, does not emit the slzr:fields:chagned event. This is only intended for use
      # when the instance manager is syncing its state to this object.
      setInputFields: (updates, silent=false) =>
        @input_fields = _.extend {}, @input_fields, updates
        @emit('slzr:fields:changed', @input_fields) unless silent
        @is_dirty = true

      resetInputFields: =>
        @setInputFields @default_field_values
        @is_dirty = false

      # Build recurrence settings from the input fields
      getRecurrenceSettings: =>
        result = {}

        weekdays = @input_fields.weekdays
        result.interval = @input_fields.interval

        switch @input_fields.repeat_type
          when 'once'
            result.type = 'once'
            weekdays = [0, 1, 2, 3, 4, 5, 6]
          when 'daily'
            result.type = 'daily'
            weekdays = [0, 1, 2, 3, 4, 5, 6]
          when 'weekly'
            result.type = 'weekly'
          when 'monthly'
            result.type = 'monthly'
            result.repeat_on = @input_fields.month_repeat_on
            weekdays = [0, 1, 2, 3, 4, 5, 6]
          when 'monthly_day_of_month'
            result.type = 'monthly'
            result.repeat_on = 'day_of_month'
            weekdays = [0, 1, 2, 3, 4, 5, 6]
          when 'monthly_day_of_week'
            result.type = 'monthly'
            result.repeat_on = 'day_of_week'
            weekdays = [0, 1, 2, 3, 4, 5, 6]
          when 'yearly'
            result.type = 'yearly'
            weekdays = [0, 1, 2, 3, 4, 5, 6]
          when 'weekdays'
            result.type = 'weekly'
            result.interval = 1
            weekdays = [1, 2, 3, 4, 5]
          when 'weekdays_mwf'
            result.type = 'weekly'
            result.interval = 1
            weekdays = [1, 3, 5]
          when 'weekdays_tth'
            result.type = 'weekly'
            result.interval = 1
            weekdays = [2, 4]

        result.weekdays = weekdays.sort()
        result.recurrence_end_type = @input_fields.recurrence_end_type
        result.recurrence_end_date = @input_fields.recurrence_end_date
        result.recurrence_end_count = @input_fields.recurrence_end_count

        result.start_date = @input_fields.start_date
        result.start_time = @input_fields.start_time
        result.end_time = @input_fields.end_time
        result.remove_future_instances = if @input_fields.repeat_type == 'once' then false else @input_fields.remove_future_instances

        result

      # Add a range of instances based on the input field state
      addInstancesFromInputFields: =>
        if @input_fields.valid_entry && @input_fields.start_date
          @addRecurring @getRecurrenceSettings()

      # Add the initial instances to the data
      _addInitialInstances: =>
        @add start_date, start_time, end_time for [start_date, start_time, end_time] in @options.initial_instances

      # Add instances in a recurring range
      #
      # The options are:
      #   type: Recurrence type
      #         daily, weekly, monthly, yearly
      #   start_date: Start date of recurrance
      #   end_date: End date of recurrance range
      #   interval: Interval for skipping
      #   limit: Limit of count to create
      #   start_time: Start time of event instances
      #   end_time: End time of event instances
      #   weekdays: Weekday numbers [0..6]
      #   repeat_on: day_of_month, day_of_week
      #
      # The start_date, start_time, end_time arguments are expected to be strings (not dates)
      addRecurring: (options) =>
        start_time = options.start_time
        end_time = options.end_time

        type = options.type
        type = 'monthly_day' if options.type == 'monthly' && options.repeat_on == 'day_of_week'

        if options.recurrence_end_type == 'date'
          end_date = options.recurrence_end_date
          limit = 1000
        else
          # Need to specify an end date, so don't allow more than 10 years at once
          end_date = moment(options.start_date).add(years: 10)
          limit = options.recurrence_end_count

        recurrence_options =
          start_date: options.start_date
          end_date: end_date
          weekdays: options.weekdays
          type: type
          limit: limit
          interval: options.interval

        # Remove any future instances to keep a clean slate
        if options.remove_future_instances
          save_end = moment()
          for instance in @instances
            @remove instance.start_date if moment(instance.start_date).isAfter(save_end, 'day')

        @is_dirty = false
        recurrence = new Slzr.Util.DateRecurrence recurrence_options
        @add date, start_time, end_time for date in recurrence.dates()

      # Add an instance
      #
      # The values are expected to be strings
      #
      # Adding an instance with the same date will cause the existing instance's start and end times to be
      # updated to the passed values.
      #
      # If an instance is added with no time, but an instance already exists on that date, then it is ignored
      #
      # If start_date is passed in as a string, it's expected to be in ISO8601 format (YYYY-MM-DD).
      # If start_time and end_time are strings, they're expected to be in HH:MM:SS format.
      #
      # Returns true if the instance was added, false otherwise
      add: (start_date, start_time, end_time) =>
        start_date = moment(start_date, 'YYYY-MM-DD').toDate() if typeof(start_date) == 'string' && !start_date.isBlank()
        start_time = moment(start_time, 'hh:mm:ss').toDate() if typeof(start_time) == 'string' && !start_time.isBlank()
        end_time = moment(end_time, 'hh:mm:ss').toDate() if typeof(end_time) == 'string' && !end_time.isBlank()

        # If date not entered and there's only one instance, change the times
        if !start_date? && start_time? && @instances.length == 1
          start_date = @instances[0].start_date
        else if !start_date?
          return false

        data = {
          start_date: start_date
          start_time: start_time
          end_time:   end_time
        }

        # Don't add an all-day instance on a date with an instance and start time
        if start_time == null && _.findIndex(@instances, (i) -> _sameDate(i.start_date, start_date) && i.start_time != null) > -1
          return true

        # Match against an instance with the same start date/time, or one without a start time.
        index_of_instance = _.findIndex @instances, (i) ->
          (_sameDate(i.start_date, start_date) && _sameTime(i.start_time, start_time)) ||
            (_sameDate(i.start_date, start_date) && (i.start_time == '' || i.start_time == null))

        if index_of_instance == -1
          # Add it
          @instances.push data
          @instances = @_cleanInstances(@instances)
          @emit 'slzr:instance:add', data
        else
          # Update
          @instances[index_of_instance] = data
          @instances = @_cleanInstances(@instances)
          @emit 'slzr:instance:change', data

        true

      # Update an instance, by index
      update: (index, start_date, start_time, end_time) =>
        start_date = new Date("#{start_date} 00:00:00") if typeof(start_date) == 'string' && !start_date.isBlank()
        start_time = new Date(start_time) if typeof(start_time) == 'string' && !start_time.isBlank()
        end_time = new Date(end_time) if typeof(end_time) == 'string' && !end_time.isBlank()

        @instances[index] = new_data = {
          start_date,
          start_time,
          end_time
        }

        @instances = @_cleanInstances(@instances)

        @emit 'slzr:instance:change', new_data

      # Remove an instance
      #
      # If start_time is not specified, remove all instances on date, otherwise removes the instance
      # specified by start_date and start_time

      remove: (start_date, start_time) =>
        start_date = new Date("#{start_date} 00:00:00") if typeof(start_date) == 'string'

        # Remove any instances with a matching start date and time
        @instances = _.sortBy _.reject(@instances, (instance) ->
          _sameDate(instance.start_date, start_date) && (if start_time then _sameTime(instance.start_time, start_time) else true)
        ), 'start_date'

        @emit 'slzr:instance:remove', start_date: start_date
        true

      # Remove an instance at the specified index
      removeAtIndex: (index) =>
        instance = @instances[index]
        @instances.splice(index, 1)

        @emit 'slzr:instance:remove', instance
        true

      # Return the number of instances currently set
      count: => @instances.length

      # Returns true if there's any instances set
      valid: => @instances.length > 0

      # Return the earliest instance in the schedule as a date
      firstInstanceAt: =>
        instance = @instances[0]

        if instance?
          @_instanceStartTime(instance)
        else
          null

      # Return the next instance in the schedule
      #
      # The next instance is the next occuring instance, or the most recent
      nextInstance: (now=null) =>
        now = moment(now)
        that = this
        next_instance = _.find @instances, (item) -> now.isSameOrBefore(that._instanceStartTime(item))
        next_instance = @instances[@instances.length - 1] unless next_instance
        next_instance

      # Return the next instance as a start date
      nextInstanceAt: =>
        next_instance = @nextInstance()

        if next_instance
          @_instanceStartTime(next_instance)
        else
          null

      # Return the next instance's duration, or null if no duration is specified
      nextInstanceDuration: =>
        next_instance = @nextInstance()
        return null unless next_instance && next_instance.start_time && next_instance.end_time

        starts_at = @_instanceStartTime(next_instance)
        ends_at = @_instanceEndTime(next_instance)

        ends_at.diff(starts_at, 'seconds')

      # Return the next instance's duration in minutes, or null if no duration is specified
      nextInstanceDurationMinutes: =>
        duration = @nextInstanceDuration()
        if duration
          Math.floor(duration / 60)
        else
          null

      instanceDatesToString: (instance) =>
        new_instance = {}

        for k,v of instance
          if k == "start_date"
            new_instance[k] = if _.isEmpty(v) then "" else moment(v).format("YYYY-MM-DD")
          else if _.contains ["start_time", "end_time"], k
            new_instance[k] = if _.isEmpty(v) then "" else moment(v).format("HH:mm:ss")
          else
            new_instance[k] = v
        
        new_instance

      # Convert the instance data into an object representing the start time
      _instanceStartTime: (instance) =>
        @_combineDateAndTime(instance.start_date, instance.start_time)

      # Convert the instance data into a moment object representing the end time
      #
      # Returns null if the instance does not have an end time
      _instanceEndTime: (instance) =>
        if instance.end_time
          starts_at = @_instanceStartTime(instance)
          ends_at = @_combineDateAndTime(instance.start_date, instance.end_time)
          ends_at.add(days: 1) if ends_at.isBefore(starts_at)
          ends_at
        else
          null

      # Utility method: Combine the date and time data
      _combineDateAndTime: (date, time) =>
        date_part = moment(date)
        time_part = if time then moment(time) else date_part.startOf('day')
        time_part.set(year: date_part.year(), month: date_part.month(), date: date_part.date())

      # Clean instances
      #
      # This removes duplicate [start_date, start_time] pairs.
      #
      # Finally, it sorts the instances by [start_date, start_time]
      _cleanInstances: (instances) ->

        # Sort
        new_instances = _.sortBy instances, (instance) -> [instance.start_date.getTime(), if instance.start_time then instance.start_time.getTime() else 0]

        # Remove duplicates
        new_instances = _.uniq new_instances, true, (instance) ->
          [instance.start_date.getTime(), if instance.start_time then instance.start_time.getTime() else 0].join ','

        new_instances
