# Event Editor javascript attachments

namespace 'Slzr.Page.EventEdit', (exports, top) ->
  Slzr.jQuery ($) ->
    return unless Slzr.Meta.page == 'event-edit'

    ## INSTANCE MANAGER ##
    $form = $('form[data-form="event-edit"]')
    top.event_instance_manager = new Slzr.UI.EventInstanceManager
      form: $form
      initial_instances: Slzr.Meta['event-instances'] || []
    $form[0].event_instance_manager = top.event_instance_manager


    ## LOCATION AUTOCOMPLETE ##

    # Attach handler for choosing a place
    $('input[data-autocomplete="event-place"]').on 'autocomplete:selected.slzr', (event, item) ->
      the_id = item.id
      item_type = the_id.substr(0, the_id.lastIndexOf('_'))
      item_id = the_id.substr(the_id.lastIndexOf('_') + 1)

      # Update relevant inputs
      $('input[data-event="location-id"]').val the_id

      if item_type == 'text'
        $('[data-event="place-label"]').html item.data.text
        $('[data-event="place-input"]').hide()
        $('[data-event="not-if-place"], [data-event="place"]').show()
        $('[data-event="address"]').prop 'disabled', false
        $('[data-event="place-type"]').val 'text'
      else
        $('[data-event="place-label"]').html item.data.name
        $('[data-event="place-input"], [data-event="not-if-place"]').hide()
        $('[data-event="place"]').show()
        $('[data-event="address"]').val(item.data.address).prop 'disabled', true
        $('[data-event="place-type"]').val 'business'

    # Handler for clearing the selected place
    $(document).onAction 'clear-place', ->
      $('[data-event="place-input"]').show()
      $('[data-event="not-if-place"], [data-event="place"]').hide()
      $('input[data-event="place-input"], input[data-event="location-id"], input[data-event="address"], input[data-event="place-type"]').val('')
      $('input[data-event="place-input"], input[data-event="address"]').parent().addClass('em-input-floating--placeholder')
      $('input[data-event="address"]').prop 'disabled', false

    # Display community/campus list
    showCommunityList = () ->
      $("#community-list").show()
    
    # Hide community/campus list
    hideCommunityList = () ->
      $("#community-list").hide()

    # Experience settings
    #
    # Attach and initialize the form to match the selected experience setting
    initializeExperienceSettings = (experience_select) ->
      # Update the form visibility states for the specified experience
      updateFormVisibility = (experience) ->
        # Hide all [data-experience-visible]
        $('[data-experience-visible]').hide()

        # Show the appropriate [data-experience-visible]
        $("[data-experience-visible~=\"#{experience}\"]").show()
        
        # Show community list despite not having the option of adding a place
        if experience == "virtual"
          showCommunityList()
        else
          hideCommunityList()

        # Disable [data-experience-enabled] inputs
        $('[data-experience-enabled]').prop('disabled', true)

        # Enable the appropriate [data-experience-enabled] input
        $("[data-experience-enabled~=\"#{experience}\"]").prop('disabled', false)

      # Change handler for the experience type select
      experience_select.on 'change', (event) ->
        experience = $(this).find('option').filter(':selected').data('experience-type')
        updateFormVisibility(experience)

      # Set initial state
      initial_experience = experience_select.find('option').filter(':selected').data('experience-type')
      updateFormVisibility(initial_experience)

    experience_select = $('select[data-experience="true"]')
    # Show community listing by default if no experience is present    
    if experience_select[0]?
      initializeExperienceSettings(experience_select)
    else
      showCommunityList()
    
    # Initialize visibility toggle
    #
    # Basically, set it to restricted if any of the extra settings are true
    visibility_select = $('#event_visibility')
    if visibility_select[0]?
      # Set to "restricted" if "visible" but one of the additional settings are selected
      if visibility_select.val() == 'visible'
        visibility_select.val('restricted') if $('[data-visibility] :checked').length > 0

      # Set initial visibility state on the extra settings
      $('[data-visibility]').hide()
      $('[data-visibility~="' + visibility_select.val() + '"]').show()

      # And attach event handler
      visibility_select.change ->
        new_val = $(this).val()
        $('[data-visibility]').hide()
        $('[data-visibility~="' + new_val + '"]').show()


    # Initialize the "hide attendees" visibility toggle
    allow_attendance_checkbox = $('#event_allows_attendance')
    if allow_attendance_checkbox[0]?
      $('[data-hide-unless="allows-attendance"]').toggle(allow_attendance_checkbox.prop('checked'))

      allow_attendance_checkbox.on 'change', (event) ->
        $('[data-hide-unless="allows-attendance"]').toggle(this.checked)

    # Zoom Meeting integration
    window.zoom_connect = new Slzr.ZoomConnect(Slzr.Meta["zoom-connect-status"], Slzr.Meta["event-id"], Slzr.Meta["has-zoom-integration"])

    # Update the dataset on the zoom meeting creation link when changes to the title and instances happen. This allows new
    # meetings to be immediately created with the title and selected time.
    create_zoom_meeting_link = document.getElementById('create_zoom_meeting_link')
    if create_zoom_meeting_link?
      # Attach listeners for changes to title...
      name_input = document.getElementById('event_name')
      name_input.addEventListener 'change', (event) ->
        zoom_connect.updateTitleData event.target.value

      # ...and instances. Instance events are custom jquery events, not normal DOM events
      form = $('form[data-form="event-edit"]')
      form.on 'slzr:instance:add slzr:instance:remove slzr:instance:change', (event, data) ->
        zoom_connect.updateTimeData(event.target.event_instance_manager)

      # Set initial form data state
      zoom_connect.updateTitleData name_input.value
      zoom_connect.updateTimeData(form[0].event_instance_manager)

    $(document).ready ->
      # Remove placeholder class if event has a place assigned already
      event_location = $('input[data-event="place-input"]')
      
      event_location.closest('div').removeClass('em-input-floating--placeholder') if event_location.is(':hidden')