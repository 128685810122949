modulejs.define 'slzr/components/instance_list',
  ['jquery', 'underscore', 'react', 'slzr/components/instance_list_item', 'slzr/date_utils'],
  ($, _, React, InstanceListItem, DateUtils) ->

    # React component for the instance list
    #
    # Expected properties:
    #   instances: instance of EventInstances to be shown
    class InstanceList extends React.Component
      @state = {}

      # Number of instances to initially show, with the rest hidden
      NUM_SHOWN_INSTANCES = 13

      constructor: (props) ->
        super props

        instances = @props.eventInstances.instances || []
        num_instances = @props.eventInstances.count()
        hidden_dates = []

        # If we're over 10 instances, hide some dates
        if num_instances > NUM_SHOWN_INSTANCES
          hidden_dates.push(instance.start_date) for instance in instances.slice(NUM_SHOWN_INSTANCES)

        @state = {instances, num_instances, hidden_dates}

      componentDidMount: =>
        @props.eventInstances.on 'slzr:instance:add', @_instanceListChanged
        @props.eventInstances.on 'slzr:instance:change', @_instanceListChanged
        @props.eventInstances.on 'slzr:instance:remove', @_instanceListChanged

      componentWillUnmount: =>
        @props.eventInstances.removeListener 'slzr:instance:add', @_instanceListChanged
        @props.eventInstances.removeListener 'slzr:instance:change', @_instanceListChanged
        @props.eventInstances.removeListener 'slzr:instance:remove', @_instanceListChanged

      render: =>
        instance_items = for instance, index in @state.instances
          is_hidden = @_isInstanceHidden(instance)

          item_key = "#{index}-" + Number(instance.start_date)
          item_key += Number(instance.start_time) if instance.start_time?

          `<InstanceListItem instance={instance}
                             eventInstances={this.props.eventInstances}
                             index={index}
                             key={item_key}
                             onInstanceChanged={this._instanceChanged.bind(this, index)}
                             hidden={is_hidden}
                             isRenderedInAdmin={this.props.isRenderedInAdmin}
            />`

        # Expand link, if we're hiding any dates
        expand_link = if @state.hidden_dates.length > 0
          last_date = _.last(@state.instances).start_date.toString(Slzr.Formats.LongDate)
          message = "#{this.state.hidden_dates.length} more through #{last_date}"

          `<div className="item event_item">
            <a href="#" onClick={this._showAllInstances}>{message}</a>
          </div>`
        else
          null

        `<div className="instance_list">
          {instance_items}
          {expand_link}
        </div>`

      # Event handler when the instance list changes, refresh instance state
      _instanceListChanged: (instance) =>
        # Always show an instance after it's modified
        @_unhideInstance instance

        @setState
          instances: @props.eventInstances.instances
          num_instances: @props.eventInstances.count()

      # Callback when an instance is updated by the user
      _instanceChanged: (index, instance_data) =>
        start_date = instance_data.start_date
        start_time = switch instance_data.start_time
                       when 'blank', 'invalid' then ''
                       else instance_data.start_time
        end_time   = switch instance_data.end_time
                       when 'blank', 'invalid' then ''
                       else instance_data.end_time

        @props.eventInstances.update index, start_date, start_time, end_time

      # Returns true if this instance is hidden
      _isInstanceHidden: (instance) =>
        _.any @state.hidden_dates, (date) -> DateUtils.sameDate(date, instance.start_date)

      _unhideInstance: (instance) =>
        hidden_dates = _.reject @state.hidden_dates, (date) -> DateUtils.sameDate(date, instance.start_date)
        @setState hidden_dates: hidden_dates

      _showAllInstances: (evt) =>
        @setState hidden_dates: []
        evt.preventDefault()

