# Code for photo management

namespace 'Slzr.Page', (exports, top) ->
  $ = Slzr.jQuery

  class exports.PhotoManagement
    constructor: ->
      # Initialize MultiUploadSelector
      input = $('[data-multi-upload="input"]')
      container = $('[data-multi-upload="input-container"]')
      file_list = $('[data-multi-upload="list"]')

      new Slzr.MultiUploadSelector
        file_list: file_list
        input: input
        input_container: container
        template: '<div class="item ugc_item"><h3><span><' + '%= filename %></span><span class="deletex"><a href="#" class="remove-link">&times;</a></span></h3><div class="form_box"><label><input type="text" name="caption[]" placeholder="Add caption" /></label></div></div>'

      # Attach photo selection
      $(document).onAction 'select-photo', @selectPhoto

      # photoSaved function
      # this needs to be global because it's called back from server :/
      top.photoSaved = -> $('li a.selectedphoto').removeClass 'selectedphoto'

      $(document).on 'change', '[data-action="select-album"]', ->
        updateUrl(album_id: $(this).val())

    selectPhoto: (ev) =>
      $target = $(ev.target).closest('a[data-action="select-photo"]')

      # Unmark all selected photos
      $('.icon_item td.selectedphoto').removeClass 'selectedphoto'
      # and the current item as selected
      $target.closest('td').addClass 'selectedphoto'

      # update the sidebar
      photo_id = $target.data('photoId')
      url = "/photo/@PHOTO_ID@/edit"
      url = url.replace('@PHOTO_ID@', photo_id) # WP_CHANGE: probably can get rid of this line with interpolation above

      $.get url, {}, (data) ->$('#x-photo-edit').html data

  $ ->
    new Slzr.Page.PhotoManagement if Slzr.Meta.page == 'manage-photos'