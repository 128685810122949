
modulejs.define 'slzr/components/photo_chooser',
  ['react', 'jquery', 'prop-types', 'classnames', 'slzr/xhrfileupload',
   'slzr/components/photo_chooser/no_photo', 'slzr/components/photo_chooser/photo',
   'slzr/components/photo_chooser/library'],
  (React, $, PropTypes, classNames, xhrfileupload,
   NoPhoto, Photo, Library) ->

    class PhotoChooser extends React.Component
      @propTypes =
        initial_photo: PropTypes.object
        upload_url: PropTypes.string.isRequired
        library_url: PropTypes.string
        # Aspect ratio to work with
        aspect: PropTypes.number
        # Caption value
        caption: PropTypes.string
        # Flag to indicate if the selected photo can be cleared
        can_remove: PropTypes.bool
        # Flag to indicate if the user can choose a library photo
        can_choose: PropTypes.bool
        # Flag to indicate if the user can upload a new photo
        can_upload: PropTypes.bool
        # Flag to indicate if the user can crop selected photos
        can_crop: PropTypes.bool
        # If true, always allow cropping
        force_cropping: PropTypes.bool
        # Crop area "style" name, passed into Photo component
        crop_area_name: PropTypes.string
        # Crop area param name, passed into Photo component
        crop_area_param: PropTypes.string
        # Callback for photo being selected
        onPhotoSelected: PropTypes.func
        # Callback for photo being cleared
        onPhotoRemoved: PropTypes.func
        # Dropzone component for the NoPhoto case
        dropzoneComponent: PropTypes.func
        # Show crop editor link when appropriate
        showCropEditorLink: PropTypes.bool
        # Show link to photo editor when appropriate
        showEditorLink: PropTypes.bool
        # Allow the caption to be edited
        allowCaptionEdit: PropTypes.bool
        # To save value with react hook form
        register: PropTypes.func
        # Callback for crop changing
        onCropChange: PropTypes.func
        # Callback for caption changing
        onCaptionChange: PropTypes.func
        # Allow overriding state crop values
        cropOverride: PropTypes.object
        # callback for zoom slider end
        onZoomEnd: PropTypes.func

      @defaultProps =
        can_remove: true
        can_choose: true
        can_upload: true
        can_crop: true
        aspect: 1.0
        force_cropping: false
        showCropEditorLink: true
        allowCaptionEdit: true

      constructor: (props) ->
        super props

        initial_state = this._initialPhotoState(props)
        _.extend initial_state,
          # Upload status
          upload_status: 'ok'
          upload_status_message: null
          upload_progress: 0

          # Library status
          recent_items: []
          library_items: []
          library_loaded: false
          library_status: null # null or 'error'

        this.state = initial_state

      UNSAFE_componentWillReceiveProps: (nextProps) =>
        if this.props.initial_photo != nextProps.initial_photo
          this.setState this._initialPhotoState(nextProps)

      _initialPhotoState: (props) =>
          # Mode indicator
          #
          # Can be one of:
          #   none: No photo selected (with uploads)
          #   photo: Photo selected (optional cropper)
          #   library: Choosing from photo library
          mode: if props.initial_photo.photo then 'photo' else 'none'

          # Data about the currently selected photo
          photo: props.initial_photo.photo
          photo_key: props.initial_photo.key
          crop: props.initial_photo.crop
          edit_cropping_url: props.initial_photo.edit_cropping_url
          edit_url: props.initial_photo.edit_url

          # Flags to enable/disable features
          allow_cropping: props.force_cropping || (props.can_crop && props.initial_photo.allow_cropping)
          allow_remove: props.can_remove
          allow_choose: props.can_choose

      # User chose image to upload, so upload it via XHR and include the form data
      imageSelected: (file) =>
        # Perform upload
        form = $(this.element).closest('form')

        # Gather the rest of the form data
        #
        # Most of this isn't actually used, but it's somewhat useful in the logs for diagnostic purposes
        formData = null
        try
          # Prefer native serialization
          formData = new FormData(form.get(0))
        catch
          # But, IE10 fails, so fake the serialization on it
          formData = new FormData()
          form.find('input,select,textarea').each (i) ->
            return if this.name == 'photo' || this.name == 'event[photo]'
            return if this.disabled
            return if !this.checked && (this.type == 'checkbox' || this.type == 'radio')

            the_value = if this.type == 'file'
              this.files[0]
            else
              this.value

            formData.append(this.name, the_value)

        # Clean up some unnecessary fields

        # IE10 doesn't support delete (??)
        formData.delete? 'photo'
        formData.delete? 'event[photo]'
        formData.append '_timestamp', new Date

        this.setState
          upload_status: 'uploading'
          upload_status_message: null
          upload_progress: 0

        xhrfileupload
          url: this.props.upload_url
          method: 'POST'
          formData: formData
          file: file
          param: 'photo'
          progress: (event) =>
            if event.lengthComputable
              percent = Math.round((event.loaded / event.total) * 100)
              this.setState upload_progress: percent

        .fail (xhr, status, error) =>
          console.error 'file upload error', status, error, xhr
          if status == 'parsererror'
            # Parse error. Indicates a non-JSON response
            error_message = "Error uploading photo: unexpected response"
          else
            if xhr.status == 413
              error_message = 'Image must be smaller than 10MB'
            else
              error_message = "Error uploading photo (#{xhr.status}: #{error})"

          this.setState upload_status: 'error', upload_status_message: error_message

        .done (data, status, xhr) =>
          if data.status == 'error'
            message = if data.error == 'not_an_image'
                        "Uploaded file must be an image (JPG, GIF, or PNG)"
                      else if data.error == 'image_too_big'
                        "Image must be smaller than 10MB"
                      else if data.error == 'image_dimensions_too_big'
                        "Image must be smaller than 5000x5000 pixels"
                      else
                        data.result.message
            this.setState upload_status: 'error', upload_status_message: message
          else
            this.setState upload_status: 'ok', upload_status_message: null
            this.imageUploaded(data)

      # Image succesfully uploaded response handler
      imageUploaded: (response) =>
        new_photo = $.extend {}, this.state.photo,
          has_crop: false
          width: response.metadata.width
          height: response.metadata.height
          id: null
          library_id: null
          preview_url: response.url
          preview_width: response.metadata.width
          preview_height: response.metadata.height
          key: response.token

        this.setPhoto new_photo

      # User cleared selected image
      imageRemoved: (event) =>
        this.setState
          photo: null
          crop: null
          photo_key: 'deleted'
          mode: 'none'

        # Emit the event indicating the photo was removed
        #
        # This eventually gets picked up by Eventreach
        $(this.element).trigger 'slzr:photo:remove'

        # And finally, call the callback function
        this.props.onPhotoRemoved?()

      # Set the selected photo
      setPhoto: (new_photo, crop=null) =>
        # If no cropping, set to cover the whole photo
        #
        # This eventually gets center cropped to the right aspect ratio. Not specifying it causes the cropper to
        # do something totally unintended.
        new_crop = crop || {x: 0, y: 0, h: new_photo.height, w: new_photo.width}

        new_state =
          photo: new_photo
          crop: new_crop
          photo_key: new_photo.key
          mode: 'photo'
          allow_cropping: this.props.force_cropping || (this.props.can_crop && !new_photo.photo_library_id)
          edit_cropping_url: new_photo.edit_cropping_url
          edit_url: new_photo.edit_url
        this.setState new_state

        $(this.element).trigger 'slzr:photo:change'
        this.props.onPhotoSelected?(new_photo, { state: new_state })

      # User opened the photo library
      libraryOpened: (event) =>
        if !this.state.library_loaded
          # Request library contents
          $.get(this.props.library_url).then (result) =>
            this.setState
              library_loaded: true
              # Empty or falsy values will break the photo chooser LOC-1549
              library_items: result.library && result.library.filter (library_item) -> library_item && !$.isEmptyObject(library_item)
              recent_items: result.recent || []
              library_status: null
          .fail (xhr, status, error) =>
            this.setState
              library_loaded: false
              library_items: []
              recent_items: []
              library_status: 'error'

        this.setState
          mode: 'library'
          upload_status: null
          upload_status_message: null


      # User closed the photo library
      libraryClosed: (event) =>
        this.setState
          mode: 'none'
          library_status: null

      # User chose a photo from the library
      libraryPhotoSelected: (photo, event) =>
        new_crop = if photo.has_crop
          {x: photo.crop_x, y: photo.crop_y, h: photo.crop_h, w: photo.crop_w}
        else
          null

        this.setPhoto photo, new_crop

      setRef: (el) => this.element = el
      
      # Render with a selected photo
      renderPhoto: ->
        `<Photo photo={this.state.photo}
                crop={this.props.cropOverride ? this.props.cropOverride : this.state.crop}
                key={this.state.photo_key}
                photoKey={this.state.photo_key}
                cropAreaParam={this.props.crop_area_param}
                cropAreaName={this.props.crop_area_name}
                allowCropping={this.state.allow_cropping}
                allowRemove={this.state.allow_remove}
                onRemove={this.imageRemoved}
                aspect={this.props.aspect == -1 ? (this.state.photo.width / this.state.photo.height) : this.props.aspect}
                height={this.props.height}
                width={this.props.width}
                editCroppingUrl={this.state.edit_cropping_url}
                editUrl={this.state.edit_url}
                showCropEditorLink={this.props.showCropEditorLink && this.state.allow_cropping}
                showEditorLink={this.props.showEditorLink}
                allowCaptionEdit={this.props.allowCaptionEdit}
                onCropChange={this.props.onCropChange}
                onCropEnd={this.props.onCropEnd}
                onZoomEnd={this.props.onZoomEnd}
                onCaptionChange={this.props.onCaptionChange}
                caption={this.props.caption}
                forceCropOverride={this.props.cropOverride}
        />`

      # Render the choose from library (or nothing when blank)
      renderLibrary: ->
        if this.state.library_loaded && this.state.library_items.length == 0 && this.state.recent_items.length == 0
          `//`# No recents or library, so show an appropriate message asking to upload
          `<NoPhoto imageSelected={this.imageSelected}
                    showingEmpty={true}
                    onClose={this.libraryClosed}
                    uploading={this.state.upload_status == 'uploading'}
                    uploadProgress={this.state.upload_progress}
          />`
        else
          `<Library libraryItems={this.state.library_items}
                    recentItems={this.state.recent_items}
                    loading={!this.state.library_loaded}
                    error={this.state.library_status == 'error'}
                    onPhotoSelected={this.libraryPhotoSelected}
                    onClose={this.libraryClosed}
          />`

      # Render empty message
      renderEmpty: ->
        `<NoPhoto imageSelected={this.imageSelected}
                  libraryOpened={this.libraryOpened}
                  uploading={this.state.upload_status == 'uploading'}
                  uploadProgress={this.state.upload_progress}
                  allowChoose={this.state.allow_choose}
                  dropzoneComponent={this.props.dropzoneComponent}
        />`

      # Main render function
      render: ->
        content = if this.state.mode == 'photo'
          this.renderPhoto()
        else if this.state.mode == 'library'
          this.renderLibrary()
        else # Fallback
          this.renderEmpty()

        upload_status_classes = classNames 'photo_upload_status',
          error: this.state.upload_status == 'error'

        upload_status = `<div className={upload_status_classes}>{this.state.upload_status_message}</div>`

        name = "photo_key"
        register = if this.props.register then this.props.register(name) else {}

        `<div className="photo-chooser" ref={this.setRef}>
          <input {...register} type="hidden" name={name} value={this.state.photo_key} />
          {content}
          {upload_status}
        </div>`
