modulejs.define 'registration/components/attendee_question_choice_list',
  ['underscore', 'react', 'react-dnd', 'prop-types',
    'registration/constants', 'slzr/react/form_row', 'registration/components/attendee_question_choice_list_item',
    'slzr/react/sortable_list', 'slzr/react/sortable_item'],
  (_, React, ReactDnD, PropTypes,
    Constants, FormRow, AttendeeQuestionChoiceListItem,
    SortableList, SortableItem) ->

    class AttendeeQuestionChoiceList extends React.Component
      @propTypes:
        onAdd: PropTypes.func
        onChange: PropTypes.func
        onDelete: PropTypes.func
        onDragStart: PropTypes.func
        onDragUpdate: PropTypes.func
        onDragFinish: PropTypes.func
        onDragCancel: PropTypes.func
        choices: PropTypes.array


      constructor: (props) ->
        super(props)

        # Make up an identifier for this question, to prevent drags of choices *between* questions
        this._listIdentifier = Constants.DRAG_TYPE_ATTENDEE_QUESTION_CHOICE + ":" + props.questionId
        this.state =
          currentlyDragging: false

      # Wrap the drag start/finish callbacks to store the state of what's currently being dragged
      onDragStart: (item) =>
        this.setState currentlyDragging: item.id
        this.props.onDragStart?(item)

      onDragFinish: (result, item) =>
        this.setState currentlyDragging: false
        this.props.onDragFinish?(result, item)

      onDragCancel: (item) =>
        this.setState currentlyDragging: false
        this.props.onDragCancel?(item)

      renderResponseChoices: =>
        my = this

        choice_items = for choice, index in this.props.choices
          `<SortableItem key={index}
                          index={index}
                          dragItemId={choice}
                          itemIsDragSource={false}
                          dragging={this.state.currentlyDragging === choice}
                          listIdentifier={this._listIdentifier}
                          onDragStart={my.onDragStart}
                          onDragUpdate={my.props.onDragUpdate}
                          onDragFinish={my.onDragFinish}
                          onDragCancel={my.onDragCancel}>
              <AttendeeQuestionChoiceListItem label={"Option " + (index + 1)}
                                              value={choice}
                                              onChange={this.props.onChange.bind(this, index)}
                                              onDelete={this.props.onDelete.bind(this, index)}/>
            </SortableItem>`

      
      onClick: (event)=>
        event.preventDefault()
        this.props.onAdd?()
      
      render: =>
        `<div className="attendee-question-choices">
          <SortableList listIdentifier={this._listIdentifier}>
            {this.renderResponseChoices()}
            <li className="attendee-question-choice attendee-question-choice--add">
              <div className="attendee-question-choice--label">
              </div>
              <a  className="add-attendee-question-choice"
                  href="#"
                  onClick={this.onClick}>+ Add another option</a>
            </li>
          </SortableList>
        </div>`
