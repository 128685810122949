modulejs.define 'registration/components/checkout/buy_tickets_button',
  ['react', 'prop-types', 'classnames',
    'registration/components/checkout/ticket_preview_list'],
  (React, PropTypes, classNames,
    TicketPreviewList) ->

    BuyTicketsButton = (props) ->
      free_class = if props.free then props.register_class else props.buy_tickets_class
      base_classes = if props.className then props.className else 'btn btn-color-alt btn-large fullwidth em-link'
      classes = classNames base_classes, free_class

      button_label = if props.loading
        'Checking'
      else if props.free
        props.register_label
      else
        props.buy_tickets_label

      `<div>
        {props.error && <p className="error_marker">There was an error processing your request. Please try again</p>}

        <div className="user_action">
          <a href="#"
             ref={props.buttonRef}
             onClick={props.onClick}
             className={classes}
             aria-label={button_label + " for " + props.event_name}
             data-ga-event="true" data-ga-category="Event" data-ga-label={props.event_name}
             data-ga-action={button_label}>

            {button_label}
            <i className="fas fa-long-arrow-alt-right"></i>
          </a>
        </div>
      </div>`
