# Bulletin schedule editor controller
#
# This is the controller to link the EventInstance in-memory model with the page itself.
#
# Expects each item to be in an element with data-instance=true and data-date=start_date
#
# Inside, a click on something with data-action="remove-instance" will remove the instance
# and the associated element
#
# The form used for adding instances is the add_form option.
#   A data-action="add-instance" inside will add an instance
#   Will use data-instance to determine what type of data it is,
#     "start-date" "start-time" or "end-time"
#   Recurrence is supported with
#     "recurrence-ends" "recurrence-type" and "recurrency-day"
#
#   Recurrence types are 'daily', 'weekly', 'monthly'
#   Recurrence days is 0-6 for days of week (can be multiple)
#
#   The other instances should be date inputs
#
# Events will be triggered on the instance container when an instance is added
# or deleted.  The extra parameter for each event is a hash with start_date, start_time,
# end_time.
#   slzr:instance:add => Triggered when an instance is added
#   slzr:instance:remove => Triggered when an instance is removed

modulejs.define 'slzr/bulletin/campaign_schedule_editor',
  ['jquery', 'underscore', 'slzr/event/instances', 'EventEmitter', 'react', 'react-dom',
   'slzr/bulletin/schedule_form/form_component'],
  ($, _, EventInstances, EventEmitter, React, ReactDOM,
   ScheduleFormComponent) ->

    class CampaignScheduleEditor extends EventEmitter
      default_options:
        # Maximum number of instances to allow
        max_instances: 20

        # Maximum date range of recurring instances
        max_range: 366 # 1 year or so

        # Form controlled by this interface
        form: null

      constructor: (options) ->
        super()

        @options = $.extend {}, @default_options, options

        @form = $(@options.form)

        @instances = new EventInstances
          max_instances:     @options.max_instances
          max_range:         @options.max_range
          initial_instances: @options.initial_instances

        @_attachEvents()
        @_initializeUI()

      # Attach event handlers to the appropriate events
      _attachEvents: =>
        # Make sure we have at least one valid date
        @form.on 'validation:complete', (event, data) =>
          if @instances.valid()
            @form.find('[data-instance-error="need-date"]').hide()
          else
            @form.find('[data-instance-error="need-date"]').show()
            data.errors?.push 'A valid date must be entered'

      _initializeUI: =>
        my = this

        # Read the initial schedule state from the form fields
        form_data = @form.find(':input').serializeArray()

        schedule_data = {
          start_date: null,
          send_time: (new Date()).getHours(),
          recurrence_type: 'manual',
          interval: 1
        }

        for i in ['send_time', 'recurrence_type', 'start_date', 'end_date', 'interval', 'recurrence_count', 'weekdays']
          v = _.find(form_data, (item) -> item.name == "bulletin_campaign[#{i}]")?.value
          schedule_data[i] = v unless _.isBlank(v)

        ReactDOM.render React.createElement(ScheduleFormComponent,
            eventInstances: @instances,
            onInstancesAdded: @_userAddedInstances,
            onInputChange: @_userChangedInputs,
            scheduleData: schedule_data,
            campaignKind: @options.kind
          ),
          @form.get(0)


# Activate schedule manager if the page has the inputs on it
Slzr.jQuery ($) ->
  $form = $('[data-campaign-schedule="form"]')
  return unless $form.length > 0

  kind = $form.data('campaign-kind')
  kind = 'Email' unless kind

  CampaignScheduleEditor = modulejs.require('slzr/bulletin/campaign_schedule_editor')
  top.campaign_schedule_manager = new CampaignScheduleEditor
    form: $form
    initial_instances: []
    kind: kind
