# Hubspot integration component for Register
#
# When enabled, listens to the localist:order:complete event, and triggers a Hubspot form submission for each attendee.
#
# Usage:
#   Call function during page load

modulejs.define 'registration/checkout/debug_hubspot',
  ['jquery', 'underscore', 'object-path-immutable', 'registration/checkout/field_mapper'],
  ($, _, immutable, FieldMapper) ->

    # Store the page's configuration
    portal_id = null
    form_id = null

    log = () ->
      parts = ['[hubspot] '].concat Array.prototype.slice.call(arguments)
      console.log.apply this, parts

    # Look up the field value

    # Build an array of form submission(s) for the order
    #
    # One submission is built for each attendee
    #
    # Each submission returns the fields property for the Hubspot submission, which is an array of objects with name and value
    buildFormSubmissions = (details, field_mapping) ->
      for attendee in details.attendees
        # Promote the current attendee to a top-level item
        attendee_details = immutable.set(details, 'attendee', attendee)

        log('  Attendee', attendee)

        # and perform the field mapping
        for target, source of field_mapping
          log('    Mapping ', target, 'from', source, ' with ', FieldMapper.lookupValue(attendee_details, source))
          {
            name: target,
            value: FieldMapper.lookupValue(attendee_details, source, '') || ''
          }

    # Submit a form to hubspot, and just log any errors out to console
    submitForms = (form_submissions) ->
      url = "https://api.hsforms.com/submissions/v3/integration/submit/#{portal_id}/#{form_id}"

      body = {
        context:        {
                          pageUri:  location.href,
                          pageName: document.title
                        },
        skipValidation: true
      }

      # Extract the hubspotutk cookie, and set it if present
      #
      # Note that adding it to the submission as an empty string causes Hubspot to return an error
      hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")
      body.context.hutk = hutk if hutk != ''

      for submission, index in form_submissions
        body.fields = submission

        log('  Submitting form', index, body)


    # Initialization function for hubspot integration, to apply map
    #
    # +config+ expects:
    #  portal_id: HubSpot portalId
    #  form_id: HubSpot formGuid
    initializeHubspot = (config={}) ->
      # Store configuration, and ensure it's set
      portal_id = config.portal_id
      form_id = config.form_id

      throw "HubSpot portal_id must be specified" unless !!portal_id
      throw "HubSpot form_id must be specified" unless !!form_id

      log('Hubspot initialized with portal_id', portal_id, 'form_id', form_id)

      document.addEventListener 'localist:order:complete', (event) ->
        log('Order complete', event.detail)
        form_submissions = buildFormSubmissions(event.detail, config.fields)

        # Submit each form, logging errors
        submitForms(form_submissions)




    # Export
    initializeHubspot


namespace 'Slzr.Register', (exports, top) ->
  exports.DebugHubspot = () ->
    hubspot = modulejs.require('registration/checkout/debug_hubspot')
    hubspot.apply(null, arguments)