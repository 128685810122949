modulejs.define 'slzr/react/form_header',
  ['react', 'slzr/components/tooltip', 'slzr/react/form_row', 'prop-types', 'classnames'],
  (React, Tooltip, FormRow, PropTypes, classNames) ->

    class FormHeader extends React.Component
      @propTypes:
        title: PropTypes.string
        className: PropTypes.string
        tooltip: PropTypes.string
        tooltipUrl: PropTypes.string
        tooltipLabel: PropTypes.string
        required: PropTypes.bool

      render: ->
        classes = classNames heading: true, this.props.className
        `<FormRow {...this.props} className={classes} />`