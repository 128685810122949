modulejs.define 'slzr/react/font_table',
  ['react', 'jquery'],
  (React, $) ->
    class FontTable extends React.Component
      constructor: (props) ->
        super(props)
               
      renderTable: ->
        for font, index in @props.fontFamilies
          if font.theme_id == null
            targetTheme = 0
          else
            targetTheme = font.theme_id
        
          deleteUrl = "/admin/theme/#{targetTheme}/styles/#{font.id}/delete"
          editUrl = "/admin/theme/#{targetTheme}/styles/#{font.id}/edit"
          editId = "edit-font-#{font.id}"
          deleteId = "delete-font-#{font.id}"

          `<tr key={index}>
            <td>{font.name}</td>
              <td>
                <div className="action_button left">
                  <a id={editId} href={editUrl} className="link btn-color" aria-label="Edit Custom Font">Edit Settings</a>
                  <a id={deleteId} href={deleteUrl} className="link btn-color" aria-label="Delete Custom Font" data-method="delete" data-confirm="Are you sure you want to delete this custom font? This action cannot be undone.">Delete Font</a>
                </div>
            </td>
          </tr>`

      render: ->
        `<table border="0" className="item-list footable-loaded footable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="theme-listing">
            {this.renderTable()}
          </tbody>
        </table>`