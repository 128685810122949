# Various random small UI widget behaviors
#
# These should all automatically attach based on an appropriate data- attribute
#
# These should implement a jQuery plugin for ease of attachment

namespace 'Slzr.UI', (exports, top) ->
  $ = Slzr.jQuery

  # Filter selection widgets
  class exports.FilterSelector
    default_options:
      element: null # Dropdown element which gets selected
      container: null # container of the selected items
      template: null # template of the selected item bubbles

    constructor: (options) ->
      @options = $.extend {}, @default_options, options
      @element = $(@options.element)
      @container = $(@options.container)

      @template = JST[@options.template] || JST["#{LOCALIST_SETTINGS.jst_namespace}/filter_item"] || JST['filter_item']
      # Get other state out of data tags if available
      template_name = @element.data('tag-template') || @options.template || 'filter_item'
      @template = JST["#{LOCALIST_SETTINGS.jst_namespace}/#{template_name}"] || JST[template_name]

      @param = @element.data('tag-param') || @options.param
      @param_index = @element.data('tag-param-index') || @options.param_index
      @param_name = @element.data('tag-param-name') || @options.param_name
      @container = if @element.data('tag-container') then $('#' + @element.data('tag-container')) else @options.container
      @selected_prefix = @element.data('tag-selected-prefix') || 'tag'

      # Attach event handlers
      @element.bind 'change.FilterSelector', => @selectionChanged()

      # Container remove
      me = this
      $('[data-action="remove-tag"]', @container).live 'click.FilterSelector', ->
        tag_id = $(this).data 'tag-id'
        tag_context = $(this).data 'tag-context'
        prefix = $(this).data('tag-prefix') || 'tag'
        $("##{prefix}_#{tag_id}").remove()
        me.element.trigger 'slzr:filter:remove', context: tag_context, tag_id: tag_id
        false

    selectionChanged: ->
      tag_id = @element.val()
      selected = @element.find('option:selected')
      tag_name = selected.data('tag-name') || selected.html().replace(/&nbsp;/g, ' ').replace(/^\s+/, '')

      if tag_id and $("#tag_#{tag_id}").length == 0
        new_el = @template tag_id: tag_id, tag_name: tag_name, attribute: @param_index, param: @param, param_name: @param_name, selected_prefix: @selected_prefix
        @container.append new_el
        @element.val null
        @element.trigger 'slzr:filter:add', context: @param_index, tag_id: tag_id

      # Stop actions
      false

  # Attach it to the jQuery object
  $.fn.extend
    filterSelector: (options) ->
      my_options = options

      this.each ->
        filter_options =
          element: this
          # container: my_options.container
          # template: my_options.template

        new exports.FilterSelector filter_options

      # Return this, allowing chaining
      this

# Attach filter selector controls
Slzr.jQuery ($) ->
  $('[data-action="add-tag"]').filterSelector()

namespace 'Slzr.Message', (exports, top) ->
  $ = Slzr.jQuery

  exports.textChanged = (el) ->
    el = $(el)
    form = $(el).closest('form')
    submit = form.find('input[type="submit"]')

    submit.prop('disabled', el.val().isBlank())
