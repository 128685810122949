modulejs.define 'registration/components/attendee_question_list',
  ['underscore', 'react', 'react-dnd', 'prop-types',
    'registration/constants', 'registration/components/attendee_question_list_item'],
  (_, React, ReactDnD, PropTypes,
    Constants, AttendeeQuestionListItem) ->

    # Display the table listing ticket classes
    class AttendeeQuestionList extends React.Component
      @propTypes:
        questions: PropTypes.array
        defaultQuestions: PropTypes.array
        onChange: PropTypes.func
        onDelete: PropTypes.func
        openedEditors: PropTypes.array
        onToggleEditor: PropTypes.func
        onAddAttendeeQuestion: PropTypes.func
        onDragUpdate: PropTypes.func
        onDragCancel: PropTypes.func
        onDragStart: PropTypes.func
        onDragFinish: PropTypes.func
        onChoiceAdd: PropTypes.func
        onChoiceChange: PropTypes.func
        onChoiceDelete: PropTypes.func
        # Callback for choice drag start
        onChoiceDragStart: PropTypes.func
        # Callback for choice drag update
        onChoiceDragUpdate: PropTypes.func
        # Callback for choice drag finish
        onChoiceDragFinish: PropTypes.func
        # Callback for choice drag cancel
        onChoiceDragCancel: PropTypes.func

      @defaultProps:
        questions: []
        defaultQuestions: []
        openedEditors: []

      render: ->

        empty_message = if this.props.questions.length == 0 && this.props.defaultQuestions.length == 0
          `<tbody>
            <tr>
              <td>
                <a href="#" onClick={this.props.onAddAttendeeQuestion}>Create your first question</a>
              </td>
            </tr>
          </tbody>`

        default_question_rows = for question in this.props.defaultQuestions
          `<AttendeeQuestionListItem question={question}
                                     key={question.id}
                                     editable={false} 
                                     defaultQuestion={true} />`

        editable_question_rows = for question in this.props.questions
            `<AttendeeQuestionListItem question={question}
                                       key={question.id}
                                       editable={true}
                                       defaultQuestion={false}
                                       showEditor={this.props.openedEditors.indexOf(question.id) > -1}
                                       onDragStart={this.props.onDragStart}
                                       onDragUpdate={this.props.onDragUpdate}
                                       onDragFinish={this.props.onDragFinish}
                                       onDragCancel={this.props.onDragCancel}
                                       onToggleEditor={this.props.onToggleEditor}
                                       onChange={this.props.onChange}
                                       onDelete={this.props.onDelete}
                                       onChoiceAdd={this.props.onChoiceAdd}
                                       onChoiceChange={this.props.onChoiceChange}
                                       onChoiceDelete={this.props.onChoiceDelete}
                                       onChoiceDragStart={this.props.onChoiceDragStart}
                                       onChoiceDragUpdate={this.props.onChoiceDragUpdate}
                                       onChoiceDragFinish={this.props.onChoiceDragFinish}
                                       onChoiceDragCancel={this.props.onChoiceDragCancel} />`

        @props.connectDropTarget `<table className="form-item-list">
          {empty_message}
          {default_question_rows}
          {editable_question_rows}
        </table>`


    dropSpec =
      drop: (props, monitor, component) ->
        { position: 'end' } unless monitor.didDrop()

      hover: (props, monitor, component) ->
        if monitor.canDrop() && monitor.isOver(shallow: true)
          item = monitor.getItem()
          props.onDragUpdate?(item, 'end')

    dropCollect = (connect, monitor) ->
      hovered: monitor.isOver()
      connectDropTarget: connect.dropTarget()

    AttendeeQuestionList = ReactDnD.DropTarget(Constants.DRAG_TYPE_ATTENDEE_QUESTION, dropSpec, dropCollect)(AttendeeQuestionList)