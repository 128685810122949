# Multiple autocomplete, based off of demo
# http://jqueryui.com/demos/autocomplete/#multiple-remote
namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  # Supported options:
  #   resultCount: Limit results to this many
  #   resizeMenu: Callback for autocomplete's _resizeMenu
  #   onSelect: Callback when an option is selected
  exports.MultiTagAutocomplete = (el, url, options={}) ->
    options = $.extend {
      resultCount: 5
    }, options

    ## HELPER FUNCTIONS ##

    # Splits a string on commas, unless the comma is in double quotes
    splitTagList = (str) ->
      in_quote = false
      term_start = 0
      terms = []

      for i in [0..str.length]
        if str[i] == '"' # If it's a double quote
          in_quote = !in_quote

        else if !in_quote && str[i] == ','
          terms.push str.substr(term_start, i - term_start).trim()
          term_start = i + 1

      if term_start <= str.length
        terms.push str.substr(term_start).trim()

      terms

    extractLast = (term) ->
      splitTagList(term).pop()

    # Filter +suggestions+ to those that match +term+
    #
    # Don't return any results unless term is at least two characters
    #
    # Limits results to +limit+ count if greater than zero
    filterSuggestions = (term, suggestions, limit=0) ->
      if term.length >= 2
        term_re = new RegExp(Slzr.Autocomplete.escapeRegexp(term), 'i')

        results = _.filter suggestions, (item) ->
          term_re.test item.label
        results = results.slice(0, limit) if limit > 0
        results
      else
        []

    # Per instance cache
    cache = {}

    # Default options for the input

    $(el)
      .bind('keydown', (event) ->
        if event.keyCode == $.ui.keyCode.TAB and $(this).data('autocomplete').menu.active
          event.preventDefault()

        if event.keyCode == $.ui.keyCode.COMMA
          if $(this).data('autocomplete').menu.active
            $(this).data('autocomplete').menu.select(event)
            event.preventDefault()
          else
            $(this).data('autocomplete').close()
      )
      .autocomplete
        delay: 100

        position: {my: 'left top', at: 'left bottom-2', of: options.attachTo || el}

        source: (request, response) ->
          term = extractLast(request.term)
          term = term.replace(/^\"|\"$/, '') # Remove leading/trailing quotes
          term = term.trim()
          prefix = term.toLowerCase()[0]

          if !prefix || prefix == '' || prefix == ' ' || prefix == ','
            response []
          else if cache[prefix]
            response filterSuggestions(term, cache[prefix], options.resultCount)
          else
            # Preload all results starting with the first character, for better responsiveness
            $.getJSON url, {term: prefix}, (data) ->
              cache[prefix] = data
              response filterSuggestions(term, cache[prefix], options.resultCount)

        focus: ->
          # Prevent value from being inserted on focus
          false

        # Force it to always search, regardless of input
        search: -> true

        select: (event, ui) ->
          if options.onSelect?
            if options.onSelect ui.item.value
              @value = ''

            false
          else
            terms = splitTagList(@value)
            # Remove current value
            terms.pop()
            # Add selected item, in quotes if it contains a comma
            new_item = ui.item.value
            new_item = '"' + new_item + '"' if new_item.match /,/
            terms.push(new_item)
            # Add placeholder to get comma space at end
            terms.push('')

            # Comma separate it
            @value = terms.join(', ')
            false

    autocomplete = $(el).data('autocomplete')

    # Provide hook for _resizeMenu
    _superResizeMenu = autocomplete._resizeMenu
    autocomplete._resizeMenu = ->
      if options.resizeMenu?
        options.resizeMenu(this.menu.element)
      else
        _superResizeMenu()

    # Override render item with a custom formatter
    autocomplete._renderItem = (ul, item) ->
      escaped_entry = Slzr.Autocomplete.escapeRegexp this.term
      replacement = new RegExp("(#{escaped_entry})", "ig")
      highlighted_label = item.label.replace(replacement, '<strong class="highlight">$1</strong>')

      label_div = $('<a>').html(highlighted_label)

      if item.count? && item.list_url?
        count_label = "#{item.count} event#{if item.count != 1 then 's' else ''}"
        count_div = $('<a>').attr(
          href:   item.list_url,
          target: '_blank',
          class:  'autocomplete-link').html(count_label).click (event) ->
            # Not preventing default action, but stop other handlers, as we still want the link to open,
            # but not add to list
            event.stopPropagation()
      else
        count_div = null

      $('<li></li>')
        .data('ui-autocomplete-item', item)
        .append([label_div, count_div])
        .appendTo(ul)

    autocomplete