# Frontend javascript for channel pages
modulejs.define 'slzr/channel_pages',
  ['jquery', 'BackgroundCheck',
   'slzr/channel_pages/ui/maps'],
  (jQuery, BackgroundCheck,
   ChannelMaps) ->

    channelPageInit = -> jQuery ($) ->
      # initialize the cover photo
      if Slzr.Meta['cover-photo-url'] && !!$('.channel-cover-photo-text').get(0)
        try
          BackgroundCheck.init
            targets:    '.channel-cover-photo-text'
            images:     '.channel-cover-photo'
            threshold:  60
            minOverlap: 1
        catch e
          if e == 'Multiple backgrounds are not supported'
            # Ignore error
            true
          else if e == 'Elements not found'
            # ignore error, likely because header text is missing
            true
          else
            console.error 'BackgroundCheck init error', e

        # Load the cover photo and refresh after it loads (assumes 2x if ratio > 1)
        cover_photo_url = Slzr.Meta['cover-photo-url-2x'] if window.devicePixelRatio > 1.0
        cover_photo_url = Slzr.Meta['cover-photo-url'] unless cover_photo_url

        image = new Image
        image.src = cover_photo_url
        image.onload = ->
          BackgroundCheck.refresh()
          image = null

        # Force a refresh after entire page load, including cover photo
        $(window).on 'load', ->
          BackgroundCheck.refresh()

      new ChannelMaps($('[data-map-component]'))

# Initialize
modulejs.require('slzr/channel_pages')(Slzr.Meta)