modulejs.define(
  "registration/components/checkout/stripe_payment_row",
  ["react", "prop-types", "@stripe/react-stripe-js", "slzr/react/form_row"],

  function (React, PropTypes, ReactStripeElements, FormRow) {
    function StripePaymentRow(props) {
      return (
        <FormRow className="payment_class payment_stripe">
          <ReactStripeElements.CardElement onChange={props.onChange} />
          {props.errors && (
            <p className="error_marker" role="alert">
              {props.errors}
            </p>
          )}
        </FormRow>
      );
    }

    StripePaymentRow.propTypes = {
      onChange: PropTypes.func,
      errors: PropTypes.string
    };

    return StripePaymentRow;
  }
);
