modulejs.define 'registration/components/attendee_question_list_item',
  ['underscore', 'react', 'react-dnd', 'prop-types',
    'registration/constants', 'registration/components/attendee_question_form'],
  (_, React, ReactDnD, PropTypes,
    Constants, AttendeeQuestionForm) ->

    class AttendeeQuestionListItem extends React.Component
      @propTypes:
        # Can this question be edited
        editable: PropTypes.bool
        # Is the editor shown
        showEditor: PropTypes.bool
        # Is the question a platform, or per event
        defaultQuestion: PropTypes.bool
        onChange: PropTypes.func
        onDelete: PropTypes.func
        onToggleEditor: PropTypes.func
        onDragUpdate: PropTypes.func
        onDragCancel: PropTypes.func
        onDragStart: PropTypes.func
        onDragFinish: PropTypes.func
        onChoiceAdd: PropTypes.func
        onChoiceChange: PropTypes.func
        onChoiceDelete: PropTypes.func
        # Callback for choice drag start
        onChoiceDragStart: PropTypes.func
        # Callback for choice drag update
        onChoiceDragUpdate: PropTypes.func
        # Callback for choice drag finish
        onChoiceDragFinish: PropTypes.func
        # Callback for choice drag cancel
        onChoiceDragCancel: PropTypes.func

      @defaultProps:
        editable: true
        defaultQuestion: false
        showEditor: false

      toggleEditForm: (event) =>
        event.preventDefault()
        this.props.onToggleEditor?(this.props.question.id)

      closeForm: (event) =>
        event.preventDefault()
        this.props.onToggleEditor?(this.props.question.id, false)

      formChange: (name, value, event) =>
        this.props.onChange?(this.props.question.id, name, value, event)

      deleteTicket: (event) =>
        event.preventDefault()
        this.props.onDelete?(this.props.question.id, event)

      render: ->
        edit_form = if this.props.showEditor
          `<AttendeeQuestionForm question={this.props.question}
                                 onClose={this.closeForm}
                                 onChange={this.formChange}
                                 onChoiceAdd={this.props.onChoiceAdd}
                                 onChoiceChange={this.props.onChoiceChange}
                                 onChoiceDelete={this.props.onChoiceDelete}
                                 onChoiceDragStart={this.props.onChoiceDragStart}
                                 onChoiceDragUpdate={this.props.onChoiceDragUpdate}
                                 onChoiceDragFinish={this.props.onChoiceDragFinish}
                                 onChoiceDragCancel={this.props.onChoiceDragCancel} />`

        @props.connectDropTarget @props.connectDragPreview `<tbody>
          <tr>
            <td>
              {this.props.editable && this.props.connectDragSource(<i className="fa fa-bars drag-handle" aria-hidden="true"/>)}
              {this.props.question.question}
              {this.props.question.required && <sup className="required">Required</sup>}
              {this.props.defaultQuestion && <sup className="required">Global Question</sup>}
            </td>
            <td>
              {this.props.editable && <div className="text-right action_icon_button">
                <a href="#"
                   onClick={this.toggleEditForm}
                   title="Edit Attendee Question"
                   className="edit_button"><i className="localist-icon-edit-small"></i></a>
                <a href="#"
                   onClick={this.deleteTicket}
                   title="Remove Attendee Question"
                   className="deletex_button"><i className="localist-icon-trash-small"></i></a>
              </div>}
            </td>
          </tr>
          {edit_form}
        </tbody>`

    # DnD drag specification
    dragSpec =
      beginDrag: (props, monitor, component) ->
        item = { id: props.question.id, position: props.question.position }
        props.onDragStart?(item)
        item

      endDrag: (props, monitor, component) ->
        if monitor.didDrop()
          result = monitor.getDropResult()
          props.onDragFinish?(result)
        else
          props.onDragCancel?(id: props.question.id, position: props.question.position)

    dragCollect = (connect, monitor) ->
      isDragging: monitor.isDragging()
      connectDragSource: connect.dragSource()
      connectDragPreview: connect.dragPreview()

    # Drop specification
    dropSpec =
      drop: (props, monitor, component) ->
        { position: props.question.position } unless monitor.didDrop()

      hover: (props, monitor, component) ->
        if monitor.canDrop() && monitor.isOver(shallow: true)
          item = monitor.getItem()
          unless item.id == props.question.id
            props.onDragUpdate?(item, props.question.position)

    dropCollect = (connect, monitor) ->
      hovered: monitor.isOver()
      connectDropTarget: connect.dropTarget()

    AttendeeQuestionListItem = ReactDnD.DragSource(Constants.DRAG_TYPE_ATTENDEE_QUESTION, dragSpec, dragCollect)(AttendeeQuestionListItem)
    ReactDnD.DropTarget(Constants.DRAG_TYPE_ATTENDEE_QUESTION, dropSpec, dropCollect)(AttendeeQuestionListItem)
    