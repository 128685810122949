# Autocomplete wrapper stuff

namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  # This encapsulates our extensions to jQuery UI's autocomplete
  class exports.Autocomplete
    # Escape a string's special regular expression characters (parens,
    # brackets, pipe, backslash)
    @escapeRegexp: (str) ->
      str.replace(/(\(|\)|\||\\|\[|\])/g, '\\$1').replace(/(\.)/, '\\$1')

    defaults:
      formatter: null
      loadingMessage: "Loading..."
      typeMessage: null
      partialChars: 2
      # If set, will allow the literal text to be used.  @TEXT@ will be
      # replaced with the entered text
      useAsEnteredMessage: null
      delay: 100
      minLength: 2
      # Function that filters items (results, term)
      itemFilter: null

    constructor: (el, options) ->
      @options = $.extend {}, @defaults, options
      @options.formatter or= @basicFormatter
      @options.itemFilter or= @filterResults
      @cache = {}
      @requests_in_progress = {}

      el = $(el)

      return unless el[0]

      @element = el

      options = $.extend {}, @options,
        source: (request, response) => @getResults request, response

      @autocomplete = $(el).autocomplete(options).data('autocomplete')
      @autocomplete._renderItem = (ul, item) => @renderItem ul, item

      # Select the first item automatically
      $(el).bind 'autocompleteopen.slzrAutocomplete', =>
        menu = @autocomplete.menu
        # Findt he target (if use is enabled, use #2 unless only one)
        children = menu.element.children()
        selection = children.first()
        if @options.useAsEnteredMessage and children.length > 1
          selection = children.eq(1)

        menu.next()
        # menu.focus $.Event({type: 'mouseenter'}), selection

      # Loading indicator
      if @options.loadingMessage
        $(el).bind 'autocompletesearch.loadingMessage', (ev) =>
          # @startIndicator()

        $(el).bind 'autocompleteopen.loadingMessage blur.loadingMessage autocompleteclose.loadingMessage', (ev) =>
          _.defer(=> @stopIndicator())
      
      if @options.typeMessage
        $(el).bind 'focus.typeMessage', (ev) =>
          @showMessage(@options.typeMessage)

        $(el).bind 'blur.typeMessage autocompleteclose.typeMessage', (ev) =>
          _.defer(=> @stopIndicator())

    # Prepare the message div
    prepareMessageDiv: ->
      @message_div or= $('<ul></ul>')
        .addClass('ui-autocomplete')
        .appendTo( $(@autocomplete.options.appendTo || 'body')[0] )
        .zIndex(@autocomplete.element.zIndex() + 1)
      @message_div.empty()

    # Show the message div
    showMessageDiv: ->
      @autocomplete.close()
      @message_div.show()
      @message_div.outerWidth Math.max(
        @message_div.width('').outerWidth(),
        @autocomplete.element.outerWidth()
      )
      @message_div.position $.extend({of: @autocomplete.element}, @autocomplete.options.position)

    # Show the tip message
    showMessage: (msg) ->
      @prepareMessageDiv()
      @renderItem @message_div, {label: msg}
      @showMessageDiv()

    # Show the loading dropdown
    startIndicator: ->
      @showMessage @options.loadingMessage

    # Hide the loading dropdown
    stopIndicator: ->
      @message_div.hide() if @message_div

    renderItem: (ul, item) ->
      $('<li></li>')
        .data('ui-autocomplete-item', item)
        .append( $('<a></a>').html(item.label) )
        .appendTo(ul)

    getResults: (request, response) ->
      term = @cleanStringPrefix request.term
      self = this
      @requestResults term, (results) ->
        # Format the results to how the widget expects it
        filtered_results = self.options.itemFilter(results, term)
        results = $.map filtered_results, (e) ->
          label = self.options.formatter e, term, self
          if _.isString(label)
            value = $('<div/>').html(label).text()
          else
            value = label.value
            label = label.label

          # Return a valid result for this element
          {
            data: e
            label: label
            id: e.id
            value: value
          }

        if self.options.useAsEnteredMessage
          # Use request.term, to keep any "a/an/the" prefix the user
          # entered
          text_label = self.options.useAsEnteredMessage.replace('@TEXT@', request.term)
          results.unshift {
            data: {raw: true, text: request.term}
            label: text_label
            value: request.term
            id: "text_#{term}"
          }

        self.stopIndicator()
        response results

    # Only return results matching term
    filterResults: (results, term) ->
      lower_term = term.toLowerCase()
      escaped_entry = Slzr.Autocomplete.escapeRegexp lower_term
      check_re = ///\b#{escaped_entry}///i
      _.select results, (i) ->
        check_re.test(i.name)

    requestResults: (term, callback) ->
      if term.length >= @options.partialChars
        prefix = term.substr(0, @options.partialChars).toLowerCase()
        the_url = if _.isFunction(@options.url)
          @options.url()
        else
          @options.url

        key = "#{the_url}|#{prefix}"

        if typeof @options.extraCacheKey == 'function'
          key += "|#{@options.extraCacheKey()}"

        self = this

        if self.cache[key]
          callback self.cache[key]
        else
          unless self.requests_in_progress[key]
            self.requests_in_progress[key] = true
            ajax_options = if typeof self.options.extraParams == 'function'
              self.options.extraParams()
            else if self.options.extraParams
              self.options.extraParams
            else
              {}

            ajax_options.value = prefix

            $.ajax
              url: the_url
              data: ajax_options
              dataType: 'json'
              success: (data) ->
                self.cache[key] = data
                self.requests_in_progress[key] = false
                callback self.cache[key]
              error: ->
                callback []
      else
        callback []


    # Strip the, an, a from the beginning of str
    cleanStringPrefix: (str) ->
      str.replace(/^(the|a|an)\s+/i, '')
        .replace(/^\s+|\s+$/g, '')

    # Return the label for this object
    #
    basicFormatter: (obj, term, autocomplete) ->
      ret = ''
      entry = term
      escaped_entry = Slzr.Autocomplete.escapeRegexp entry
      replacement = new RegExp("(#{escaped_entry})", "ig")
      obj.name.replace(replacement, '<strong class="highlight">$1</strong>')

    destroy: ->
      @autocomplete.destroy()
      @message_div?.remove()
      @element.off('.slzrAutocomplete')
      @element.off('.typeMessage')
      @element.off('.loadingMessage')

# Autocomplete formatters
namespace 'Slzr.Formatter', (exports, top) ->
  exports.nameWithAddress = (obj, term, autocomplete) ->
    ret = ''
    entry = term
    escaped_entry = Slzr.Autocomplete.escapeRegexp entry
    replacement = new RegExp("(#{escaped_entry})", "ig")
    highlighted_name = obj.name.replace(replacement, '<strong class="highlight">$1</strong>')

    ret = highlighted_name
    if obj.address
      ret += '<div class="informal location">'
      ret += obj.address
      ret += '</div>'

    {value: obj.name, label: ret}

  exports.nameWithBusinessType = (obj, term, autocomplete) ->
    ret = ''
    entry = term
    escaped_entry = Slzr.Autocomplete.escapeRegexp entry
    replacement = new RegExp("(#{escaped_entry})", "ig")
    highlighted_name = obj.name.replace(replacement, '<strong class="highlight">$1</strong>')

    ret = highlighted_name
    if obj.business_type
      ret += '<div class="informal location">'
      ret += obj.business_type
      ret += '</div>'

    {value: obj.name, label: ret}

  exports.nameWithPlaceType = exports.nameWithBusinessType

  exports.nameWithDateAndDescription = (obj, term, autocomplete) ->
    ret = ''
    entry = term
    escaped_entry = Slzr.Autocomplete.escapeRegexp entry
    replacement = new RegExp("(#{escaped_entry})", "ig")
    highlighted_name = obj.name.replace(replacement, '<strong class="highlight">$1</strong>')

    ret += '<span class="dateright">'
    ret += Date.parse(obj.starts_at).toString('M/d')
    ret += '</span>'

    ret += highlighted_name
    if obj.description
      ret += '<div class="informal">'
      ret += substr(obj.description, 0, 160)
      ret += '</div>'

    {value: obj.name, label: ret}

  exports.nameWithEmail = (obj, term, autocomplete) ->
    ret = ''
    entry = term
    escaped_entry = Slzr.Autocomplete.escapeRegexp entry
    replacement = new RegExp("(#{escaped_entry})", "ig")
    highlighted_name = obj.name.replace(replacement, '<strong class="highlight">$1</strong>')
    highlighted_email = obj.email.replace(replacement, '<strong class="highlight">$1</strong>')

    ret = highlighted_name
    ret += '<div class="informal location">'
    ret += highlighted_email
    ret += '</div>'

    {value: obj.email, label: ret}

namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  exports.initializeAutocompletes = ->
    $('input[data-autocomplete]').each ->
      $this = $(this)
      data = $this.data()

      return if data['slzr.autocomplete'] != undefined

      autocomplete_type = data.autocomplete

      switch autocomplete_type
        when 'places'
          data_url = '/settings/get_business_list'
        when 'event-place'
          data_url = '/event/place_lookup'
        else
          return false

      formatter = Slzr.Formatter[data.autocompleteFormatter]

      select_function = Slzr.AutocompleteActions[data.autocompleteSelected] || window.eval(data.autocompleteSelected)

      options =
        url: data_url
        typeMessage: data.autocompleteTypeMessage
        useAsEnteredMessage: data.autocompleteUseAsEntered
        formatter: formatter
        select: (event, ui) ->
          # Trigger autocomplete selected event on the element
          $this.trigger('autocomplete:selected', ui.item)
          select_function?(ui.item, event.target)

      $this.data 'slzr.autocomplete', new Slzr.Autocomplete($this, options)

  # Attach autocomplete to inputs with [data-autocomplete]
  $ -> exports.initializeAutocompletes()

# Autocomplete selection actions
#
# TODO: organize all these better
namespace 'Slzr.AutocompleteActions', (exports, top) ->
  $ = Slzr.jQuery

  # Add place to favorites for settings page
  exports.addPlaceToFavorites = (item, input) ->
    add_business_friend_url = '/settings/add_favorite_place' # WP_CHANGE: does this need to be full url
    $.post add_business_friend_url, {friend_id: item.id.replace(/business_/, ''), tab: 'places'}, (result) ->
      $(input).val('')
