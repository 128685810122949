modulejs.define 'slzr/react/form_input_group',
  ['react', 'create-react-class', 'prop-types'],
  (React, createReactClass, PropTypes) ->
    # FormInputGroup = React.createClass
    FormInputGroup = createReactClass
      propTypes:
        className: PropTypes.string

      render: ->
        style = {"display": "none"} if this.props.hidden

        `<fieldset style={style} className={this.props.className}>
          {this.props.children}
        </fieldset>`