modulejs.define 'registration/components/checkout/quantity_chooser',
  ['underscore', 'react', 'object-path-immutable', 'prop-types',
   'slzr/react/form_input_group', 'slzr/react/form_row', 'registration/components/checkout/ticket_class_quantity_row',
   'registration/components/checkout/promo_code_form', 'registration/components/checkout/reservation_timer'],
  (_, React, immutable, PropTypes
   FormInputGroup, FormRow, TicketClassQuantityRow,
   PromoCodeForm, ReservationTimer) ->

    class QuantityChooser extends React.Component
      @propTypes:
        # Callback for form submission
        onSubmit: PropTypes.func
        # Callback for applying promo code
        onApplyPromoCode: PropTypes.func
        # Callback for cleaing applied promo code
        onRemovePromoCode: PropTypes.func
        # Callback for user requesting to cancel checkout
        onCancel: PropTypes.func

      constructor: (props) ->
        super props

        # Prefill selectedQuantities with what the server says was selected for the selected instance
        selectedDate = this.props.selected_instance_id || @props.instances[0]?.id
        selectedQuantities = {}
        donationAmounts = {}

        for ticket_class_id, quantity of @props.ticket_availability?[selectedDate]?.selected_quantities
          selectedQuantities[ticket_class_id] = quantity

        # Restore donation amounts from cart
        for item in props.cart?.items
          donationAmounts[item.ticket_class_id] = item.price #if @props.ticket_classes[ticket_class_id]

        @state =
          haveInstances: @props.instances.length > 0
          selectedDate: selectedDate
          selectedQuantities: selectedQuantities
          donationAmounts: donationAmounts
          showingPromoCodeForm: false

        # Prefill selectedQuantities

      onSubmitForm: (event) =>
        event.preventDefault()
        data = this.getFormData()
        this.props.onSubmit?(data)

      onCancel: (event) =>
        event.preventDefault()
        this.props.onCancel?()

      # Return form data
      getFormData: =>
        event_instance_id: this.state.selectedDate
        ticket_quantities: this.state.selectedQuantities
        donation_amounts: this.state.donationAmounts

      isValid: =>
        selected_quantities = this.state.selectedQuantities

        for item in this.props.ticket_class_groups
          ticket_classes = item[1]
          for ticket_class in ticket_classes
            error = this.props.ticket_errors?[ticket_class.id]
            donation_amount = this.state.donationAmounts[ticket_class.id]
            selected_quantity = selected_quantities[ticket_class.id] || 0

            # Indicate error on price in real time
            if ticket_class.donation && selected_quantity > 0
              if ticket_class.min_price > 0 && ticket_class.max_price > 0 && (donation_amount < ticket_class.min_price || donation_amount > ticket_class.max_price)
                return false
              else if ticket_class.min_price == 0 && ticket_class.max_price > 0 && (donation_amount > ticket_class.max_price)
                return false
              else if ticket_class.max_price == 0 && ticket_class.min_price > 0 && (donation_amount < ticket_class.min_price)
                return false
        true

      render: ->
        have_selections = _.reduce(_.values(this.state.selectedQuantities), (memo, num) -> memo + num) > 0

        if !this.state.haveInstances
          `<div className="widget-content">
            <form className="form_box form-sidebar">
              {!this.state.haveInstances && <p className="error_marker" role="alert">Registration is no longer available for this event</p>}

              <div className="action_button">
                <a href="#" className="cancel_link em-link" onClick={this.onCancel}>Cancel</a>
              </div>
            </form>
          </div>`
        else
          `<div className="widget-content">
            <form className="form_box form-sidebar">
              {this.props.reservation_ends_at && <ReservationTimer now={this.props.now}
                                reservationEnds={this.props.reservation_ends_at}
                                browserTimeOffset={this.props.browser_time_offset} />}

              <FormInputGroup className="select_date">

                  {this.renderInstanceSelector()}

              </FormInputGroup>

              {this.renderTicketGroups()}

              {this.renderPromoCodeForm()}

              {this.props.error && <p className="error_marker" role="alert">There was an error processing your request. Please try again</p>}

              <div className="action_button">
                <a href="#" className="cancel_link em-link" onClick={this.onCancel}>Cancel</a>
                <input type="submit"
                      className="em-button em-primary"
                      disabled={!have_selections || !this.isValid() || this.props.loading}
                      onClick={this.onSubmitForm}
                      value={this.props.loading ? "Processing..." : "Continue"} />

              </div>
            </form>
          </div>`

      renderPromoCodeForm: =>
        `<PromoCodeForm activePromoCode={this.props.active_promo_code}
                        promoCodeError={this.props.promo_code_error}
                        promoCode={this.props.promo_code}
                        loading={this.props.loading}
                        onApplyPromoCode={this.props.onApplyPromoCode}
                        onRemovePromoCode={this.props.onRemovePromoCode} />`

      onInstanceChange: (event) =>
        this.setState immutable.set(this.state, 'selectedDate', event.target.value)

      # Format an instance hash as a date and time.
      #
      # The +instance+ hash should just have start_date and start_time properties.
      _formatInstanceDate: (instance) ->
        format_string = 'dddd, MMMM D, YYYY'
        format_string += ' h:mma' if instance.start_time

        moment.parseZone(instance.starts_at).format(format_string)

      # Format the date range of all instances
      _formatInstanceRange: (instances) ->
        format_string = 'dddd, MMMM D, YYYY'
        joiner = ' - '

        formatted = moment.parseZone(instances[0].starts_at).format(format_string)
        if instances.length > 1
          formatted += joiner + moment.parseZone(instances[instances.length - 1].starts_at).format(format_string)

        formatted

      renderInstanceSelector: =>
        if @props.kind == 'conference'
          this._formatInstanceRange(@props.instances)
        else
          if @props.instances.length == -1
            # Just render the instance date
            this._formatInstanceDate(@props.instances[0])
          else
            options = (`<option key={instance.id} value={instance.id}>{this._formatInstanceDate(instance)}</option>` for instance in @props.instances)

            `//`# Render the dropdown to choose the current date
            `<div className="em-select-floating">
                <label htmlFor="instance_select_date">Select date</label>
                <select id="instance_select_date" value={this.state.selectedDate} onChange={this.onInstanceChange}>
                  {options}
                </select>
                <i className="fas fa-sort-down"></i>
              </div>`

      # Callback when changing the quantity of a ticket class
      onQuantityChange: (ticket_class_id, quantity) =>
        this.setState immutable.set(this.state, ['selectedQuantities', ticket_class_id], Number(quantity))

      # Callback to handle a donation ticket price change
      #
      # Setting price to a non-zero value also sets quantity to 1
      onItemPriceChange: (ticket_class_id, amount) =>
        if amount > 0
          new_quantity = 1
        else
          new_quantity = 0

        this.setState immutable(this.state).
          set(['donationAmounts', ticket_class_id], amount).
          set(['selectedQuantities', ticket_class_id], new_quantity).
          value()


      renderTicketGroups: =>
        my = this
        selected_quantities = this.state.selectedQuantities
        selected_date = this.state.selectedDate
        currency = this.props.currency

        for item in this.props.ticket_class_groups
          group = item[0]
          ticket_classes = item[1]

          if group
            header = `<FormRow title={group.name} className="ticket-group-header"/>`
          else
            header = null

          rows = for ticket_class in ticket_classes
            error = my.props.ticket_errors?[ticket_class.id]
            donation_amount = my.state.donationAmounts[ticket_class.id] || _.find(my.props.cart.items, (item) => item.ticket_class_id == ticket_class.id)?.price || null
            selected_quantity = selected_quantities[ticket_class.id] || 0

            # Indicate error on price in real time
            max_price = if ticket_class.max_price == 0 then Infinity else ticket_class.max_price
            if ticket_class.donation && selected_quantity > 0 && (donation_amount < ticket_class.min_price || donation_amount > max_price)
              error = 'invalid_price'

            `<TicketClassQuantityRow {...ticket_class}
                                     availability={ticket_class.availability[selected_date]}
                                     selectedQuantity={selected_quantity}
                                     donationAmount={donation_amount}
                                     currency={currency}
                                     onQuantityChange={my.onQuantityChange}
                                     onItemPriceChange={my.onItemPriceChange}
                                     error={error}
                                     key={ticket_class.id} />`

          `<div key={group ? group.id : 'default'}>
            {header}

            <FormInputGroup className="ticket-group-wrap">
              {rows}
            </FormInputGroup>
          </div>`
