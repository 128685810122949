# Glue code for linking an inline uploader and the cropper to a form
#
# This manages changing state and resetting the cropper as needed.

namespace 'Slzr.UI.PhotoUploader', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  class exports.Manager
    default_options:
      # Prefix for crop offset elements
      cropPrefix: null

      # Input selector for upload element
      uploadInput: null

      # Element for transmitting photo state back to server
      stateInput: null

      # Container for the action buttons
      actionButtonContainer: null

      # Element to place cropper in
      cropContainer: null

      # Crop aspect ratio ([width, height])
      cropAspect: [200, 150]

      # Legacy input, will be hidden if on supported browser (IE8+)
      legacyInput: null

    # Initialize this photo manager
    #
    # The element should be some wrapper with various child elements tagged with data-photo values:
    #
    # crop-x, crop-y, crop-w, crop-h: crop value hidden fields
    # key: hidden field for communicating photo state back to server
    # current: container for current photo + cropper (if available)
    # placeholder: container for placeholder photo
    # status: upload status display
    # menu-button: button for activating modify menu
    # menu-items: modify menu popup itself
    # upload: file input for uploading photos
    constructor: (element, options) ->
      @options = $.extend {}, @default_options, options

      @crop_width = @options.cropAspect[0]
      @crop_height = @options.cropAspect[1]

      # Extract elements
      @crop_container          = $(element)
      @upload_input            = @crop_container.find('[data-photo="upload-input"]')
      @state_input             = @crop_container.find('[data-photo="key"]')
      @current_container       = @crop_container.find('[data-photo="current"]')
      @placeholder_container   = @crop_container.find('[data-photo="placeholder"]')
      @action_button_container = @crop_container.find('[data-photo="actions"]')
      @status                  = @crop_container.find('[data-photo="status"]')
      @legacy_input            = $(@options.legacyInput)
      @drop_zone               = @crop_container if options.disableDocumentDrop
      @current_image           = {} if @current_container.find('[data-has-image="true"]').length > 0
      @allow_cropping          = @current_container.data('photo-allow-crop')

      @_initializeCropper()

      # Don't initialize the menu and inline uploads on IE 7 and older,
      # to work around browser crashes
      if @legacy_input.length == 0 || !(Slzr.Browser.IE && Slzr.Browser.version < 8)
        @_initializeUpload()
        @_initializeActionButtons()
        @legacy_input.hide()

        # Find any containing LABELs and disable clicks on it
        @crop_container.closest('label').attr 'for', '_unused_'
      else
        @legacy_input.show()


    # Disable cropper
    disableCropper: =>
      @cropper?.disable()

    # Enable cropper
    enableCropper: =>
      @cropper?.enable()

    # Remove cropper element and image
    destroyCropper: =>
      @cropper?.destroy()
      @cropper = null
      @current_image = null

    # Crop changed callback
    cropChanged: (c) =>
      @crop_container.find('[data-photo="crop-x"]').val(c.x)
      @crop_container.find('[data-photo="crop-y"]').val(c.y)
      @crop_container.find('[data-photo="crop-w"]').val(c.w)
      @crop_container.find('[data-photo="crop-h"]').val(c.h)

    # Create cropper element
    createCropper: (el, options={}) =>
      # Calculate minimum crop size, and scale into image dimensions
      # if the image is smaller.
      crop_aspect = @crop_width / @crop_height
      image_aspect = options.width / options.height

      min_crop_width = @crop_width
      min_crop_height = @crop_height

      if image_aspect < crop_aspect
        # Portrait
        if options.width < @crop_width
          min_crop_width = options.width
          min_crop_height = options.width / crop_aspect

      else if image_aspect > crop_aspect
        # Landscape
        if options.height < @crop_height
          min_crop_height = options.height
          min_crop_width = options.height * crop_aspect

      crop_options =
        cropChanged: @cropChanged
        cropWidth:   min_crop_width
        cropHeight:  min_crop_height
        initialCrop: options.initial_crop
        width:       options.width
        height:      options.height

      @cropper = new Slzr.UI.PhotoUploader.Cropper el, crop_options

    # Update image using the hash returned from upload API
    updateImage: (data) =>
      @destroyCropper()
      @current_image = data

      # Calculate display dimensions; width 300, height whatever
      display_w = if data.metadata.width >= 300
        300
      else
        data.metadata.width

      display_h = display_w / data.metadata.aspect_ratio

      # Set image tag
      display_w = Math.floor display_w
      display_h = Math.floor display_h

      img_attrs =
        src: data.url
        width: Math.floor(display_w)
        height: Math.floor(display_h)

      # Update the img tag with the new image and sizing
      img_tag = @current_container.find('img')
      img_tag.attr(img_attrs)
      img_tag.css(width: "#{display_w}px", height: "#{display_h}px", maxWidth: 'none', maxHeight: 'none')

      @current_container.show()
      @placeholder_container.hide()

      if data.crop
        initial_crop = [data.crop.x, data.crop.y, data.crop.x + data.crop.w, data.crop.y + data.crop.h]
      else
        initial_crop = false

      if @allow_cropping
        @createCropper img_tag,
          width: data.metadata.width
          height: data.metadata.height
          initial_crop: initial_crop

      @state_input.val data.token
      @crop_container.trigger 'slzr:photo:change'
      @_updateActionButtonState()

    # Clear uploaded photo and state
    clearPhoto: =>
      @destroyCropper()
      @current_container.find('img').css(width: '', height: '')
      @state_input.val('deleted')
      $('#' + @options.cropPrefix + '_x').val('')
      $('#' + @options.cropPrefix + '_y').val('')
      $('#' + @options.cropPrefix + '_w').val('')
      $('#' + @options.cropPrefix + '_h').val('')

      @current_image = null
      @current_container.hide()
      @placeholder_container.show()
      @crop_container.trigger 'slzr:photo:remove'
      @_updateActionButtonState()

    # Upload done event handler
    uploadDone: (event, data) =>
      result = data.result
      if result?.status == 'ok'
        @updateImage data.result

      @upload_input = @action_button_container.find('[data-photo="upload-input"]')

    uploadFail: (event, data) =>
      # console?.error('upload failed', event, data)

    uploadDragOver: (event) =>
      return true if event.srcElement?.id == 'zenbox_tab'

      if !@upload_layer_shown
        unless @upload_layer
          @upload_layer = $('<div>', class: 'drop-image').html('DROP IMAGE NOW')
          $(document.body).append(@upload_layer)

        # Calculate font size for text
        # 385px width = 30px size
        # 960px width = 100px size

        scaler = ($(window).width() - 385) / 575 # 960-385
        new_font_size = 70 * scaler + 30

        # Clamp font size to 30-100px
        new_font_size = Math.max new_font_size, 30
        new_font_size = Math.min new_font_size, 100

        @upload_layer.css fontSize: "#{new_font_size}px"
        @upload_layer.show()
        @upload_layer_shown = true

      true

    uploadDragLeave: (event) =>
      return true if event.type == 'dragleave' && event.target != @upload_layer?[0]

      if @upload_layer_shown
        @upload_layer.hide()
        @upload_layer_shown = false

      true

    # Initialize the inline uploader
    _initializeUpload: =>
      @uploader = new Slzr.UI.PhotoUploader.Input @upload_input, statusElement: @status, dropZone: @drop_zone

      @upload_layer_shown = false

      @uploader.bind 'fileuploaddone', @uploadDone
      @uploader.bind 'fileuploadfail', @uploadFail

      @uploader.bind 'fileuploaddragover', @uploadDragOver
      @uploader.bind 'fileuploaddrop', @uploadDragLeave
      $(document).bind 'dragleave', @uploadDragLeave

    # Initialize the cropper if there's an img with data-auto-crop=true in cropContainer
    #
    # The img needs to have data tags for crop-x/y/w/h for initial crop and original-width and original-height
    _initializeCropper: =>
      img = @crop_container.find('img[data-auto-crop]')
      if img.length > 0
        @current_image = {}

        @element = img

        img_w = img.data 'original-width'
        img_h = img.data 'original-height'

        crop_x = img.data 'crop-x'
        crop_y = img.data 'crop-y'
        crop_w = img.data 'crop-w'
        crop_h = img.data 'crop-h'

        initial_crop = if crop_x? and crop_y? and crop_w? and crop_h?
          [crop_x, crop_y, crop_x + crop_w, crop_y + crop_h]
        else
          null

        @createCropper img,
          initial_crop: initial_crop
          width: img_w
          height: img_h

    # Initialize the action menu
    _initializeActionButtons: =>
      # Attach listeners for menu item actions
      @action_button_container.on 'click.photomanager', 'a[data-photo-action]', @_handleActionButtonClick

      # Attach movement listener for the file input, and make sure the event data is the file input itself
      self = this # the Manager object, the event handler also needs to know the jquery "this" for the event target
      @action_button_container.on 'mousemove.photomanager mouseleave.photomanager', '[data-photo-action="upload-photo"]', (ev) ->
        self._handleUploadItemMove(ev, this)

      # Attach hover thing to image
      @crop_container.on 'mouseenter.photomanager', 'img, div', @_showActionButtons
      @crop_container.on 'mouseleave.photomanager', 'img, div', @_hideActionButtons
      @_updateActionButtonState()

      @_hideActionButtons() if @current_image

    _showActionButtons: (ev) =>
      @action_button_container.show()

    _hideActionButtons: (ev) =>
      # Buttons are always visible when placeholder is shown
      @action_button_container.hide() if @current_image

    # Move the upload input so the button stays under cursor
    #
    # IE especially needs the button clicked, not the text part
    #
    # ev is the event object; item is the link for uploading
    _handleUploadItemMove: (ev, item) =>
      item = $(item)

      # Only move if we're in the link's area, otherwise snap back and treat as a leave
      in_link_area = if ev.type == 'mousemove'
        link_pos = item.offset()

        x1 = link_pos.left
        x2 = x1 + item.width()
        y1 = link_pos.top
        y2 = y1 + item.height()

        x1 <= ev.pageX <= x2 && y1 <= ev.pageY <= y2
      else
        false

      if in_link_area
        # Make sure the button on the right is under the mouse cursor
        @upload_input.position
          my: 'right+30 center'
          at: 'right center'
          of: ev
          collision: 'none'
      else
        # Reset position
        @upload_input.position
          my: 'right top'
          at: 'right top'
          of: item
          collision: 'none'

      true

    # Handle a menu item click in the edit menu
    _handleActionButtonClick: (ev) =>
      action = $(ev.target).data('photo-action')

      switch action
        # Upload a new photo
        #
        # This really needs to be handled by an invisible file input above the menu item
        when 'upload-photo'
          true

        # Choose an existing photo from the library, using photopop
        when 'choose-existing-photo'
          window.open '/admin/photos/photo_popup', 'photopop', 'width=550,height=350,scrollbars=yes'

        # Remove currently set photo
        when 'remove-photo'
          @clearPhoto()

        else
          console?.error('Unknown photo menu action:', action)

      ev.preventDefault()

    # Resize menu button to match the width of the displayed image
    _updateActionButtonState: =>
      if @current_image
        @action_button_container.find('[data-photo-action="remove-photo"]').show()
        width = @current_container.find('img').width()
      else
        @action_button_container.find('[data-photo-action="remove-photo"]').hide()
        width = @placeholder_container.find('img').width()

      # button has 20px inset on each side from image
      @action_button_container.width(width)
