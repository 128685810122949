# Minicalendar (not minical widget, but minicalendar embeds in our view) related javascript

namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  # Render a javascript-based minicalendar
  #
  # By default, this only renders the calendar rows themselves, and not any
  # header or footer.  Each date will be linked to linkTemplate.
  #
  # Looks for various data-minicalendar attributes inside container for the various
  # functions:
  #   "true" => container
  #   "previous" => previous month link
  #   "next" => next month link
  #   "month-label" => label for currently displayed month
  #   "calendar" => container for calendar rows
  #
  # Options can be set with various data attributes on the container:
  #   data-minicalendar-initial => start date (YYYY-MM-DD)
  #   data-minicalendar-today => today's date (YYYY-MM-DD)
  #   data-minicalendar-render-initial => render the initial calendar (defaults to off)
  #   data-minicalendar-start-day => start day of week (0=sunday, 1=monday, etc.)
  #   data-minicalendar-link-template => URL template for each day's link, with @YEAR@ @MONTH@ @DAY@ placeholders
  #   data-minicalendar-class-prefix => prefix to add to all class names
  #   data-minicalendar-class-ITEM => various css classes
  #     ITEM can be: disabled, selected, active, past, today
  #   data-minicalendar-tag-week => tag name for week rows (default: TR)
  #   data-minicalendar-tag-day => tag name for day cell (default: TD)
  #   data-minicalendar-format-month => format for current month label
  #   data-minicalendar-format-day => format for date cell
  class exports.MiniCalendar
    default_options:
      # Outer container
      container: undefined
      
      # initial date
      initial: undefined

      # today's date
      today: new Date()

      # selected date
      selected: undefined

      # Render initial calendar
      renderInitial: false

      # template for each day's link URL, should have @YEAR@ @MONTH@ @DAY@ placeholders
      linkTemplate: '/calendar/@YEAR@/@MONTH@/@DAY@'

      # Start day of week (0=sunday, 1=monday, etc.)
      startDay: 0

      # Limit viewable range (months in either direction from today)
      dateLimit: 24

      # CSS class names
      classPrefix: ''
      disabledClass: 'disabled'
      selectedClass: 'selected'
      activeClass: 'active'
      pastClass: 'dimday' # days outside this month
      todayClass: 'today'

      # tag names
      tagWeek: 'tr'
      tagDay: 'td'

      # formats
      formatMonth: 'MMMM'
      formatMonthYear: 'MMMM yyyy'
      formatDay: 'd'

    # Initialize this instance. options specified override those found on the element
    constructor: (element, options={}) ->
      @element = $(element)

      @options = $.extend {}, @default_options, @_parseOptions(), options
      @options.initial or= @options.today
      @options.selected or= @options.today

      # set dates
      @options.today = new Date(@options.today) unless typeof(@options.today) == 'object'
      @options.initial = new Date(@options.initial) unless typeof(@options.initial) == 'object'
      @options.selected = new Date(@options.selected) unless typeof(@options.selected) == 'object'

      @currentDate = new Date(@options.initial)
      @minimumDate = @currentDate.clone().add(months: -@options.dateLimit)
      @maximumDate = @currentDate.clone().add(months: @options.dateLimit)

      @_updateNavLabels()
      @_attachEvents()

    # Change the currently displayed month (change is how many months to change forward or back)
    changeMonth: (change) =>
      @currentDate.addMonths change
      @_render()
      false

    _attachEvents: =>
      @element.on 'click', '[data-minicalendar="next"]', (evt) =>
        @changeMonth(1) unless $(evt.target).hasClass('disabled')
        evt.preventDefault()
        
      @element.on 'click', '[data-minicalendar="previous"]', (evt) =>
        @changeMonth(-1) unless $(evt.target).hasClass('disabled')
        evt.preventDefault()

    # Rerender the calendar
    _render: =>
      calendar_html = []

      # get first and last dates of this month...
      first = @currentDate.clone().set day: 1
      last = first.clone().add(months: 1).add(days: -1)

      # ...and adjust them to stretch to a full week
      dow = first.getDay()
      first = first.add(days: -dow)
      last.add days: 6 - last.getDay()

      # Loop over and do the work
      current = first.clone()
      while !current.isAfter(last)
        # Week begin
        calendar_html.push "<#{@options.tagWeek}>" if current.getDay() == 0

        # The space here keeps datejs from evaluating "d" as a shortcut for m/d/Y
        label = current.toString(" " + @options.formatDay)

        # Build link
        url = @options.linkTemplate.replace(/@YEAR@/, current.getFullYear())
        url = url.replace(/@DAY@/, current.getDate())
        url = url.replace(/@MONTH@/, current.getMonth() + 1)

        content = "<a href=\"#{url}\">#{label}</a>"

        classes = []
        classes.push @options.pastClass if current.getMonth() != @currentDate.getMonth()
        # classes.push "#{@options.classPrefix}#{@options.activeClass}" if current.getMonth() != first.getMonth()
        classes.push @options.todayClass if current.equals(@options.today)
        classes.push @options.selectedClass if current.equals(@options.selected)

        calendar_html.push "<#{@options.tagDay} class=\"#{classes.join(' ')}\">#{content}</#{@options.tagDay}>"
        
        # week end
        calendar_html.push "</#{@options.tagWeek}>" if current.getDay() == 6

        current.add days: 1

      # Update DOM
      month_label = if @currentDate.getFullYear() != @options.today.getFullYear()
        @currentDate.toString(@options.formatMonthYear)
      else
        @currentDate.toString(@options.formatMonth)

      @element.find('[data-minicalendar="month-label"]').html(month_label)
      @element.find('[data-minicalendar="calendar"]').html(calendar_html.join(''))

      # And the enabled state of the nav buttons, as well as accessibility labels
      @_updateNavLabels()

      @element.find('[data-minicalendar="previous"]')
        .toggleClass('disabled', !@currentDate.isAfter(@minimumDate))
      @element.find('[data-minicalendar="next"]')
        .toggleClass('disabled', !@currentDate.isBefore(@maximumDate))

    # Update minicalendar navigation accessibility labels
    _updateNavLabels: =>
      previous_month = @currentDate.clone().add(months: -1)
      next_month = @currentDate.clone().add(months: 1)
      @element.find('[data-minicalendar="previous"]')
        .find('.sr-only').html("Switch to #{previous_month.toString("MMMM yyyy")}")
      @element.find('[data-minicalendar="next"]')
        .find('.sr-only').html("Switch to #{next_month.toString("MMMM yyyy")}")


    # Parse options from data attributes
    _parseOptions: =>
      data = @element.data()
      options = {}

      # attributes
      for item in [
        ['initial',         'Initial'],
        ['today',           'Today'],
        ['renderInitial',   'RenderInitial'],
        ['startDay',        'StartDay'],
        ['linkTemplate',    'LinkTemplate'],
        ['tagWeek',         'TagWeek'],
        ['tagDay',          'TagDay'],
        ['formatMonth',     'FormatMonth'],
        ['formatMonthYear', 'FormatMonthYear'],
        ['formatDay',       'FormatDay']
      ]
        option_name = item[0]
        data_name = item[1]
        options[option_name] = data["minicalendar#{data_name}"] if data["minicalendar#{data_name}"]?

      # classes
      for name, i in ['Prefix', 'Disabled', 'Selected', 'Active', 'Past', 'Today']
        options["class#{name}"] = data["minicalendarClass#{name}"] if data["minicalendarClass#{name}"]

      options

  # Activate any minicalendars in page on ready
  $ -> $('[data-minicalendar="true"]').each ->
    new exports.MiniCalendar(this)
