modulejs.define 'registration/components/editor',
  ['react', 'react-dom', 'react-redux', 'react-dnd', 'react-dnd-html5-backend', 'reselect', 'underscore', 'registration/store/actions',
   'slzr/react/form_row', 'slzr/react/form_input_group', 'slzr/react/form_header', 'slzr/react/json_blob',
   'registration/components/ticket_group', 'registration/components/ticket_list', 'registration/components/promo_code_list',
   'registration/components/attendee_question_list'],
  (React, ReactDOM, ReactRedux, ReactDnD, ReactDnDHTML5Backend, Reselect, _, Actions,
   FormRow, FormInputGroup, FormHeader, JsonBlob,
   TicketGroup, TicketList, PromoCodeList,
   AttendeeQuestionList) ->

    class Editor extends React.Component
      constructor: (props) ->
        super props

        # Set up initial state
        this.state =
          # List of IDs of ticket editors that are opened
          openedTickets: []
          # List of IDs of ticket group editors that are opened
          openedTicketGroups: []
          # List of IDs of promo code editors that are opened
          openedPromoCodes: []
          # List of IDs of question editors that are opened
          openedAttendeeQuestions: []
          # Enable debugging
          debug: false
          # Records whether the element is actually visible in the browser via IntersectionObserver
          # see _createIntersectionObserver
          isElementVisible: true
          # The HTML to mirror when the component is not visible "hidden"
          hiddenHTML: null

        this._createIntersectionObserver()

      # This is the basis of a workaround for the editor breaking photo upload by dragging a file in
      # on the admin event editor
      #
      # The drag/drop components used inside this Editor capture events at too wide, covering the entire page,
      # and prevent the photo component (which is an entirely parallel React tree) from receiving a drop event.
      #
      # This works around it by hiding the actual ticket editor when it is not visible to the user, by monitoring
      # an IntersectionObserver to determine when it is visible.
      #
      # When the editor is hidden, a HTML-only copy of the editor markup is rendered, rather than real React markup.
      # This prevents a page "jump" when switching in Chrome and Firefox because the tab visibility switch occurs before
      # React re-renders the component with contents.
      #
      # In browsers that don't support IntersectionObserver (notably, Safari older than 12.1 and IE), this workaround is
      # not activated and upload by dragging is still broken.
      #
      # See also render and renderHidden
      _createIntersectionObserver: =>
        # Create the intersection observer to observe for visibility changes of the component.
        # The element to monitor is managed through componentDidMount/componentWillUnmount
        if typeof IntersectionObserver != 'undefined'
          this._intersectionObserver = new IntersectionObserver (entries) =>
            if entries[0].intersectionRatio > 0
              # Our element is now visible
              this.setState
                isElementVisible: true
                hiddenHTML: null
            else
              # Our element is now hidden, so capture the HTML that was displayed to use as a dummy copy.
              element = ReactDOM.findDOMNode(this)
              this.setState
                isElementVisible: false
                hiddenHTML: element?.innerHTML




      # Register for updates on our element's visibility
      componentDidMount: =>
        element = ReactDOM.findDOMNode(this)
        this._intersectionObserver?.observe(element) if element

      # Clean up observer registration
      componentWillUnmount: =>
        element = ReactDOM.findDOMNode(this)
        this._intersectionObserver?.unobserve(element) if element



      onTicketChanged: (ticket_id, name, value, event) =>
        this.props.ticketChanged(ticket_id, name, value)

      onTicketDeleted: (ticket_id, event) =>
        this.props.removeTicketClass(ticket_id)

      onAddTicketClass: (event) =>
        new_ticket = this.props.addTicketClass()
        @setState openedTickets: [new_ticket.payload.id]
        event.preventDefault()

      onTicketClassDragStart: (item) =>
        this.props.ticketClassDragStart(item)

      onTicketClassDragFinish: (item) =>
        this.props.ticketClassDragFinish(item)

      onTicketClassDragUpdate: (item, target_id, target_position, target_group) =>
        this.props.ticketClassDragUpdate(item, target_id, target_position, target_group)

      onTicketClassDragCancel: (item) =>
        this.props.ticketClassDragCancel(item)

      # Toggle the visible state of the editor for +ticket_id+
      onToggleTicketEditor: (ticket_id, state=undefined) =>
        if state != true && this.state.openedTickets.indexOf(ticket_id) > -1
          # Close it
          new_opened_tickets = _.reject this.state.openedTickets, (i) -> i == ticket_id
        else
          # Open it
          new_opened_tickets = this.state.openedTickets.slice()
          new_opened_tickets.push(ticket_id)

        this.setState openedTickets: new_opened_tickets


      onTicketGroupChanged: (ticket_group_id, name, value, event) =>
        this.props.ticketGroupChanged(ticket_group_id, name, value)

      onTicketGroupDeleted: (ticket_group_id, event) =>
        this.props.removeTicketGroup(ticket_group_id)

      onAddTicketGroup: (event) =>
        new_ticket_group = this.props.addTicketGroup()
        @setState openedTicketGroups: [new_ticket_group.payload.id]
        event.preventDefault()

      onTicketGroupDragStart: (item) =>
        this.props.ticketGroupDragStart(item)

      onTicketGroupDragFinish: (item) =>
        this.props.ticketGroupDragFinish(item)

      onTicketGroupDragUpdate: (item, target_position) =>
        this.props.ticketGroupDragUpdate(item, target_position)

      onTicketGroupDragCancel: (item) =>
        this.props.ticketGroupDragCancel(item)

      # Toggle the visible state of the editor for +ticket_group_id+
      onToggleTicketGroupEditor: (ticket_group_id, state=undefined) =>
        if state != true && this.state.openedTicketGroups.indexOf(ticket_group_id) > -1
          # Close it
          new_opened_ticket_groups = _.reject this.state.openedTicketGroups, (i) -> i == ticket_group_id
        else
          # Open it
          new_opened_ticket_groups = this.state.openedTicketGroups.slice()
          new_opened_ticket_groups.push(ticket_group_id)

        this.setState openedTicketGroups: new_opened_ticket_groups


      onPromoCodeChanged: (promo_code_id, name, value, event) =>
        this.props.promoCodeChanged(promo_code_id, name, value)

      onPromoCodeDeleted: (promo_code_id, event) =>
        this.props.removePromoCode(promo_code_id)

      onAddPromoCode: (event) =>
        new_promo_code = this.props.addPromoCode()
        @setState openedPromoCodes: [new_promo_code.payload.id]
        event.preventDefault()

      onPromoCodeAddTicketClass: (promo_code_id, ticket_class_id) =>
        this.props.addPromoCodeTicketClass(promo_code_id, ticket_class_id)

      onPromoCodeRemoveTicketClass: (promo_code_id, ticket_class_id) =>
        this.props.removePromoCodeTicketClass(promo_code_id, ticket_class_id)

      # Toggle the visible state of the editor for +promo_code_id+
      onTogglePromoCodeEditor: (promo_code_id, state=undefined) =>
        if state != true && this.state.openedPromoCodes.indexOf(promo_code_id) > -1
          # Close it
          newOpenedPromoCodes = _.reject this.state.openedPromoCodes, (i) -> i == promo_code_id
        else
          # Open it
          newOpenedPromoCodes = this.state.openedPromoCodes.slice()
          newOpenedPromoCodes.push(promo_code_id)

        this.setState openedPromoCodes: newOpenedPromoCodes


      onAttendeeQuestionChanged: (question_id, name, value, event) =>
        this.props.attendeeQuestionChanged(question_id, name, value)

      onAttendeeQuestionDeleted: (question_id, event) =>
        this.props.removeAttendeeQuestion(question_id)

      onAddAttendeeQuestion: (event) =>
        new_question = this.props.addAttendeeQuestion()
        @setState openedAttendeeQuestions: [new_question.payload.id]
        event.preventDefault()
      
      onAttendeeQuestionChoiceAdd: (question_id) =>
        event.preventDefault()
        this.props.addAttendeeQuestionChoice(question_id)
      
      onAttendeeQuestionChoiceChange: (question_id, index, value, event) =>
        this.props.changeAttendeeQuestionChoice(question_id, index, value)
      
      onAttendeeQuestionChoiceDelete: (question_id, index) =>
        this.props.removeAttendeeQuestionChoice(question_id, index)

      onAttendeeQuestionChoiceDragStart: (question_id, item) =>
        this.props.attendeeQuestionChoiceDragStart(question_id, item)

      onAttendeeQuestionChoiceDragUpdate: (question_id, item, index) =>
        this.props.attendeeQuestionChoiceDragUpdate(question_id, item, index)

      onAttendeeQuestionChoiceDragFinish: (question_id, result, item) =>
        this.props.attendeeQuestionChoiceDragFinish(question_id, result, item)

      onAttendeeQuestionChoiceDragCancel: (question_id, item) =>
        this.props.attendeeQuestionChoiceDragCancel(question_id, item)

      # Toggle the visible state of the editor for +attendee_question_id+
      onToggleQuestionEditor: (attendee_question_id, state=undefined) =>
        if state != true && this.state.openedAttendeeQuestions.indexOf(attendee_question_id) > -1
          # Close it
          newOpenedQuestions = _.reject this.state.openedAttendeeQuestions, (i) -> i == attendee_question_id
        else
          # Open it
          newOpenedQuestions = this.state.openedAttendeeQuestions.slice()
          newOpenedQuestions.push(attendee_question_id)

        this.setState openedAttendeeQuestions: newOpenedQuestions

      onAttendeeQuestionDragStart: (item) =>
        this.props.attendeeQuestionDragStart(item)

      onAttendeeQuestionDragFinish: (item) =>
        this.props.attendeeQuestionDragFinish(item)

      onAttendeeQuestionDragUpdate: (item, target_position) =>
        this.props.attendeeQuestionDragUpdate(item, target_position)

      onAttendeeQuestionDragCancel: (item) =>
        this.props.attendeeQuestionDragCancel(item)


      # Render the editor appropriately, based on the element's visibility
      render: ->
        if this.state.isElementVisible
          this.renderEditor()
        else
          this.renderHidden()

      # Render the hidden version of the editor, which is the previously-rendered editor's HTML only, without
      # any event handlers.
      #
      # This must use dangerouslySetInnerHTML in order for the HTML to be rendered as HTML and not escaped text.
      renderHidden: -> `<div className="ticket-editor--editor" dangerouslySetInnerHTML={{__html: this.state.hiddenHTML}} />`

      # Render the editor in its normal, visible state.
      renderEditor: ->
        default_group_table = `<TicketGroup key="default"
                                            debug={this.state.debug}
                                            header={false}
                                            tableHeader={true}
                                            canMove={false}
                                            canRemove={false}
                                            tickets={this.props.groupedTickets['default']}
                                            haveNoTickets={this.props.haveNoTickets}
                                            openedEditors={this.state.openedTickets}
                                            openedGroupEditors={this.state.openedTicketGroups}
                                            settings={this.props.settings}
                                            onToggleTicketEditor={this.onToggleTicketEditor}
                                            onToggleTicketGroupEditor={this.onToggleTicketGroupEditor}
                                            onAddTicketClass={this.onAddTicketClass}
                                            onTicketChange={this.onTicketChanged}
                                            onTicketDelete={this.onTicketDeleted}
                                            onTicketDragStart={this.onTicketClassDragStart}
                                            onTicketDragFinish={this.onTicketClassDragFinish}
                                            onTicketDragUpdate={this.onTicketClassDragUpdate}
                                            onTicketDragCancel={this.onTicketClassDragCancel}
                                            onGroupChange={this.onTicketGroupChanged}
                                            onGroupDelete={this.onTicketGroupDeleted}
                                            onGroupDragStart={this.onTicketGroupDragStart}
                                            onGroupDragFinish={this.onTicketGroupDragFinish}
                                            onGroupDragUpdate={this.onTicketGroupDragUpdate}
                                            onGroupDragCancel={this.onTicketGroupDragCancel} />`

        group_tables = [default_group_table].concat (
          for group in this.props.ticketGroups
            `<TicketGroup key={group.id}
                          debug={this.state.debug}
                          header={true}
                          tableHeader={false}
                          ticketGroup={group}
                          tickets={this.props.groupedTickets[group.id]}
                          openedEditors={this.state.openedTickets}
                          showGroupEditor={this.state.openedTicketGroups.indexOf(group.id) > -1}
                          settings={this.props.settings}
                          onToggleTicketEditor={this.onToggleTicketEditor}
                          onToggleTicketGroupEditor={this.onToggleTicketGroupEditor}
                          onTicketChange={this.onTicketChanged}
                          onTicketDelete={this.onTicketDeleted}
                          onTicketDragStart={this.onTicketClassDragStart}
                          onTicketDragFinish={this.onTicketClassDragFinish}
                          onTicketDragUpdate={this.onTicketClassDragUpdate}
                          onTicketDragCancel={this.onTicketClassDragCancel}
                          onGroupChange={this.onTicketGroupChanged}
                          onGroupDelete={this.onTicketGroupDeleted}
                          onGroupDragStart={this.onTicketGroupDragStart}
                          onGroupDragFinish={this.onTicketGroupDragFinish}
                          onGroupDragUpdate={this.onTicketGroupDragUpdate}
                          onGroupDragCancel={this.onTicketGroupDragCancel} />`
        )

        `<div className="ticket-editor--editor">
          <FormInputGroup>
            <FormHeader title="Tickets" labelFor="#invalid">
              <div className="action_button pull-right">
                <button rel="tooltip" title="Use separators to organize different categories of tickets"
                        className="btn btn-color" onClick={this.onAddTicketGroup}><i className="localist-icon-add-small"
                                                                                     aria-label="Add" /> Ticket Type
                </button>
                {' '}
                <button className="btn btn-color" onClick={this.onAddTicketClass}><i className="localist-icon-add-small"
                                                                                     aria-label="Add" /> Ticket
                </button>
              </div>
            </FormHeader>
          </FormInputGroup>

          <fieldset className="ticket-groups-wrapper">
            {group_tables}
          </fieldset>

          <FormInputGroup>
            <FormHeader title="Promo Codes" className="" labelFor="#invalid">
              <div className="action_button pull-right">
                <button className="btn btn-color" onClick={this.onAddPromoCode}><i className="localist-icon-add-small"
                                                                                   aria-label="Add" /> Promo Code
                </button>
              </div>
            </FormHeader>
          </FormInputGroup>

          <PromoCodeList promoCodes={this.props.promoCodes}
                         settings={this.props.settings}
                         availableTicketClasses={this.props.availableTicketClasses}
                         openedEditors={this.state.openedPromoCodes}
                         onChange={this.onPromoCodeChanged}
                         onDelete={this.onPromoCodeDeleted}
                         onToggleEditor={this.onTogglePromoCodeEditor}
                         onAddPromoCode={this.onAddPromoCode}
                         onAddTicketClass={this.onPromoCodeAddTicketClass}
                         onRemoveTicketClass={this.onPromoCodeRemoveTicketClass} />

          <FormInputGroup>
            <FormHeader title="Attendee Questions" className="" labelFor="#invalid">
              <div className="action_button pull-right">
                <button className="btn btn-color" onClick={this.onAddAttendeeQuestion}><i
                  className="localist-icon-add-small" aria-label="Add" /> Attendee Question
                </button>
              </div>
            </FormHeader>
          </FormInputGroup>

          <AttendeeQuestionList defaultQuestions={this.props.defaultQuestions}
                                questions={this.props.questions}
                                settings={this.props.settings}
                                openedEditors={this.state.openedAttendeeQuestions}
                                onDragStart={this.onAttendeeQuestionDragStart}
                                onDragUpdate={this.onAttendeeQuestionDragUpdate}
                                onDragFinish={this.onAttendeeQuestionDragFinish}
                                onDragCancel={this.onAttendeeQuestionDragCancel}
                                onToggleEditor={this.onToggleQuestionEditor}
                                onAddAttendeeQuestion={this.onAddAttendeeQuestion}
                                onChange={this.onAttendeeQuestionChanged}
                                onDelete={this.onAttendeeQuestionDeleted}
                                onChoiceAdd={this.onAttendeeQuestionChoiceAdd}
                                onChoiceChange={this.onAttendeeQuestionChoiceChange}
                                onChoiceDelete={this.onAttendeeQuestionChoiceDelete}
                                onChoiceDragStart={this.onAttendeeQuestionChoiceDragStart}
                                onChoiceDragUpdate={this.onAttendeeQuestionChoiceDragUpdate}
                                onChoiceDragFinish={this.onAttendeeQuestionChoiceDragFinish}
                                onChoiceDragCancel={this.onAttendeeQuestionChoiceDragCancel} />

          <JsonBlob name="ticket_data[ticket_groups]" value={this.props.allTicketGroups} />
          <JsonBlob name="ticket_data[ticket_classes]" value={this.props.allTickets} />
          <JsonBlob name="ticket_data[promo_codes]" value={this.props.allPromoCodes} />
          <JsonBlob name="ticket_data[attendee_questions]" value={this.props.allQuestions} />
        </div>`

    selectors =
      ticketGroups: (state) -> state.ticket_groups
      ticketClasses: (state) -> state.ticket_classes
      promoCodes: (state) -> state.promo_codes
      attendeeQuestions: (state) -> state.attendee_questions
      defaultAttendeeQuestions: (state) -> state.default_attendee_questions
      settings: (state) -> state.settings

    # Fee settings
    selectors.feeSettings = Reselect.createSelector selectors.settings, (settings) -> settings.fees

    # Not-deleted ticket classes
    selectors.notDeletedTicketClasses = Reselect.createSelector selectors.ticketClasses, (ticketClasses) ->
      _.reject ticketClasses, {delete: true}

    # Not-deleted ticket groups
    selectors.notDeletedTicketGroups = Reselect.createSelector selectors.ticketGroups, (ticketGroups) ->
      _.reject ticketGroups, {delete: true}

    selectors.sortedTicketGroups = Reselect.createSelector selectors.notDeletedTicketGroups, (ticketGroups) ->
      _.sortBy ticketGroups, 'position'

    # Not deleted promo codes
    selectors.notDeletedPromoCodes = Reselect.createSelector selectors.promoCodes, (promoCodes) ->
      _.reject promoCodes, {delete: true}

    # Not deleted attendee questions
    selectors.notDeletedAttendeeQuestions = Reselect.createSelector selectors.attendeeQuestions, (questions) ->
      _.reject questions, {delete: true}

    # Group the available ticket classes into a default group, and one for each ticket group defined
    #
    # Returns {group_id: [ticket_classes]}
    selectors.ticketsByGroup = Reselect.createSelector selectors.notDeletedTicketGroups, selectors.notDeletedTicketClasses, (ticketGroups, ticketClasses) ->
      result = {default: []}
      tickets_by_id = _.indexBy(ticketClasses, 'id')

      # Defined groups
      for group in ticketGroups
        result[group.id] = _.compact(tickets_by_id[ticket_id] for ticket_id in group.ticket_class_ids)
        result[group.id] = _.sortBy(result[group.id], 'position')

      result['default'] = _.sortBy _.select(ticketClasses, (ticket_class) -> !ticket_class.ticket_group_id), 'position'
      result

    selectors.sortedAttendeeQuestions = Reselect.createSelector selectors.notDeletedAttendeeQuestions, (questions) ->
      _.sortBy questions, 'position'

    # True if there's no ticket classes or groups defined
    selectors.haveNoTickets = Reselect.createSelector selectors.notDeletedTicketClasses, selectors.notDeletedTicketGroups,
      (ticket_classes, ticket_groups) ->
        ticket_classes.length == 0 && ticket_groups.length == 0


    mapStateToProps = (state) ->
      allTickets: selectors.ticketClasses(state)
      availableTicketClasses: selectors.notDeletedTicketClasses(state)
      groupedTickets: selectors.ticketsByGroup(state)
      ticketGroups: selectors.sortedTicketGroups(state)
      allTicketGroups: selectors.ticketGroups(state)
      promoCodes: selectors.notDeletedPromoCodes(state)
      allPromoCodes: selectors.promoCodes(state)
      questions: selectors.sortedAttendeeQuestions(state)
      defaultQuestions: selectors.defaultAttendeeQuestions(state)
      allQuestions: selectors.attendeeQuestions(state)
      settings: selectors.settings(state)
      haveNoTickets: selectors.haveNoTickets(state)


    mapDispatchToProps = (dispatch) ->
      ticketChanged: (ticket_id, name, value) -> dispatch Actions.changeTicketClass id: ticket_id, name: name, value: value
      addTicketClass: () -> dispatch Actions.addTicketClass()
      removeTicketClass: (ticket_id) -> dispatch Actions.removeTicketClass id: ticket_id

      ticketClassDragStart: (item) -> dispatch Actions.ticketClassDragStart item
      ticketClassDragFinish: (item) -> dispatch Actions.ticketClassDragFinish item
      ticketClassDragCancel: (item) -> dispatch Actions.ticketClassDragCancel item
      ticketClassDragUpdate: (item, target_position, target_group_id) ->
        dispatch Actions.ticketClassDragUpdate {item, target_position, target_group_id}

      ticketGroupChanged: (ticket_group_id, name, value) -> dispatch Actions.changeTicketGroup id: ticket_group_id, name: name, value: value
      addTicketGroup: () -> dispatch Actions.addTicketGroup()
      removeTicketGroup: (ticket_group_id) -> dispatch Actions.removeTicketGroup id: ticket_group_id

      ticketGroupDragStart: (item) -> dispatch Actions.ticketGroupDragStart item
      ticketGroupDragFinish: (item) -> dispatch Actions.ticketGroupDragFinish item
      ticketGroupDragCancel: (item) -> dispatch Actions.ticketGroupDragCancel item
      ticketGroupDragUpdate: (item, target_position) ->
        dispatch Actions.ticketGroupDragUpdate {item, target_position}

      promoCodeChanged: (promo_code_id, name, value) -> dispatch Actions.changePromoCode id: promo_code_id, name: name, value: value
      addPromoCode: () -> dispatch Actions.addPromoCode()
      removePromoCode: (promo_code_id) -> dispatch Actions.removePromoCode id: promo_code_id
      addPromoCodeTicketClass: (promo_code_id, ticket_class_id) -> dispatch Actions.promoCodeAddTicketClass id: promo_code_id, ticket_class_id: ticket_class_id
      removePromoCodeTicketClass: (promo_code_id, ticket_class_id) -> dispatch Actions.promoCodeRemoveTicketClass id: promo_code_id, ticket_class_id: ticket_class_id

      attendeeQuestionChanged: (question_id, name, value) -> dispatch Actions.changeAttendeeQuestion id: question_id, name: name, value: value
      addAttendeeQuestion: () -> dispatch Actions.addAttendeeQuestion()
      removeAttendeeQuestion: (question_id) -> dispatch Actions.removeAttendeeQuestion id: question_id

      attendeeQuestionDragStart: (item) -> dispatch Actions.attendeeQuestionDragStart item
      attendeeQuestionDragFinish: (item) -> dispatch Actions.attendeeQuestionDragFinish item
      attendeeQuestionDragCancel: (item) -> dispatch Actions.attendeeQuestionDragCancel item
      attendeeQuestionDragUpdate: (item, target_position) ->
        dispatch Actions.attendeeQuestionDragUpdate {item, target_position}
      
      addAttendeeQuestionChoice: (question_id) -> dispatch Actions.addAttendeeQuestionChoice question_id: question_id
      changeAttendeeQuestionChoice: (question_id, index, value) -> dispatch Actions.changeAttendeeQuestionChoice question_id: question_id, index: index, value: value
      removeAttendeeQuestionChoice: (question_id, index) -> dispatch Actions.removeAttendeeQuestionChoice question_id: question_id, index: index
      attendeeQuestionChoiceDragStart: (question_id, item) -> dispatch Actions.attendeeQuestionChoiceDragStart question_id: question_id, item: item
      attendeeQuestionChoiceDragUpdate: (question_id, item, index) -> dispatch Actions.attendeeQuestionChoiceDragUpdate question_id: question_id, item: item, index: index
      attendeeQuestionChoiceDragFinish: (question_id, result, item) -> dispatch Actions.attendeeQuestionChoiceDragFinish question_id: question_id, item: item, result: result
      attendeeQuestionChoiceDragCancel: (question_id, item) -> dispatch Actions.attendeeQuestionChoiceDragCancel question_id: question_id, item: item

    # Mark it as a drag-drop context
    Editor = ReactDnD.DragDropContext(ReactDnDHTML5Backend)(Editor)

    # and connect it to Redux
    ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Editor)