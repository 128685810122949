# Message list support stuff

namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  class exports.MessageList
    constructor: (options) ->
      defaults =
        # URL to ping to mark an item as read (null disables)
        setReadURL: null
        # Container selector or element of item listings
        container: null
        # Support ajax replacement of container
        isAjaxed: false

      @options = $.extend({}, defaults, options)
      @container = $(@options.container)
      @selector = "##{@container[0].id} *"

      @container.on 'click.MessageList', 'h3 a[data-action="expand"]', (ev) => @messageListClicked(ev)

    bodyClicked: (ev) ->
      el = $(ev.target)

      if el.is(@selector)
        return @messageListClicked(ev)

      true

    messageListClicked: (ev) ->
      el = $(ev.target)

      message_item = if el.hasClass('manage_item') then el else el.closest('div.manage_item')
      return unless message_item

      # Toggle expanded state
      if message_item.hasClass('expanded')
        @collapseItem message_item
      else
        @container
          .find('div.manage_item.expanded')
          .each (i, el) => @collapseItem el

        @expandItem message_item

      false

    setReadState: (id) ->
      if @options.setReadURL
        $.post(@options.setReadURL, {id: id})

    showActionButtons: (el) ->
      message_el = $(el).closest('div.manage_item')
      message_el.find('div.action_buttons.form_trigger').show()

    hideActionButtons: (el) ->
      message_el = $(el).closest('div.manage_item')
      message_el.find('div.action_buttons.form_trigger').hide()

    showReply: (el) ->
      message_el = $(el).closest('div.manage_item')
      @hideForward el
      @hideActionButtons el
      $('form.reply_form', message_el).show()

    hideReply: (el) ->
      $(el).closest('div.manage_item').find('form.reply_form').hide()

    showForward: (el) ->
      message_el = $(el).closest('div.manage_item')
      @hideReply el
      @hideActionButtons el
      $('form.forward_form', message_el).show()

    hideForward: (el) ->
      $(el).closest('div.manage_item').find('form.forward_form').hide()

    cancelEdit: (el) ->
      @hideForward el
      @hideReply el
      @showActionButtons el

    cancelCompose: (el) ->
      $('#x-compose-form')
        .hide()
        .prevAll('.link_action')
        .show()
      @clearCompose()

    composeMessage: (el) ->
      $('#x-compose-form')
        .show()
        .prevAll('.link_action')
        .hide()

    clearCompose: ->
      $('#x-compose-form').find('select, textarea, input[type="text"]').val('')

    expandItem: (message_item) ->
      $message_item = $(message_item)

      $('div.details, img.icon_small', message_item).show()
      $('img.icon_tiny, .preview_text', message_item).hide()
      $message_item.addClass('expanded')
      @setReadState($message_item.attr('id')) if $message_item.hasClass('unread') or $message_item.find('.unread').length > 0
      $message_item.removeClass('unread')
      $('.unread', message_item).removeClass('unread')

    collapseItem: (message_item) ->
      $('div.details, img.icon_small', message_item).hide()
      $('img.icon_tiny, .preview_text', message_item).show()
      $(message_item).removeClass('expanded')
