modulejs.define 'registration/components/attendee_question_form',
  ['react', 'react-redux', 'prop-types'
   'registration/store/actions', 'registration/utilities', 'slzr/react/form_row', 'slzr/react/form_input_group', 'slzr/react/form_theme_context',
   'slzr/react/form_header', 'slzr/components/date_input_row', 'registration/components/attendee_question_choice_list'],
  (React, ReactRedux, PropTypes,
   Actions, Utilities, FormRow, FormInputGroup, FormThemeContext,
   FormHeader, DateInputRow, AttendeeQuestionChoiceList) ->

    class AttendeeQuestionForm extends React.Component
      @contextType: FormThemeContext

      @propTypes:
        question: Actions.AttendeeQuestionShape
        # Callback when an input field changes
        onChange: PropTypes.func
        # Callback for the "Update" button, which just closes the editor
        onClose: PropTypes.func
        # Callback for adding a choice
        onChoiceAdd: PropTypes.func
        # Callback for changing a choice
        onChoiceChange: PropTypes.func
        # Callback for choice drag start
        onChoiceDragStart: PropTypes.func
        # Callback for choice drag update
        onChoiceDragUpdate: PropTypes.func
        # Callback for choice drag finish
        onChoiceDragFinish: PropTypes.func
        # Callback for choice drag cancel
        onChoiceDragCancel: PropTypes.func

      onReponseTypeChange: (event) =>
        if event.target.value == "dropdown" && this.props.question.choices.length == 0
          this.props.onChoiceAdd?(this.props.question.id)
        this.onInputChange(event)

      onInputChange: (event, name, value) =>
        field_name = if name? then name else event.target.name
        field_value = if value? then value else if event.target.type == 'checkbox' then event.target.checked else event.target.value
        this.props.onChange?(field_name, field_value, event)

      # Returns a callback function to call onInputChange with the specified name
      inputChanged: (name) =>
        (event) => this.onInputChange(event, name)

      dateInputChanged: (event, value, input_value) =>
        if value == 'blank'
          new_value = ''
        else if value != 'error'
          new_value = value.toString('yyyy-MM-dd')

        this.onInputChange(event, event.target.name, new_value)

      isValid: =>
        return false if this.props.question.question.isBlank()
        true

      onChoiceChange: (index, event) =>
        question_id = this.props.question.id
        value = event.target.value
        this.props.onChoiceChange?(question_id, index, value, event)

      onChoiceAdd: =>
        this.props.onChoiceAdd?(this.props.question.id)

      onChoiceDelete: (index) =>
        this.props.onChoiceDelete?(this.props.question.id, index)

      onChoiceDragStart: (item) =>
        this.props.onChoiceDragStart?(this.props.question.id, item)

      onChoiceDragUpdate: (item, index) =>
        this.props.onChoiceDragUpdate?(this.props.question.id, item, index)

      onChoiceDragFinish: (result, item) =>
        this.props.onChoiceDragFinish?(this.props.question.id, item, result)

      onChoiceDragCancel: (item) =>
        this.props.onChoiceDragCancel?(this.props.question.id, item)


      render: ->
        wants_choice_list = this.props.question.response_type == 'dropdown'

        id_prefix = "attendee-question-#{this.props.question.id}"

        `<tr>
          <td className="edit-form" colSpan="5">
            <FormInputGroup>
              <FormRow title="Question"
                       labelFor={id_prefix + "-question"}
                       {...this.context.textInputRow}
                       required>
                <input name="question"
                       type="text"
                       id={id_prefix + "-question"}
                       className={this.context.textInputRow.inputClassName}
                       value={this.props.question.question}
                       onChange={this.onInputChange}
                       autoFocus />
              </FormRow>

              <FormRow title="Require Response"
                       tooltip="User must answer this to register successfully"
                       labelFor={id_prefix + "-required"}
                       {...this.context.checkboxRow}>
                <input name="required"
                       type="checkbox"
                       id={id_prefix + "-required"}
                       className={this.context.checkboxRow.inputClassName}
                       checked={this.props.question.required}
                       onChange={this.onInputChange} />
              </FormRow>

              <FormRow title="Question Type"
                       labelFor={id_prefix + "-response_type"}
                       {...this.context.selectInputRow}>
                <select name="response_type"
                        id={id_prefix + "-response_type"}
                        className={this.context.selectInputRow.inputClassName}
                        value={this.props.question.response_type}
                        onChange={this.onReponseTypeChange}>
                  <option value="string">Short Answer</option>
                  <option value="dropdown">Multiple Choice</option>
                </select>
              </FormRow>

              {wants_choice_list && <AttendeeQuestionChoiceList questionId={this.props.question.id}
                                                                choices={this.props.question.choices}
                                                                onAdd={this.onChoiceAdd}
                                                                onChange={this.onChoiceChange}
                                                                onDelete={this.onChoiceDelete}
                                                                onDragStart={this.onChoiceDragStart}
                                                                onDragUpdate={this.onChoiceDragUpdate}
                                                                onDragFinish={this.onChoiceDragFinish}
                                                                onDragCancel={this.onChoiceDragCancel}/>}

            </FormInputGroup>

            <div className="action-button pull-right">
              <button className="btn-color"
                      disabled={!this.isValid()}
                      onClick={this.props.onClose}>
                Update Question
              </button>
            </div>
          </td>
        </tr>`
