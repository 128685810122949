modulejs.define 'slzr/components/photo_chooser/library',
  ['react', 'prop-types', 'underscore'],
  (React, PropTypes, _) ->

    class Library extends React.Component
      @propTypes =
        # Library items
        libraryItems: PropTypes.array
        # Recent items
        recentItems: PropTypes.array
        # Flag indicating if the data is loading
        loading: PropTypes.bool
        # Handler when user requests closing library
        onClose: PropTypes.func
        # Handler when user selects an item
        onPhotoSelected: PropTypes.func

      constructor: (props) ->
        super(props)

        this.state =
          search: null

      onClose: (event) =>
        event.preventDefault()
        this.props.onClose?(event)

      onPhotoSelected: (photo, event) =>
        event.preventDefault()
        this.props.onPhotoSelected?(photo, event)

      onSearchChange: (event) =>
        this.setState search: event.target.value

      # Return a predicate function for matching items against the specified search term
      _searchFilter: (search_term) ->
        (item) ->
          # Match caption
          if String(item.caption).toLowerCase().indexOf(search_term) > -1
            return true

          # Match against tags
          _.any item.tags, (tag) -> String(tag).toLowerCase().indexOf(search_term) > -1

      render: ->
        recent_items = this.props.recentItems
        library_items = this.props.libraryItems

        if this.state.search && String(this.state.search).trim() != ''
          search_term = String(this.state.search).toLowerCase().trim()
          recent_items = _.filter recent_items, this._searchFilter(search_term)
          library_items = _.filter library_items, this._searchFilter(search_term)

        me = this
        cancel_button = `<div className="action_button">
          <button className="btn btn-color" onClick={this.onClose}>Cancel</button>
        </div>`

        if recent_items && recent_items.length > 0
          recent_header = `<div className="admin_header sub-heading">
            <span>Recent Items</span>
            {cancel_button}
          </div>`
          recent_item_elements = (`<img key={item.key}
                                        onClick={me.onPhotoSelected.bind(me, item)}
                                        src={item.thumb_url}
                                        width={item.thumb_width}
                                        height={item.thumb_height}/>` for item in recent_items)

        if library_items && library_items.length > 0
          library_header = `<div className="admin_header sub-heading">
            <span>Photo Library</span>
            {recent_items.length == 0 && cancel_button}
          </div>`
          library_item_elements = (`<img key={item.key}
                                         onClick={me.onPhotoSelected.bind(me, item)}
                                         src={item.thumb_url}
                                         width={item.thumb_width}
                                         height={item.thumb_height}/>` for item in library_items)

        status_message = if this.props.error
          "There was an error loading the library"
        else if this.props.loading
          "Loading..."
        else
          null

        if this.props.loading
          `<div className="no-photos">
            <div className="drop-target">
              <div className="drop-target-text">
                Loading...
              </div>
            </div>
          </div>`
        else if false && this.props.recentItems.length == 0 && this.props.libraryItems.length == 0
          `<div className="no-photos">
            <div className="drop-target">
              <div className="drop-target-text">
                Your recent photos are empty. Drag one here to add it.
                {' '/* jsx collapses spaces */}
                <button className="btn btn-color" onClick={this.onClose}>Go Back</button>
              </div>
            </div>
          </div>`
        else
          `//`# Search box, if desired:
          `//`#   <input type="search" onChange={this.onSearchChange} placeholder="Search photos" value={this.search} className="search-box" />
          `<div className="photo-library">
            <p>{status_message}</p>

            <div className="photo-library-list">
              {recent_header}
              <div className="photo-library-list-items">
                {recent_item_elements}
              </div>

              {library_header}
              <div className="photo-library-list-items">
                {library_item_elements}
              </div>
            </div>
          </div>`
