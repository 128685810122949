# Event View specific code

namespace 'Slzr.Page', (exports, top) ->
  $ = Slzr.jQuery

  class exports.EventView
    constructor: (event_id, is_over=false, has_ticket_info=false) ->
      $ ->
        $(document).on 'click', '.ticket_action a', =>
          # This defaults to the next instance, which is probably good enough
          $.get "/event/confirm?id=#{event_id}"

        if !is_over && has_ticket_info
          $(document).on 'click', '[data-action="going"], [data-action="event-going"]', ->
            openIbox '#x-ticket-lightbox' if $('#x-ticket-lightbox').get(0)

    # Activate on page load, if meta localist-page=event-view
    $ ->
      if Slzr.Meta.page == 'event-view'
        event_id = Number(Slzr.Meta['event-id'])
        event_is_over = Slzr.Meta['event-is-past']
        event_has_tickets = Slzr.Meta['event-has-tickets']
        if event_id > 0
          new Slzr.Page.EventView(event_id, event_is_over, event_has_tickets)