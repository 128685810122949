modulejs.define 'registration/components/ticket_list_item',
  ['underscore', 'react', 'react-dnd', 'prop-types', 'classnames', 'registration/constants', 'registration/utilities', 'registration/components/ticket_form'],
  (_, React, ReactDnD, PropTypes, classNames, Constants, Utilities, TicketForm) ->

    class TicketListItem extends React.Component
      @propTypes:
        onChange: PropTypes.func
        onDelete: PropTypes.func
        onToggleTicketEditor: PropTypes.func
        editorOpened: PropTypes.bool

      constructor: (props) ->
        super props

      toggleEditForm: (event) =>
        this.props.onToggleTicketEditor? this.props.ticket.id
        event.preventDefault()

      closeForm: (event) =>
        event.preventDefault()
        # Cause the editor to be closed
        this.props.onToggleTicketEditor? this.props.ticket.id, false

      formChange: (name, value, event) =>
        this.props.onChange?(this.props.ticket.id, name, value, event)

      deleteTicket: (event) =>
        event.preventDefault()
        this.props.onDelete?(this.props.ticket.id, event)

      render: ->
        ticket_form = if this.props.editorOpened
          `<TicketForm ticket={this.props.ticket}
                       settings={this.props.settings}
                       onClose={this.closeForm}
                       onChange={this.formChange} />`

        status = if this.props.ticket.active
          'Active'
        else
          'Inactive'

        classes = classNames this.props.className,
          'ticket-item-dragging': this.props.dragging

        price_details = if this.props.ticket.donation
          if this.props.ticket.max_price == null || this.props.ticket.max_price == 0
            "#{Utilities.formatCurrency(this.props.ticket.min_price, this.props.settings.currency)} + donation"
          else
            "#{Utilities.formatCurrency(this.props.ticket.min_price, this.props.settings.currency)} - #{Utilities.formatCurrency(this.props.ticket.max_price, this.props.settings.currency)}"

        else
          Utilities.formatCurrency(this.props.ticket.price, this.props.settings.currency)

        @props.connectDropTarget @props.connectDragPreview `<tbody>
          <tr className={classes}>
            <td width="41%">
              {this.props.connectDragSource(<i className="fa fa-bars drag-handle" aria-hidden="true"></i>)}
              {this.props.ticket.name}
              {this.props.debug && (
                <span>(#{this.props.ticket.id}, @{this.props.ticket.ticket_group_id},{this.props.ticket.position})</span>
              )}
            </td>
            <td width="13%">{status}</td>
            <td width="15%">
              {this.props.ticket.amount_sold || 0}
              {this.props.ticket.quantity_limit > 0 && ' / ' + (this.props.ticket.quantity_limit * this.props.settings.instance_count)}
            </td>
            <td width="20%">
              {price_details}
            </td>
            <td width="11%">
              <div className="text-right action_icon_button">
                <a href="#"
                   onClick={this.toggleEditForm}
                   title="Edit Ticket"
                   className="edit_button"><i className="localist-icon-edit-small"></i></a>
                <a href="#"
                   onClick={this.deleteTicket}
                   title="Remove Ticket"
                   className="deletex_button"><i className="localist-icon-trash-small"></i></a>
              </div>
            </td>
          </tr>
          {ticket_form}
        </tbody>`


    # Drag and Drop configuration
    #
    # This component can be dragged, and is also a drop target to point out the
    # desired location

    # Drag source configuration
    dragSpec =
      beginDrag: (props, monitor, component) ->
        item = { id: props.ticket.id }
        props.onDragStart?(item)
        item

      endDrag: (props, monitor, component) ->
        if monitor.didDrop()
          result = monitor.getDropResult()
          props.onDragFinish?(item: {id: props.ticket.id}, target_id: result.id, target_position: result.position, target_group_id: result.ticket_group_id)
        else
          props.onDragCancel?(id: props.ticket.id)

    dragCollect = (connect, monitor) ->
      isDragging: monitor.isDragging()
      connectDragSource: connect.dragSource()
      connectDragPreview: connect.dragPreview()

    # Drop target configuration
    dropSpec =
      drop: (props, monitor, component) =>
        {id: props.ticket.id, position: props.ticket.position, ticket_group_id: props.ticket.ticket_group_id}


      hover: (props, monitor, component) =>
        if monitor.canDrop() && monitor.isOver(shallow: true)
          item = monitor.getItem()
          if item.id != props.ticket.id
            props.onDragUpdate?(item, props.ticket.position, props.ticket.ticket_group_id)

    dropCollect = (connect, monitor) ->
      hovered: monitor.isOver()
      connectDropTarget: connect.dropTarget()

    TicketListItem = ReactDnD.DragSource(Constants.DRAG_TYPE_TICKET_CLASS, dragSpec, dragCollect)(TicketListItem)
    ReactDnD.DropTarget(Constants.DRAG_TYPE_TICKET_CLASS, dropSpec, dropCollect)(TicketListItem)