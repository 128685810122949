# Main EventReach engine

modulejs.define 'slzr/eventreach/engine', () ->

  # Return true if obj is empty
  isEmpty = (obj) ->
    [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length

  # Return true if the value is blank.
  #
  # A blank value is undefined, null, an empty string, an empty array, an empty object (no keys)
  # or a string composed entirely of spaces
  #
  # Roughly speaking, this is the same behavior as Rails' #blank?. Note that 0 is not blank.
  isBlank = (value) ->
    return true if value == undefined || value == null

    if typeof(value) == 'string'
      !!value.match(/^\s*$/)
    else if typeof(value) == 'number'
      false
    else if typeof(value) == 'boolean'
      !value
    else
      isEmpty(value)

  # @private
  #
  # Rule Evaluator
  #
  # Calls the `evaluate` function defined on the rule
  class RuleEvaluator
    # Rule configuration data
    #
    # @see EventReachEngine.rules
    rule: null

    # Platform-specific rule configuration settings
    platform_config: null

    # Evaluated score for this rule
    score: 0

    # Maximum score available for this rule
    max_score: 1

    # Result of evaluation status
    status: null

    # Is this rule required for completion
    required: true

    # Is this a pre-requiste rule (a rule that is required for the score to be generated)
    prereq: false

    # Is this rule hidden
    hidden: false

    # Initialize an instance of the RuleEvaluator
    #
    # @param rule [Object] The rule configuration data
    # @param platform_config [Object] Platform-specific rule configuration data
    #
    # Each rule has a type:
    # * `presence`: Checks for the presence of the specified field
    # * `presence_any`: Checks for the presence of at least one of the specified fields
    # * `custom`: Runs the function defined in +test+
    #
    # @option rule type [String] The type of rule this is, one of `presence`, `presence_any`, or `custom`
    # @option rule required [Boolean] `true` if this a required rule. An optional rule does not contribute
    #   to the resulting score. (default: `true`)
    # @option rule prereq [Boolean] `true` if this is a required rule for the score to be generated (default: `false`)
    # @option rule score [Number] The maximum number of points this rule contributes to the score. (default: `1`)
    # @option rule field [String] For `presence` rules, the attribute whose presence is checked
    # @option rule fields [Array<String>] For `presence_any` rules, the list of attributes of which at least
    #   one must be present.
    # @option rule condition [Function] Function that returns true if this rule should be evaluated as part
    #   of the run. Used to conditionally enable rules based on platform configuration.
    # @option rule evaluate [Function] For `custom` rules, the evaluation function to run. Expected to return
    #   an object `{score: N, status: "satisfied"|"failed"|"ignored"}` based on the results of the
    #   test. `N` is the number of points (out of the maximum) that were awarded.
    #
    # @example presence rule
    #   type: 'presence'
    #   field: 'field_1'
    #   required: true
    #   score: 1
    #
    # @example presence_any rule
    #   type: 'presence_any'
    #   fields: ['field_1', 'field_2']
    #
    # @example custom rule
    #   type: 'custom'
    #   evaluate: (event) ->
    #     if event.name.length <= 60
    #       score: 1, status: 'satisfied'
    #     else
    #       score: 0, status: 'failed'
    constructor: (rule, platform_config) ->
      @rule = rule
      @platform_config = platform_config
      @max_score = if @rule.score? then @rule.score else 1
      @required = if @rule.required? then @rule.required else true
      @prereq = if @rule.prereq? then @rule.prereq else false
      @hidden = if @rule.hidden? then @rule.hidden else false

    # Returns true if this rule is enabled
    #
    # @return [Boolean] true if the rule is enabled
    enabled: =>
      if @rule.condition?
        @rule.condition.call(@)
      else
        true

    # Evaluate this rule
    #
    # @param event_data [Object] The event data to evaluate the rule against
    # @return [Object] result with the following properties: `score`: number of points,
    #   `status`: one of `satisfied`, `partial`, `failed`, or `ignored` based on the success result
    evaluate: (event_data) =>
      @_setResult @rule.evaluate.call(@, event_data)

    # Set the result of this rule
    #
    # Returns the result itself
    _setResult: (result) =>
      @status = result.status
      switch result.status
        when 'satisfied', 'partial' then @score = result.score
        else @score = 0
      result

  # @private
  #
  # Evaluates a presence rule
  class PresenceRuleEvaluator extends RuleEvaluator
    # Field to check for presence of
    field: null

    # Initialize an instance of PresenceRuleEvaluator
    #
    # @throw [TypeError] if `field` is not specified in the rule
    constructor: (rule, platform_config) ->
      super(rule, platform_config)
      throw new TypeError("field must be specified for presence rules") unless rule.field?
      @field = rule.field

    # Check for the presence of `field` on the `event` data
    evaluate: (event_data) =>
      result = if isBlank(event_data[@field])
        # blank
        score: 0, status: if @required then 'failed' else 'ignored'
      else
        score: @max_score, status: 'satisfied'

      @_setResult result


  # @private
  #
  # Evaluates a presence_any rule
  class PresenceAnyRuleEvaluator extends RuleEvaluator
    # Field to check for presence of
    fields: null

    # Initialize an instance of PresenceAnyRuleEvaluator
    #
    # @throw [TypeError] if `fields` is not specified or is empty in the rule
    constructor: (rule, platform_config) ->
      super(rule, platform_config)

      if !rule.fields? || rule.fields.length == 0
        throw new TypeError("fields must be specified for presence_any rules")

      @fields = rule.fields

    # Check for the presence of at least one field in `fields` on the `event` data
    evaluate: (event_data) =>
      result = score: 0, status: if @required then 'failed' else 'ignored'

      for field in @fields
        result = {score: @max_score, status: 'satisfied'} unless isBlank(event_data[field])

      @_setResult result

  # @private
  #
  # Evaluates a presence_all rule
  class PresenceAllRuleEvaluator extends RuleEvaluator
    # Field to check for presence of
    fields: null

    # Initialize an instance of PresenceAnyRuleEvaluator
    #
    # @throw [TypeError] if `fields` is not specified or is empty in the rule
    constructor: (rule, platform_config) ->
      super(rule, platform_config)

      if !rule.fields? || rule.fields.length == 0
        throw new TypeError("fields must be specified for presence_all rules")

      @fields = rule.fields

    # Check for the presence of at least one field in `fields` on the `event` data
    evaluate: (event_data) =>
      result = score: @max_score, status: 'satisfied'

      for field in @fields
        result = {score: 0, status: if @required then 'failed' else 'ignored'} if isBlank(event_data[field])

      @_setResult result


  # EventReach calculation engine
  #
  # Given platform_config and event data, evaluates each rule and returns the score for
  # the given event.
  class EventReachEngine
    # List of rules to evaluate for the score
    #
    # Each key represents a specific rule. The key is returned in the evaluation
    # results, along with its evaluated state.
    #
    # @see RuleEvaluator#constructor
    rules:
      # Presence (single field) checks
      name:
        type: 'presence'
        score: 0
        prereq: true
        field: 'name'
        hidden: true
      date_prereq:
        type: 'presence'
        score: 0
        prereq: true
        field: 'instance_dates'
        hidden: true
      hashtag:
        type: 'presence'
        field: 'hashtag'
      ticket_url:
        type: 'presence'
        field: 'ticket_url'
        required: false
      allows_reviews:
        type: 'presence_all'
        fields: ['allows_reviews', 'allows_attendance']
      # Presence any (one of multiple) checks
      keywords_or_tags:
        type: 'presence_any'
        fields: ['keywords', 'tags']
      facebook_or_url:
        type: 'presence_any'
        fields: ['facebook_id', 'url']
      featured_or_sponsored:
        type: 'presence_any'
        fields: ['is_sponsored', 'featured_sections']
        required: false
      photo:
        type: 'presence_any'
        fields: ['has_photo', 'photo_id']
      included_in_trending:
        type: 'presence'
        field: 'included_in_trending'
      place:
        type: 'presence'
        field: 'business_id'
        condition: -> !!@platform_config.has_places
      location:
        type: 'presence_any'
        fields: ['business_id', 'location']

      # Custom rules

      filters:
        type: 'custom'
        required: true
        score: 1
        evaluate: (event_data) ->
          needed_filters = 1
          num_filters = 0

          if event_data.filters?
            for key, values of event_data.filters
              num_filters += 1 unless isBlank(values)

          if num_filters == 0
            score: 0, status: 'failed'
          else
            score: 1, status: 'satisfied'


      # Date is at least 7 days out
      date:
        type: 'custom'
        required: true
        score: 1
        evaluate: (event_data) ->
          created_at = event_data.created_at || new Date
          next_instance = event_data.instance_dates.reduce(((item, currentMax) -> if item >= currentMax then item else currentMax), event_data.instance_dates[0]) unless isEmpty(event_data.instance_dates)
          diff = next_instance - created_at

          if diff >= (7 * 86400 * 1000) # 7 days in ms
            score: 1, status: 'satisfied'
          else
            score: 0, status: 'failed'

      # Description length
      description:
        type: 'custom'
        required: true
        score: 1
        evaluate: (event_data) ->
          length = event_data.description?.length

          if !isBlank(event_data.description) && length >= 160
            score: 1, status: 'satisfied'
          else
            score: 0, status: 'failed'

      # Custom prereq rule to ensure the event is visible
      visible_prereq:
        type: 'custom'
        hidden: true
        prereq: true
        score: 0
        evaluate: (event_data) ->
          if event_data.visibility == 'visible' && !event_data.hide_from_main_calendar && !event_data.visible_only_to_widget && !event_data.visible_only_to_channel
            score: 0, status: 'satisfied'
          else
            score: 0, status: 'failed'

    # Initialize an instance of EventReachEngine with the specified `platform_config`.
    #
    # @param platform_config [Hash] Platform configuration. Passed into the rules.
    constructor: (platform_config) ->
      @platform_config = platform_config || {}

    # Evaluates the defined rules, returning an object containing the following keys:
    # * `score`: Points assigned to matched rules
    # * `max`: Total available points
    # * `rules`: Hash of rule_key -> {score: N, status: (`satisfied`|`failed`|`ignored`|`disabled`)} for each defined rule.
    #   "disabled" means the rule was not enabled.
    #
    # @throw [TypeError] if rule type is unknown
    evaluate: (event_data) =>
      event_data = @_cleanEventData(event_data) if event_data?

      results =
        score: 0
        max: 0
        rules: {}

      # Validate the prereqs
      prereqs_satisfied = true
      prereq_results = @_evaluateRules(@prereqRules(), event_data)
      other_results = @_evaluateRules(@otherRules(), event_data)

      # Calculate total score and generate full results
      for key, rule of prereq_results
        results.rules[key] = rule.status unless rule.hidden
        if rule.required
          switch rule.status
            when 'satisfied', 'partial'
              results.max += rule.max_score
              results.score += rule.score
            when 'failed'
              results.max += rule.max_score
              prereqs_satisfied = false

      for key, rule of other_results
        results.rules[key] = rule.status unless rule.hidden
        if rule.required
          switch rule.status
            when 'satisfied', 'partial'
              results.max += rule.max_score
              results.score += rule.score
            when 'failed'
              results.max += rule.max_score

        results.rules[key] = 'ignored' unless prereqs_satisfied || results.rules[key] == 'disabled'

      # Force score to 0 if the prereq rules aren't satisfied
      results.score = 0 unless prereqs_satisfied

      results

    # Return the list of prereq rules
    prereqRules: =>
      rules = {}
      for key, rule_data of @rules
        rules[key] = @_ruleEvaluator(key, rule_data) if rule_data.prereq
      rules

    # Return the list of normal rules
    otherRules: =>
      rules = {}
      for key, rule_data of @rules
        rules[key] = @_ruleEvaluator(key, rule_data) unless rule_data.prereq
      rules

    # Return the Rule Evaluator
    _ruleEvaluator: (key, rule_data) =>
      switch rule_data.type
        when 'presence' then new PresenceRuleEvaluator(rule_data, @platform_config)
        when 'presence_any' then new PresenceAnyRuleEvaluator(rule_data, @platform_config)
        when 'presence_all' then new PresenceAllRuleEvaluator(rule_data, @platform_config)
        when 'custom' then new RuleEvaluator(rule_data, @platform_config)
        else
          console?.error 'No evaluator found for rule', key, rule_data
          throw new TypeError("No evaluator found for rule #{key} type #{rule_data.type}")

    # Evaluate a list of rules
    #
    # Takes in an object of rule key -> rule_evaluator and returns a hash of
    # rule key -> {score, max, result}
    _evaluateRules: (rules, event_data) =>
      for key, rule of rules
        if rule
          if rule.enabled()
            rule.evaluate(event_data)
          else
            rule.status = 'disabled'
            rule.score = 0

      rules

    # Clean event data
    #
    # This converts some date strings into date objects in created_at and instance_dates
    _cleanEventData: (data) =>
      data.created_at = new Date(data.created_at) if data.created_at? && typeof(data.created_at) == 'string'
      if data.instance_dates?
        data.instance_dates = for instance in data.instance_dates
          if typeof(instance) == 'string'
            new Date(instance)
          else
            instance
      data





  # Exports
  {EventReachEngine, PresenceAnyRuleEvaluator, PresenceAllRuleEvaluator, PresenceRuleEvaluator, RuleEvaluator}