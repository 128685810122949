modulejs.define 'slzr/react/progress_bar',
  ['react', 'prop-types'],
  (React, PropTypes) ->
    # Progress Bar component
    #
    # Basically just a couple divs around something to generate a progress bar background with the given percent.
    class ProgressBar extends React.Component
      @propTypes =
        # Percentage to show
        percent: PropTypes.number

      @defaultProps:
        percent: 0

      render: ->
        `<div className="percent">
          <div className="progress_wrapper">
            <div className="progress" style={{width: this.props.percent + "%"}}>&nbsp;</div>
          </div>
          {this.props.children}
        </div>`