# Dashboard page (/me/home) client-side scripts
namespace 'Slzr.Page.Dashboard', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  Slzr.jQuery ($) ->
    return unless Slzr.Meta.page == 'dashboard'

    # After deletion, accept, or ignore, remove the item from the page
    $('[data-request="list"]').on 'ajax:success.slzr', '[data-action="remove-request"], [data-action="accept-request"], [data-action="ignore-request"]',
      (evt, data, status, xhr) ->
        request_element = $(this).closest('[data-request="request"]')

        request_element.remove()