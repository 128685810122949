# Manage any JS for the login dialog
#
# Does not include code to trigger facebook or linkedin logins, see
# respective modules for that
#
# data-login=container
# data-login-target on a[data-action=change-tab]
# data-login-tab gets shown

# Login lightbox handler
namespace 'Slzr.Login', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  # Enable it for each login container on page
  $ -> $(document).on 'click', '[data-login="container"] [data-action="change-tab"]', (evt) ->
    $element = $(this)
    $container = $element.closest('[data-login="container"]')
    tab = $element.data 'login-target'

    # Highlight current tab
    $container.find('[data-action="change-tab"]').removeClass 'current'
    $element.addClass 'current'

    # Show right login tab
    $container.find('div[data-login-tab]').hide()
    $container.find("div[data-login-tab=\"#{tab}\"]").show()

    # and finalize
    $.fancybox.resize()
    evt.preventDefault()

  # LEGACY CODE
  switchLogin = (el) ->
    el = $(el)
    root = el.closest('div.localist_modal_container')

    # Get the class from the button
    service = RegExp.$1 if el[0].className.match /button-login-([a-z]+)/i

    if service
      # Move class to the new button
      root.find('div.login_choices a.current').removeClass 'current'
      el.addClass 'current'

      # Show the appropriate div
      root.find('div[class^=container-login]').hide()
      root.find("div.container-login-#{service}").show()

      # Resize lightbox if necessary
      $.fancybox.resize()

  exports.switchLogin = switchLogin
