modulejs.define 'registration/components/promo_code_list_item',
  ['underscore', 'react', 'prop-types', 'registration/components/promo_code_form'],
  (_, React, PropTypes, PromoCodeForm) ->

    class PromoCodeListItem extends React.Component
      @propTypes:
        # Ticket classes available for selection, passed down into the editor
        availableTicketClasses: PropTypes.array
        showEditor: PropTypes.bool
        onChange: PropTypes.func
        onDelete: PropTypes.func
        onAddTicketClass: PropTypes.func
        onRemoveTicketClass: PropTypes.func
        onToggleEditor: PropTypes.func

      @defaultProps:
        showEditor: false

      toggleEditForm: (event) =>
        event.preventDefault()
        this.props.onToggleEditor?(this.props.promoCode.id)

      closeForm: (event) =>
        event.preventDefault()
        this.props.onToggleEditor?(this.props.promoCode.id, false)

      formChange: (name, value, event) =>
        this.props.onChange?(this.props.promoCode.id, name, value, event)

      deleteTicket: (event) =>
        event.preventDefault()
        this.props.onDelete?(this.props.promoCode.id, event)

      render: ->
        edit_form = if this.props.showEditor
          `<PromoCodeForm promoCode={this.props.promoCode}
                          settings={this.props.settings}
                          availableTicketClasses={this.props.availableTicketClasses}
                          onChange={this.formChange}
                          onClose={this.closeForm}
                          onAddTicketClass={this.props.onAddTicketClass}
                          onRemoveTicketClass={this.props.onRemoveTicketClass} />`

        `<tbody>
          <tr>
            <td>{this.props.promoCode.code}</td>
            <td>
              {this.props.promoCode.total_usage || 0}
              {this.props.promoCode.usage_limit > 0 && ' / ' + this.props.promoCode.usage_limit}
            </td>
            <td>
              <div className="text-right action_icon_button">
                <a href="#"
                   onClick={this.toggleEditForm}
                   title="Edit Promo Code"
                   className="edit_button"><i className="localist-icon-edit-small"></i></a>
                <a href="#"
                   onClick={this.deleteTicket}
                   title="Remove Promo Code"
                   className="deletex_button"><i className="localist-icon-trash-small"></i></a>
              </div>
            </td>
          </tr>
          {edit_form}
        </tbody>`
