modulejs.define 'registration/components/promo_code_list',
  ['underscore', 'react', 'prop-types', 'registration/components/promo_code_list_item'],
  (_, React, PropTypes, PromoCodeListItem) ->

    # Display the table listing promo codes
    class PromoCode extends React.Component
      @propTypes:
        # Promo codes to show
        promoCodes: PropTypes.array
        # Ticket classes available for selection, passed down into the editor
        availableTicketClasses: PropTypes.array
        header: PropTypes.bool
        onChange: PropTypes.func
        onDelete: PropTypes.func
        onAddTicketClass: PropTypes.func
        onRemoveTicketClass: PropTypes.func
        openedEditors: PropTypes.array
        onToggleEditor: PropTypes.func
        onAddPromoCode: PropTypes.func

      @defaultProps:
        promoCodes: []
        openedEditors: []
        header: true

      render: ->

        header = if this.props.header
          `<thead>
            <tr>
              <th>Code</th>
              <th># Uses</th>
              <th></th>
            </tr>
          </thead>`

        contents = if this.props.promoCodes.length == 0
          `<tbody>
            <tr>
              <td colSpan="3">
                <a href="#" onClick={this.props.onAddPromoCode}>Create your first promo code</a>
              </td>
            </tr>
          </tbody>`
        else
          for promo_code in this.props.promoCodes
            `<PromoCodeListItem key={promo_code.id}
                                settings={this.props.settings}
                                promoCode={promo_code}
                                showEditor={this.props.openedEditors.indexOf(promo_code.id) > -1}
                                availableTicketClasses={this.props.availableTicketClasses}
                                onChange={this.props.onChange}
                                onDelete={this.props.onDelete}
                                onToggleEditor={this.props.onToggleEditor}
                                onAddTicketClass={this.props.onAddTicketClass}
                                onRemoveTicketClass={this.props.onRemoveTicketClass} />`

        `<table className="form-item-list">
          {header}

          {contents}
        </table>`

