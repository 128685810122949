# Javascript event handlers for manage reviews

namespace 'Slzr.Page.ManageReviews', (exports, top) ->
  Slzr.jQuery ($) ->
    return unless Slzr.Meta.page == 'manage-reviews'

    # Update rating input when clicking one of the submit buttons
    #
    # Do this here, because on mobile devices there isn't necessarily an "active" element
    $(document.body).on 'click.manage-reviews', 'form[data-review="form"] input[data-rating-value]', (evt) ->
      $this = $(this)
      $this.closest('form[data-review="form"]').find('input[name="rating"]').val $this.data('rating-value')

      true

    $(document.body).on 'submit.manage-reviews', 'form[data-review="form"]', (evt) ->
      $form = $(this)

      # Update rating, only if activeElement is an INPUT tag
      #
      # This is meant for non-click interactions, but also handles clicks.
      if $(document.activeElement).is("input[data-rating-value]")
        $form.find('input[name="rating"]').val $(document.activeElement).data('rating-value')

      # Validate review
      if $form.find('[name="review"]').val().isBlank()
        alert 'You must provide a review!'
        evt.preventDefault()
        return false
