modulejs.define 'slzr/react/json_blob',
  ['react', 'prop-types'],
  (React, PropTypes) ->

    class JsonBlob extends React.Component
      @propTypes:
        name: PropTypes.string
        value: PropTypes.any

      render: ->
        json = JSON.stringify(this.props.value)
        `<input type="hidden" name={this.props.name} value={json} />`