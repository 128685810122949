# Registration Initialization

Slzr.jQuery ($) ->
  # Initialize editor on any pages with registration data and a form
  if Slzr.Meta['registration']
    RegistrationEditorController = modulejs.require('registration/editor_controller')

    $('[data-registration="form"]').each (index, element) ->
      initial_state = Object.assign({}, Slzr.Meta['registration'], { payout_accounts: Slzr.Meta['registration-payout-accounts'] })
      controller = new RegistrationEditorController(element, Slzr.Meta['event-id'], initial_state)
      $(element).data('registration', controller)

      # Update the experience state in the registration editor when the user changes the form value
      $(document).on 'change', '[data-experience="true"]', (event) ->
        experience = $(this).find('option').filter(':selected').data('experience-type')
        controller.eventExperienceChanged(experience)

      # Update the default stream URL in the registration editor when the form value changes
      $('#event_stream_url').on 'change', (event) ->
        controller.streamUrlChanged(this.value)

      # Update the payout account ID in the registration editor when the form value changes
      $('#event_payout_account_id').on 'change', (event) ->
        controller.eventPayoutAccountIdChanged(this.value)

  # Initialize checkout code if the page has ticket offers
  if Slzr.Meta['event-has-ticket-offers'] || Slzr.Meta['conference-has-ticket-offers']
    RegistrationCheckoutController = modulejs.require('registration/checkout/controller')
    CheckoutModalCoordinator = modulejs.require('registration/checkout/modal_coordinator')

    # The first instance of the checkout element for a given event_id is the one responsible for showing the modal,
    # regardless of which checkout element requests it be opened. This instance is also the one responsible for auto
    # opening on page load, if needed.
    initializeCheckout = () ->
      event_modal_coordinators = {}

      $('[data-tickets="container"]').each (index, element) ->
        unless $(element).data('checkout')?
          # Extract event_id from the button's data-ticket-data attribute, falling back to the page's meta tag
          ticket_data = $(element).data('ticket-data')
          event_id = ticket_data?['event_id'] || Slzr.Meta['event-id']

          if event_modal_coordinators[event_id]?
            # Not the first button for event_id
            is_modal_container = false
            coordinator = event_modal_coordinators[event_id]
          else
            is_modal_container = true
            coordinator = event_modal_coordinators[event_id] = new CheckoutModalCoordinator

          controller = new RegistrationCheckoutController(element, event_id, is_modal_container, coordinator)

          $(element).data('checkout', controller)

    # Listen for slzr:attendance:change events, to refresh the react component if necessary
    $(document).on 'slzr:attendance:change', (event) ->
      initializeCheckout()

    $(document).on 'click', '[data-action="going"]', (event) ->
      _.defer -> initializeCheckout()

    initializeCheckout()