module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table border="0" class="item-list">\n  ';
 if(showHeader) { ;
__p += '\n    <thead>\n      <tr>\n        <th width="10%">Position</th>\n        <th>Name</th>\n        <th width="20%">Action</th>\n      </tr>\n    </thead>\n  ';
 } ;
__p += '\n\n  <tbody class="listing">\n  </tbody>\n</table>\n';

}
return __p
}