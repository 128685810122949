modulejs.define 'slzr/conference/conference_schedule_editor',
  ['jquery', 'underscore', 'react', 'react-dom', 'prop-types', 'slzr/conference/schedule_form/form_component'],
  ($, _, React, ReactDOM, PropTypes, ConferenceForm) ->

    class ConferenceScheduleEditor extends React.Component
      @propTypes:
        startDate: PropTypes.string
        endDate: PropTypes.string
        eventInstances: PropTypes.array
      
      @defaultProps:
        startDate: null
        endDate: null
        eventInstances: []

      constructor: (props) ->
        super(props)

      render: ->
        `<ConferenceForm startDate={this.props.startDate}
                          endDate={this.props.endDate}
                          eventInstances={this.props.eventInstances}
        />`
