module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<td width="10%">\n  <div class="action_button">\n    ';
 if(!isFirst) { ;
__p += '<a href="#" class="move-up">&uarr;</a>';
 } ;
__p += '\n    ';
 if(!isLast) { ;
__p += '<a href="#" class="move-down">&darr;</a>';
 } ;
__p += '\n  </div>\n</td>\n<td>' +
((__t = ( name )) == null ? '' : __t) +
'</td>\n<td width="20%">\n  <div class="action_button left">\n    ';
 if(hasFeatured) { ;
__p += '<a href="#" class="toggle-featured btn-color">' +
((__t = ( featured ? 'Remove' : 'Add' )) == null ? '' : __t) +
' Feature</a>';
 } ;
__p += '\n    <a href="#" class="remove bad btn-color-alt">&times;</a>\n  </div>\n</td>\n\n<input type="hidden" name="';
 if(featured) { ;
__p += 'featured_';
 } ;
__p +=
((__t = ( formParam )) == null ? '' : __t) +
'[]" value="' +
((__t = ( id )) == null ? '' : __t) +
'">\n';

}
return __p
}