modulejs.define 'registration/checkout/controller',
  ['jquery', 'react', 'react-dom', 'registration/components/checkout/checkout'],
  ($, React, ReactDOM, Checkout) ->

    class RegistrationCheckoutController
      # Initialize this instance of the controller
      #
      # is_modal_container is true when this instance is responsible for hosting the modal, and auto opening
      # coordinator should be an instance of CheckoutModalCoordinator
      constructor: (container, event_id, is_modal_container=true, coordinator=null) ->
        @container = container
        $container = $(@container)
        @event_id = event_id

        data = $container.data('ticket-data')

        # Extract initial state from the URL
        if m = location.hash.match /checkout=([a-z0-9]+)/
          initial_mode = m[1]
        else
          initial_mode = null

        # Grab info from the URL to display confirmation or refund component
        # param ticket_id [Integer] single ticket
        # param order_id [Integer] multiple tickets (purchaser only)
        # param Token [String] the corresponding token for the ticket or order requested
        params = new URLSearchParams(window.location.hash.substring(1))

        # Flag to automatically start the checkout process; used for post-signin flow
        # Only activates if this button's event id matches the page's event id
        open_checkout = is_modal_container &&
          event_id.toString() == Slzr.Meta['event-id'] && # toString because meta parses as a string
          params.has("tickets")
          
        # If the marker cookie for a successful payment is present, request a finalize and dispatch of the completed order
        if $.cookie("completed_order_#{data.event_id}")
          console.log 'got order complete cookie', "completed_order_#{data.event_id}", $.cookie("completed_order_#{data.event_id}")
          order_cookie_data = $.cookie "completed_order_#{data.event_id}"
          [order_id, cart_id] = order_cookie_data.split('|', 2)

          # Clear the cookie
          $.cookie "completed_order_#{data.event_id}", null
          # Set the order id
          data['completed_ticket_order_id'] = order_id
          data['completed_ticket_order_cart_id'] = cart_id

        # If the marker cookie for a failed payment is present, request a payment retry
        if $.cookie("payment_failed_#{data.event_id}")
          # The jQuery cookie plugin doesn't replace + in cookies with spaces, so all this is just to parse it cleanly
          order_cookie_data = $.cookie "payment_failed_#{data.event_id}"
          [order_id, cart_id] = order_cookie_data.split('|', 2)
          console.log "got payment failed cookie", "payment_failed_#{data.event_id}", order_cookie_data

          # Clear cookie
          $.cookie "payment_failed_#{data.event_id}", null

          # Set flags for reopening checkout behavior
          open_checkout = true
          data['order_id'] = order_id
          data['cart_id'] = cart_id

        params_mode = if params.has("refund")
                        'refund'
                      else if params.has("confirm")
                        'confirm'
                      else
                        null

        if params_mode
          open_checkout = true
          data[params_mode] = true
          data['order_id'] = params.get("order_id")
          data['ticket_id'] = params.get("ticket_id")
          data['token'] = params.get("token")

        checkout_button_classes = $container.data('ticket-checkout-button-class')
        if checkout_button_classes
          data['checkoutButtonClassName'] = checkout_button_classes

        # If this is the modal container, create and append a div to body, and use that for the overlay
        modal_container = document.createElement('div')
        if is_modal_container then document.body.appendChild(modal_container)

        # Don't activate React if a login is needed
        unless data.need_login
          @react_element = ReactDOM.render(
            `<Checkout initialMode={initial_mode}
                       openCheckout={open_checkout}
                       modalCoordinator={coordinator}
                       modalContainerElement={modal_container}
                       isModalContainer={is_modal_container}
                       {...data} />`
          , container)
