modulejs.define 'slzr/eventreach/component',
['react', 'react-dom', 'slzr/eventreach/messages', 'jquery', 'classnames'],
(React, ReactDOM, Messages, $, classNames) ->

  # Component for an individual rule's row
  class EventReachRowItem extends React.Component
    componentDidMount: =>
      # Initialize jquery tooltip
      $(ReactDOM.findDOMNode(this)).find('[rel="tooltip"]').tooltip()

    render: =>
      rule = @props.rule
      result = @props.result

      classes = classNames
        item: true
        item_failed:    result == 'failed'
        item_partial:   result == 'partial'
        item_satisfied: result == 'satisfied'
        item_ignored:   result == 'ignored'

      rule_label = Messages[rule]?.label || "[missing label: #{rule}]"
      result_check_class = classNames
        fa: true
        'fa-check-circle': result == 'satisfied'
        'fa-times-circle': result == 'failed'
        'fa-circle-o': result != 'satisfied' && result != 'failed'

      tooltip = if Messages[rule]?.tooltip && Messages[rule]?.tooltip != ''
        `<a href="#" title={Messages[rule].tooltip} rel="tooltip" onClick={this.ignoreClick}>?</a>`
      else
        null

      `<div className={classes}>
        <h3>
          <span>
            <i className={result_check_class}></i>
            {' '}
            {rule_label}
            {' '}
            {tooltip}
          </span>
        </h3>
      </div>`

    ignoreClick: (ev) =>
      ev.preventDefault()
      false

  # Main EventReach React component
  #
  # This is the main component that displays the eventreach list
  class EventReachComponent extends React.Component
    constructor: (props) ->
      super(props)

      @state =
        score: @props.eventReach.result.score
        max: @props.eventReach.result.max
        rules: @props.eventReach.result.rules
        visible: @props.eventReach.visible
        can_trend: @props.eventReach.can_trend

      @ordered_rules = _([key, value.order] for key, value of Messages)
        .sortBy (i) -> i[1]
        .map (i) -> i[0]

    componentDidMount: =>
      @props.eventReach.on 'change', @_scoreChanged

    componentWillUnmount: =>
      @props.eventReach.removeListener 'change', @_scoreChanged

    # Update state based on scoring change
    _scoreChanged: =>
      @setState
        score: @props.eventReach.result.score
        max: @props.eventReach.result.max
        rules: @props.eventReach.result.rules
        visible: @props.eventReach.visible
        can_trend: @props.eventReach.can_trend

    render: =>
      rows = []
      # Create rows for the defined rules in order
      for rule in @ordered_rules
        result = @state.rules[rule]
        continue if !result? || result == 'disabled'
        rows.push `<EventReachRowItem rule={rule} result={result} key={rule}/>`

      # And then, append any extra results to the bottom
      for rule, result of @state.rules
        continue if rule in @ordered_rules
        continue if result == 'disabled'
        rows.push `<EventReachRowItem rule={rule} result={result} key={rule}/>`

      contents = if @state.visible
        percent = Math.round((@state.score / @state.max) * 100)
        `<div className="percent">
          <div className="progress_wrapper">
            <div className="progress" style={{width: percent + "%"}}>&nbsp;</div>
          </div>
          <p><a href="#" data-action="toggle" data-target="#x-eventreach-item-list">{percent}% Reach</a></p>
        </div>`
      else
        rows = []
        `<div>
          <p>EventReach Unavailable</p>
          <p>Because this event has limited visibility, it will not be very discoverable by your audience. If you would like this event to be more easily discovered, change its visibility setting.</p>
        </div>`

      `//`# EventReach block
      `<div className="widget">
        <h2 className="widget-header">EventReach <a rel="tooltip" href="https://help.concept3d.com/hc/en-us/articles/11938388753043-EventReach-EventScore" title="Click here to learn more about EventReach">?</a></h2>
        <div className="widget-content widget-eventreach">
          {contents}
          <div className="eventreach-item-list" id="x-eventreach-item-list">
            {rows}
          </div>
        </div>
      </div>`