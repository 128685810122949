# Checkbox multiselect.
# This is a all or nothing checkbox_multiselect library. Considering using generic_checkbox_multiselect (GenericCheckboxMultiSelect) instead to achieve the same goal. 
# This code relies mainly on all checkboxes on the form needing to be maganged.
# GenericCheckboxMultiSelect's code relies on data attributes and meta items being set to precisely select the checkboxes that need managing.

# Requirements 
## parent checkbox to have id oof "checkAll"
## data attributes 
#### is-child, either true or false
#### parent-id, the checkbox id

namespace 'Slzr.Page.CheckboxMultiSelect', (exports, top) ->
  Slzr.jQuery ($) ->
    return unless $(document.body).is('.admin-filters-context') || $(document.body).is(' .admin-moderation-index')
    
    # Check or uncheck all checkboxes
    $('#checkAll').on 'change', (event) ->
      $(this).closest('form').find(':checkbox').prop('checked', this.checked);

    # Decide how to display select all checkbox based on other checkboxes being checked or not
    #
    $("form input[type='checkbox']").on 'change', (event) ->
      # Returns an array of 1 JQuery checkbox object
      $checkAll = $('#checkAll')

      # Removes that one object from total number of checkbox JQuery objects found on the page
      $checkboxes = $(this).closest('form').find(':checkbox').filter (index, $elm) ->
        return $elm != $checkAll[0]

      # Decides what to do with that one JQuery checkbox object based on the number of checkboxex checked
      $checkAll.prop 'checked', $checkboxes.filter(':checked').length == $checkboxes.length

    # Check all children under a parent filter. 
    # A parent can't be converted unless all of the children are converted with it or it doesno't have children
    #
    # When checkbox with data attribute is_child=false
    $("form :checkbox[data-is-child='false']").on 'change', (event) ->
      checkbox_id = $(this).val()
      $("form :checkbox[data-parent-id='#{checkbox_id}']").prop('checked', this.checked)

    # Uncheck the parent checkbox if a child is de-selected
    $("form :checkbox[data-is-child='true']").on 'change', (event) ->
      # find the parent id of the child that has been clicked
      parent_id = $(this).data('parentId')
      # get all checkboxes with that parent id
      $checkboxes = $("form :checkbox[data-parent-id='#{parent_id}']")
      
      # when a check event happens and all of the checkboxes are checked, check the checkbox with the value of the parent id of the child selected
      $("form :checkbox[value='#{parent_id}']").prop('checked', $checkboxes.filter(':checked').length == $checkboxes.length)
