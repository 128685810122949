# Event instance manager
#
# This is the controller to link the EventInstance in-memory model with the page itself.
#
# Expects each item to be in an element with data-instance=true and data-date=start_date
#
# Inside, a click on something with data-action="remove-instance" will remove the instance
# and the associated element
#
# The form used for adding instances is the add_form option.
#   A data-action="add-instance" inside will add an instance
#   Will use data-instance to determine what type of data it is,
#     "start-date" "start-time" or "end-time"
#   Recurrence is supported with
#     "recurrence-ends" "recurrence-type" and "recurrency-day"
#
#   Recurrence types are 'daily', 'weekly', 'monthly'
#   Recurrence days is 0-6 for days of week (can be multiple)
#
#   The other instances should be date inputs
#
# Events will be triggered on the instance container when an instance is added
# or deleted.  The extra parameter for each event is a hash with start_date, start_time,
# end_time.
#   slzr:instance:add => Triggered when an instance is added
#   slzr:instance:remove => Triggered when an instance is removed

namespace 'Slzr.UI', (exports, top) ->
  $ = jQuery = Slzr.jQuery
  EventInstances = modulejs.require('slzr/event/instances')
  EventEmitter = modulejs.require('EventEmitter')
  React = require('react')
  ReactDOM = require('react-dom')
  InstanceList = modulejs.require('slzr/components/instance_list')
  InstanceManagerComponent = modulejs.require('slzr/components/instance_manager')

  class exports.EventInstanceManager extends EventEmitter
    default_options:
      # Maximum number of instances to allow
      max_instances: 20

      # Maximum date range of recurring instances
      max_range: 366 # 1 year or so

      # Initial instances
      #
      # Each element is [start_date, start_time, end_time], should be string values
      initial_instances: []

      # Form controlled by this interface
      form: null

    # Flag variable indicating if the instance inputs are dirty
    inputs_changed: true

    # Flag indicating that the form has been validated with a date error
    form_has_date_error: false

    constructor: (options) ->
      super()

      @options = $.extend {}, @default_options, options

      @form = $(@options.form)
      @date_manager_container = @form.find('[data-instance="manager"]')

      @instances = new EventInstances
        max_instances:     @options.max_instances
        max_range:         @options.max_range
        initial_instances: @options.initial_instances

      @_attachEvents()
      @_initializeUI()

    # Return the next instance data
    nextInstance: => @instances.nextInstance()

    # Return the next instance start time
    nextInstanceAt: => @instances.nextInstanceAt()

    # Return the next instance duration
    nextInstanceDuration: => @instances.nextInstanceDuration()

    # Return the next instance duration in minutes
    nextInstanceDurationMinutes: => @instances.nextInstanceDurationMinutes()

    # Attach event handlers to the appropriate events
    _attachEvents: =>
      # Trigger instance add when submitting
      @form.on 'submit validation:before', (event) =>
        @form.find('[data-action="add-instance"]').prop('disabled', null).trigger('click') if @inputs_changed
        true

      # Make sure we have at least one valid date
      @form.on 'validation:complete', (event, data) =>
        if @instances.valid()
          @form.find('[data-instance-error="need-date"]').hide()
          @form_has_date_error = false
        else
          @form.find('[data-instance-error="need-date"]').show()
          data.errors?.push 'A valid date must be entered'
          @form_has_date_error = true

        # Update the react props; this should only be formHasDateError that changes
        @_renderReactComponent()

      @form.on 'slzr:date:change', '[data-instance]', => @inputs_changed = true

      @instances.on 'slzr:instance:add', @_instanceAdded
      @instances.on 'slzr:instance:change', @_instanceChanged
      @instances.on 'slzr:instance:remove', @_instanceRemoved

    ### INSTANCE EVENT HANDLERS ###
    # These exist refresh the UI, mainly
    _initializeUI: =>
      my = this

      # Trigger events for the initial instances being added
      @_instanceAdded instance for instance in @instances.instances

      @_renderReactComponent()

    # Render the react componnent with the latest state
    _renderReactComponent: =>
      # isRenderedInAdmin: Difference between admin and user platform
      ReactDOM.render React.createElement(InstanceManagerComponent,
          eventInstances: @instances,
          onInstancesAdded: @_userAddedInstances,
          onInputChange: @_userChangedInputs
          isRenderedInAdmin: !@date_manager_container.data().renderForUser,
          formHasDateError: @form_has_date_error
        ),
        @date_manager_container.get(0)

    # Callbacks regarding form input changes and committing instances
    #
    # This enables automatic commit of instances on form submit, but only if the date inputs have been changed
    _userChangedInputs: (event) =>
      @inputs_changed = true

    _userAddedInstances: (event) =>
      @inputs_changed = false

    # Instance event change handlers
    #
    # Just propogate the event through through the form element, as the UI is now handled
    # by InstanceManagerComponent
    _instanceAdded: (instance_data) =>
      # Propogate the event to anything listening to us
      @emit 'slzr:instance:add', instance_data
      @form.trigger 'slzr:instance:add', instance_data

    _instanceRemoved: (instance_data) =>
      @emit 'slzr:instance:remove', instance_data
      @form.trigger 'slzr:instance:remove', instance_data

    _instanceChanged: (instance_data) =>
      @emit 'slzr:instance:change', instance_data
      @form.trigger 'slzr:instance:change', instance_data


  # Activate instance manager if the page says so
  $ ->
    unless Slzr.Meta['event-is-conference']
      $form = $('form[data-instance="form"]')
      return unless $form.length > 0

      event_instance_manager = new Slzr.UI.EventInstanceManager
        form: $form
        initial_instances: Slzr.Meta['event-instances'] || []

      $form[0].event_instance_manager = top.event_instance_manager = event_instance_manager