# Simple client-side form validation stuff

namespace 'Slzr.FormValidation', (exports, top) ->
  $ = jQuery = Slzr.jQuery
  VALIDATION_SELECTOR = 'input[data-validation],input[required],input[data-required="true"],textarea[data-required="true"],textarea[data-validation]'

  # Implements simple input validation on a form
  #
  # The inputs to validate are given a data-validation attribute, with the
  # validation type (required, email, matches).
  #
  # Other data attributes configure behavior:
  #   data-validation-match:     For matches validation, the other input to match
  #   data-validation-error:     Selector for element to show when input is not valid
  #   data-validation-add-class: Add the specified class to elements matching data-validation-add-class-to when not valid
  #   data-validation-add-class-to: Selector to add class to
  #
  # Triggers a validation:complete event on the form when validation is complete.
  # This event has these parameters:
  #   form: Form that is being validated
  #   valid: Result of the validation
  #   errors: List of errors during validation
  # Any additional errors in this field should be appended to errors.

  class exports.FormValidator
    form: null
    inputs: null

    constructor: (form) ->
      @form = $(form)
      @form.submit @validateForm

    validateForm: (evt) =>
      # Validate each element based on the validation type
      errors = []
      $form = @form

      $form.trigger 'validation:before'

      $form.find(VALIDATION_SELECTOR).each ->
        is_ok = true
        $input = $(this)
        data = $input.data()

        validation_type = $input.data('validation')
        validation_type = 'required' if !validation_type and $input.prop('required')
        validation_type = 'required' if !validation_type and $input.data('required') == true

        my_id = this.id || this.name

        error_message = null
        if $input.data('validation-error')
          error_message = $form.find($input.data('validation-error'))
        else
          error_message = $form.find("[data-validation-error-message-for='#{my_id}']")
          error_message = $form.find("#x-need-#{my_id}") unless error_message.get(0)

        if data.validationAddClass
          add_class_to = $form.find(data.validationAddClassTo)
          add_class = data.validationAddClass

        # If it's an input with an attached rich text editor, get the data from there and strip HTML from it.
        if $input.data('ckeditor-instance')
          ckeditor_instance = $input.data('ckeditor-instance')
          snapshot = ckeditor_instance.getSnapshot()
          val = $(snapshot).text()
        else
          val = $input.val()

        switch validation_type
          when 'required'
            if val.isBlank()
              is_ok = false
              errors.push $input.attr('id') + ' is required'

          when 'email'
            unless val.match(/^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+\.[A-Za-z0-9-.]+$/i)
              is_ok = false
              errors.push $input.attr('id') + ' is not a valid email'

          when 'matches'
            other_input = $form.find($input.data('validation-match'))
            other_val = other_input.val()

            if val.isBlank() || other_val.isBlank() || val != other_val
              is_ok = false
              errors.push $input.attr('id') + ' does not match ' + other_input.attr('id')

          when 'urlname'
            if val.isBlank() && $input.data('validation-allow-blank')
              is_ok = true
            else
              unless val.trim().match(/^[-_A-Za-z0-9]+$/i)
                is_ok = false
                errors.push $input.attr('id') + ' is not a valid urlname'

          when 'required_checkbox'
            if $input.is(':checked') == false
              is_ok = false
              errors.push $input.attr('id') + ' is required'

          else
            console.error('Unknown validation type', validation_type, 'on', this)

        console.groupEnd()

        error_message?.toggle(!is_ok)
        add_class_to?.toggleClass(add_class, !is_ok)
        $input.attr('aria-invalid', !is_ok)

      $form.triggerHandler 'validation:complete', validator: this, form: $form, errors: errors

      if errors.length > 0
        evt.preventDefault()
        false
      else
        true


  # Attach to all forms with data-validation and internal elements
  $ ->
    $('form[data-validation]').each ->
      $form = $(this)
      if !$form.data('slzr.validator') and $form.find(VALIDATION_SELECTOR).length > 0
        validator = new exports.FormValidator($form)
        $form.data('slzr.validator', validator)
