# Profile view specific code

namespace 'Slzr.Page', (exports, top) ->
  # Minimum number of paragraphs to truncate
  EXTRA_NUM = 3

  # Number of paragraphs to keep visible
  SHOW_NUM = 2

  Slzr.jQuery ($) ->
    $('[data-action="collapse-text"]').each ->
      div = $(this)
      paragraphs = div.find('p')

      if paragraphs.length >= EXTRA_NUM
        # Hide the extra paragraphs
        paragraphs.slice(SHOW_NUM).hide()

        # and add a more link
        new_el = $('<a/>', href: '#')
        new_el.html 'Show more...'
        new_el.click (evt) ->
          $(this).parent().remove()
          paragraphs.show()
          evt.preventDefault()
          false

        # Append the expand link
        new_p = $('<p>')
        new_p.append(new_el).appendTo(div)
