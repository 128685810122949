modulejs.define 'registration/components/checkout/reservation_timer',
  ['react', 'prop-types', 'moment', 'registration/utilities',
   'slzr/react/form_input_group', 'slzr/react/form_header'],
  (React, PropTypes, moment, Utilities,
   FormInputGroup, FormHeader) ->

    class ReservationTimer extends React.Component
      @propTypes:
        # How often to update the countdown
        frequency: PropTypes.number
        # Current server time
        now: PropTypes.any.isRequired
        # Reservation end time, again as server time
        reservationEnds: PropTypes.any.isRequired
        # Offset for browser's time from the server, will be computed from now if not provided
        browserTimeOffset: PropTypes.number

      @defaultProps:
        frequency: 1000

      constructor: (props) ->
        super props

        now = moment(props.now)
        reservation_ends_at = moment(props.reservationEnds)
        time_difference = reservation_ends_at.diff(now)
        browser_time_offset = props.browserTimeOffset || moment().diff(props.now)

        current = moment().add(browser_time_offset, 'ms')
        remaining = reservation_ends_at.diff(current)

        # Initialize state with the provided props for answers
        @state =
          # Server's current timestamp, used for calculating the offset
          now: now
          # Offset between browser time and server time
          browser_time_offset: @props.browserTimeOffset || browser_time_offset
          # Server time reservation ends
          reservation_ends_at: reservation_ends_at
          # Remaining time, in milliseconds, of the reservation
          remaining_time: remaining

        # Set up callback for time countdown
        @_countdownTimer = setInterval(@_countdownTimerFired, @props.frequency) unless @_countdownTimer

      componentWillUnmount: ->
        # Cancel the timer for the countdown clock
        clearInterval(@_countdownTimer)
        @_countdownTimer = null

      # Callback to update the countdown timer every 0.9 seconds
      _countdownTimerFired: =>
        current = moment().add(this.state.browser_time_offset, 'ms')
        remaining = this.state.reservation_ends_at.diff(current)

        @setState remaining_time: remaining

        if remaining <= 0
          clearInterval(@_countdownTimer)
          @_countdownTimer = null


      UNSAFE_componentWillReceiveProps: (nextProps) ->
        return if this.props.reservationEnds == nextProps.reservationEnds

        # Update Remaining time state
        now = moment(nextProps.now)
        reservation_ends_at = moment(nextProps.reservationEnds)
        time_difference = reservation_ends_at.diff(now)
        browser_time_offset = moment().diff(nextProps.now)

        current = moment().add(this.state.browser_time_offset, 'ms')
        remaining = reservation_ends_at.diff(current)

        # Update state with the provided props for answers
        @setState
#          now: now
          reservation_ends_at: reservation_ends_at
#          browser_time_offset: browser_time_offset
          remaining_time: remaining

        # Set timer for time updates
        @_countdownTimer = setInterval(@_countdownTimerFired, @props.frequency) unless @_countdownTimer


      renderExpired: ->
        `<FormInputGroup className="reservation_timer">
          <FormHeader>
            Ticket hold expired
          </FormHeader>
        </FormInputGroup>`

      renderCountdown: ->
        remaining_time = moment.duration(this.state.remaining_time)
        `<FormInputGroup className="reservation_timer">
          <FormHeader>
            Complete your registration in {Utilities.formatDuration(remaining_time)}
          </FormHeader>
        </FormInputGroup>`


      render: ->
        if this.state.remaining_time <= 0
          @renderExpired()
        else
          @renderCountdown()
