
modulejs.define 'slzr/components/dropzone',
  ['react', 'prop-types', 'classnames'],
  (React, PropTypes, classNames) ->

    # Helper to return files that are dragged in, in a cross-browser way
    #
    # https://github.com/okonet/react-dropzone/blob/master/src/getDataTransferItems.js
    getDataTransferFiles = (event, multipleAllowed=true) ->
      dataTransferItemsList = []

      if event.dataTransfer
        dt = event.dataTransfer
        if dt.files && dt.files.length
          dataTransferItemsList = dt.files
        else if dt.items && dt.items.length
          # Chrome fills in items even if files is empty during a drag
          dataTransferItemsList = dt.items
      else if event.target && event.target.files
        dataTransferItemsList = event.target.files

      if dataTransferItemsList.length > 0
        dataTransferItemsList = if multipleAllowed then dataTransferItemsList else [dataTransferItemsList[0]]

      # Convert it into a native array
      Array.prototype.slice.call(dataTransferItemsList)


    # Class implementing a HTML5 drag and drop drop zone.
    #
    # Will call onDrop prop when a file is dropped and accepted
    class Dropzone extends React.Component
      @propTypes =
        activeClassName: PropTypes.string
        className: PropTypes.string
        accepts: PropTypes.oneOf ['all', 'images']
        onDrop: PropTypes.func
        onDragOver: PropTypes.func
        onDragLeave: PropTypes.func

      @defaultProps =
        className: 'drop-target'
        activeClassName: 'active'
        accepts: 'all'

      constructor: (props) ->
        super props

        this.state =
          dragActive: false

      # Drop handler, make sure we can accept it before handling the item
      onDrop: (event) =>
        this.setState dragActive: false
        event.preventDefault()

        files = getDataTransferFiles event, false
        if this.isFileAccepted files[0]
          this.props.onDrop? files[0]

      # Drag over, so make sure we can accept it before indicating that's the case
      onDragOver: (event) =>
        event.preventDefault()

        files = getDataTransferFiles event, false
        if this.isFileAccepted(files[0])
          this.setState dragActive: true
          try
            event.dataTransfer.dropEffect = 'copy'
          catch err
            # silently ignore any errors
            true
        else
          this.setState dragActive: false
          try
            event.dataTransfer.dropEffect = 'none'
          catch err
            # silently ignore any errors
            true


      onDragLeave: (event) =>
        this.setState dragActive: false
        event.preventDefault()

      onDragEnter: (event) =>
        this.setState dragActive: true
        event.preventDefault()

      isFileAccepted: (file) ->
        switch this.props.accepts
          when 'images'
            file.type == 'application/x-moz-file' || file.type.substr(0, 6) == 'image/'
          else # everything else
            true

      render: ->
        classes = classNames this.props.className,
          if this.state.dragActive then this.props.activeClassName

        `<div className={classes}
              onDragEnter={this.onDragEnter}
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}>
          {this.props.children}
        </div>`