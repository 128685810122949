modulejs.define 'slzr/components/photo_chooser/photo',
  ['react', 'underscore', 'prop-types', 'react-cropper', 'react-input-range', 'slzr/components/photo_chooser/utilities'],
  (React, _, PropTypes, Cropper, InputRange, Utilities) ->

    # Displays a selected photo and manages the cropper for it, if enabled
    #
    # The selected crop area is rendered into hidden inputs, named with +cropAreaParam+ and +cropAreaName+ as such:
    #   x -> param[name]["x"]
    #   y -> param[name]["y"]
    #   ...
    # Basically, +cropAreaParam+ will be a hash, with +cropAreaName+ as a key, and then +x+, +y+, +w+, +h+ inside it.
    #
    # Be warned: This component currently doesn't fully reset when the props change. This is OK for now,
    # since the flow requires the user explicitly remove the photo before choosing a new one, in the process
    # destroying this component and eventually creating a new one.
    #
    # In most cases, a significant change such as changing the underlying photo, should be handled by changing the +key+
    # assigned to tell React to not reuse this component.
    class Photo extends React.Component
      @propTypes =
        # Photo object
        photo: PropTypes.shape(
          id: PropTypes.any
          library_id: PropTypes.any
          width: PropTypes.number
          height: PropTypes.number
          has_crop: PropTypes.bool
          preview_url: PropTypes.string
          preview_height: PropTypes.number
          preview_width: PropTypes.number
          min_height: PropTypes.number
        ).isRequired

        # Active crop area
        crop: PropTypes.shape
          x: PropTypes.number
          y: PropTypes.number
          w: PropTypes.number
          h: PropTypes.number

        # Crop area style name
        cropAreaName: PropTypes.string
        # Crop area param name
        cropAreaParam: PropTypes.string
        # Render hidden inputs with crop dimensions
        cropAreaInputs: PropTypes.bool

        # Allow adjustment of cropping area
        allowCropping: PropTypes.bool
        # Allow removing the photo
        allowRemove: PropTypes.bool
        # Show cropping editor link
        showCropEditorLink: PropTypes.bool
        # Show editor link
        showEditorLink: PropTypes.bool
        # Show caption editor
        allowCaptionEdit: PropTypes.bool

        # Callback for photo removal
        onRemove: PropTypes.func
        # Callback on crop change
        onCropChange: PropTypes.func
        # Callback on caption change
        onCaptionChange: PropTypes.func

        # Aspect ratio of crop, or -1 to match image
        aspect: PropTypes.number
        # Display width of the cropper
        width: PropTypes.number
        # Display height of the cropper
        height: PropTypes.number
        # Margin around crop area when cropping is allowed
        cropInset: PropTypes.number

        # These callbacks are intended only to notify of an user interaction
        #
        # onCropChange may be called during automated crop changes

        # Callback on crop start (cropper's cropstart)
        onCropStart: PropTypes.func
        # Callback on crop move (cropper's cropmove)
        onCropMove: PropTypes.func
        # Callback on crop end (cropper's cropend)
        onCropEnd: PropTypes.func
        # Callback on the oom slider starting to be manipulated
        onZoomStart: PropTypes.func
        # Callback on the zoom slider being changed
        onZoomChange: PropTypes.func
        # Callback on the zoom slider being finished moving
        onZoomEnd: PropTypes.func
        # force crop to be overriden without photo has_crop
        forceCropOverride: PropTypes.bool

      @defaultProps:
        # Width of preview display
        width: 220
        # Height of preview display, automatically determined from aspect ratio
        height: null
        # Minimum height of preview display
        min_height: 100
        # Crop aspect ratio
        aspect: 1
        # Crop box margin
        cropInset: 10
        # Crop area name
        cropAreaName: 'default'
        # Crop area param
        cropAreaParam: 'photo[crop_area]'
        # Crop area inputs
        cropAreaInputs: true
        showCropEditorLink: true
        showEditorLink: true
        allowCaptionEdit: true
        caption: ''

      constructor: (props) ->
        super props

        @element_id = _.uniqueId('photo-chooser-')
        @zooming = false
        this.state = this._calculateInitialState(props)

      # Reset state if we get a new photo or crop
      #
      # NOTE: New crop disabled as live updating breaks things like crazy
      UNSAFE_componentWillReceiveProps: (nextProps) =>
        if this.props.photo != nextProps.photo #|| this.props.crop != nextProps.crop
          unless @zooming
            next_state = this._calculateInitialState(nextProps)
            this.setState next_state
            @_cropChanged = true


      # NOTE: This is where incoming crop prop changes would be applied in a live-update scenario.
      # Howeever, until precision and other issues are accounted for, this causes many more problems than
      # it tries to solve, so it's disabled entirely.

      # Update canvas position if the crop changed
      # componentDidUpdate: (prevProps, prevState) =>
      #
      #   if false && @_cropChanged && this.props.crop && prevProps.crop && prevState.crop && !(
      #     prevProps.crop.x == this.props.crop.x && this.props.crop.x == this.state.crop.x &&
      #     prevProps.crop.y == this.props.crop.y && this.props.crop.y == this.state.crop.y &&
      #     prevProps.crop.w == this.props.crop.w && this.props.crop.w == this.state.crop.w &&
      #     prevProps.crop.h == this.props.crop.h && this.props.crop.h == this.state.crop.h
      #   )
      #     @_cropChanged = false
      #     # console.log 'componetnDidUpdate', this.props.cropAreaName, 'crop=', this.props.crop, 'prev=', prevProps.crop, 'state=', this.state.crop
      #     # console.log('would update canvas', this.props.cropAreaName, this.state.canvas)
      #     # Crop prop changed, so reposition the canvas
      #     this.cropper.setCanvasData this.state.canvas

      _calculateInitialState: (props) =>
        # Preview scale factors
        scale_w = props.photo.width / props.photo.preview_width
        scale_h = props.photo.height / props.photo.preview_height

        # Prepare the initial crop dimensions, scaled to the preview width
        if (props.photo.has_crop && props.crop) || (props.crop && props.forceCropOverride)
          # Don't round, as the double rounding causes drift over multiple saves
          the_crop =
            x: props.crop.x / scale_w
            y: props.crop.y / scale_h
            w: props.crop.w / scale_w
            h: props.crop.h / scale_h
        else
          # Create a default crop of the center portion in whatever crop aspect ratio we have
          the_crop =
            x: 0
            y: 0
            w: props.photo.width
            h: props.photo.height

          the_aspect = if props.aspect == -1 then props.photo.width / props.photo.height else props.aspect

          if props.photo.width > props.photo.height
            # Landscape
            crop_height = props.photo.preview_height
            crop_width = crop_height * the_aspect

            the_crop.x = Math.floor((props.photo.preview_width - crop_width) / 2)
            the_crop.y = 0
            the_crop.h = crop_height
            the_crop.w = crop_width
          else
            # Portrait
            crop_width = props.photo.preview_width
            crop_height = crop_width / the_aspect

            the_crop.x = 0
            the_crop.y = Math.floor((props.photo.preview_height - crop_height) / 2)
            the_crop.h = crop_height
            the_crop.w = crop_width

        # Calculate the zoom limits and initial zoom factor
        container_rect = this._calculateContainerDimensions()
        crop_box = this._calculateCropBox container_rect
        canvas_data = this._calculateCanvasPosition crop_box, the_crop
        zoom_data = this._calculateZoomData crop_box, canvas_data

        # The state stores all dimensions relative to the *preview* image.
        initial_state =
          showDebug: false
          crop: the_crop
          scale_w: scale_w
          scale_h: scale_h
          zoom: zoom_data.zoom
          min_zoom: zoom_data.min_zoom
          max_zoom: zoom_data.max_zoom
          zooming: false
          zoom_center:
            x: Math.floor the_crop.x + (the_crop.w / 2)
            y: Math.floor the_crop.y + (the_crop.h / 2)
          # Not truly part of state, but possibly needed in UNSAFE_componentWillReceiveProps
          canvas: canvas_data


      onRemove: (event) =>
        event.preventDefault()
        this.props.onRemove?(event)

      onCrop: (event) =>
        data = this.cropper.getData(true)

        the_crop =
          x: data.x
          y: data.y
          w: data.width
          h: data.height

        this.setState
          crop: the_crop

        unless @zooming
          this.setState
            zoom_center:
              x: data.x + (data.width / 2)
              y: data.y + (data.height / 2)

        this.props.onCropChange?(
          x: Math.floor(the_crop.x * this.state.scale_w)
          y: Math.floor(the_crop.y * this.state.scale_h)
          w: Math.ceil(the_crop.w * this.state.scale_w)
          h: Math.ceil(the_crop.h * this.state.scale_h)
        )


      # Zoom changed from the cropper
      onZoom: (event) =>
        # Clamp active zoom level to [min_zoom..max_zoom]
        target_zoom = Utilities.clamp Number(event.detail.ratio), this.state.min_zoom, this.state.max_zoom
        this.setState zoom: target_zoom

      # Zoom via slider started
      #
      # Capture the center point to anchor the zoom
      _zoomStarted: (event) =>
        x = Math.floor this.state.crop.x + (this.state.crop.w / 2)
        y = Math.floor this.state.crop.y + (this.state.crop.h / 2)
        this.setState zoom_center: {x, y}, zooming: true
        @zooming = true
        this.props.onZoomStart?(event, zoom_center: {x, y})

      _zoomEnd: (event) =>
        this.setState zooming: false
        @zooming = false
        this.props.onZoomEnd?(event, this.state.zoom, this.state.crop)

      _cropEnd: (event) =>
        this.props.onCropEnd?(event, this.state.crop)

      # Zoom changed via the zoom slider
      _zoomChange: (event) =>
        # There's a spurious change event before the first zoom, so just ignore it
        return unless @zooming

        # Clamp active zoom level to [min_zoom..max_zoom]
        value = event?.target?.value || event

        target_zoom = Utilities.clamp Number(value) / 1000, this.state.min_zoom, this.state.max_zoom

        crop_box = this.cropper.getCropBoxData()

        center_x = this.state.zoom_center.x
        center_y = this.state.zoom_center.y

        # Determine width/height
        new_crop_w = crop_box.width / target_zoom
        new_crop_h = new_crop_w / this.props.aspect

        # Apply offsets
        new_crop_x = center_x - (new_crop_w / 2)
        new_crop_y = center_y - (new_crop_h / 2)

        new_crop =
          x: Math.floor new_crop_x
          y: Math.floor new_crop_y
          w: Math.floor new_crop_w
          h: Math.floor new_crop_h

        # Reposition canvas based on zoom
        canvas_data = this._calculateCanvasPosition crop_box, new_crop
        this.cropper.setCanvasData canvas_data

        this.props.onZoomChange?(event, target_zoom)

        this.setState
          zoom: target_zoom
          crop: new_crop


      # Set the canvas based on the initial crop
      #
      # This also configures the crop box to have a 10px inset.
      #
      # We do this here, rather than below, because the canvas needs to be positioned and sized relative
      # to the crop box.
      _cropperReady: (event) =>
#        console.log 'cropper ready', this.props.cropAreaName
        crop_data =
          x: this.state.crop.x
          y: this.state.crop.y
          width: this.state.crop.w
          height: this.state.crop.h

        container = this.cropper.getContainerData()
        crop_box = this._calculateCropBox container
        canvas_data = this._calculateCanvasPosition crop_box
        zoom_data = this._calculateZoomData crop_box, canvas_data

        # Enable cropping and set up the canvas and crop areas to match
        # the initial crop state
        this.cropper.crop()
        this.cropper.setCanvasData canvas_data
        this.cropper.setCropBoxData crop_box

        if this.props.allowCropping
          this.cropper.enable()
        else
          this.cropper.disable()

        this.setState zoom_data


      _setCropRef: (crop) =>
        this.cropper = crop

      # Calculate container dimensions if necessary
      #
      # Will return width/height if specified, otherwise calculates height based on aspect
      _calculateContainerDimensions: =>
        the_width = this.props.width
        the_height = if this.props.height then this.props.height else Math.ceil(the_width / this.props.aspect)
        the_height = Math.max(the_height, this.props.min_height)

        # Rect for crop container
        container =
          top:    0
          left:   0
          width:  the_width
          height: the_height

      # Calculate the position of the crop box inside the container
      _calculateCropBox: (container) =>
        crop_box = {left: 0, top: 0, width: container.width, height: container.height}
        crop_box = Utilities.insetRect crop_box, this.props.cropInset if this.props.allowCropping
        crop_box

      # Calculate canvas position to fit the crop area inside crop_box
      _calculateCanvasPosition: (crop_box, the_crop=null) =>
        the_crop = this.state.crop unless the_crop?

        # Center  cut the crop area to match cropper aspect ratio
        crop_area = Utilities.cropRect {
          left: the_crop.x,
          top: the_crop.y,
          width: the_crop.w,
          height: the_crop.h
        }, crop_box.width / crop_box.height

        # Scale crop area
        [scale_x, scale_y] = Utilities.getRectScale crop_area, crop_box
        crop_area = Utilities.scaleRect crop_area, scale_x, scale_y

        canvas_data =
          left: Math.floor -crop_area.left + crop_box.left
          top: Math.floor -crop_area.top + crop_box.top
          width: Math.ceil this.props.photo.preview_width * scale_x
          height: Math.max(Math.ceil(this.props.photo.preview_height * scale_y), this.props.min_height)

      # Calculate the zoom data for the given crop box and canvas
      _calculateZoomData: (crop_box, canvas_data) =>
        # Minimum zoom is shortest dimension fully matching cropper height
        if this.props.photo.width > this.props.photo.height
          min_zoom = crop_box.height / this.props.photo.preview_height
        else
          min_zoom = crop_box.width / this.props.photo.preview_width

        zoom_data =
          zoom: canvas_data.width / this.props.photo.preview_width
          min_zoom: min_zoom
          max_zoom: 3.0

      captionForm: =>
        if this.props.allowCaptionEdit && this.props.allowCropping
          `<fieldset className="em-input-floating photo-chooser--caption-row">
            <label htmlFor={this.element_id + "-caption"}
                   className="em-input-floating--label">
              Photo Description
            </label>

            <input type="text"
                   className="em-input-floating--input"
                   id={this.element_id + "-caption"}
                   name="photo[caption]"
                   aria-label="Photo Description" /* Because input grouping is weird, overridden to be explicit */
                   defaultValue={this.props.photo.caption ? this.props.photo.caption : this.props.caption}
                   onChange={this.props.onCaptionChange} />
          </fieldset>`

      render: ->
        # Calculate the dimensions to fit in width
        target_size = this._calculateContainerDimensions()

        if this.props.allowCropping
          crop_name = "#{this.props.cropAreaParam}[#{this.props.cropAreaName}]"

          crop_inputs = this.props.cropAreaInputs && [
            `<input type="hidden" name={crop_name + "[x]"} key="x" value={Math.floor(this.state.crop.x * this.state.scale_w)} />`,
            `<input type="hidden" name={crop_name + "[y]"} key="y" value={Math.floor(this.state.crop.y * this.state.scale_h)} />`,
            `<input type="hidden" name={crop_name + "[w]"} key="w" value={Math.floor(this.state.crop.w * this.state.scale_w)} />`,
            `<input type="hidden" name={crop_name + "[h]"} key="h" value={Math.floor(this.state.crop.h * this.state.scale_h)} />`
          ]
          zoom_slider = `
            <div className="zoom-slider" style={{width: target_size.width}}>
              <i className="zoom-slider-label-left localist-icon-search-small-minus"></i>
              <i className="zoom-slider-label-right localist-icon-search-small-plus"></i>

              <div className="zoom-slider-input">
                <span id={this.element_id + "-zoom-slider-label"} hidden>Photo Zoom</span>
                <InputRange minValue={Math.floor(this.state.min_zoom * 1000)}
                            maxValue={Math.floor(this.state.max_zoom * 1000)}
                            onChange={this._zoomChange}
                            onChangeStart={this._zoomStarted}
                            onChangeComplete={this._zoomEnd}
                            value={Math.floor(this.state.zoom * 1000)}
                            formatLabel={function(){""}}
                            ariaLabelledby={this.element_id + "-zoom-slider-label"} />
              </div>
            </div>
          `
        else
          crop_inputs = null
          zoom_slider = null


        crop_debug = if this.state.showDebug
          `<p>
            State Crop: x={this.state.crop.x}, y={this.state.crop.y}, w={this.state.crop.w}, h={this.state.crop.h}, z={this.state.zoom}<br/>
            Scale: w={this.state.scale_w}, h={this.state.scale_h}<br/>
            Center: x={this.state.zoom_center.x}, y={this.state.zoom_center.y}<br/>
            Scaled Crop: x={Math.floor(this.state.crop.x * this.state.scale_w)},
            y={Math.floor(this.state.crop.y * this.state.scale_h)},
            w={Math.floor(this.state.crop.w * this.state.scale_w)},
            h={Math.floor(this.state.crop.h * this.state.scale_h)}<br/>
          </p>`

        # Build the action buttons
        remove_button = if this.props.allowRemove
          `<li><a href="#" onClick={this.onRemove} title="Remove Photo" className="remove-photo"><i className="fas fa-trash-alt localist-icon-trash-small" aria-hidden="true"></i><span className="sr-only">Remove photo</span></a></li>`
        edit_cropping_link = if this.props.showCropEditorLink && this.props.editCroppingUrl
          `<li><a href={this.props.editCroppingUrl} title="Crop Photo" target="_blank"><i className="fa fas fa-crop" aria-hidden="true"></i><span className="sr-only">Crop photo</span></a></li>`
        edit_link = if this.props.showEditorLink && this.props.editUrl
          `<li><a href={this.props.editUrl} title="Edit Photo" target="_blank"><i className="fas fa-edit localist-icon-edit-small" aria-hidden="true"></i><span className="sr-only">Edit photo</span></a></li>`

        `//`# Setting the key on Cropper forces it to recreate the component when the photo changes
        `<div className="photo-chooser-preview">
          {crop_inputs}

          <Cropper ref={this._setCropRef}
                   key={this.props.photoKey}
                   viewMode={1}
                   src={this.props.photo.preview_url}
                   style={{width: target_size.width, height: target_size.height}}
                   className="photo-chooser-cropper-container"
                   aspectRatio={this.props.aspect}
                   dragMode="move"
                   guides={false}
                   highlight={false}
                   center={false}
                   autoCropArea={1}
                   autoCrop={false}
                   rotatable={false}
                   cropBoxMovable={false}
                   cropBoxResizable={false}
                   toggleDragModeOnDblclick={false}
                   crop={this.onCrop}
                   zoom={this.onZoom}
                   ready={this._cropperReady}
                   cropstart={this.props.onCropStart}
                   cropmove={this.props.onCropMove}
                   cropend={this._cropEnd}

            />

          {zoom_slider}

          <ul className="photo-chooser-actions">
            {remove_button}
            {edit_cropping_link}
            {edit_link}
          </ul>

          {this.captionForm()}
          {crop_debug}
        </div>`