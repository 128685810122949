modulejs.define 'slzr/local_session_store', ->
  # Tiny wrapper around localStorage or sesisonStorage, to automatically serialize as json, and optional expiration
  #
  # If the browser does not support localStorage or sessionStorage, writes are ignored and reads always return undefined.
  class LocalSessionStore
    store: null

    # Initialize an instance of LocalSessionStore.
    #
    # +type+ may be either "local" for localStorage or "session" for sessionStorage
    constructor: (type='local') ->
      switch type
        when 'local'
          @store = window.localStorage if 'localStorage' of window
        when 'session'
          @store = window.sessionStorage if 'sessionStorage' of window
        else
          throw 'Unknown storage type'

    # Set +key+ to +value+, with optional expiration time in +expires_in+ seconds
    set: (key, value, expires_in=0) ->
      return undefined unless @store

      data = {
        expires_at: (new Date()).getTime() + (expires_in * 1000)
        value: value
      }

      @store.setItem key, JSON.stringify(data)


    # Retrieve +key+
    get: (key) ->
      return undefined unless @store

      data = @store.getItem key
      return undefined unless data

      data = JSON.parse(data)

      # Verify expiration
      if data.expires_at < (new Date()).getTime()
        @store.removeItem key
        return undefined

      data.value

    # Remove +key+
    remove: (key) ->
      return undefined unless @store
      @store.removeItem key