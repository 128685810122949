modulejs.define 'registration/editor_controller',
  ['underscore', 'react', 'react-dom', 'redux', 'react-redux',
   'slzr/redux/middleware', 'registration/components/editor', 'registration/store/reducer',
   'registration/store/actions'],
  (_, React, ReactDOM, Redux, ReactRedux,
   Middleware, RegistrationEditor, RegistrationStoreReducer,
   Actions) ->

    # The Editor "Controller" for an instance.
    #
    # Sets up a redux store and initializes React
    class Editor
      # Element editor is attached to
      element: null

      # Editor for this event
      event_id: null

      # initial registration data
      initial_data: null

      constructor: (element, event_id, initial_data) ->
        @element = element
        @event_id = event_id
        @initial_data = initial_data

        @store = @createStore()

        @react_element = ReactDOM.render React.createElement(
          ReactRedux.Provider, {
            store: @store
          }, React.createElement(RegistrationEditor)
        ), element

      # Handler to notify that the selected event experience changed
      eventExperienceChanged: (experience) ->
        @store.dispatch Actions.eventExperienceChange(experience: experience)


      # Handler to notify that the stream URL changed
      streamUrlChanged: (stream_url) ->
        @store.dispatch Actions.eventStreamUrlChange(stream_url: stream_url)

      # Handler to notify that the payout account ID changed
      eventPayoutAccountIdChanged: (payout_account_id) ->
        @store.dispatch Actions.eventPayoutAccountIdChange(payout_account_id: payout_account_id)

      triggerChange: =>
        this.element.dispatchEvent(new Event('change', {bubbles: true}))


      # Create the Redux store and initialize it with the initial data provided
      createStore: =>
        initial_state = _.extend {debug: true}, this.initial_data

        my = this
        # Simple middleware for listening to the specified actions to emit a change event
        changedActionEventMiddleware = (store) -> (next) -> (action) ->
          result = next(action)

          # Trigger a change event on all dispatcehd actions, except for the intermediate dragging ones
          # (This takes advantage of the fact that the action types all have the same naming structure.)
          # It matters that the _ is only included for _DRAG_START but not the rest, as it's just taking the suffix on the type.
          unless action.type.substr(-11) in ['_DRAG_START', 'DRAG_CANCEL', 'DRAG_UPDATE']
            my.triggerChange()

          result

        middlewares = [
          Middleware.debounceMiddleware,
          Middleware.promiseMiddleware,
          Middleware.thunkMiddleware,
          changedActionEventMiddleware,
        ]
        middlewares.push(Middleware.loggerMiddleware) if Middleware.isDevelopment()

        # Coffeescript 1 doesn't support arguments splat
        createStoreWithMiddleware = Redux.applyMiddleware.apply(null, middlewares)(Redux.createStore)

        store = createStoreWithMiddleware RegistrationStoreReducer,
          initial_state,
          window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
