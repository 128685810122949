# Eventreach form adapter and controller
#
# On an event edit page, this will attach to the form and monitor changes,
# triggering score updates. This also is responsible for initializing the
# on-page react list component.

modulejs.define 'slzr/eventreach/form',
['slzr/eventreach/engine', 'jquery', 'underscore', 'react', 'react-dom', 'EventEmitter', 'slzr/eventreach/component'],
(EventReach, $, _, React, ReactDOM, EventEmitter, EventReachComponent) ->

  Engine = EventReach.EventReachEngine

  # EventReach data source from an event edit form
  #
  # Emits an event on (form) change, causing the react component to update
  class EventReachForm extends EventEmitter
    # jQuery element for the form we attach to
    form: null

    # current event data, in normalized form for the rules engine
    event: {}

    # Eventreach engine
    engine: null

    # Eventreach result
    result: null

    # Event visibility is public
    visible: true

    # Event is included in trending
    can_trend: true

    constructor: (options) ->
      super()

      @form = $(options.form)
      @eventreach_container = $(options.status_container)
      @platform_config = options.platform_config

      @_parseForm()
      @_initializeEngine()
      @_initializeReact()
      @_attachEvents()

    # Attach events to the form inputs
    _attachEvents: =>
      @form.on 'change.eventreach ifChanged.eventreach', 'input, select, textarea', @_onChange
      @form.on 'slzr:instance:add.eventreach slzr:instance:change.eventreach slzr:instance:remove.eventreach', (ev) => _.defer(@_onChange, ev)
      @form.on 'slzr:filter:add.eventreach slzr:filter:remove.eventreach', @_onChange
      @form.on 'slzr:photo:remove.eventreach slzr:photo:change.eventreach', @_onChange
      @form.on 'slzr:location:change.eventreach slzr:location:remove.eventreach', @_onChange
      @form.on 'slzr:item:add.eventreach slzr:item:remove.eventreach', @_onChange
      @form.on 'slzr:date:change', @_onChange

    # Initialize eventreach engine
    _initializeEngine: =>
      @engine = new Engine(@platform_config)
      @result = @engine.evaluate(@event)

    # Initialize react component
    _initializeReact: =>
      event_reach_component = React.createElement(EventReachComponent, eventReach: this)
      ReactDOM.render event_reach_component, @eventreach_container.get(0)

    # Form changed
    _onChange: (ev) =>
      _.defer =>
        @_parseForm()
        @result = @engine.evaluate(@event)
        @emit 'change'

    # Parse form into the internal state EventReach needs
    _parseForm: =>
      data = @form.serializeArray()

      # Get names of any inputs that have the IE8 placeholders active
      placeholdered = @form.find('.input_placeholder').map(-> @name).get()

      # Convert the array into an object
      #
      # If the key ends in [], treat it as an array value, otherwise
      # a single value.
      values = {}
      for field in data
        field_value = field.value
        # If the placeholder is active, the field has no value
        field_value = '' if placeholdered.indexOf(field.name) > -1

        if field.name.match /\[\]$/
          values[field.name] ||= []
          values[field.name].push field_value
        else
          values[field.name] = field_value

      event =
        id: values._event_id
        created_at: if values.created_at != '' then new Date(values.created_at)
        filters: {}

      # Extract basic input fields
      for key, value of values
        if m = key.match /^event\[([a-z0-9_-]+)\]/i
          # Rename fields
          attribute_name = switch m[1]
            when 'ticket_info' then 'ticket_url'
            when 'keyword_list' then 'keywords'
            when 'tag_list' then 'tags'
            else m[1]

          event[attribute_name] = value

        # Add selected tags that aren't blank
        if m = key.match /^tags\[([a-z0-9_-]+)\]/i
          context = m[1]
          event.filters[context] = _.select value, (i) -> i != ''

      # Fix boolean values
      event.allows_reviews = event.allows_reviews == '1'
      event.allows_attendance = event.allows_attendance == '1'
      event.is_sponsored = event.is_sponsored == '1'
      event.hide_from_main_calendar = event.hide_from_main_calendar == '1'
      event.visible_only_to_widget = event.visible_only_to_widget == '1'
      event.visible_only_to_channel = event.visible_only_to_channel == '1'
      event.included_in_trending = event.exclude_from_trending != '1'

      # Add instance dates, filters, featured sections
      if values.location_is == 'venue'
        event.business_id = values.business_email
        event.location = values.location
      else if values.location_is == 'other'
        event.business_id = null
        event.location = values.location

      event.has_photo = values.photo_key != '' && values.photo_key != 'deleted'
      values["scheduled_date[]"] ||= []
      event.instance_dates = (new Date(d.split(/,/)[0]) for d in values["scheduled_date[]"])
      event.featured_sections = values['featured_section_ids[]']

      # And the date input
      date_input = @form.find('[data-instance="start-date"]').data()
      if date_input?.dateStatus == 'ok'
        event.instance_dates.push date_input.date

      @event = event
      @visible = (event.visibility == 'visible') && !event.hide_from_main_calendar && !event.visible_only_to_widget && !event.visible_only_to_channel
      @can_trend = (event.exclude_from_trending != '1')

