# Analytics integrations

modulejs.define 'slzr/analytics',
  ['jquery', 'slzr/google_analytics'],
  ($, GoogleAnalytics) ->

    # Track a form submission.
    #
    # Not sure this is actively used anymore.
    trackFormSubmit = (form) ->
      $form = $(form)
      category = $form.data('submit-ganame')
      label = $form.find('input[type="submit"]').attr('originalValue') || $form.find('input[type="submit"]').val()

      GoogleAnalytics.trackGAEvent(category, 'clicked', label)


    # Register event listeners for automatic google analytics event tracking on both data-ganame, and data-ga-event
    registerListeners = ->
      $(document).on 'click', '[data-ga-event]', ->
        $this = $(this)

        event = $this.data('ga-event')
        category = $this.data('ga-category') || $this.parents('[id!=""]:first').get(0).id
        action = $this.data('ga-action') || 'clicked'
        label = $this.data('ga-label') #|| $this.text() || $this.children('img:first').attr('alt')

        GoogleAnalytics.trackGAEvent(category, action, label)

      $(document).on 'click', '[data-ganame]', ->
        el = $(this)

        event_category = el.data('ganame')
        event_label = el.data('ganame-label') || el.text() || el.val() || el.children('img:first').attr('alt')

        GoogleAnalytics.trackGAEvent event_category, 'clicked', event_label


    # Exports
    return { trackFormSubmit, registerListeners }