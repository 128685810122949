modulejs.define 'slzr/react/currency_input',
  ['react', 'prop-types', 'currency-formatter', 'classnames', 'react-text-mask', 'text-mask-addons/dist/createNumberMask'],
  (React, PropTypes, currencyFormatter, classNames, MaskedInput, createNumberMask) ->

    # "Dumb" Currency input
    #
    # Basically, just a text field with a currency symbol prefix/suffix, and some input normalization to an integer
    # cents value
    class CurrencyInput extends React.Component
      @propTypes:
        # Currency identifier, as a string ("usd")
        currency: PropTypes.string
        # Initial value as cents
        value: PropTypes.number
        # Change callback, takes arguments: (event, cents)
        onChange: PropTypes.func
        # Placeholder currency value as cents (will be formatted automatically)
        placeholderValue: PropTypes.number
        # Placeholder string
        placeholder: PropTypes.string
        # Is input disabled
        disabled: PropTypes.bool

      @defaultProps:
        currency: 'usd'
        value: null
        disabled: false
        onChange: () ->

      # Flag indicating the user has interacted with this input
      dirty: false

      # The raw cents value currently held in this input
      centsValue: null

      # How many cents are in the primary "unit" of the currency (i.e. 100 cents in 1 US Dollar)
      centsUnit: 1

      constructor: (props) ->
        super(props)

        # Look up currency details
        @currency = currencyFormatter.findCurrency(props.currency.toUpperCase()) || currencyFormatter.findCurrency('USD')

        # Cents unit size (i.e. 100 cents = 1 dollar)
        @centsUnit = Math.pow(10, @currency.decimalDigits)

        @dirty = false
        @centsValue = props.value || null

        @currencyMask = createNumberMask
          prefix: ''
          suffix: ''
          thousandsSeparatorSymbol: @currency.thousandsSeparator
          decimalSymbol: @currency.decimalSymbol
          allowDecimal: true
          decimalLimit: @currency.decimalDigits
          allowNegative: false
          allowLeadingZeros: false

        @state =
          placeholder: if props.placeholderValue then @_formatCentsValue(props.placeholderValue) else if props.placeholder then props.placeholder else @_formatCentsValue(0)
          # Initialize the input value to a formatted version of the value prop, unless it's blank
          inputValue: if @centsValue == null then '' else @_formatCentsValue(@centsValue)

      UNSAFE_componentWillReceiveProps: (nextProps) =>
        # Refresh cached currency
        if nextProps.currency != @props.currency
          @currency  = currencyFormatter.findCurrency(nextProps.currency.toUpperCase())
          @centsUnit = Math.pow(10, @currency.decimalDigits)
          @setState placeholder: @_formatCentsValue(0)

          @currencyMask = createNumberMask
            prefix: ''
            suffix: ''
            thousandsSeparatorSymbol: @currency.thousandsSeparator
            decimalSymbol: @currency.decimalSymbol
            allowDecimal: true
            decimalLimit: @currency.decimalDigits
            allowNegative: false
            allowLeadingZeros: false

        # If we receive a new value prop that is actually different than the current input state, not just
        # different than the last value prop we received, then reset the internal state.
        #
        # Receiving a different value prop that doesn't *actually* change the input currency value is likely
        # just from an input handler above us.
        if nextProps.value != @props.value && nextProps.value != @centsValue
          @dirty = false
          @centsValue = nextProps.value
          @setState
            inputValue: @_formatCentsValue(nextProps.value)


      # Delegate input change event to onChange prop, providing a numeric cents value, or an error
      onInputChange: (event) =>
        @centsValue = if event.target.value == ''
          null
        else
          Math.round(currencyFormatter.unformat(event.target.value, {code: @currency.code}) * @centsUnit)

        @dirty = true
        @setState
          inputValue: event.target.value

        @props.onChange event, @centsValue

      render: ->
        # Prepare the prefix/suffix for the currency symbol
        if @currency.symbolOnLeft
          prefix = @currency.symbol
          suffix = null
        else
          prefix = null
          suffix = @currency.symbol

        outerClasses = classNames 'currency-input', this.props.className,
          'currency-input-prefixed': !!prefix
          'currency-input-suffixed': !!suffix

        inputClasses = classNames 'currency-input-value',
          'currency-input-prefixed': !!prefix
          'currency-input-suffixed': !!suffix


        `<span className={outerClasses}>
          {prefix && <span className="currency-input-prefix">{prefix}</span>}
          <MaskedInput type="text"
                       className={inputClasses}
                       name={this.props.name}
                       value={this.state.inputValue}
                       mask={this.currencyMask}
                       disabled={this.props.disabled}
                       placeholder={this.state.placeholder}
                       onChange={this.onInputChange}/>
          {suffix && <span className="currency-input-suffix">{suffix}</span>}
        </span>`

      # Format +cents+ as a pretty value for the input, according to the currency's rules, without the currency symbol
      _formatCentsValue: (cents) =>
        currencyFormatter.format cents / @centsUnit, code: @currency.code, symbol: ''