/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Main application javascript entry point

// This is roughly equivalent to app/assets/javascripts/application.js
//
// "Modules" are required using `require`. If the path is not relative to `.` or `..`,
// it is resolved relative to, in order: app/javascripts, app/assets, vendor/assets
//
// The legacy files, under app/assets and vendor/assets, are not true modules as
// understood by webpack. However, requiring them from an entry point file does
// cause them to be compiled into the bundle, and initialization code to be run.
//
// The ordering of files here is important, as it influences the order the any
// initialization code within the file is run. For example, jquery must be required
// before any of its plugins are required.
//
// The modulejs system used in the legacy files does not implement full dependency
// ordering, so modulejs modules must be ordered to ensure their dependencies are
// available at the time of require. This is why some extra scripts are required
// that aren't in the legacy manifests.

// Third party dependencies, located in vendor/assets
require("javascripts/firebug/firebugx.js");
require("javascripts/modulejs.js"); // Exposes itself globally
require("javascripts/jquery.js"); // Exposes itself
require("javascripts/jquery_ujs.js");
require("javascripts/easing.js"); // jQuery plugin
require("javascripts/jquery.string.1.1.0.js"); // jQuery plugin
require("javascripts/jquery.addplaceholder.js"); // jQuery plugin
require("javascripts/jquery.bgiframe.js"); // jQuery plugin
require("javascripts/jquery-ui-1.9.2.custom.js"); // jQuery plugin
require("javascripts/jquery.cookie.js"); // jQuery plugin
require("javascripts/jquery.iframe-transport.js"); // jQuery plugin
require("javascripts/jquery.fileupload.js"); // jQuery plugin
require("javascripts/date.js"); // Modifies global Date
require("javascripts/date-en-AU.js"); // Plugin for above
require("javascripts/date-en-GB.js"); // Plugin for above
require("javascripts/fancybox.js"); // jQuery plugin
require("javascripts/bootstrap-tooltip.js"); // jQuery plugin
require("javascripts/icheck.min.js"); // jQuery plugin
require("javascripts/background-check.js"); // Doesn't need to be exposed

// Old IE polyfills
require("javascripts/es5-shim.js");
require("javascripts/es5-sham.js");
require("javascripts/polyfills/custom_event.js");

// Load some polyfills for older IEs
// Babel's polyfills
require("@babel/polyfill");
// and fetch
require("whatwg-fetch");

// Register underscore with modulejs, and also expose it on the global _
const underscore = require("expose-loader?exposes=_!underscore");
modulejs.define("underscore", () => underscore);

// Register moment with modulejs
const moment = require("moment");
modulejs.define("moment", () => moment);

// Register moment-recur with modulejs, and expose it as the global `moment`
const momentRecur = require("expose-loader?exposes=moment!moment-recur");
modulejs.define("moment-recur", () => momentRecur);

// Register object-path with modulejs
const objectPath = require("object-path");
modulejs.define("object-path", () => objectPath);

// Register object-path-immutable with modulejs
const objectPathImmutable = require("object-path-immutable");
modulejs.define("object-path-immutable", () => objectPathImmutable);

const react = () => require("react");
modulejs.define("react", react);

const reactDOM = () => require("react-dom");
modulejs.define("react-dom", ["react"], reactDOM);

const redux = () => require("redux");
modulejs.define("redux", redux);

const reactRedux = () => require("react-redux");
modulejs.define("react-redux", ["react", "redux"], reactRedux);

const reactRouterDom = () => require("react-router-dom");
modulejs.define("react-router-dom", ["react"], reactRouterDom);

const propTypes = () => require("prop-types");
modulejs.define("prop-types", ["react"], propTypes);

const classnames = () => require("classnames");
modulejs.define("classnames", classnames);

const immutabilityHelper = () => require("immutability-helper");
modulejs.define("immutability-helper", immutabilityHelper);

const reactInputRange = () => require("react-input-range");
modulejs.define("react-input-range", ["react"], reactInputRange);

const reactCropper = () => require("react-cropper").default;
modulejs.define("react-cropper", reactCropper);

const createReactClass = () => require("create-react-class");
modulejs.define("create-react-class", ["react"], createReactClass);

const currencyFormatter = () => require("currency-formatter");
modulejs.define("currency-formatter", currencyFormatter);

const reactTextMask = () => require("react-text-mask").default;
modulejs.define("react-text-mask", ["react"], reactTextMask);

const createNumberMask = () =>
  require("text-mask-addons/dist/createNumberMask").default;
modulejs.define("text-mask-addons/dist/createNumberMask", createNumberMask);

const reactStripeElements = () => require("react-stripe-elements");
modulejs.define("react-stripe-elements", reactStripeElements);

const isemail = () => require("isemail");
modulejs.define("isemail", isemail);

const reselect = () => require("reselect");
modulejs.define("reselect", reselect);

const reReselect = () => require("re-reselect").default;
modulejs.define("re-reselect", reReselect);

const uuidV1 = () => require("uuid/v1");
modulejs.define("uuid/v1", uuidV1);

const uuidV3 = () => require("uuid/v3");
modulejs.define("uuid/v3", uuidV3);

const uuidV4 = () => require("uuid/v4");
modulejs.define("uuid/v4", uuidV4);

const uuidV5 = () => require("uuid/v5");
modulejs.define("uuid/v5", uuidV5);

const popperCore = () => require("@popperjs/core");
modulejs.define("@popperjs/core", popperCore);

// Emphasis theme
const reactDayPicker = () => require("react-day-picker");
modulejs.define("react-day-picker", reactDayPicker);
const dateRangePicker = () => require("daterangepicker");
modulejs.define("daterangepicker", dateRangePicker); //TODO: Remove

// Registration specific modules
// Eventually, these should be pulled out into another module
const history = () => require("history");
modulejs.define("history", history);

const reactTransitionGroup = () => require("react-transition-group");
modulejs.define("react-transition-group", reactTransitionGroup);

const reactQRReader = () => require("react-qr-reader");
modulejs.define("react-qr-reader", reactQRReader);

// React-DnD
const reactDnD = () => require("react-dnd");
modulejs.define("react-dnd", reactDnD);

const reactDnDHTML5Backend = () => require("react-dnd-html5-backend");
modulejs.define("react-dnd-html5-backend", reactDnDHTML5Backend);

// React-Select
const reactSelect = () => require("react-select");
modulejs.define("react-select", reactSelect);

// CKEditor
const ckEditor4React = () => require("ckeditor4-react");
modulejs.define("ckeditor4-react", ckEditor4React);

// Register React stripe libraries
const reactStripeJS = require("@stripe/react-stripe-js");
modulejs.define("@stripe/react-stripe-js", () => reactStripeJS);

const stripeJS = require("@stripe/stripe-js");
modulejs.define("@stripe/stripe-js", () => stripeJS);

const FocusTrap = require("./utils/modal-focus-trap.js").default;
modulejs.define("slzr/modal-focus-trap", () => FocusTrap);

// Our primary javascript (app/assets/coffee/index.js manifest)

// Exposes namespace, Slzr exports as globals for legacy compatibility
//
// These are named exports from the lightbox module, hence the duplication of names
require("expose-loader?exposes=Slzr|Slzr,namespace|namespace!javascripts/coffee/base.js.coffee");
// Load the consent manager early, so it can be initialized for the rest of the JS
require("expose-loader?exposes=Slzr.ConsentManager!./cookie-consent-manager/api.js");
require("./cookie-consent-manager/index.js");
require("javascripts/coffee/onready.js.coffee");
require("javascripts/coffee/xhrfileupload.js.coffee");

// Expose some globals from the lightbox and facebook modules
//
// These are named exports from the modules, hence the duplication of names
require("expose-loader?exposes=openIbox|openIbox,hideIbox|hideIbox,default_fancybox_options|default_fancybox_options!javascripts/coffee/lightbox.js.coffee");
require("expose-loader?exposes=postFBFeedItem|postFBFeedItem!javascripts/coffee/facebook.js.coffee");

// Require javascripts/coffee recursively
const coffeeScripts = require.context("javascripts/coffee", true);
coffeeScripts.keys().forEach(coffeeScripts);

// Our JST templates
//
// The default export of the module is exposed as window.JST
require("expose-loader?exposes=JST!./jst-templates/index.js");

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)
