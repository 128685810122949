var ___EXPOSE_LOADER_IMPORT___ = require("-!../../../../node_modules/babel-loader/lib/index.js??ruleSet[1].rules[2].use[0]!../../../../node_modules/coffee-loader/dist/cjs.js!./lightbox.js.coffee");
var ___EXPOSE_LOADER_GET_GLOBAL_THIS___ = require("../../../../node_modules/expose-loader/dist/runtime/getGlobalThis.js");
var ___EXPOSE_LOADER_GLOBAL_THIS___ = ___EXPOSE_LOADER_GET_GLOBAL_THIS___;
var ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___ = ___EXPOSE_LOADER_IMPORT___.openIbox
if (typeof ___EXPOSE_LOADER_GLOBAL_THIS___["openIbox"] === 'undefined') ___EXPOSE_LOADER_GLOBAL_THIS___["openIbox"] = ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___;
var ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___ = ___EXPOSE_LOADER_IMPORT___.hideIbox
if (typeof ___EXPOSE_LOADER_GLOBAL_THIS___["hideIbox"] === 'undefined') ___EXPOSE_LOADER_GLOBAL_THIS___["hideIbox"] = ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___;
var ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___ = ___EXPOSE_LOADER_IMPORT___.default_fancybox_options
if (typeof ___EXPOSE_LOADER_GLOBAL_THIS___["default_fancybox_options"] === 'undefined') ___EXPOSE_LOADER_GLOBAL_THIS___["default_fancybox_options"] = ___EXPOSE_LOADER_IMPORT_MODULE_LOCAL_NAME___;
module.exports = ___EXPOSE_LOADER_IMPORT___;
