modulejs.define 'registration/components/checkout/promo_code_form',
  ['react',
   'slzr/react/form_input_group', 'slzr/react/form_row'],
  (React,
   FormInputGroup, FormRow) ->

    class PromoCodeForm extends React.Component



      render: ->
        if this.props.activePromoCode
          this.renderPromoCodeStatus()
        else
          this.renderOpenForm()

      onRemovePromoCode: (event) =>
        event.preventDefault()
        this.props.onRemovePromoCode?()

      renderPromoCodeStatus: =>
        `<FormInputGroup className="promo-group-wrap">
          <FormRow title="Promo Code: ">
            {this.props.promoCode}
            <a className="promo_remove em-link" href="#" onClick={this.onRemovePromoCode}>{this.props.loading ? "Removing..." : "Remove"}</a>
          </FormRow>
        </FormInputGroup>`

      _setInputRef: (element) => this.promoCodeInput = element

      onApplyPromoCode: (event) =>
        event.preventDefault()

        new_promo_code = this.promoCodeInput.value
        this.props.onApplyPromoCode?(new_promo_code)

      renderOpenForm: =>
        error_message = switch @props.promoCodeError
          when 'invalid'
            "That promo code is invalid"
          when 'expired'
            "That promo code has expired"
          else
            null

        `<div className="promo_code">
            <div className="em-input_container">
              <div className={error_message ? "em-input-floating em-error" : "em-input-floating"}>
                <input type="text" defaultValue={this.props.promoCode} ref={this._setInputRef} placeholder="Add promo code" />
                <label>Promo code</label>
                <i className="fas fa-ticket-alt"></i>
              </div>
              {error_message && <span className="error_marker" role="alert">{error_message}</span>}
            </div>

          <div className="action_button">
            <button className="em-link" onClick={this.onApplyPromoCode}>{this.props.loading ? "Checking..." : "Apply promo"}
              <i className="fas fa-long-arrow-alt-right"></i>
            </button>
          </div>
        </div>`


