modulejs.define 'registration/store/actions',
  ['react', 'redux', 'prop-types'],
  (React, Redux, PropTypes) ->

    exports = {}

    # Global counter for creating placeholder IDs for new objects
    #
    # (The actual value doesn't matter, it's just for linking data before it's persisted)
    _next_id = 0

    # Return an ID for the given type
    getId = (type) ->
      _next_id = _next_id + 1
      "_#{type}#{_next_id}"

    exports.getId = getId

    ###################
    ## OBJECT SHAPES ##
    ###################

    DateStringType = PropTypes.oneOfType [ PropTypes.instanceOf(Date), PropTypes.string ]

    # Ticket class properties
    exports.TicketClassShape = PropTypes.shape
      active: PropTypes.bool
      availability_finish: DateStringType
      availability_start: DateStringType
      created_at: DateStringType
      created_by: PropTypes.number
      currency: PropTypes.string
      customer_pays_fees: PropTypes.bool
      deleted_at: DateStringType
      deleted_by: PropTypes.number
      description: PropTypes.string
      donation: PropTypes.bool
      event_id: PropTypes.number
      hidden: PropTypes.bool
      id: PropTypes.oneOfType [
          PropTypes.number,
          PropTypes.string
        ]
      max_price: PropTypes.number
      min_price: PropTypes.number
      name: PropTypes.string
      payout_account_id: PropTypes.number
      platform_id: PropTypes.number
      position: PropTypes.number
      price: PropTypes.number
      purchase_limit: PropTypes.number
      purchase_minimum: PropTypes.number
      quantity_limit: PropTypes.number
      refunds_allowed: PropTypes.bool
      ticket_group_id: PropTypes.number
      updated_at: DateStringType
      updated_by: PropTypes.number
      promo_code_ids: PropTypes.arrayOf PropTypes.number
      create: PropTypes.bool
      delete: PropTypes.bool
      ticket_type: PropTypes.oneOf ["in_person", "virtual", "wait_list"]
      virtual_event_message: PropTypes.string
      virtual_event_url: PropTypes.string

    # Group properties
    exports.TicketGroupShape = PropTypes.shape
      id: PropTypes.oneOfType [
          PropTypes.number,
          PropTypes.string
        ]
      name: PropTypes.string
      position: PropTypes.number
      create: PropTypes.bool
      delete: PropTypes.bool

    # Promo code properties
    exports.PromoCodeShape = PropTypes.shape
      active: PropTypes.bool
      amount: PropTypes.number
      code: PropTypes.string
      created_at: DateStringType
      created_by: PropTypes.number
      deleted_at: DateStringType
      deleted_by: PropTypes.number
      discount_type: PropTypes.string
      event_id: PropTypes.number
      id: PropTypes.oneOfType [
          PropTypes.number,
          PropTypes.string
        ]
      percent: PropTypes.number
      response_type: PropTypes.string
      usage_limit: PropTypes.number
      total_usage: PropTypes.number
      updated_at: DateStringType
      updated_by: PropTypes.number
      create: PropTypes.bool
      delete: PropTypes.bool

    # Attendee Question properties
    exports.AttendeeQuestionShape = PropTypes.shape
      active: PropTypes.bool
      created_at: DateStringType
      created_by: PropTypes.number
      deleted_at: DateStringType
      deleted_by: PropTypes.number
      event_id: PropTypes.number
      id: PropTypes.oneOfType [
          PropTypes.number,
          PropTypes.string
        ]
      position: PropTypes.number
      question: PropTypes.string
      required: PropTypes.bool
      response_type: PropTypes.string
      updated_at: DateStringType
      updated_by: PropTypes.number
      create: PropTypes.bool
      delete: PropTypes.bool

    #####################
    ## ACTION CREATORS ##
    #####################

    # Helper to define the constant and basic action creator
    createAction = (name) ->
      exports[name] = name

      (payload, extra={}) ->
        _.assign {type: name, payload: payload}, extra

    ## GENERAL ACTIONS ##

    # Set ticket groups, ticket classes, promo codes and attendee questions
    exports.setTicketClassesGroupsAttendeeQuestionsPromos = createAction 'SET_TICKET_CLASSES_GROUPS_ATTENDEE_QUESTIONS_PROMOS'

    # Selected event experience in form changed
    exports.eventExperienceChange = createAction 'EVENT_EXPERIENCE_CHANGE'
    # Event Stream URL entered in form changed
    exports.eventStreamUrlChange = createAction 'EVENT_STREAM_URL_CHANGE'
    # Event Payout Account ID entered in the form changed
    exports.eventPayoutAccountIdChange = createAction 'EVENT_PAYOUT_ACCOUNT_ID_CHANGE'

    ## TICKET CLASS ACTIONS ##
    exports.TICKET_CLASS_ADD = 'TICKET_CLASS_ADD'
    exports.addTicketClass = (payload, extra={}) ->
      next_id = getId('ticket')
      _.assign {type: 'TICKET_CLASS_ADD', payload: {id: next_id}}, extra

    exports.removeTicketClass = createAction 'TICKET_CLASS_REMOVE'
    exports.changeTicketClass = createAction 'TICKET_CLASS_CHANGE'
    exports.ticketClassDragStart = createAction 'TICKET_CLASS_DRAG_START'
    exports.ticketClassDragFinish = createAction 'TICKET_CLASS_DRAG_FINISH'
    exports.ticketClassDragCancel = createAction 'TICKET_CLASS_DRAG_CANCEL'
    exports.ticketClassDragUpdate = createAction 'TICKET_CLASS_DRAG_UPDATE'

    ## TICKET GROUP ACTIONS ##
    exports.TICKET_GROUP_ADD = 'TICKET_GROUP_ADD'
    exports.addTicketGroup = (payload, extra={}) ->
      next_id = getId('ticket_group')
      _.assign {type: 'TICKET_GROUP_ADD', payload: {id: next_id}}, extra

    exports.removeTicketGroup = createAction 'TICKET_GROUP_REMOVE'
    exports.changeTicketGroup = createAction 'TICKET_GROUP_CHANGE'
    exports.ticketGroupDragStart = createAction 'TICKET_GROUP_DRAG_START'
    exports.ticketGroupDragFinish = createAction 'TICKET_GROUP_DRAG_FINISH'
    exports.ticketGroupDragCancel = createAction 'TICKET_GROUP_DRAG_CANCEL'
    exports.ticketGroupDragUpdate = createAction 'TICKET_GROUP_DRAG_UPDATE'

    ## PROMO CODE ACTIONS ##
    exports.PROMO_CODE_ADD = 'PROMO_CODE_ADD'
    exports.addPromoCode = (payload, extra={}) ->
      next_id = getId('promo_code')
      _.assign {type: 'PROMO_CODE_ADD', payload: {id: next_id}}, extra

    exports.removePromoCode = createAction 'PROMO_CODE_REMOVE'
    exports.changePromoCode = createAction 'PROMO_CODE_CHANGE'
    exports.promoCodeAddTicketClass = createAction 'PROMO_CODE_TICKET_CLASS_ADD'
    exports.promoCodeRemoveTicketClass = createAction 'PROMO_CODE_TICKET_CLASS_REMOVE'

    ## ATTENDEE QUESTION ACTIONS ##
    exports.ATTENDEE_QUESTION_ADD = 'ATTENDEE_QUESTION_ADD'
    exports.addAttendeeQuestion = (payload, extra={}) ->
      next_id = getId('question')
      _.assign {type: 'ATTENDEE_QUESTION_ADD', payload: {id: next_id}}, extra

    exports.removeAttendeeQuestion = createAction 'ATTENDEE_QUESTION_REMOVE'
    exports.changeAttendeeQuestion = createAction 'ATTENDEE_QUESTION_CHANGE'
    exports.attendeeQuestionDragStart = createAction 'ATTENDEE_QUESTION_DRAG_START'
    exports.attendeeQuestionDragFinish = createAction 'ATTENDEE_QUESTION_DRAG_FINISH'
    exports.attendeeQuestionDragCancel = createAction 'ATTENDEE_QUESTION_DRAG_CANCEL'
    exports.attendeeQuestionDragUpdate = createAction 'ATTENDEE_QUESTION_DRAG_UPDATE'

    exports.addAttendeeQuestionChoice = createAction 'ATTENDEE_QUESTION_CHOICE_ADD'
    exports.changeAttendeeQuestionChoice = createAction 'ATTENDEE_QUESTION_CHOICE_CHANGE'
    exports.removeAttendeeQuestionChoice = createAction 'ATTENDEE_QUESTION_CHOICE_REMOVE'
    exports.attendeeQuestionChoiceDragStart = createAction 'ATTENDEE_QUESTION_CHOICE_DRAG_START'
    exports.attendeeQuestionChoiceDragUpdate = createAction 'ATTENDEE_QUESTION_CHOICE_DRAG_UPDATE'
    exports.attendeeQuestionChoiceDragFinish = createAction 'ATTENDEE_QUESTION_CHOICE_DRAG_FINISH'
    exports.attendeeQuestionChoiceDragCancel = createAction 'ATTENDEE_QUESTION_CHOICE_DRAG_CANCEL'

    exports
