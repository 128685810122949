module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<h3><span>' +
((__t = ( filename )) == null ? '' : __t) +
'</span> <a href="#" class="remove-link">&times;</a></h3><label><span>Caption</span><input type="text" name="caption[]" /></label>';

}
return __p
}