# Rich-text editor utilities
namespace 'Slzr.RichText', (exports, top) ->
  $ = Slzr.jQuery

  # Watches for updates we need to care about in the editor, and updates an associated
  # input with the contents
  class exports.Editor
    constructor: ->

      CKEDITOR.on 'instanceCreated', (evt) ->
        editor = evt.editor
        element = $(editor.element.$)

        # Remove title attribute from editable div
        #
        # This is a lower-priority event handler, because CKEditor sets the title
        # on all inline editors on a mode event with the default priority (10)
        editor.on 'mode', (evt) ->
          editor = evt.editor
          editable = editor.editable()
          editable.changeAttr 'title', '' if editable && editable.isInline()
        , null, null, 100

        # Update the underlying element, and trigger change events
        editor.on 'blur', ->
          old_val = element.val()
          new_val = editor.getData()
          if old_val != new_val
            element.val(new_val).trigger('change')

        editor.on 'change', ->
          element.trigger('slzr:richtext:change')

        if (form = element.closest('form'))[0]
          form.on 'submit', -> element.val editor.getData()

      # Initialize RTE on textarea[data-rich-text="true"]
      $ ->
        $('textarea[data-rich-text="inline"]').each ->
          config = {}

          if $(this).data('rich-text-toolbar')
            config.sharedSpaces =
              top: $(this).data('rich-text-toolbar')
          
          # EVNT-1900: set the title of the editor to the aria-label of the textarea if it exists
          label = $(this).attr('aria-label')
          config.title = label if label

          editor = CKEDITOR.inline(this, config)
          $(this).data('ckeditor-instance', editor)

        $('textarea[data-rich-text="true"]').each ->
          editor = CKEDITOR.replace(this)
          $(this).data('ckeditor-instance', editor)
