# Date utility functions

modulejs.define 'slzr/date_utils', ->
  ## Utility Functions ##

  # Returns true if the date portion of the two Date objects are the same
  sameDate: (a, b) ->
    a.getFullYear() == b.getFullYear() &&
    a.getMonth() == b.getMonth() &&
    a.getDate() == b.getDate()

  # Returns true if the time portion of the two Date objects are the same
  #
  # Returns false if either is null, but returns true if both are null.

  sameTime: (a, b) ->
    return true if !a && !b

    (a && b) && (
      a.getHours() == b.getHours() &&
      a.getMinutes() == b.getMinutes() &&
      a.getSeconds() == b.getSeconds()
    )

