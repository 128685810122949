import React from "react";
import ReactDOM from "react-dom";

import { CookieConsentPrompt } from "./components/cookie_consent_prompt.jsx";

// Created element hosting the interface
let element = null;

export function showConsentPrompt({ onUpdateConsentStatus }) {
  element = document.createElement("div");
  document.body.appendChild(element);

  ReactDOM.render(
    <CookieConsentPrompt onUpdateConsentStatus={onUpdateConsentStatus} />,
    element
  );
}

export function closeConsentPrompt() {
  // Unmount react and clean up
  if (element) {
    ReactDOM.unmountComponentAtNode(element);
    element.parentElement.removeChild(element);
    element = null;
  }
}
