modulejs.define 'registration/components/ticket_group',
  ['underscore', 'react', 'react-dnd', 'prop-types',
    'slzr/react/form_row', 'slzr/react/form_input_group', 'slzr/react/form_header',
    'registration/constants', 'registration/store/actions', 'registration/components/ticket_list',
    'registration/components/ticket_group_form'],
  (_, React, ReactDnD, PropTypes,
    FormRow, FormInputGroup, FormHeader,
    Constants, Actions, TicketList,
    TicketGroupForm) ->

    # Display the table listing ticket classes
    class TicketGroup extends React.Component
      @propTypes:
        header: PropTypes.bool
        tableHeader: PropTypes.bool
        ticketGroup: Actions.TicketGroupShape
        tickets: PropTypes.array
        onTicketChange: PropTypes.func
        onTicketDelete: PropTypes.func
        onToggleTicketEditor: PropTypes.func
        onGroupChange: PropTypes.func
        onGroupDelete: PropTypes.func
        onToggleTicketGroupEditor: PropTypes.func
        canMove: PropTypes.bool
        canRemove: PropTypes.bool
        openedEditors: PropTypes.array
        showGroupEditor: PropTypes.bool
        haveNoTickets: PropTypes.bool

      @defaultProps:
        tickets: []
        ticketGroup:
          id: null
          name: 'Default'
        header: true
        canMove: true
        canRemove: true
        settings: {}
        openedEditors: []
        showGroupEditor: false
        haveNoTickets: false

      groupChanged: (field_name, field_value, event) =>
        this.props.onGroupChange?(this.props.ticketGroup.id, field_name, field_value, event)

      deleteTicket: (event) =>
        event.preventDefault()
        this.props.onGroupDelete?(this.props.ticketGroup.id, event)

      toggleEditForm: (event) =>
        event.preventDefault()
        this.props.onToggleTicketGroupEditor?(this.props.ticketGroup.id)

      closeEditForm: (event) =>
        event.preventDefault()
        this.props.onToggleTicketGroupEditor?(this.props.ticketGroup.id, false)

      render: ->
        header = if this.props.header
          group_form = if this.props.showGroupEditor
            `<TicketGroupForm ticketGroup={this.props.ticketGroup}
                              onChange={this.groupChanged}
                              onClose={this.closeEditForm}/>`

          `<tbody>
            <tr className="ticket-group-header-row">
              <td width="89%" colSpan="4" className="ticket-group-header">
                {this.props.connectDragSource(<i className="fa fa-bars drag-handle" aria-hidden="true"></i>)}
                {this.props.ticketGroup.name}
              </td>
              <td width="11%">
                <div className="text-right action_icon_button">
                  <a href="#"
                     onClick={this.toggleEditForm}
                     title="Edit Ticket"
                     className="edit_button"><i className="localist-icon-edit-small"></i></a>
                  <a href="#"
                     onClick={this.deleteTicket}
                     title="Remove Ticket"
                     className="deletex_button"><i className="localist-icon-trash-small"></i></a>
                </div>
              </td>
            </tr>
            {group_form}
          </tbody>`

        @props.connectDropTarget `<div className="ticket-group-wrapper">
            <TicketList header={this.props.tableHeader}
                        groupHeader={header}
                        debug={this.props.debug}
                        tickets={this.props.tickets}
                        haveNoTickets={this.props.haveNoTickets}
                        openedEditors={this.props.openedEditors}
                        settings={this.props.settings}
                        connectGroupDragPreview={this.props.connectDragPreview}
                        onToggleTicketEditor={this.props.onToggleTicketEditor}
                        onAddTicketClass={this.props.onAddTicketClass}
                        onChange={this.props.onTicketChange}
                        onDelete={this.props.onTicketDelete}
                        onDragStart={this.props.onTicketDragStart}
                        onDragFinish={this.props.onTicketDragFinish}
                        onDragCancel={this.props.onTicketDragCancel}
                        onDragUpdate={this.props.onTicketDragUpdate} />
        </div>`

    # Drag source configuration
    dragSpec =
      beginDrag: (props, monitor, component) ->
        item = { id: props.ticketGroup.id }
        props.onGroupDragStart?(item)
        item

      endDrag: (props, monitor, component) ->
        if monitor.didDrop()
          result = monitor.getDropResult()
          props.onGroupDragFinish?(item: {id: props.ticketGroup.id}, target_position: result.position)
        else
          props.onGroupDragCancel?(id: props.ticketGroup.id)

      canDrag: (props, monitor) ->
        props.canMove != false

    dragCollect = (connect, monitor) ->
      isDragging: monitor.isDragging()
      connectDragSource: connect.dragSource()
      connectDragPreview: connect.dragPreview()

    # Drop target configuration
    dropSpec =
      drop: (props, monitor, component) ->
        switch monitor.getItemType()
          when Constants.DRAG_TYPE_TICKET_GROUP
            {position: if props.ticketGroup then props.ticketGroup.position else 0}
          when Constants.DRAG_TYPE_TICKET_CLASS
            {position: 'top', ticket_group_id: props.ticketGroup?.id}


      hover: (props, monitor, component) ->
        switch monitor.getItemType()
          when Constants.DRAG_TYPE_TICKET_GROUP
            if monitor.canDrop() && monitor.isOver()
              item = monitor.getItem()
              if item.id != props.ticketGroup?.id
                props.onGroupDragUpdate?(item, if props.ticketGroup then props.ticketGroup.position else 0)
          when Constants.DRAG_TYPE_TICKET_CLASS
            if monitor.canDrop() && monitor.isOver(shallow: true)
              item = monitor.getItem()
              props.onTicketDragUpdate?(item, 'top', props.ticketGroup?.id)

    dropCollect = (connect, monitor) ->
      hovered: monitor.isOver()
      connectDropTarget: connect.dropTarget()

    TicketGroup = ReactDnD.DragSource(Constants.DRAG_TYPE_TICKET_GROUP, dragSpec, dragCollect)(TicketGroup)
    ReactDnD.DropTarget([Constants.DRAG_TYPE_TICKET_GROUP, Constants.DRAG_TYPE_TICKET_CLASS], dropSpec, dropCollect)(TicketGroup)

