modulejs.define 'registration/components/checkout/quantity_select',
  ['underscore', 'react', 'prop-types'],
  (_, React, PropTypes) ->

    QuantitySelect = (props) ->
      if props.min > props.max
        null
      else if props.max == 0
        null
      else
        options = (`<option value={i} key={i}>{i}</option>` for i in [props.min..props.max])

        `<div className="em-select-input">
          <select {...props}>
            <option value={0}>0</option>
            {options}
          </select>
          <i className="fas fa-sort-down"></i>
        </div>`

    QuantitySelect.propTypes =
      min: PropTypes.number
      max: PropTypes.number

    QuantitySelect.defaultProps =
      min: 1
      max: 20

    QuantitySelect