module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="box_content">\n  <h2>' +
((__t = ( name )) == null ? '' : __t) +
'</h2>\n  <p><a href="' +
((__t = ( view_url )) == null ? '' : __t) +
'">View ' +
((__t = ( type )) == null ? '' : __t) +
' Page</a> | <a href="' +
((__t = ( admin_url )) == null ? '' : __t) +
'">Edit ' +
((__t = ( type )) == null ? '' : __t) +
'</a></p>\n</div>\n\n';

}
return __p
}