/**
 * Cookie Consent manager
 */

import { ConsentStatus, getConsentStatus, setConsentStatus } from "./api.js";
import { showConsentPrompt, closeConsentPrompt } from "./ui.jsx";

function setupConsentPrompt() {
  showConsentPrompt({
    onUpdateConsentStatus: (status) => {
      setConsentStatus(status);
      closeConsentPrompt();
    }
  });
}

// Initialization code, publish the consent status to LOCALIST_SETTINGS
LOCALIST_SETTINGS.cookie_consent_status = getConsentStatus();

// Display the consent dialog if the user hasn't acknowledged it.
if (
  LOCALIST_SETTINGS.show_cookie_consent_prompt &&
  LOCALIST_SETTINGS.cookie_consent_status === ConsentStatus.UNACCEPTED
) {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", setupConsentPrompt);
  } else {
    setupConsentPrompt();
  }
}
