# Code to activate the React-based TokenInput for a specific input
modulejs.define 'slzr/ui/token_tag_input',
  ['jquery', 'underscore', 'react', 'react-dom',
   'slzr/react/token_input']
  ($, _, React, ReactDOM,
   TokenInput) ->

    # Splits a string on commas, unless the comma is in double quotes
    #
    # Returns an array of terms, without any quoting
    splitTagList = (str) ->
      in_quote = false
      was_quoted = false
      term_start = 0
      terms = []

      for i in [0..str.length]
        if str[i] == '"' # If it's a double quote
          was_quoted = true unless in_quote
          in_quote = !in_quote

        else if !in_quote && str[i] == ','
          found_term = str.substr(term_start, i - term_start).trim()
          if was_quoted # strip leading/trailing quotes
            was_quoted = false
            found_term = found_term.replace(/^"|"$/g, '')

          terms.push found_term
          term_start = i + 1

      if term_start <= str.length
        terms.push str.substr(term_start).trim()

      terms

    class TokenTagInput
      # Default options
      default_options:
        name: 'tags'
        initial_list: []
        initial_value: ''
        placeholder: 'Type something and press Enter'
        autocomplete_suggest_url: null
        # Callback when item is added
        on_item_added: null
        # Callback when item is removed
        on_item_removed: null

      # Element to attach react to
      element: null

      constructor: (element, options={}) ->
        @element = $ element
        
        # Convert data attribute options to the appropriate value
        data_options = {}
        if options.tokenInputId
          data_options.id = options.tokenInputId
          delete options.tokenInputId
        if options.tokenInputInitial
          data_options.initial_value = options.tokenInputInitial
          delete options.tokenInputInitial
        if options.tokenInputPlaceholder
          data_options.placeholder = options.tokenInputPlaceholder
          delete options.tokenInputPlaceholder
        if options.tokenInputName
          data_options.name = options.tokenInputName
          delete options.tokenInputName
        # assumes an array of objects being used if key exists and is true
        if options.hasOwnProperty("tokenInputIsSubmittedString")
          data_options.is_submitted_string = options.tokenInputIsSubmittedString
          delete options.tokenInputIsSubmittedString
        else
          # assumes comma delimited string (original implementation)
          data_options.is_list = true
        
        @options = _.extend {}, @default_options, data_options, options

        @options.initial_list = []

        if typeof(@options.initial_value) == 'string'
          @options.initial_list = splitTagList(@options.initial_value) unless @options.initial_value.isBlank()
        else
          @options.initial_list = data_options.initial_value unless data_options.initial_value.length == 0

        # Attach react component
        ReactDOM.render React.createElement(TokenInput,
          placeholder:            @options.placeholder,
          autocompleteSuggestURL: @options.autocomplete_suggest_url,
          name:                   @options.name,
          items:                  @options.initial_list
          onItemAdded:            @options.on_item_added
          onItemRemoved:          @options.on_item_removed
          isSubmittedString:      @options.is_submitted_string
          id:                     @options.id
        ), @element.get(0)