import React from "react";
import PropTypes from "prop-types";
import { ConsentStatus } from "../api";

export function PolicyPanel({ onUpdateConsentStatus, onSettingsClick }) {
  return (
    <div className="c3d-cookie-consent-prompt--panel c3d-cookie-consent-prompt--panel--policy">
      <div className="c3d-cookie-consent-prompt--description">
        <h1 id="c3d-cookie-consent-prompt-title">Concept3D Cookie Policy</h1>
        <p id="c3d-cookie-consent-prompt-description">
          We use cookies to understand how you use our site and to improve your
          experience. By continuing to use our site, you consent to our Privacy
          Policy, including the use of cookies.{" "}
          <a
            href="https://concept3d.com/concept3d-privacy-policy/"
            target="_blank"
          >
            Learn More »
          </a>
        </p>
      </div>
      <div className="c3d-cookie-consent-prompt--actions">
        <button
          className="c3d-cookie-consent-prompt--button c3d-cookie-consent-prompt--button-primary"
          onClick={() => onUpdateConsentStatus(ConsentStatus.ACCEPTED)}
        >
          Accept All Cookies
        </button>
        <button
          className="c3d-cookie-consent-prompt--button"
          onClick={onSettingsClick}
        >
          Settings
        </button>
      </div>
    </div>
  );
}

PolicyPanel.propTypes = {
  onUpdateConsentStatus: PropTypes.func,
  onSettingsClick: PropTypes.func
};
