modulejs.define 'registration/components/checkout/ticket_preview_list',
  ['react', 'prop-types', 'moment', 'registration/utilities',
    'slzr/react/form_input_group', 'slzr/react/form_row'],
  (React, PropTypes, moment, Utilities,
    FormInputGroup, FormRow) ->

    class TicketPreviewList extends React.Component
      @propTypes:
        ticketGroups: PropTypes.array
        dateFormat: PropTypes.string
        timeFormat: PropTypes.string

      @defaultProps:
        ticketGroups: []
        dateFormat: "dddd, MMMM Do YYYY"
        timeFormat: "h:mma"

      render: ->
        rows = for item in this.props.ticket_class_groups
          group = item[0]
          ticket_classes = item[1]

          this.renderTicketGroup(group, ticket_classes)

        `          <div className="widget-content">
<form className="form_box form-sidebar">
          {rows}
        </form></div>`

      renderTicketGroup: (group, ticket_classes) =>
        my = this
        currency = this.props.currency

        if group
          header = `<FormInputGroup className="ticket-group-header"><FormRow title={group.name} /></FormInputGroup>`
        else
          header = null

        rows = for ticket_class in ticket_classes
          amount = if ticket_class.donation
            if ticket_class.min_price > 0 && ticket_class.max_price > 0
              Utilities.formatCurrency(ticket_class.min_price, currency) + " - " + Utilities.formatCurrency(ticket_class.max_price, currency)
            else if ticket_class.max_price > 0
              "MAX " + Utilities.formatCurrency(ticket_class.max_price, currency)
            else
              Utilities.formatCurrency(ticket_class.min_price, currency) + " +"
          else
            if ticket_class.price == 0 then 'Free' else Utilities.formatCurrency(ticket_class.price, currency)

          `<label className="ticket_class" key={ticket_class.id}>
            <span className="leftside">{ticket_class.name}</span>
            <span className="rightside ticket_price">
              {amount}
            </span>

            <div className="leftside">
              {ticket_class.description && (<p className="ticket_description">{ticket_class.description}</p>)}
              {ticket_class.not_yet_available && <p className="ticket_notes">Available Starting {my._formatDateTime(ticket_class.availability_start)}</p>}
              {ticket_class.no_longer_available && <p className="ticket_notes">Sales Ended {my._formatDate(ticket_class.availability_finish)}</p>}
              {ticket_class.available_now && ticket_class.availability_finish && <p className="ticket_notes">On Sale Until {my._formatDateTime(ticket_class.availability_finish)}</p>}
            </div>
          </label>`

        `<div key={group ? group.id : 'default'}>
          {header}

          <FormInputGroup className="ticket-group-wrap">
            {rows}
          </FormInputGroup>
        </div>`

      # Format a date as a string
      _formatDate: (time) -> moment(time).format(this.props.dateFormat)

      # Format a date and time as a string
      _formatDateTime: (time) -> moment(time).format(this.props.dateFormat + ' ' + this.props.timeFormat)