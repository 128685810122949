modulejs.define 'slzr/react/token_input',
  ['react', 'underscore', 'prop-types', 'jquery', 'slzr/react/selected-filter-tag'],
  (React, _, PropTypes, $, SelectedFilterTag) ->

    # Handles a multi-valued input, where each value is presented as a token bubble.
    #
    # Also supports being hooked up to a jquery autocomplete
    class TokenInput extends React.Component
      @propTypes =
        items: PropTypes.array
        autocompleteSuggestURL: PropTypes.string
        name: PropTypes.string
        placeholder: PropTypes.string
        onItemAdded: PropTypes.func
        onItemRemoved: PropTypes.func
        isSubmittedString: PropTypes.bool
        id: PropTypes.string

      @defaultProps =
        items: []
        autocompleteSuggestURL: null
        name: 'tags'
        placeholder: 'Type something and press Enter'
        onItemAdded: null
        onItemRemoved: null
        isSubmittedString: true

      constructor: (props) ->
        super props

        this.state =
          items: props.items

      # If the props change, reset the
      UNSAFE_componentWillReceiveProps: (nextProps) ->
        if this.props.items != nextProps.items
          this.setState items: nextProps.items


      # Initialize autocomplete here, if it's requested
      componentDidMount: ->
        this.autocompleter = Slzr.MultiTagAutocomplete this.inputEl, this.props.autocompleteSuggestURL,
          onSelect: this.onTagSelect
          attachTo: this.wrapperEl
          resizeMenu: (element) =>
            element.outerWidth $(this.wrapperEl).outerWidth()

      # Remove autocomplete here, if it's requested
      componentWillUnmount: ->
        this.autocompleter.destroy()

      # Return true if +value+ is in the current selection
      #
      # Value can be either a string or an object with {label, value}. It will match either strings or objects
      # in the current list with a matching value (either the string, or from the value property)
      _isItemSelected: (value) =>
        my = this
        new_value = my.valueFromItem(value)
        _.any this.state.items, (item) -> my.valueFromItem(item) == new_value

      # Tag selected from dropdown
      onTagSelect: (value) =>
        unless @_isItemSelected(value)
          new_items = this.state.items.slice()

          if @props.isSubmittedString || typeof(value) == 'object'
            new_items.push(value)
          else
            new_value = {label: value, value: value, new: true}
            new_items.push(new_value)

          $(this.inputEl).trigger('slzr:token:change')
          this.setState items: new_items
          @props.onItemAdded?(value, new_items)

        true

      # Override enter key presses in the input field to add the current item
      onInputKeyPress: (event) =>
        if event.key == 'Enter'
          trimmed_value = event.target.value.trim()
          unless trimmed_value == ''
            @onTagSelect trimmed_value
            event.target.value = ''

            # Hide the autocomplete
            $(event.target).data('autocomplete').close()

          event.preventDefault()

      # Remove an item from the index
      onRemoveItem: (index, event) ->
        event.preventDefault()

        # Modify a copy of the items array
        $(this.inputEl).trigger('slzr:token:change')
        new_items = this.state.items.slice()
        new_items.splice(index, 1)
        @props.onItemRemoved?(this.state.items[index], new_items)
        this.setState items: new_items
      
      listFromItems: ->
        # render hidden comman delimited list param
        selected_item_list = ((if item.indexOf(',') != -1 then '"' + this.valueFromItem(item) + '"' else this.valueFromItem(item)) for item in this.state.items).join(', ')
        `<input type="hidden" name={this.props.name} readOnly={true} value={selected_item_list} />`
      
      arrayFromItems: ->
        # render hidden array param
        for item, index in this.state.items
          if item.hasOwnProperty("new")
            # Create a hash in the array params called new_records that sets
            # key to the index of the item and
            # value to the name of the item
            name = this.props.name + "[new_records][]"
            `<input type="hidden" key={index} name={name} readOnly={true} value={this.labelFromItem(item)} />`
          else
            `<input type="hidden" key={index} name={this.props.name} readOnly={true} value={this.valueFromItem(item)} />`

      labelFromItem: (item) ->
        if typeof(item) == 'string'
          item
        else
          item.label
      
      valueFromItem: (item) ->
        if typeof(item) == 'string'
          item
        else
          item.value

      _setInputRef: (element) => this.inputEl = element
      _setWrapperRef: (element) => this.wrapperEl = element
      
      render: ->        
        selected_items = for item, index in this.state.items
          `<SelectedFilterTag
            key={item.value}
            onRemove={this.onRemoveItem.bind(this, index)}
            name={this.labelFromItem(item)}
          >
            {this.labelFromItem(item)}
          </SelectedFilterTag>`

        hidden_fields = if this.props.isSubmittedString
          this.listFromItems()
        else
          this.arrayFromItems()
        
        `<div className="form-input-token" ref={this._setWrapperRef}>
          <div className="tag_list picked_item_inline">
            {selected_items}

            <input type="text"
                   className="tag_input"
                   ref={this._setInputRef}
                   onKeyPress={this.onInputKeyPress}
                   placeholder={this.props.placeholder}
                   id={this.props.id} />
          </div>

          {hidden_fields}
        </div>`
