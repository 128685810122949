# Facebook convenience wrapper

namespace 'Slzr', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  class exports.Facebook
    @streamPublish: (data) ->
      data.method = 'feed'
      FB.getLoginStatus (response) ->
        FB.ui data if response.authResponse

    # Perform a facebook login, with the options specified.
    #
    # Options:
    #   scope: array of extended scopes to request (default: [email])
    @login: (options_or_callback, callback) ->
      if typeof(options_or_callback) == 'function'
        callback = options_or_callback
        options = {}
      else
        options = options_or_callback
        
      # Defaults
      options = $.extend {}, scope: ['public_profile', 'email'], options

      # Require email permission, always
      options.scope.push 'email' if $.inArray('email', options.scope) == -1

      FB.login (response) ->
        hideIbox() if response.authResponse
        callback?(response)
      , scope: options.scope.join(',')

    # Log in without any extended permissions
    @oldLogin: (callback) ->
      FB.login (response) ->
        console?.log?("logged in without permissions:", response)
        callback?(response)

    # Revoke authorization
    @revoke: (callback) ->
      FB.api '/me/permissions', 'delete', (response) ->
        console?.log?('revoked authorization', response)
        callback?(response)

    # Normalize permissions between graph v1 and v2
    #
    # Expects the response object from the API call to /me/permissions
    @normalizePermissions = (response) ->
      if response.data[0].installed?
        # API 1
        permission for permission, value of response.data[0]
      else
        # API 2
        item.permission for item in response.data

  top.postFBFeedItem = exports.Facebook.streamPublish

  # Export the global variable assigned to `top` when built with webpack
  if module?.exports?
    module.exports.postFBFeedItem = top.postFBFeedItem

#  $(document).onAction 'facebook-login', (evt) ->
#    previous_url = $(this).data('previous-url')
#
#    Slzr.Facebook.login (response) ->
#      if response.authResponse
#        window._lightbox_fb_login = true
#        location.href = "/auth/with/facebook/callback?origin=#{escape previous_url}"
#
#  $(document).onAction 'facebook-logout', (event) ->
#    FB.logout ->
#      location.href = "/auth/logout"
